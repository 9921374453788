import React from 'react';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button} from '@mui/material';
import Colors from "../../components/ui/Colors";
import NumberFormat from "react-number-format";
import swal from "sweetalert";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import {getCreditRiskWeights, setCreditRiskWeights} from "../../utilityFunctions/OpenCardUtil";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const weightsUpdateRequest = {};

export default function RiskWeightsUpdate(props) {

    const {loginUser} = props;

    const [loading, setLoading] = React.useState(false);

    const [dependents, setDependents] = React.useState("");
    const [employmentType, setEmploymentType] = React.useState("");
    const [maritalStatus, setMaritalStatus] = React.useState("");
    const [monthlyExpenses, setMonthlyExpenses] = React.useState("");
    const [monthlyIncome, setMonthlyIncome] = React.useState("");
    const [otherIncome, setOtherIncome] = React.useState("");
    const [residenceType, setResidenceType] = React.useState("");
    const [stratum, setStratum] = React.useState("");
    const [studyLevel, setStudyLevel] = React.useState("");
    const [totalLiabilities, setTotalLiabilities] = React.useState("");
    const [totalAssets, setTotalAssets] = React.useState("");

    React.useEffect(() => {
        initalValues();
    }, []);

    const initalValues = async () => {
        
        setLoading(true);
        const response = await getCreditRiskWeights(loginUser.jwtToken);
        setLoading(false);

        if(!response){
            return;
        }

        setDependents(response.dependents);
        setEmploymentType(response.employmentType);
        setMaritalStatus(response.maritalStatus);
        setMonthlyExpenses(response.monthlyExpenses);
        setMonthlyIncome(response.monthlyIncome);
        setOtherIncome(response.otherIncome);
        setResidenceType(response.residenceType);
        setStratum(response.stratum);
        setStudyLevel(response.studyLevel);
        setTotalLiabilities(response.totalLiabilities);
        setTotalAssets(response.totalAssets);

        weightsUpdateRequest.dependents = response.dependents;
        weightsUpdateRequest.employmentType = response.employmentType;  
        weightsUpdateRequest.maritalStatus = response.maritalStatus;
        weightsUpdateRequest.monthlyExpenses = response.monthlyExpenses;
        weightsUpdateRequest.monthlyIncome = response.monthlyIncome;
        weightsUpdateRequest.otherIncome = response.otherIncome;
        weightsUpdateRequest.residenceType = response.residenceType;
        weightsUpdateRequest.stratum = response.stratum;
        weightsUpdateRequest.studyLevel = response.studyLevel;
        weightsUpdateRequest.totalLiabilities = response.totalLiabilities;
        weightsUpdateRequest.totalAssets = response.totalAssets;
    };

    const dependentsChange = (e) => {
        setDependents(e.floatValue);
        weightsUpdateRequest.dependents = e.floatValue;
    };

    const employmentTypeChange = (e) => {
        setEmploymentType(e.floatValue);
        weightsUpdateRequest.employmentType = e.floatValue;
    };

    const maritalStatusChange = (e) => {
        setMaritalStatus(e.floatValue);
        weightsUpdateRequest.maritalStatus = e.floatValue;
    };

    const monthlyExpensesChange = (e) => {
        setMonthlyExpenses(e.floatValue);
        weightsUpdateRequest.monthlyExpenses = e.floatValue;
    };

    const monthlyIncomeChange = (e) => {
        setMonthlyIncome(e.floatValue);
        weightsUpdateRequest.monthlyIncome = e.floatValue;
    };

    const otherIncomeChange = (e) => {
        setOtherIncome(e.floatValue);
        weightsUpdateRequest.otherIncome = e.floatValue;
    };

    const residenceTypeChange = (e) => {
        setResidenceType(e.floatValue);
        weightsUpdateRequest.residenceType = e.floatValue;
    };

    const stratumChange = (e) => {
        setStratum(e.floatValue);
        weightsUpdateRequest.stratum = e.floatValue;
    };

    const studyLevelChange = (e) => {
        setStudyLevel(e.floatValue);
        weightsUpdateRequest.studyLevel = e.floatValue;
    };

    const totalLiabilitiesChange = (e) => {
        setTotalLiabilities(e.floatValue);
        weightsUpdateRequest.totalLiabilities = e.floatValue;
    };

    const totalAssetsChange = (e) => {
        setTotalAssets(e.floatValue);
        weightsUpdateRequest.totalAssets = e.floatValue;
    };

    const updateRiskWeights = async () => {

        const sumOfValues = await checkWeights();

        if(sumOfValues !== 1){
            swal({
                title: "Error en la actualización",
                text: `La suma de los pesos debe ser igual a 100%. La suma actual es ${sumOfValues * 100}%`,
                icon: "error",
                button: "Ok",
            });
            return;
        };

        setLoading(true);
        const response = await setCreditRiskWeights(weightsUpdateRequest, loginUser.jwtToken);
        setLoading(false);
        
        if(!response){
            swal({
                title: "Error en la actualización",
                icon: "error",
                button: "Ok",
            })
            return;
        }

        swal({
            title: "Pesos actualizados",
            icon: "success",
            button: "Ok",
        });
    };

    const checkWeights = () => {
        const sumOfValues = Object.values(weightsUpdateRequest).reduce((sum, value) => {
            return sum + (typeof value === 'number' ? value : 0);
        }, 0);

        return sumOfValues;

        
    };


    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                    height: "90%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: "1.5rem"},
                }}
            >
                {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                            <NumberFormat
                                variant="standard"
                                style={{width: "100%", height: "90%"}}
                                name="content"
                                customInput={TextField}
                                // prefix={'$ '}
                                // suffix={' meses'}
                                type="content"
                                label={<Typography variant="body2">Peso Dependientes</Typography>}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                autoComplete="off"
                                value={dependents}
                                onValueChange={(values) => {
                                    dependentsChange(values);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <NumberFormat
                                variant="standard"
                                style={{width: "100%", height: "90%"}}
                                name="content"
                                customInput={TextField}
                                // prefix={'$ '}
                                // suffix={' meses'}
                                type="content"
                                label={<Typography variant="body2">Peso Empleo</Typography>}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                autoComplete="off"
                                value={employmentType}
                                onValueChange={(values) => {
                                    employmentTypeChange(values);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <NumberFormat
                                variant="standard"
                                style={{width: "100%", height: "90%"}}
                                name="content"
                                customInput={TextField}
                                // prefix={'$ '}
                                // suffix={' meses'}
                                type="content"
                                label={<Typography variant="body2">Peso Estado Marital</Typography>}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                autoComplete="off"
                                value={maritalStatus}
                                onValueChange={(values) => {
                                    maritalStatusChange(values);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <NumberFormat
                                variant="standard"
                                style={{width: "100%", height: "90%"}}
                                name="content"
                                customInput={TextField}
                                // prefix={'$ '}
                                // suffix={' meses'}
                                type="content"
                                label={<Typography variant="body2">Peso Gastos</Typography>}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                autoComplete="off"
                                value={monthlyExpenses}
                                onValueChange={(values) => {
                                    monthlyExpensesChange(values);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <NumberFormat
                                variant="standard"
                                style={{width: "100%", height: "90%"}}
                                name="content"
                                customInput={TextField}
                                // prefix={'$ '}
                                // suffix={' meses'}
                                type="content"
                                label={<Typography variant="body2">Peso Ingresos</Typography>}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                autoComplete="off"
                                value={monthlyIncome}
                                onValueChange={(values) => {
                                    monthlyIncomeChange(values);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <NumberFormat
                                variant="standard"
                                style={{width: "100%", height: "90%"}}
                                name="content"
                                customInput={TextField}
                                // prefix={'$ '}
                                // suffix={' meses'}
                                type="content"
                                label={<Typography variant="body2">Peso Otros Ingresos</Typography>}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                autoComplete="off"
                                value={otherIncome}
                                onValueChange={(values) => {
                                    otherIncomeChange(values);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <NumberFormat
                                variant="standard"
                                style={{width: "100%", height: "90%"}}
                                name="content"
                                customInput={TextField}
                                // prefix={'$ '}
                                // suffix={' meses'}
                                type="content"
                                label={<Typography variant="body2">Peso Residencia</Typography>}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                autoComplete="off"
                                value={residenceType}
                                onValueChange={(values) => {
                                    residenceTypeChange(values);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <NumberFormat
                                variant="standard"
                                style={{width: "100%", height: "90%"}}
                                name="content"
                                customInput={TextField}
                                // prefix={'$ '}
                                // suffix={' meses'}
                                type="content"
                                label={<Typography variant="body2">Peso Estrato</Typography>}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                autoComplete="off"
                                value={stratum}
                                onValueChange={(values) => {
                                    stratumChange(values);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <NumberFormat
                                variant="standard"
                                style={{width: "100%", height: "90%"}}
                                name="content"
                                customInput={TextField}
                                // prefix={'$ '}
                                // suffix={' meses'}
                                type="content"
                                label={<Typography variant="body2">Peso Estudio</Typography>}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                autoComplete="off"
                                value={studyLevel}
                                onValueChange={(values) => {
                                    studyLevelChange(values);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <NumberFormat
                                variant="standard"
                                style={{width: "100%", height: "90%"}}
                                name="content"
                                customInput={TextField}
                                // prefix={'$ '}
                                // suffix={' meses'}
                                type="content"
                                label={<Typography variant="body2">Peso Pasivos</Typography>}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                autoComplete="off"
                                value={totalLiabilities}
                                onValueChange={(values) => {
                                    totalLiabilitiesChange(values);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <NumberFormat
                                variant="standard"
                                style={{width: "100%", height: "90%"}}
                                name="content"
                                customInput={TextField}
                                // prefix={'$ '}
                                // suffix={' meses'}
                                type="content"
                                label={<Typography variant="body2">Peso Activos</Typography>}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                autoComplete="off"
                                value={totalAssets}
                                onValueChange={(values) => {
                                    totalAssetsChange(values);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Button onClick={updateRiskWeights} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                Cargar
                            </Button>
                        </Grid>
                    </Grid>
                }
            </Box>
        </Box>
    )
}
