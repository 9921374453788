import React from 'react';
import { persistBankAccount } from '../../utilityFunctions/MarketPlaceUtil';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button} from '@mui/material';
import TextField from '@mui/material/TextField';
import Colors from "../../components/ui/Colors";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import {bankCodes} from "../../Api";
import swal from "sweetalert";


const bankAcountObject = {
    username: "",
    bankAccount: "",
    bankCode: "",
    bankAccountTypeString: "",
    bankAccountType: 1,
};

const acounTypesArray = ["Ahorros", "Corriente"];

export default function CreateBankAccount(props) {

    const{loginUser} = props;

    React.useEffect(() => {
        bankArray();
    }, []);

    const [bankAccount, setBankAccount] = React.useState();
    const [bankCode, setBankCode] = React.useState();
    const [bankAccountType, setBankAccountType]= React.useState();
    const [accountCertification, setAccountCertification] = React.useState();
    const [idCopy, setIdCopy] = React.useState();

    const [bank, setBank] = React.useState();

    const bankArray = () => {
        setBank(
            {
                options: bankCodes,
                getOptionLabel: (option) => option.bank,
            }
        );
    };
    
    const bankAccountChange = (e) => {
        setBankAccount(e.target.value);
        bankAcountObject.bankAccount = e.target.value;
    };

    const bankCodeChange = (e) => {
        setBankCode(e.target.value);
        bankAcountObject.bankCode = e.target.value;
    };

    const bankAccountTypeChange = (e) => {
        setBankAccountType(e.target.value);
        bankAcountObject.bankAccountTypeString = e.target.value;
    };

    const accountCertificationChange = (e) => {
        
        setAccountCertification(e.target.files[0]);
        setCertificationBox(true);
    }

    const idCopyChange = (e) => {
        setIdCopy(e.target.files[0]);
        setIdBox(true);
    }

    const onChangeAutocomplete = (event, value) => {
        console.log(value);
        bankAcountObject.bankCode = value.bank

    };
    
    const newBankAccount = async () => {

        if(!accountCertification || !idCopy || !bankAcountObject.bankAccount || !bankAcountObject.bankAccountTypeString || !bankAcountObject.bankCode){
            
            swal({
                title: "Datos Incompletos",
                text: "Debes cargar todos los datos, incuyendo los documentos",
                icon: "info",
                button: "Entiendo",
            });
            return; 
        }

        bankAcountObject.username = loginUser.accountId;

        console.log(bankAcountObject)

        const formData  = new FormData();

        const json = JSON.stringify(bankAcountObject);

        const accountObject = new Blob([json], {
            type: 'application/json'
            // type: 'application/json'
        });

        formData.append("accountObject", accountObject);

        formData.append("accountCertification", accountCertification);
        formData.append("idCopy", idCopy);

        const bankAccountResult = await persistBankAccount(formData, loginUser.jwtToken);

        if(!bankAccountResult){
            swal({
                title: "No Creada",
                text: "No se creó la cuenta bancaria, vuelve a intentarlo",
                icon: "error",
                button: "Entiendo",
            });
            return; 
        }

        swal({
            title: "Cuenta Creada",
            text: "Cuenta creada. Vamos a revisar los documentos que cargaste para aprobarla",
            icon: "success",
            button: "Súper",
        });

    };

    const [certificationBox, setCertificationBox] = React.useState(false);
    const [cidBox, setIdBox] = React.useState(false);

    const closeCertBox = () => {
        setCertificationBox(false);
    };

    const closeIdBox = () => {
        setIdBox(false);
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                    height: "100%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                }}
            >
                <Grid container direction="row" spacing={2} sx={{width: { xs: "95%", sm: "80%", md: "40%"}}}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Autocomplete
                            sx={{width: "100%"}}
                            {...bank}
                            fullWidth={true}
                            id="bank"
                            clearOnEscape
                            autoComplete = {true}
                            noOptionsText = {"Banco no existe"}
                            // sx={{ width: 600 }}
                            onChange={onChangeAutocomplete}
                            // onInputChange={onChange}
                            renderInput={(params) => (
                                <TextField {...params} label={<Typography variant="body2">Banco</Typography>} variant="standard" />
                            )}
                        /> 
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="bankCode"
                            name="bankCode"
                            label={<Typography variant="body2">Banco</Typography>}
                            value={bankCode}
                            onChange={(e)=> bankCodeChange(e)}
                            autoComplete="off"
                        />
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="bankAccount"
                            name="bankAccount"
                            label={<Typography variant="body2">Número de cuenta</Typography>}
                            value={bankAccount}
                            onChange={(e)=> bankAccountChange(e)}
                            autoComplete="off"
                        />
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="bankAccountType"
                            name="bankAccountType"
                            label={<Typography variant="body2">Tipo de cuenta</Typography>}
                            value={bankAccountType}
                            onChange={(e)=> bankAccountTypeChange(e)}
                            autoComplete="off"
                        />
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            sx={{textAlign: "start"}}
                            variant="standard"
                            fullWidth={true}
                            id="bankAccountType"
                            name="bankAccountType"
                            select
                            label={<Typography variant="body2">Tipo de cuenta</Typography>}
                            value={bankAccountType}
                            onChange={(e)=>bankAccountTypeChange(e)}
                            autoComplete="off"
                        >
                            {acounTypesArray.map((typeEl) => (
                                <MenuItem key={typeEl} value={typeEl}>
                                    {typeEl}
                                </MenuItem>
                            ))}
                        </TextField> 
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Button
                            sx={{marginTop: "2rem", backgroundColor: "none", color: "gray", fontSize: "1rem", fontWeight: "bold"}}
                            // variant="contained"
                            component="label"
                            >
                            Certificación
                            <input
                                type="file"
                                hidden
                                onChange={(event) => {
                                    console.log(event.target.files[0]);
                                    accountCertificationChange(event);
                                }}
                            />
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Button
                            sx={{marginTop: "2rem", backgroundColor: "none", color: "gray", fontSize: "1rem", fontWeight: "bold"}}
                            // variant="contained"
                            component="label"
                            >
                            Identificación
                            <input
                                type="file"
                                hidden
                                onChange={(event) => {
                                    console.log(event.target.files[0]);
                                    idCopyChange(event);
                                }}
                            />
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Button onClick={newBankAccount} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                            Crear
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Dialog open={certificationBox} onClose={closeCertBox}>
                <DialogContent>
                {accountCertification ? 
                <Box 
                    component="img"
                    sx={{
                    height: "90%",
                    width: "100%",
                    justifyContent: "center",
                    }}
                    alt="Foto"
                    src={URL.createObjectURL(accountCertification)}
                >
                </Box>
                :
                <></>
                }
                </DialogContent>
                <DialogActions sx={{display: "flex", justifyContent: "center"}}>
                    <Button onClick={closeCertBox} variant="contained" sx={{backgroundColor: Colors.primary}}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={cidBox} onClose={closeIdBox}>
                <DialogContent>
                {idCopy ? 
                <Box 
                    component="img"
                    sx={{
                    height: "90%",
                    width: "100%",
                    justifyContent: "center",
                    }}
                    alt="Foto"
                    src={URL.createObjectURL(idCopy)}
                >
                </Box>
                :
                <></>
                }
                </DialogContent>
                <DialogActions sx={{display: "flex", justifyContent: "center"}}>
                    <Button onClick={closeIdBox} variant="contained" sx={{backgroundColor: Colors.primary}}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}
