import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import EmailImage from "../../../img/redirects/email-image.png";
import { Typography } from '@mui/material';
import {
    useLocation
  } from "react-router-dom";

export default function OneAction() {

    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search);

    console.log(queryParameters.get("id"))

    return (
    <Box
        sx={{
        display: "flex",
        // flexGrow: "1",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        // backgroundColor: "#ededed",
        }}
    >
        <Grid container sx={{height: "50%", alignItems: "center", justifyContent: "center"}}>
            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center",}}>
                <Box 
                    component="img"
                    sx={{
                    height: "25%",
                    width: "30%",
                    }}
                    alt="logo"
                    src={EmailImage} 
                >
                    
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ display: "flex", justifyContent: "center"}}>
                <Typography variant="h4" sx={{fontWeight: "bold"}}>GRACIAS</Typography>
            </Grid>
        </Grid>
    </Box>
    )
}
