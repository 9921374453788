import React from 'react';
import {getCodes, createSettlement} from "../../../utilityFunctions/MarketPlaceUtil";
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button} from '@mui/material';
import NumberFormat from "react-number-format";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../../components/ui/Colors";
import Autocomplete from '@mui/material/Autocomplete';

let settlementValues = {
    code: "",
    animalsSold: "",
    sellingValue: "",
    totalWeight: "",
};

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export default function BatchSettlement(props) {

    const {loginUser} = props

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        
        getProductsCodes();

        settlementValues = {
            code: "",
            animalsSold: "",
            sellingValue: "",
            totalWeight: "",
        };
    }, []);

    const [codes, setCodes] = React.useState("");
    const [animalsSold, setAnimalsSold] = React.useState("");
    const [sellingValue, setSellingValue] = React.useState("");
    const [totalWeight, setTotalWeight] = React.useState("");

    const getProductsCodes = async () => {
       const codes = await getCodes(loginUser.jwtToken); 

       setCodes(
        {
            options: codes,
            getOptionLabel: (option) => option.code,
        }
       );
    };

    const onChangeAutocomplete = (event, value) => {
        console.log(value);
        settlementValues.code = value.code;
    };

    const animalsSoldChange = (e) => {
        setAnimalsSold(e.floatValue);
        settlementValues.animalsSold = e.floatValue;
    };

    const sellingValueChange = (e) => {
        setSellingValue(e.floatValue);
        settlementValues.sellingValue = e.floatValue;
    };

    const totalWeightChange = (e) => {
        setTotalWeight(e.floatValue);
        settlementValues.totalWeight = e.floatValue;
    };

    const handelSettlement = async () => {
        setLoading(true);
        const sellingResult = await createSettlement(settlementValues, loginUser.jwtToken);
        setLoading(false);
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
            sx={{
                display: "flex",
                // flex: 1,
                justifyContent: "center",
                alignItems: "center",
                width: "95%",
                height: "100%",
                textAlign: "center",
                marginTop: {xs: "1.3rem", sm: 0},
            }}
            >
        {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                    <Autocomplete
                        {...codes}
                        id="codes"
                        clearOnEscape
                        autoComplete = {true}
                        noOptionsText = {"Lote no existe"}
                        // sx={{ width: 600 }}
                        onChange={onChangeAutocomplete}
                        // onInputChange={onChange}
                        renderInput={(params) => (
                            <TextField {...params} label={<Typography variant="body2">Código del lote</Typography>} variant="standard" />
                        )}
                    /> 
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <NumberFormat
                        variant="standard"
                        style={{width: "100%", height: "50%"}}
                        name="animalsAmount"
                        customInput={TextField}
                        // prefix={'$ '}
                        // suffix={'%'}
                        type="animalsAmount"
                        label={<Typography variant="body2">Cantidad de animales</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete="off"
                        value={animalsSold}
                        onValueChange={(values) => {
                            // const {formattedValue, value, floatValue} = values;
                            animalsSoldChange(values);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <NumberFormat
                        variant="standard"
                        style={{width: "100%", height: "50%"}}
                        name="sellingValue"
                        customInput={TextField}
                        prefix={'$ '}
                        type="text"
                        label={<Typography variant="body2">Valor de venta</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete="off"
                        value={sellingValue}
                        onValueChange={(values) => {
                            // const {formattedValue, value, floatValue} = values;
                            sellingValueChange(values);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <NumberFormat
                        variant="standard"
                        style={{width: "100%", height: "50%"}}
                        name="totalWeight"
                        customInput={TextField}
                        // prefix={'$ '}
                        suffix={' kg'}
                        type="totalWeight"
                        label={<Typography variant="body2">Peso total</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete="off"
                        value={totalWeight}
                        onValueChange={(values) => {
                            // const {formattedValue, value, floatValue} = values;
                            totalWeightChange(values);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Button onClick={handelSettlement} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                        Liquidar
                    </Button>
                </Grid>
            </Grid>
        }
        </Box>
        </Box>
    )
}
