import React from 'react';
import {getClient, getAllUsernames} from "../../../utilityFunctions/MarketPlaceUtil";
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { Grid, Typography, Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import Colors from "../../../components/ui/Colors";
import { useHistory } from "react-router-dom";

let selectedUsername;

export default function MarketplaceClient(props) {

    const {loginUser} = props;

    let history = useHistory();

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        selectedUsername="";
        getAllClients();

    }, []);

    const [usernames, setUsernames] = React.useState();

    const getAllClients = async () => {
        setLoading(true);
        const fetchedClients = await getAllUsernames(loginUser.jwtToken);

        if(!fetchedClients){
            return;
        };


        setUsernames(
            {
                options: fetchedClients,
                getOptionLabel: (option) => option.username,
            }
        );

        setLoading(false);

    };

    const autocompleteUsername = (event, value) => {
        console.log(value);

        selectedUsername = value.username;
    };

    const goToPii = async () => {
        if(!selectedUsername){
            return;
        }

        const user = await getClient(selectedUsername, loginUser.jwtToken);
        console.log(user);

        history.push({
            pathname: "/info/review-data",
            state: {
                user: user,
            }
        });

    };

    const goToPortfolio = async () => {
        if(!selectedUsername){
            return;
        }

        const user = await getClient(selectedUsername, loginUser.jwtToken);
        console.log(user);

        history.push({
            pathname: "/info/review-purchases",
            state: {
                user: user,
            }
        });

    };

    return (
    <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
        <Box
            sx={{
                display: "flex",
                // flex: 1,
                justifyContent: "center",
                alignItems: "center",
                width: "30%",
                height: "100%",
                textAlign: "center",
                marginTop: {xs: "1.3rem", sm: 0},
            }}
        >
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                    <Autocomplete
                        {...usernames}
                        id="usernames"
                        clearOnEscape
                        autoComplete = {true}
                        noOptionsText = {"Cliente no existe"}
                        // sx={{ width: 600 }}
                        onChange={autocompleteUsername}
                        // onInputChange={onChange}
                        renderInput={(params) => (
                            <TextField {...params} label="Usuario cliente" variant="standard" />
                        )}
                    /> 
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Button onClick={goToPii} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                        Datos
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Button onClick={goToPortfolio} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                        Compras
                    </Button>
                </Grid>
            </Grid>
        </Box>
    </Box>
    )
}
