// const RippleAPI = require("ripple-lib").RippleAPI;

// const api = new RippleAPI({
//   server: "wss://s.altnet.rippletest.net:51233", // Public rippled server hosted by Ripple, Inc.
// });

const api = "default";

// const wupoServerUri = {
//   mainnet: "http://wupoapi.ngrok.io",
//   testnet: "http://wupoback.ngrok.io",
//   // devnet: "https://wupoback.ngrok.io", //Changed for testing purposes
//   // devnet: "https://localhost:8080",
//   // devnet: "https://wupotestmiddle.ngrok.io",
//   // devnet: "http://54.166.234.69:5000"
//   devnet: "https://awseb-e-p-awsebloa-m3ljpwrvikxh.wupealo.com"

// }

const wupoServerUri = {
  devnet: process.env.REACT_APP_BACK_URI,
}


const stockOrderSymbol = [
  {
    currency: "APL",
    stockSymbol: "AAPL"
  },
  {
    currency: "GOG",
    stockSymbol: "GOOG"
  },
  {
    currency: "FCB",
    stockSymbol: "FB"
  },
  {
    currency: "AMZ",
    stockSymbol: "AMZN"
  },
  {
    currency: "TSL",
    stockSymbol: "TSLA"
  },
  {
    currency: "GLD",
    stockSymbol: "GOLD"
  },
]

const exchangeCurrencies = [
  {
    currency: "USD",
    exchangeCurrency: "USDT",
  },
  {
    currency: "EUR",
    exchangeCurrency: "EUR",
  },
  {
    currency: "BTC",
    exchangeCurrency: "BTC",
  },
  {
    currency: "ETH",
    exchangeCurrency: "ETH",
  }
];

const retailerTokens = [
  {
    label: "Rappi",
    asset: "RPI",
    value: "COP",
  },
  {
    label: "Merqueo",
    asset: "MQO",
    value: "COP",
  },
  {
    label: "Laika",
    asset: "LKA",
    value: "COP",
  },
  {
    label: "Uber",
    asset: "UBR",
    value: "COP",
  },
  {
    label: "Gastronomy Market",
    asset: "GMK",
    value: "COP",
  },
  {
    label: "Carulla",
    asset: "CAL",
    value: "COP",
  },
  {
    label: "WUPOS",
    asset: "WUP",
    value: "COP",
  }

];

const retailersTokensReferenceCurrency = "COP"

const retailOperation ={
  retailLoginEnable: process.env.REACT_APP_ENABLE_RETAIL_LOGIN,
  retailerJwtEndpoint: process.env.REACT_APP_RETAILER_JWT_ENDPOINT
};

// const creditAsset = "WUP" //currently WUPOS, but exploring if other assets loans make sense.
const creditAsset = "COP";

const creditTerm = {
  day: "day",
  week: "week",
  month: "month",
};

const bankAccountTypes = [
  {code: 7, type: "Cuenta de Ahorros"},
  {code: 1, type: "Cuenta Corriente"},
  {code: 9, type: "Depósito Electrónico"},
]

const bankCodes = [

  {bank: "ITAU", code: "06"},
  {bank: "BANCOLDEX S.A.S", code: "31"},
  {bank: "BANCO AGRARIO", code: "40"},
  {bank: "BANCO MUNDO MUJER", code: "47"},
  {bank: "BANCO W S.A.", code: "53"},
  {bank: "BANCO DE LAS MICROFINANZAS - BANCAMIA S.A.", code: "59"},
  {bank: "BANCO PICHINCHA", code: "60"},
  {bank: "BANCOOMEVA", code: "61"},
  {bank: "BANCO FALABELLA S.A.", code: "62"},
  {bank: "BANCO FINANDINA S.A.", code: "63"},
  {bank: "BANCO SANTANDER DE NEGOCIOS COLOMBIA S.A", code: "65"},
  {bank: "BANCO COOPERATIVO COOPCENTRAL", code: "66"},
  {bank: "MIBANCO S.A.", code: "67"},
  {bank: "BANCO SERFINANZA S.A", code: "69"},
  {bank: "BANCO J.P. MORGAN COLOMBIA S.A.", code: "71"},
  {bank: "ASOPAGOS S.A.S", code: "98"},
  {bank: "FINANCIERA JURISCOOP S.A. COMPANIA DE FINANCIAMIENTO", code: "21"},
  {bank: "COOPERATIVA FINANCIERA DE ANTIOQUIA", code: "83"},
  {bank: "COOTRAFA COOPERATIVA FINANCIERA", code: "89"},
  {bank: "COOFINEP COOPERATIVA FINANCIERA", code: "99"},
  {bank: "CONFIAR COOPERATIVA FINANCIERA", code: "92"},
  {bank: "BANCO UNION S.A", code: "73"},
  {bank: "COLTEFINANCIERA S.A", code: "39"},
  {bank: "NEQUI", code: "1507"},
  {bank: "DAVIPLATA", code: "91"},
  {bank: "BANCO CREDIFINANCIERA SA.", code: "58"},
  {bank: "IRIS", code: "37"},
  {bank: "JFK COOPERATIVA FINANCIERA", code: "86"},
  {bank: "MOVII", code: "41"},
  {bank: "DING TECNIPAGOS SA", code: "82"},
  {bank: "UALA", code: "84"},
  {bank: "BANCO BTG PACTUAL", code: "85"},
  {bank: "LULO BANK S.A.", code: "70"},
  {bank: "RAPPIPAY", code: "81"},
  {bank: "BANCO DE BOGOTA", code: "01"},
  {bank: "BANCO POPULAR", code: "02"},
  {bank: "BANCOLOMBIA", code: "07"},
  {bank: "CITIBANK", code: "09"},
  {bank: "BANCO GNB SUDAMERIS", code: "12"},
  {bank: "BBVA COLOMBIA", code: "13"},
  {bank: "SCOTIABANK COLPATRIA S.A", code: "19"},
  {bank: "BANCO DE OCCIDENTE", code: "23"},
  {bank: "BANCO CAJA SOCIAL BCSC SA", code: "32"},
  {bank: "BANCO DAVIVIENDA SA", code: "51"},
  {bank: "BANCO AV VILLAS", code: "52"},
]

const municipalities = [

  {stateCode: "5" , cityCode: "2", city: "ABEJORRAL"},
{stateCode: "54" , cityCode: "3", city: "ABREGO"},
{stateCode: "5" , cityCode: "4", city: "ABRIAQUI"},
{stateCode: "50" , cityCode: "6", city: "ACACIAS"},
{stateCode: "27" , cityCode: "6", city: "ACANDI"},
{stateCode: "41" , cityCode: "6", city: "ACEVEDO"},
{stateCode: "13" , cityCode: "6", city: "ACHI"},
{stateCode: "41" , cityCode: "13", city: "AGRADO"},
{stateCode: "25" , cityCode: "1", city: "AGUA DE DIOS"},
{stateCode: "20" , cityCode: "11", city: "AGUACHICA"},
{stateCode: "68" , cityCode: "13", city: "AGUADA"},
{stateCode: "17" , cityCode: "13", city: "AGUADAS"},
{stateCode: "85" , cityCode: "10", city: "AGUAZUL"},
{stateCode: "20" , cityCode: "13", city: "AGUSTIN CODAZZI"},
{stateCode: "41" , cityCode: "16", city: "AIPE"},
{stateCode: "25" , cityCode: "19", city: "ALBAN"},
{stateCode: "52" , cityCode: "19", city: "ALBAN (SAN JOSE)"},
{stateCode: "68" , cityCode: "20", city: "ALBANIA"},
{stateCode: "18" , cityCode: "29", city: "ALBANIA"},
{stateCode: "44" , cityCode: "431", city: "ALBANIA"},
{stateCode: "44" , cityCode: "900", city: "ALBANIA"},
{stateCode: "76" , cityCode: "20", city: "ALCALA"},
{stateCode: "52" , cityCode: "22", city: "ALDANA"},
{stateCode: "5" , cityCode: "21", city: "ALEJANDRIA"},
{stateCode: "41" , cityCode: "20", city: "ALGECIRAS"},
{stateCode: "19" , cityCode: "22", city: "ALMAGUER"},
{stateCode: "15" , cityCode: "22", city: "ALMEIDA"},
{stateCode: "73" , cityCode: "24", city: "ALPUJARRA"},
{stateCode: "41" , cityCode: "26", city: "ALTAMIRA"},
{stateCode: "27" , cityCode: "25", city: "ALTO BAUDO (PIE DE PATO)"},
{stateCode: "13" , cityCode: "30", city: "ALTOS DEL ROSARIO"},
{stateCode: "73" , cityCode: "26", city: "ALVARADO"},
{stateCode: "5" , cityCode: "30", city: "AMAGA"},
{stateCode: "5" , cityCode: "31", city: "AMALFI"},
{stateCode: "73" , cityCode: "30", city: "AMBALEMA"},
{stateCode: "25" , cityCode: "35", city: "ANAPOIMA"},
{stateCode: "52" , cityCode: "36", city: "ANCUYA"},
{stateCode: "76" , cityCode: "36", city: "ANDALUCIA"},
{stateCode: "5" , cityCode: "34", city: "ANDES"},
{stateCode: "5" , cityCode: "36", city: "ANGELOPOLIS"},
{stateCode: "5" , cityCode: "38", city: "ANGOSTURA"},
{stateCode: "25" , cityCode: "40", city: "ANOLAIMA"},
{stateCode: "5" , cityCode: "40", city: "ANORI"},
{stateCode: "17" , cityCode: "42", city: "ANSERMA"},
{stateCode: "17" , cityCode: "47", city: "ANSERMA (CREDIBANCO)"},
{stateCode: "76" , cityCode: "41", city: "ANSERMANUEVO"},
{stateCode: "5" , cityCode: "42", city: "ANTIOQUIA (SANTAFE DE)"},
{stateCode: "5" , cityCode: "44", city: "ANZA"},
{stateCode: "73" , cityCode: "43", city: "ANZOATEGUI"},
{stateCode: "5" , cityCode: "45", city: "APARTADO"},
{stateCode: "66" , cityCode: "45", city: "APIA"},
{stateCode: "15" , cityCode: "47", city: "AQUITANIA"},
{stateCode: "47" , cityCode: "53", city: "ARACATACA"},
{stateCode: "17" , cityCode: "50", city: "ARANZAZU"},
{stateCode: "68" , cityCode: "51", city: "ARATOCA"},
{stateCode: "81" , cityCode: "1", city: "ARAUCA"},
{stateCode: "17" , cityCode: "60", city: "ARAUCA"},
{stateCode: "81" , cityCode: "65", city: "ARAUQUITA"},
{stateCode: "25" , cityCode: "53", city: "ARBELAEZ"},
{stateCode: "52" , cityCode: "51", city: "ARBOLEDA (BERRUECOS)"},
{stateCode: "54" , cityCode: "51", city: "ARBOLEDAS"},
{stateCode: "5" , cityCode: "51", city: "ARBOLETES"},
{stateCode: "15" , cityCode: "51", city: "ARCABUCO"},
{stateCode: "13" , cityCode: "42", city: "ARENAL"},
{stateCode: "19" , cityCode: "50", city: "ARGELIA"},
{stateCode: "76" , cityCode: "54", city: "ARGELIA"},
{stateCode: "5" , cityCode: "55", city: "ARGELIA"},
{stateCode: "47" , cityCode: "58", city: "ARIGUANI (EL DIFICIL)"},
{stateCode: "13" , cityCode: "52", city: "ARJONA"},
{stateCode: "63" , cityCode: "1", city: "ARMENIA"},
{stateCode: "5" , cityCode: "59", city: "ARMENIA"},
{stateCode: "73" , cityCode: "55", city: "ARMERO (GUAYABAL)"},
{stateCode: "13" , cityCode: "62", city: "ARROYOHONDO"},
{stateCode: "20" , cityCode: "32", city: "ASTREA"},
{stateCode: "73" , cityCode: "67", city: "ATACO"},
{stateCode: "27" , cityCode: "50", city: "ATRATO (YUTO)"},
{stateCode: "23" , cityCode: "68", city: "AYAPEL"},
{stateCode: "27" , cityCode: "73", city: "BAGADO"},
{stateCode: "27" , cityCode: "75", city: "BAHIA SOLANO (MUTIS)"},
{stateCode: "27" , cityCode: "77", city: "BAJO BAUDO   (PIZARRO)"},
{stateCode: "19" , cityCode: "75", city: "BALBOA"},
{stateCode: "66" , cityCode: "75", city: "BALBOA"},
{stateCode: "8" , cityCode: "78", city: "BARANOA"},
{stateCode: "41" , cityCode: "78", city: "BARAYA"},
{stateCode: "52" , cityCode: "79", city: "BARBACOAS"},
{stateCode: "68" , cityCode: "77", city: "BARBOSA"},
{stateCode: "5" , cityCode: "79", city: "BARBOSA"},
{stateCode: "63" , cityCode: "260", city: "BARCELONA"},
{stateCode: "68" , cityCode: "79", city: "BARICHARA"},
{stateCode: "50" , cityCode: "110", city: "BARRANCA DE UPIA"},
{stateCode: "68" , cityCode: "81", city: "BARRANCABERMEJA"},
{stateCode: "44" , cityCode: "78", city: "BARRANCAS"},
{stateCode: "13" , cityCode: "74", city: "BARRANCO DE LOBA"},
{stateCode: "8" , cityCode: "1", city: "BARRANQUILLA"},
{stateCode: "20" , cityCode: "45", city: "BECERRIL"},
{stateCode: "17" , cityCode: "88", city: "BELALCAZAR"},
{stateCode: "52" , cityCode: "83", city: "BELEN"},
{stateCode: "15" , cityCode: "87", city: "BELEN"},
{stateCode: "18" , cityCode: "94", city: "BELEN DE LOS ANDAQUIES"},
{stateCode: "66" , cityCode: "88", city: "BELEN DE UMBRIA"},
{stateCode: "15" , cityCode: "88", city: "BELENCITO"},
{stateCode: "5" , cityCode: "88", city: "BELLO"},
{stateCode: "5" , cityCode: "86", city: "BELMIRA"},
{stateCode: "25" , cityCode: "86", city: "BELTRAN"},
{stateCode: "15" , cityCode: "90", city: "BERBEO"},
{stateCode: "5" , cityCode: "91", city: "BETANIA"},
{stateCode: "15" , cityCode: "92", city: "BETEITIVA"},
{stateCode: "68" , cityCode: "92", city: "BETULIA"},
{stateCode: "5" , cityCode: "93", city: "BETULIA"},
{stateCode: "25" , cityCode: "95", city: "BITUIMA"},
{stateCode: "15" , cityCode: "97", city: "BOAVITA"},
{stateCode: "54" , cityCode: "99", city: "BOCHALEMA"},
{stateCode: "11" , cityCode: "1", city: "BOGOTA"},
{stateCode: "25" , cityCode: "99", city: "BOJACA"},
{stateCode: "25" , cityCode: "99", city: "BOJACA"},
{stateCode: "27" , cityCode: "99", city: "BOJAYA (BELLAVISTA)"},
{stateCode: "76" , cityCode: "100", city: "BOLIVAR"},
{stateCode: "19" , cityCode: "100", city: "BOLIVAR"},
{stateCode: "68" , cityCode: "101", city: "BOLIVAR"},
{stateCode: "5" , cityCode: "101", city: "BOLIVAR (CIUDAD)"},
{stateCode: "25" , cityCode: "2", city: "BOSA"},
{stateCode: "20" , cityCode: "60", city: "BOSCONIA"},
{stateCode: "15" , cityCode: "104", city: "BOYACA"},
{stateCode: "25" , cityCode: "90", city: "BRICE—O"},
{stateCode: "15" , cityCode: "106", city: "BRICE—O"},
{stateCode: "5" , cityCode: "107", city: "BRICE—O"},
{stateCode: "68" , cityCode: "1", city: "BUCARAMANGA"},
{stateCode: "54" , cityCode: "109", city: "BUCARASICA"},
{stateCode: "76" , cityCode: "109", city: "BUENAVENTURA"},
{stateCode: "23" , cityCode: "79", city: "BUENAVISTA"},
{stateCode: "15" , cityCode: "109", city: "BUENAVISTA"},
{stateCode: "70" , cityCode: "110", city: "BUENAVISTA"},
{stateCode: "63" , cityCode: "111", city: "BUENAVISTA"},
{stateCode: "19" , cityCode: "110", city: "BUENOS AIRES"},
{stateCode: "52" , cityCode: "110", city: "BUESACO"},
{stateCode: "76" , cityCode: "111", city: "BUGA"},
{stateCode: "76" , cityCode: "113", city: "BUGALAGRANDE"},
{stateCode: "5" , cityCode: "113", city: "BURITICA"},
{stateCode: "15" , cityCode: "114", city: "BUSBANZA"},
{stateCode: "5" , cityCode: "138", city: "CA—ASGORDAS"},
{stateCode: "25" , cityCode: "120", city: "CABRERA"},
{stateCode: "68" , cityCode: "121", city: "CABRERA"},
{stateCode: "50" , cityCode: "124", city: "CABUYARO"},
{stateCode: "94" , cityCode: "886", city: "CACAHUAL"},
{stateCode: "5" , cityCode: "120", city: "CACERES"},
{stateCode: "25" , cityCode: "123", city: "CACHIPAY"},
{stateCode: "54" , cityCode: "128", city: "CACHIRA"},
{stateCode: "54" , cityCode: "125", city: "CACOTA"},
{stateCode: "5" , cityCode: "125", city: "CAICEDO"},
{stateCode: "76" , cityCode: "122", city: "CAICEDONIA"},
{stateCode: "70" , cityCode: "124", city: "CAIMITO"},
{stateCode: "73" , cityCode: "124", city: "CAJAMARCA"},
{stateCode: "19" , cityCode: "130", city: "CAJIBIO"},
{stateCode: "25" , cityCode: "126", city: "CAJICA"},
{stateCode: "95" , cityCode: "15", city: "CALAMAR"},
{stateCode: "13" , cityCode: "140", city: "CALAMAR"},
{stateCode: "63" , cityCode: "130", city: "CALARCA"},
{stateCode: "5" , cityCode: "129", city: "CALDAS"},
{stateCode: "15" , cityCode: "131", city: "CALDAS"},
{stateCode: "19" , cityCode: "137", city: "CALDONO"},
{stateCode: "76" , cityCode: "1", city: "CALI"},
{stateCode: "68" , cityCode: "132", city: "CALIFORNIA"},
{stateCode: "76" , cityCode: "126", city: "CALIMA (DARIEN)"},
{stateCode: "19" , cityCode: "142", city: "CALOTO"},
{stateCode: "5" , cityCode: "134", city: "CAMPAMENTO"},
{stateCode: "8" , cityCode: "137", city: "CAMPO DE LA CRUZ"},
{stateCode: "41" , cityCode: "132", city: "CAMPOALEGRE"},
{stateCode: "15" , cityCode: "135", city: "CAMPOHERMOSO"},
{stateCode: "23" , cityCode: "90", city: "CANALETE"},
{stateCode: "76" , cityCode: "130", city: "CANDELARIA"},
{stateCode: "8" , cityCode: "141", city: "CANDELARIA"},
{stateCode: "13" , cityCode: "160", city: "CANTAGALLO"},
{stateCode: "27" , cityCode: "135", city: "CANTON DEL SAN PABLO"},
{stateCode: "25" , cityCode: "148", city: "CAPARRAPI"},
{stateCode: "68" , cityCode: "147", city: "CAPITANEJO"},
{stateCode: "5" , cityCode: "140", city: "CAPURGANA"},
{stateCode: "25" , cityCode: "151", city: "CAQUEZA"},
{stateCode: "5" , cityCode: "142", city: "CARACOLI"},
{stateCode: "5" , cityCode: "145", city: "CARAMANTA"},
{stateCode: "68" , cityCode: "152", city: "CARCASI"},
{stateCode: "5" , cityCode: "146", city: "CAREPA"},
{stateCode: "5" , cityCode: "147", city: "CAREPA"},
{stateCode: "73" , cityCode: "148", city: "CARMEN DE APICALA"},
{stateCode: "25" , cityCode: "154", city: "CARMEN DE CARUPA"},
{stateCode: "5" , cityCode: "148", city: "CARMEN DE VIBORAL"},
{stateCode: "5" , cityCode: "150", city: "CAROLINA"},
{stateCode: "13" , cityCode: "1", city: "CARTAGENA"},
{stateCode: "18" , cityCode: "150", city: "CARTAGENA DEL CHAIRA"},
{stateCode: "76" , cityCode: "147", city: "CARTAGO"},
{stateCode: "97" , cityCode: "161", city: "CARURU"},
{stateCode: "73" , cityCode: "152", city: "CASABIANCA"},
{stateCode: "50" , cityCode: "150", city: "CASTILLA LA NUEVA"},
{stateCode: "99" , cityCode: "122", city: "CASUARITO"},
{stateCode: "5" , cityCode: "154", city: "CAUCASIA"},
{stateCode: "68" , cityCode: "160", city: "CEPITA"},
{stateCode: "23" , cityCode: "162", city: "CERETE"},
{stateCode: "15" , cityCode: "162", city: "CERINZA"},
{stateCode: "68" , cityCode: "162", city: "CERRITO"},
{stateCode: "47" , cityCode: "161", city: "CERRO SAN ANTONIO"},
{stateCode: "52" , cityCode: "240", city: "CHACHAGUI"},
{stateCode: "25" , cityCode: "168", city: "CHAGUANI"},
{stateCode: "70" , cityCode: "230", city: "CHALAN"},
{stateCode: "85" , cityCode: "15", city: "CHAMEZA"},
{stateCode: "73" , cityCode: "168", city: "CHAPARRAL"},
{stateCode: "68" , cityCode: "167", city: "CHARALA"},
{stateCode: "68" , cityCode: "169", city: "CHARTA"},
{stateCode: "25" , cityCode: "175", city: "CHIA"},
{stateCode: "73" , cityCode: "175", city: "CHICORAL"},
{stateCode: "73" , cityCode: "231", city: "CHICORAL"},
{stateCode: "5" , cityCode: "172", city: "CHIGORODO"},
{stateCode: "23" , cityCode: "168", city: "CHIMA"},
{stateCode: "68" , cityCode: "176", city: "CHIMA"},
{stateCode: "20" , cityCode: "175", city: "CHIMICHAGUA"},
{stateCode: "54" , cityCode: "172", city: "CHINACOTA"},
{stateCode: "15" , cityCode: "172", city: "CHINAVITA"},
{stateCode: "66" , cityCode: "160", city: "CHINCHINA"},
{stateCode: "17" , cityCode: "174", city: "CHINCHINA"},
{stateCode: "23" , cityCode: "182", city: "CHINU"},
{stateCode: "25" , cityCode: "178", city: "CHIPAQUE"},
{stateCode: "68" , cityCode: "179", city: "CHIPATA"},
{stateCode: "15" , cityCode: "176", city: "CHIQUINQUIRA"},
{stateCode: "15" , cityCode: "232", city: "CHIQUIZA"},
{stateCode: "20" , cityCode: "178", city: "CHIRIGUANA"},
{stateCode: "15" , cityCode: "180", city: "CHISCAS"},
{stateCode: "15" , cityCode: "183", city: "CHITA"},
{stateCode: "54" , cityCode: "174", city: "CHITAGA"},
{stateCode: "15" , cityCode: "185", city: "CHITARAQUE"},
{stateCode: "15" , cityCode: "187", city: "CHIVATA"},
{stateCode: "47" , cityCode: "170", city: "CHIVOLO"},
{stateCode: "15" , cityCode: "236", city: "CHIVOR"},
{stateCode: "25" , cityCode: "181", city: "CHOACHI"},
{stateCode: "25" , cityCode: "183", city: "CHOCONTA"},
{stateCode: "13" , cityCode: "188", city: "CICUCO"},
{stateCode: "47" , cityCode: "189", city: "CIENAGA"},
{stateCode: "23" , cityCode: "189", city: "CIENAGA DE ORO"},
{stateCode: "15" , cityCode: "189", city: "CIENEGA"},
{stateCode: "68" , cityCode: "190", city: "CIMITARRA"},
{stateCode: "63" , cityCode: "190", city: "CIRCASIA"},
{stateCode: "5" , cityCode: "190", city: "CISNEROS"},
{stateCode: "13" , cityCode: "222", city: "CLEMENCIA"},
{stateCode: "19" , cityCode: "155", city: "COCONUCO"},
{stateCode: "5" , cityCode: "197", city: "COCORNA"},
{stateCode: "73" , cityCode: "200", city: "COELLO"},
{stateCode: "25" , cityCode: "200", city: "COGUA"},
{stateCode: "41" , cityCode: "206", city: "COLOMBIA"},
{stateCode: "86" , cityCode: "219", city: "COLON"},
{stateCode: "52" , cityCode: "203", city: "COLON (GENOVA)"},
{stateCode: "70" , cityCode: "204", city: "COLOSO (RICAURTE)"},
{stateCode: "15" , cityCode: "204", city: "COMBITA"},
{stateCode: "5" , cityCode: "206", city: "CONCEPCION"},
{stateCode: "68" , cityCode: "207", city: "CONCEPCION"},
{stateCode: "5" , cityCode: "209", city: "CONCORDIA"},
{stateCode: "27" , cityCode: "205", city: "CONDOTO"},
{stateCode: "68" , cityCode: "209", city: "CONFINES"},
{stateCode: "52" , cityCode: "207", city: "CONSACA"},
{stateCode: "52" , cityCode: "210", city: "CONTADERO"},
{stateCode: "68" , cityCode: "211", city: "CONTRATACION"},
{stateCode: "54" , cityCode: "206", city: "CONVENCION"},
{stateCode: "5" , cityCode: "212", city: "COPACABANA"},
{stateCode: "15" , cityCode: "212", city: "COPER"},
{stateCode: "13" , cityCode: "212", city: "CORDOBA"},
{stateCode: "63" , cityCode: "212", city: "CORDOBA"},
{stateCode: "52" , cityCode: "215", city: "CORDOBA"},
{stateCode: "19" , cityCode: "212", city: "CORINTO"},
{stateCode: "68" , cityCode: "217", city: "COROMORO"},
{stateCode: "70" , cityCode: "215", city: "COROZAL"},
{stateCode: "15" , cityCode: "215", city: "CORRALES"},
{stateCode: "25" , cityCode: "214", city: "COTA"},
{stateCode: "23" , cityCode: "300", city: "COTORRA"},
{stateCode: "15" , cityCode: "218", city: "COVARACHIA"},
{stateCode: "70" , cityCode: "222", city: "COVE—AS"},
{stateCode: "70" , cityCode: "220", city: "COVE?AS"},
{stateCode: "73" , cityCode: "217", city: "COYAIMA"},
{stateCode: "81" , cityCode: "220", city: "CRAVO NORTE"},
{stateCode: "52" , cityCode: "224", city: "CUASPUD (CARLOSAMA)"},
{stateCode: "15" , cityCode: "223", city: "CUBARA"},
{stateCode: "50" , cityCode: "223", city: "CUBARRAL (SAN LUIS DE )"},
{stateCode: "15" , cityCode: "224", city: "CUCAITA"},
{stateCode: "25" , cityCode: "224", city: "CUCUNUBA"},
{stateCode: "54" , cityCode: "1", city: "CUCUTA"},
{stateCode: "54" , cityCode: "223", city: "CUCUTILLA"},
{stateCode: "15" , cityCode: "226", city: "CUITIVA"},
{stateCode: "50" , cityCode: "226", city: "CUMARAL"},
{stateCode: "99" , cityCode: "773", city: "CUMARIBO"},
{stateCode: "52" , cityCode: "227", city: "CUMBAL"},
{stateCode: "52" , cityCode: "233", city: "CUMBITARA"},
{stateCode: "73" , cityCode: "226", city: "CUNDAY"},
{stateCode: "18" , cityCode: "205", city: "CURILLO"},
{stateCode: "68" , cityCode: "229", city: "CURITI"},
{stateCode: "20" , cityCode: "228", city: "CURUMANI"},
{stateCode: "5" , cityCode: "234", city: "DABEIBA"},
{stateCode: "76" , cityCode: "233", city: "DAGUA"},
{stateCode: "44" , cityCode: "90", city: "DIBULLA"},
{stateCode: "44" , cityCode: "98", city: "DISTRACCION"},
{stateCode: "73" , cityCode: "236", city: "DOLORES"},
{stateCode: "5" , cityCode: "237", city: "DON MATIAS"},
{stateCode: "66" , cityCode: "170", city: "DOS QUEBRADAS"},
{stateCode: "15" , cityCode: "238", city: "DUITAMA"},
{stateCode: "54" , cityCode: "239", city: "DURANIA"},
{stateCode: "5" , cityCode: "240", city: "EBEJICO"},
{stateCode: "76" , cityCode: "243", city: "EL AGUILA"},
{stateCode: "5" , cityCode: "250", city: "EL BAGRE"},
{stateCode: "47" , cityCode: "245", city: "EL BANCO"},
{stateCode: "76" , cityCode: "246", city: "EL CAIRO"},
{stateCode: "50" , cityCode: "245", city: "EL CALVARIO"},
{stateCode: "27" , cityCode: "245", city: "EL CARMEN"},
{stateCode: "54" , cityCode: "245", city: "EL CARMEN"},
{stateCode: "13" , cityCode: "244", city: "EL CARMEN DE BOLIVAR"},
{stateCode: "68" , cityCode: "235", city: "EL CARMEN DE CHUCURI"},
{stateCode: "50" , cityCode: "251", city: "EL CASTILLO"},
{stateCode: "76" , cityCode: "248", city: "EL CERRITO"},
{stateCode: "52" , cityCode: "250", city: "EL CHARCO"},
{stateCode: "15" , cityCode: "244", city: "EL COCUY"},
{stateCode: "25" , cityCode: "245", city: "EL COLEGIO"},
{stateCode: "20" , cityCode: "238", city: "EL COPEY"},
{stateCode: "18" , cityCode: "247", city: "EL DONCELLO"},
{stateCode: "50" , cityCode: "270", city: "EL DORADO"},
{stateCode: "76" , cityCode: "250", city: "EL DOVIO"},
{stateCode: "91" , cityCode: "263", city: "EL ENCANTO"},
{stateCode: "15" , cityCode: "248", city: "EL ESPINO"},
{stateCode: "68" , cityCode: "245", city: "EL GUACAMAYO"},
{stateCode: "13" , cityCode: "248", city: "EL GUAMO"},
{stateCode: "27" , cityCode: "250", city: "EL LITORAL DEL SAN JUAN"},
{stateCode: "44" , cityCode: "110", city: "EL MOLINO"},
{stateCode: "25" , cityCode: "485", city: "EL NEUZA"},
{stateCode: "20" , cityCode: "250", city: "EL PASO"},
{stateCode: "18" , cityCode: "256", city: "EL PAUJIL"},
{stateCode: "68" , cityCode: "250", city: "EL PE?ON"},
{stateCode: "25" , cityCode: "258", city: "EL PE?ON"},
{stateCode: "13" , cityCode: "268", city: "EL PE?ON"},
{stateCode: "47" , cityCode: "258", city: "EL PI?ON"},
{stateCode: "68" , cityCode: "255", city: "EL PLAYON"},
{stateCode: "47" , cityCode: "268", city: "EL RETEN"},
{stateCode: "95" , cityCode: "25", city: "EL RETORNO"},
{stateCode: "25" , cityCode: "260", city: "EL ROSAL"},
{stateCode: "52" , cityCode: "256", city: "EL ROSARIO"},
{stateCode: "52" , cityCode: "258", city: "EL TABLON"},
{stateCode: "19" , cityCode: "256", city: "EL TAMBO"},
{stateCode: "52" , cityCode: "260", city: "EL TAMBO"},
{stateCode: "54" , cityCode: "250", city: "EL TARRA"},
{stateCode: "27" , cityCode: "270", city: "EL VALLE"},
{stateCode: "54" , cityCode: "261", city: "EL ZULIA"},
{stateCode: "41" , cityCode: "244", city: "ELIAS"},
{stateCode: "68" , cityCode: "264", city: "ENCINO"},
{stateCode: "68" , cityCode: "266", city: "ENCISO"},
{stateCode: "5" , cityCode: "264", city: "ENTRERRIOS"},
{stateCode: "5" , cityCode: "266", city: "ENVIGADO"},
{stateCode: "73" , cityCode: "268", city: "ESPINAL"},
{stateCode: "25" , cityCode: "269", city: "FACATATIVA"},
{stateCode: "73" , cityCode: "270", city: "FALAN"},
{stateCode: "17" , cityCode: "272", city: "FILADELFIA"},
{stateCode: "63" , cityCode: "272", city: "FILANDIA"},
{stateCode: "15" , cityCode: "272", city: "FIRAVITOBA"},
{stateCode: "73" , cityCode: "275", city: "FLANDES"},
{stateCode: "18" , cityCode: "1", city: "FLORENCIA"},
{stateCode: "19" , cityCode: "290", city: "FLORENCIA"},
{stateCode: "17" , cityCode: "326", city: "FLORENCIA"},
{stateCode: "15" , cityCode: "276", city: "FLORESTA"},
{stateCode: "68" , cityCode: "271", city: "FLORIAN"},
{stateCode: "76" , cityCode: "275", city: "FLORIDA"},
{stateCode: "68" , cityCode: "278", city: "FLORIDA BLANCA"},
{stateCode: "68" , cityCode: "276", city: "FLORIDABLANCA"},
{stateCode: "25" , cityCode: "279", city: "FOMEQUE"},
{stateCode: "44" , cityCode: "279", city: "FONSECA"},
{stateCode: "25" , cityCode: "3", city: "FONTIBON"},
{stateCode: "81" , cityCode: "300", city: "FORTUL"},
{stateCode: "25" , cityCode: "281", city: "FOSCA"},
{stateCode: "52" , cityCode: "520", city: "FRANCISCO PIZARRO"},
{stateCode: "5" , cityCode: "282", city: "FREDONIA"},
{stateCode: "73" , cityCode: "283", city: "FRESNO"},
{stateCode: "5" , cityCode: "284", city: "FRONTINO"},
{stateCode: "50" , cityCode: "287", city: "FUENTE DE ORO"},
{stateCode: "47" , cityCode: "288", city: "FUNDACION"},
{stateCode: "52" , cityCode: "287", city: "FUNES"},
{stateCode: "25" , cityCode: "286", city: "FUNZA"},
{stateCode: "25" , cityCode: "288", city: "FUQUENE"},
{stateCode: "25" , cityCode: "290", city: "FUSAGASUGA"},
{stateCode: "25" , cityCode: "293", city: "GACHALA"},
{stateCode: "25" , cityCode: "295", city: "GACHANCIPA"},
{stateCode: "15" , cityCode: "293", city: "GACHANTIVA"},
{stateCode: "25" , cityCode: "297", city: "GACHETA"},
{stateCode: "68" , cityCode: "296", city: "GALAN"},
{stateCode: "8" , cityCode: "296", city: "GALAPA"},
{stateCode: "70" , cityCode: "235", city: "GALERAS (NUEVA GRANADA)"},
{stateCode: "25" , cityCode: "299", city: "GAMA"},
{stateCode: "20" , cityCode: "295", city: "GAMARRA"},
{stateCode: "68" , cityCode: "298", city: "GAMBITA"},
{stateCode: "15" , cityCode: "296", city: "GAMEZA"},
{stateCode: "15" , cityCode: "299", city: "GARAGOA"},
{stateCode: "41" , cityCode: "298", city: "GARZON"},
{stateCode: "63" , cityCode: "302", city: "GENOVA"},
{stateCode: "41" , cityCode: "306", city: "GIGANTE"},
{stateCode: "76" , cityCode: "306", city: "GINEBRA"},
{stateCode: "5" , cityCode: "306", city: "GIRALDO"},
{stateCode: "25" , cityCode: "307", city: "GIRARDOT"},
{stateCode: "5" , cityCode: "308", city: "GIRARDOTA"},
{stateCode: "68" , cityCode: "307", city: "GIRON"},
{stateCode: "5" , cityCode: "310", city: "GOMEZ PLATA"},
{stateCode: "20" , cityCode: "310", city: "GONZALEZ"},
{stateCode: "54" , cityCode: "313", city: "GRAMALOTE"},
{stateCode: "25" , cityCode: "312", city: "GRANADA"},
{stateCode: "5" , cityCode: "313", city: "GRANADA"},
{stateCode: "50" , cityCode: "313", city: "GRANADA"},
{stateCode: "68" , cityCode: "318", city: "GUACA"},
{stateCode: "15" , cityCode: "317", city: "GUACAMAYAS"},
{stateCode: "76" , cityCode: "318", city: "GUACARI"},
{stateCode: "25" , cityCode: "317", city: "GUACHETA"},
{stateCode: "52" , cityCode: "317", city: "GUACHUCAL"},
{stateCode: "5" , cityCode: "315", city: "GUADALUPE"},
{stateCode: "41" , cityCode: "319", city: "GUADALUPE"},
{stateCode: "68" , cityCode: "320", city: "GUADALUPE"},
{stateCode: "25" , cityCode: "320", city: "GUADUAS"},
{stateCode: "52" , cityCode: "320", city: "GUAITARILLA"},
{stateCode: "73" , cityCode: "321", city: "GUALANDAY"},
{stateCode: "52" , cityCode: "323", city: "GUALMATAN"},
{stateCode: "47" , cityCode: "318", city: "GUAMAL"},
{stateCode: "50" , cityCode: "318", city: "GUAMAL"},
{stateCode: "73" , cityCode: "319", city: "GUAMO"},
{stateCode: "19" , cityCode: "318", city: "GUAPI"},
{stateCode: "68" , cityCode: "322", city: "GUAPOTA"},
{stateCode: "70" , cityCode: "265", city: "GUARANDA"},
{stateCode: "5" , cityCode: "318", city: "GUARNE"},
{stateCode: "25" , cityCode: "322", city: "GUASCA"},
{stateCode: "5" , cityCode: "321", city: "GUATAPE"},
{stateCode: "25" , cityCode: "324", city: "GUATAQUI"},
{stateCode: "25" , cityCode: "326", city: "GUATAVITA"},
{stateCode: "15" , cityCode: "322", city: "GUATEQUE"},
{stateCode: "66" , cityCode: "318", city: "GUATICA"},
{stateCode: "68" , cityCode: "324", city: "GUAVATA"},
{stateCode: "94" , cityCode: "343", city: "GUAVIARE (BARRANCO MINAS)"},
{stateCode: "73" , cityCode: "325", city: "GUAYABAL"},
{stateCode: "25" , cityCode: "328", city: "GUAYABAL DE SIQUIMA"},
{stateCode: "25" , cityCode: "335", city: "GUAYABETAL"},
{stateCode: "15" , cityCode: "325", city: "GUAYATA"},
{stateCode: "68" , cityCode: "327", city: "GUEPSA"},
{stateCode: "15" , cityCode: "332", city: "GUICAN"},
{stateCode: "25" , cityCode: "339", city: "GUTIERREZ"},
{stateCode: "54" , cityCode: "344", city: "HACARI"},
{stateCode: "13" , cityCode: "300", city: "HATILLO DE LOBA"},
{stateCode: "68" , cityCode: "344", city: "HATO"},
{stateCode: "85" , cityCode: "125", city: "HATO COROZAL"},
{stateCode: "44" , cityCode: "378", city: "HATONUEVO"},
{stateCode: "5" , cityCode: "347", city: "HELICONIA"},
{stateCode: "54" , cityCode: "347", city: "HERRAN"},
{stateCode: "73" , cityCode: "347", city: "HERVEO"},
{stateCode: "5" , cityCode: "353", city: "HISPANIA"},
{stateCode: "41" , cityCode: "349", city: "HOBO"},
{stateCode: "73" , cityCode: "349", city: "HONDA"},
{stateCode: "73" , cityCode: "1", city: "IBAGUE"},
{stateCode: "73" , cityCode: "352", city: "ICONONZO"},
{stateCode: "52" , cityCode: "352", city: "ILES"},
{stateCode: "52" , cityCode: "354", city: "IMUES"},
{stateCode: "94" , cityCode: "1", city: "INIRIDA"},
{stateCode: "19" , cityCode: "355", city: "INZA"},
{stateCode: "52" , cityCode: "356", city: "IPIALES"},
{stateCode: "41" , cityCode: "357", city: "IQUIRA"},
{stateCode: "27" , cityCode: "203", city: "ISLA DE PALACIOS"},
{stateCode: "41" , cityCode: "359", city: "ISNOS (SAN JOSE DE)"},
{stateCode: "5" , cityCode: "360", city: "ITAGUI"},
{stateCode: "27" , cityCode: "361", city: "ITSMINA"},
{stateCode: "5" , cityCode: "361", city: "ITUANGO"},
{stateCode: "15" , cityCode: "362", city: "IZA"},
{stateCode: "19" , cityCode: "364", city: "JAMBALO"},
{stateCode: "76" , cityCode: "364", city: "JAMUNDI"},
{stateCode: "5" , cityCode: "364", city: "JARDIN"},
{stateCode: "15" , cityCode: "367", city: "JENESANO"},
{stateCode: "5" , cityCode: "368", city: "JERICO"},
{stateCode: "15" , cityCode: "368", city: "JERICO"},
{stateCode: "25" , cityCode: "368", city: "JERUSALEN"},
{stateCode: "68" , cityCode: "368", city: "JESUS MARIA"},
{stateCode: "68" , cityCode: "370", city: "JORDAN"},
{stateCode: "8" , cityCode: "372", city: "JUAN DE ACOSTA"},
{stateCode: "25" , cityCode: "372", city: "JUNIN"},
{stateCode: "27" , cityCode: "372", city: "JURADO"},
{stateCode: "23" , cityCode: "350", city: "LA APARTADA (LA FRONTERA)"},
{stateCode: "41" , cityCode: "378", city: "LA ARGENTINA"},
{stateCode: "68" , cityCode: "377", city: "LA BELLEZA"},
{stateCode: "25" , cityCode: "377", city: "LA CALERA"},
{stateCode: "15" , cityCode: "380", city: "LA CAPILLA"},
{stateCode: "5" , cityCode: "376", city: "LA CEJA"},
{stateCode: "66" , cityCode: "383", city: "LA CELIA"},
{stateCode: "91" , cityCode: "405", city: "LA CHORRERA"},
{stateCode: "52" , cityCode: "378", city: "LA CRUZ"},
{stateCode: "76" , cityCode: "377", city: "LA CUMBRE"},
{stateCode: "17" , cityCode: "380", city: "LA DORADA"},
{stateCode: "54" , cityCode: "385", city: "LA ESPERANZA"},
{stateCode: "5" , cityCode: "380", city: "LA ESTRELLA"},
{stateCode: "52" , cityCode: "381", city: "LA FLORIDA"},
{stateCode: "20" , cityCode: "383", city: "LA GLORIA"},
{stateCode: "70" , cityCode: "390", city: "LA GLORIA"},
{stateCode: "94" , cityCode: "885", city: "LA GUADALUPE"},
{stateCode: "86" , cityCode: "500", city: "LA HORMIGA"},
{stateCode: "20" , cityCode: "400", city: "LA JAGUA DE IBIRICO"},
{stateCode: "52" , cityCode: "385", city: "LA LLANADA"},
{stateCode: "50" , cityCode: "350", city: "LA MACARENA"},
{stateCode: "17" , cityCode: "388", city: "LA MERCED"},
{stateCode: "25" , cityCode: "386", city: "LA MESA"},
{stateCode: "18" , cityCode: "410", city: "LA MONTA?ITA"},
{stateCode: "25" , cityCode: "394", city: "LA PALMA"},
{stateCode: "68" , cityCode: "397", city: "LA PAZ"},
{stateCode: "20" , cityCode: "621", city: "LA PAZ (ROBLES)"},
{stateCode: "25" , cityCode: "398", city: "LA PE?A"},
{stateCode: "91" , cityCode: "407", city: "LA PEDRERA"},
{stateCode: "5" , cityCode: "390", city: "LA PINTADA"},
{stateCode: "5" , cityCode: "394", city: "LA PINTADA"},
{stateCode: "41" , cityCode: "396", city: "LA PLATA"},
{stateCode: "54" , cityCode: "398", city: "LA PLAYA"},
{stateCode: "99" , cityCode: "524", city: "LA PRIMAVERA"},
{stateCode: "85" , cityCode: "136", city: "LA SALINA"},
{stateCode: "19" , cityCode: "392", city: "LA SIERRA"},
{stateCode: "63" , cityCode: "401", city: "LA TEBAIDA"},
{stateCode: "52" , cityCode: "390", city: "LA TOLA"},
{stateCode: "52" , cityCode: "399", city: "LA UNION"},
{stateCode: "76" , cityCode: "400", city: "LA UNION"},
{stateCode: "5" , cityCode: "400", city: "LA UNION"},
{stateCode: "70" , cityCode: "400", city: "LA UNION"},
{stateCode: "50" , cityCode: "370", city: "LA URIBE"},
{stateCode: "15" , cityCode: "403", city: "LA UVITA"},
{stateCode: "19" , cityCode: "397", city: "LA VEGA"},
{stateCode: "25" , cityCode: "402", city: "LA VEGA"},
{stateCode: "15" , cityCode: "401", city: "LA VICTORIA"},
{stateCode: "76" , cityCode: "403", city: "LA VICTORIA"},
{stateCode: "66" , cityCode: "400", city: "LA VIRGINIA"},
{stateCode: "54" , cityCode: "377", city: "LABATECA"},
{stateCode: "15" , cityCode: "377", city: "LABRANZAGRANDE"},
{stateCode: "68" , cityCode: "385", city: "LANDAZURI"},
{stateCode: "68" , cityCode: "406", city: "LEBRIJA"},
{stateCode: "52" , cityCode: "405", city: "LEIVA"},
{stateCode: "50" , cityCode: "400", city: "LEJANIAS"},
{stateCode: "25" , cityCode: "407", city: "LENGUAZAQUE"},
{stateCode: "73" , cityCode: "408", city: "LERIDA"},
{stateCode: "91" , cityCode: "1", city: "LETICIA"},
{stateCode: "73" , cityCode: "411", city: "LIBANO"},
{stateCode: "5" , cityCode: "411", city: "LIBORINA"},
{stateCode: "52" , cityCode: "411", city: "LINARES"},
{stateCode: "27" , cityCode: "413", city: "LLORO"},
{stateCode: "19" , cityCode: "418", city: "LOPEZ (MICAY)"},
{stateCode: "23" , cityCode: "417", city: "LORICA"},
{stateCode: "52" , cityCode: "418", city: "LOS ANDES (SOTOMAYOR)"},
{stateCode: "23" , cityCode: "419", city: "LOS CORDOBAS"},
{stateCode: "70" , cityCode: "418", city: "LOS PALMITOS"},
{stateCode: "54" , cityCode: "405", city: "LOS PATIOS"},
{stateCode: "68" , cityCode: "418", city: "LOS SANTOS"},
{stateCode: "54" , cityCode: "418", city: "LOURDES"},
{stateCode: "8" , cityCode: "421", city: "LURUACO"},
{stateCode: "15" , cityCode: "425", city: "MACANAL"},
{stateCode: "68" , cityCode: "425", city: "MACARAVITA"},
{stateCode: "5" , cityCode: "425", city: "MACEO"},
{stateCode: "25" , cityCode: "426", city: "MACHETA"},
{stateCode: "25" , cityCode: "430", city: "MADRID"},
{stateCode: "13" , cityCode: "430", city: "MAGANGUE"},
{stateCode: "52" , cityCode: "427", city: "MAGUI (PAYAN)"},
{stateCode: "13" , cityCode: "433", city: "MAHATES"},
{stateCode: "44" , cityCode: "430", city: "MAICAO"},
{stateCode: "70" , cityCode: "429", city: "MAJAGUAL"},
{stateCode: "68" , cityCode: "432", city: "MALAGA"},
{stateCode: "8" , cityCode: "433", city: "MALAMBO"},
{stateCode: "52" , cityCode: "435", city: "MALLAMA (PIEDRANCHA)"},
{stateCode: "8" , cityCode: "436", city: "MANATI"},
{stateCode: "44" , cityCode: "560", city: "MANAURE"},
{stateCode: "20" , cityCode: "443", city: "MANAURE BALCON DEL CESAR"},
{stateCode: "85" , cityCode: "139", city: "MANI"},
{stateCode: "17" , cityCode: "1", city: "MANIZALEZ"},
{stateCode: "25" , cityCode: "436", city: "MANTA"},
{stateCode: "17" , cityCode: "433", city: "MANZANARES"},
{stateCode: "50" , cityCode: "325", city: "MAPIRIPAN"},
{stateCode: "13" , cityCode: "440", city: "MARGARITA"},
{stateCode: "13" , cityCode: "442", city: "MARIA LA BAJA"},
{stateCode: "5" , cityCode: "440", city: "MARINILLA"},
{stateCode: "15" , cityCode: "442", city: "MARIPI"},
{stateCode: "73" , cityCode: "443", city: "MARIQUITA"},
{stateCode: "17" , cityCode: "442", city: "MARMATO"},
{stateCode: "17" , cityCode: "444", city: "MARQUETALIA"},
{stateCode: "66" , cityCode: "440", city: "MARSELLA"},
{stateCode: "17" , cityCode: "446", city: "MARULANDA"},
{stateCode: "68" , cityCode: "444", city: "MATANZA"},
{stateCode: "5" , cityCode: "1", city: "MEDELLIN"},
{stateCode: "25" , cityCode: "438", city: "MEDINA"},
{stateCode: "73" , cityCode: "449", city: "MELGAR"},
{stateCode: "19" , cityCode: "450", city: "MERCADERES"},
{stateCode: "50" , cityCode: "330", city: "MESETAS"},
{stateCode: "18" , cityCode: "460", city: "MILAN"},
{stateCode: "95" , cityCode: "200", city: "MIRAFLORES"},
{stateCode: "15" , cityCode: "455", city: "MIRAFLORES"},
{stateCode: "19" , cityCode: "455", city: "MIRANDA"},
{stateCode: "91" , cityCode: "460", city: "MIRITI-PARANA"},
{stateCode: "66" , cityCode: "456", city: "MISTRATO"},
{stateCode: "97" , cityCode: "1", city: "MITU"},
{stateCode: "23" , cityCode: "500", city: "MO?ITOS"},
{stateCode: "86" , cityCode: "1", city: "MOCOA"},
{stateCode: "68" , cityCode: "464", city: "MOGOTES"},
{stateCode: "68" , cityCode: "468", city: "MOLAGAVITA"},
{stateCode: "23" , cityCode: "464", city: "MOMIL"},
{stateCode: "13" , cityCode: "468", city: "MOMPOS"},
{stateCode: "15" , cityCode: "464", city: "MONGUA"},
{stateCode: "15" , cityCode: "466", city: "MONGUI"},
{stateCode: "15" , cityCode: "469", city: "MONIQUIRA"},
{stateCode: "5" , cityCode: "467", city: "MONTEBELLO"},
{stateCode: "13" , cityCode: "458", city: "MONTECRISTO"},
{stateCode: "23" , cityCode: "466", city: "MONTELIBANO"},
{stateCode: "63" , cityCode: "470", city: "MONTENEGRO"},
{stateCode: "23" , cityCode: "1", city: "MONTERIA"},
{stateCode: "85" , cityCode: "162", city: "MONTERREY"},
{stateCode: "13" , cityCode: "473", city: "MORALES"},
{stateCode: "19" , cityCode: "473", city: "MORALES"},
{stateCode: "18" , cityCode: "479", city: "MORELIA"},
{stateCode: "94" , cityCode: "888", city: "MORICHAL NUEVO"},
{stateCode: "70" , cityCode: "473", city: "MORROA"},
{stateCode: "25" , cityCode: "473", city: "MOSQUERA"},
{stateCode: "52" , cityCode: "473", city: "MOSQUERA"},
{stateCode: "15" , cityCode: "476", city: "MOTAVITA"},
{stateCode: "73" , cityCode: "461", city: "MURILLO"},
{stateCode: "5" , cityCode: "475", city: "MURINDO"},
{stateCode: "5" , cityCode: "480", city: "MUTATA"},
{stateCode: "54" , cityCode: "480", city: "MUTISCUA"},
{stateCode: "15" , cityCode: "480", city: "MUZO"},
{stateCode: "5" , cityCode: "483", city: "NARI?O"},
{stateCode: "25" , cityCode: "483", city: "NARI?O"},
{stateCode: "41" , cityCode: "483", city: "NATAGA"},
{stateCode: "73" , cityCode: "483", city: "NATAGAIMA"},
{stateCode: "5" , cityCode: "495", city: "NECHI"},
{stateCode: "5" , cityCode: "490", city: "NECOCLI"},
{stateCode: "17" , cityCode: "486", city: "NEIRA"},
{stateCode: "41" , cityCode: "1", city: "NEIVA"},
{stateCode: "25" , cityCode: "486", city: "NEMOCON"},
{stateCode: "25" , cityCode: "488", city: "NILO"},
{stateCode: "25" , cityCode: "489", city: "NIMAIMA"},
{stateCode: "15" , cityCode: "491", city: "NOBSA"},
{stateCode: "25" , cityCode: "491", city: "NOCAIMA"},
{stateCode: "17" , cityCode: "495", city: "NORCASIA"},
{stateCode: "27" , cityCode: "491", city: "NOVITA"},
{stateCode: "15" , cityCode: "494", city: "NUEVO COLON"},
{stateCode: "85" , cityCode: "225", city: "NUNCHIA"},
{stateCode: "27" , cityCode: "495", city: "NUQUI"},
{stateCode: "76" , cityCode: "497", city: "OBANDO"},
{stateCode: "54" , cityCode: "498", city: "OCA?A"},
{stateCode: "68" , cityCode: "498", city: "OCAMONTE"},
{stateCode: "68" , cityCode: "500", city: "OIBA"},
{stateCode: "15" , cityCode: "500", city: "OICATA"},
{stateCode: "5" , cityCode: "501", city: "OLAYA"},
{stateCode: "52" , cityCode: "490", city: "OLAYA HERRERA"},
{stateCode: "68" , cityCode: "502", city: "ONZAGA"},
{stateCode: "41" , cityCode: "503", city: "OPORAPA"},
{stateCode: "86" , cityCode: "320", city: "ORITO"},
{stateCode: "85" , cityCode: "230", city: "OROCUE"},
{stateCode: "73" , cityCode: "504", city: "ORTEGA"},
{stateCode: "52" , cityCode: "506", city: "OSPINA"},
{stateCode: "15" , cityCode: "507", city: "OTANCHE"},
{stateCode: "70" , cityCode: "508", city: "OVEJAS"},
{stateCode: "15" , cityCode: "511", city: "PACHAVITA"},
{stateCode: "25" , cityCode: "513", city: "PACHO"},
{stateCode: "97" , cityCode: "511", city: "PACOA"},
{stateCode: "17" , cityCode: "513", city: "PACORA"},
{stateCode: "19" , cityCode: "513", city: "PADILLA"},
{stateCode: "15" , cityCode: "514", city: "PAEZ"},
{stateCode: "19" , cityCode: "517", city: "PAEZ (BELALCAZAR)"},
{stateCode: "41" , cityCode: "518", city: "PAICOL"},
{stateCode: "20" , cityCode: "517", city: "PAILITAS"},
{stateCode: "25" , cityCode: "518", city: "PAIME"},
{stateCode: "15" , cityCode: "516", city: "PAIPA"},
{stateCode: "15" , cityCode: "518", city: "PAJARITO"},
{stateCode: "41" , cityCode: "524", city: "PALERMO"},
{stateCode: "17" , cityCode: "524", city: "PALESTINA"},
{stateCode: "41" , cityCode: "530", city: "PALESTINA"},
{stateCode: "68" , cityCode: "522", city: "PALMAR"},
{stateCode: "8" , cityCode: "520", city: "PALMAR DE VARELA"},
{stateCode: "68" , cityCode: "524", city: "PALMAS DEL SOCORRO"},
{stateCode: "76" , cityCode: "520", city: "PALMIRA"},
{stateCode: "70" , cityCode: "523", city: "PALMITO"},
{stateCode: "73" , cityCode: "520", city: "PALOCABILDO"},
{stateCode: "73" , cityCode: "530", city: "PALOCABILDO (CREDIBANCO)"},
{stateCode: "54" , cityCode: "518", city: "PAMPLONA"},
{stateCode: "54" , cityCode: "520", city: "PAMPLONITA"},
{stateCode: "94" , cityCode: "887", city: "PANA PANA (CAMPO ALEGRE)"},
{stateCode: "25" , cityCode: "524", city: "PANDI"},
{stateCode: "15" , cityCode: "522", city: "PANQUEBA"},
{stateCode: "97" , cityCode: "777", city: "PAPUNAUA (MORICHAL)"},
{stateCode: "68" , cityCode: "533", city: "PARAMO"},
{stateCode: "25" , cityCode: "530", city: "PARATEBUENO"},
{stateCode: "25" , cityCode: "535", city: "PASCA"},
{stateCode: "52" , cityCode: "1", city: "PASTO (SAN JUAN DE)"},
{stateCode: "19" , cityCode: "532", city: "PATIA(EL BORDO)"},
{stateCode: "15" , cityCode: "531", city: "PAUNA"},
{stateCode: "76" , cityCode: "896", city: "PAVAS"},
{stateCode: "15" , cityCode: "533", city: "PAYA"},
{stateCode: "85" , cityCode: "250", city: "PAZ DE ARIPORO"},
{stateCode: "15" , cityCode: "537", city: "PAZ DE RIO"},
{stateCode: "5" , cityCode: "541", city: "PE?OL"},
{stateCode: "47" , cityCode: "541", city: "PEDRAZA"},
{stateCode: "20" , cityCode: "550", city: "PELAYA"},
{stateCode: "17" , cityCode: "541", city: "PENSILVANIA"},
{stateCode: "5" , cityCode: "543", city: "PEQUE"},
{stateCode: "66" , cityCode: "1", city: "PEREIRA"},
{stateCode: "15" , cityCode: "542", city: "PESCA"},
{stateCode: "19" , cityCode: "533", city: "PIAMONTE"},
{stateCode: "68" , cityCode: "547", city: "PIEDECUESTA"},
{stateCode: "73" , cityCode: "547", city: "PIEDRAS"},
{stateCode: "19" , cityCode: "548", city: "PIENDAMO"},
{stateCode: "63" , cityCode: "548", city: "PIJAO"},
{stateCode: "47" , cityCode: "545", city: "PIJI?O DEL CARMEN"},
{stateCode: "68" , cityCode: "549", city: "PINCHOTE"},
{stateCode: "13" , cityCode: "549", city: "PINILLOS"},
{stateCode: "8" , cityCode: "549", city: "PIOJO"},
{stateCode: "15" , cityCode: "550", city: "PISVA"},
{stateCode: "41" , cityCode: "548", city: "PITAL"},
{stateCode: "41" , cityCode: "551", city: "PITALITO"},
{stateCode: "47" , cityCode: "551", city: "PIVIJAY"},
{stateCode: "73" , cityCode: "555", city: "PLANADAS"},
{stateCode: "23" , cityCode: "555", city: "PLANETA RICA"},
{stateCode: "47" , cityCode: "555", city: "PLATO"},
{stateCode: "52" , cityCode: "540", city: "POLICARPA"},
{stateCode: "8" , cityCode: "558", city: "POLONUEVO"},
{stateCode: "8" , cityCode: "560", city: "PONEDERA"},
{stateCode: "19" , cityCode: "1", city: "POPAYAN"},
{stateCode: "85" , cityCode: "263", city: "PORE"},
{stateCode: "52" , cityCode: "560", city: "POTOSI"},
{stateCode: "76" , cityCode: "563", city: "PRADERA"},
{stateCode: "73" , cityCode: "563", city: "PRADO"},
{stateCode: "88" , cityCode: "564", city: "PROVIDENCIA"},
{stateCode: "52" , cityCode: "565", city: "PROVIDENCIA"},
{stateCode: "20" , cityCode: "570", city: "PUEBLO BELLO"},
{stateCode: "23" , cityCode: "570", city: "PUEBLO NUEVO"},
{stateCode: "66" , cityCode: "572", city: "PUEBLO RICO"},
{stateCode: "5" , cityCode: "576", city: "PUEBLORRICO"},
{stateCode: "47" , cityCode: "570", city: "PUEBLOVIEJO"},
{stateCode: "68" , cityCode: "572", city: "PUENTE NACIONAL"},
{stateCode: "52" , cityCode: "573", city: "PUERRES"},
{stateCode: "86" , cityCode: "573", city: "PUERTO  LEGUIZAMO"},
{stateCode: "86" , cityCode: "568", city: "PUERTO ASIS"},
{stateCode: "5" , cityCode: "579", city: "PUERTO BERRIO"},
{stateCode: "15" , cityCode: "572", city: "PUERTO BOYACA"},
{stateCode: "86" , cityCode: "569", city: "PUERTO CAICEDO"},
{stateCode: "99" , cityCode: "1", city: "PUERTO CARRE?O"},
{stateCode: "8" , cityCode: "573", city: "PUERTO COLOMBIA"},
{stateCode: "94" , cityCode: "884", city: "PUERTO COLOMBIA"},
{stateCode: "50" , cityCode: "450", city: "PUERTO CONCORDIA"},
{stateCode: "23" , cityCode: "574", city: "PUERTO ESCONDIDO"},
{stateCode: "50" , cityCode: "568", city: "PUERTO GAITAN"},
{stateCode: "86" , cityCode: "571", city: "PUERTO GUZMAN"},
{stateCode: "23" , cityCode: "580", city: "PUERTO LIBERTADOR"},
{stateCode: "50" , cityCode: "577", city: "PUERTO LLERAS"},
{stateCode: "50" , cityCode: "573", city: "PUERTO LOPEZ"},
{stateCode: "5" , cityCode: "585", city: "PUERTO NARE (LA MAGDALENA)"},
{stateCode: "91" , cityCode: "540", city: "PUERTO NARI?O"},
{stateCode: "68" , cityCode: "573", city: "PUERTO PARRA"},
{stateCode: "50" , cityCode: "590", city: "PUERTO RICO"},
{stateCode: "18" , cityCode: "592", city: "PUERTO RICO"},
{stateCode: "81" , cityCode: "591", city: "PUERTO RONDON"},
{stateCode: "25" , cityCode: "572", city: "PUERTO SALGAR"},
{stateCode: "54" , cityCode: "553", city: "PUERTO SANTANDER"},
{stateCode: "91" , cityCode: "669", city: "PUERTO SANTANDER"},
{stateCode: "19" , cityCode: "573", city: "PUERTO TEJADA"},
{stateCode: "5" , cityCode: "591", city: "PUERTO TRIUNFO"},
{stateCode: "68" , cityCode: "575", city: "PUERTO WILCHES"},
{stateCode: "25" , cityCode: "580", city: "PULI"},
{stateCode: "52" , cityCode: "585", city: "PUPIALES"},
{stateCode: "19" , cityCode: "585", city: "PURACE (COCONUCO)"},
{stateCode: "73" , cityCode: "585", city: "PURIFICACION"},
{stateCode: "23" , cityCode: "586", city: "PURISIMA"},
{stateCode: "25" , cityCode: "592", city: "QUEBRADANEGRA"},
{stateCode: "25" , cityCode: "594", city: "QUETAME"},
{stateCode: "27" , cityCode: "1", city: "QUIBDO"},
{stateCode: "63" , cityCode: "594", city: "QUIMBAYA"},
{stateCode: "66" , cityCode: "594", city: "QUINCHIA"},
{stateCode: "15" , cityCode: "580", city: "QUIPAMA"},
{stateCode: "25" , cityCode: "596", city: "QUIPILE"},
{stateCode: "25" , cityCode: "599", city: "RAFAEL REYES (APULO)"},
{stateCode: "54" , cityCode: "599", city: "RAGONVALIA"},
{stateCode: "15" , cityCode: "599", city: "RAMIRIQUI"},
{stateCode: "15" , cityCode: "600", city: "RAQUIRA"},
{stateCode: "85" , cityCode: "279", city: "RECETOR"},
{stateCode: "13" , cityCode: "580", city: "REGIDOR"},
{stateCode: "5" , cityCode: "604", city: "REMEDIOS"},
{stateCode: "47" , cityCode: "605", city: "REMOLINO"},
{stateCode: "8" , cityCode: "606", city: "REPELON"},
{stateCode: "76" , cityCode: "606", city: "RESTREPO"},
{stateCode: "50" , cityCode: "606", city: "RESTREPO"},
{stateCode: "5" , cityCode: "607", city: "RETIRO"},
{stateCode: "25" , cityCode: "612", city: "RICAURTE"},
{stateCode: "52" , cityCode: "612", city: "RICAURTE"},
{stateCode: "20" , cityCode: "614", city: "RIO DE ORO"},
{stateCode: "13" , cityCode: "600", city: "RIO VIEJO"},
{stateCode: "73" , cityCode: "616", city: "RIOBLANCO"},
{stateCode: "76" , cityCode: "616", city: "RIOFRIO"},
{stateCode: "44" , cityCode: "1", city: "RIOHACHA"},
{stateCode: "5" , cityCode: "615", city: "RIONEGRO"},
{stateCode: "68" , cityCode: "615", city: "RIONEGRO"},
{stateCode: "66" , cityCode: "80", city: "RIOSUCIO"},
{stateCode: "17" , cityCode: "614", city: "RIOSUCIO"},
{stateCode: "27" , cityCode: "615", city: "RIOSUCIO"},
{stateCode: "17" , cityCode: "616", city: "RISARALDA"},
{stateCode: "41" , cityCode: "615", city: "RIVERA"},
{stateCode: "52" , cityCode: "621", city: "ROBERTO PAYAN (SAN JOSE)"},
{stateCode: "76" , cityCode: "622", city: "ROLDANILLO"},
{stateCode: "73" , cityCode: "622", city: "RONCESVALLES"},
{stateCode: "15" , cityCode: "621", city: "RONDON"},
{stateCode: "19" , cityCode: "622", city: "ROSAS"},
{stateCode: "73" , cityCode: "624", city: "ROVIRA"},
{stateCode: "76" , cityCode: "897", city: "ROZO"},
{stateCode: "68" , cityCode: "655", city: "SABANA DE TORRES"},
{stateCode: "8" , cityCode: "634", city: "SABANAGRANDE"},
{stateCode: "85" , cityCode: "300", city: "SABANALARGA"},
{stateCode: "5" , cityCode: "628", city: "SABANALARGA"},
{stateCode: "8" , cityCode: "638", city: "SABANALARGA"},
{stateCode: "5" , cityCode: "631", city: "SABANETA"},
{stateCode: "15" , cityCode: "632", city: "SABOYA"},
{stateCode: "85" , cityCode: "315", city: "SACAMA"},
{stateCode: "15" , cityCode: "638", city: "SACHICA"},
{stateCode: "23" , cityCode: "660", city: "SAHAGUN"},
{stateCode: "41" , cityCode: "660", city: "SALADOBLANCO"},
{stateCode: "17" , cityCode: "653", city: "SALAMINA"},
{stateCode: "47" , cityCode: "675", city: "SALAMINA"},
{stateCode: "54" , cityCode: "660", city: "SALAZAR"},
{stateCode: "73" , cityCode: "671", city: "SALDA?A"},
{stateCode: "63" , cityCode: "690", city: "SALENTO"},
{stateCode: "5" , cityCode: "642", city: "SALGAR"},
{stateCode: "8" , cityCode: "646", city: "SALGAR"},
{stateCode: "15" , cityCode: "646", city: "SAMACA"},
{stateCode: "17" , cityCode: "662", city: "SAMANA"},
{stateCode: "52" , cityCode: "678", city: "SAMANIEGO"},
{stateCode: "70" , cityCode: "670", city: "SAMPUES"},
{stateCode: "41" , cityCode: "668", city: "SAN AGUSTIN"},
{stateCode: "20" , cityCode: "710", city: "SAN ALBERTO"},
{stateCode: "88" , cityCode: "1", city: "SAN ANDRES"},
{stateCode: "5" , cityCode: "647", city: "SAN ANDRES"},
{stateCode: "68" , cityCode: "669", city: "SAN ANDRES"},
{stateCode: "23" , cityCode: "670", city: "SAN ANDRES SOTAVENTO"},
{stateCode: "23" , cityCode: "672", city: "SAN ANTERO"},
{stateCode: "54" , cityCode: "665", city: "SAN ANTONIO"},
{stateCode: "73" , cityCode: "675", city: "SAN ANTONIO"},
{stateCode: "25" , cityCode: "645", city: "SAN ANTONIO DE TEQUENDAMA"},
{stateCode: "68" , cityCode: "673", city: "SAN BENITO"},
{stateCode: "70" , cityCode: "678", city: "SAN BENITO ABAD"},
{stateCode: "25" , cityCode: "649", city: "SAN BERNARDO"},
{stateCode: "52" , cityCode: "685", city: "SAN BERNARDO"},
{stateCode: "23" , cityCode: "675", city: "SAN BERNARDO DEL VIENTO"},
{stateCode: "54" , cityCode: "670", city: "SAN CALIXTO"},
{stateCode: "5" , cityCode: "649", city: "SAN CARLOS"},
{stateCode: "23" , cityCode: "678", city: "SAN CARLOS"},
{stateCode: "50" , cityCode: "680", city: "SAN CARLOS DE GUAROA"},
{stateCode: "25" , cityCode: "653", city: "SAN CAYETANO"},
{stateCode: "54" , cityCode: "673", city: "SAN CAYETANO"},
{stateCode: "13" , cityCode: "620", city: "SAN CRISTOBAL"},
{stateCode: "20" , cityCode: "750", city: "SAN DIEGO"},
{stateCode: "15" , cityCode: "660", city: "SAN EDUARDO"},
{stateCode: "13" , cityCode: "647", city: "SAN ESTANISLAO"},
{stateCode: "94" , cityCode: "883", city: "SAN FELIPE"},
{stateCode: "17" , cityCode: "670", city: "SAN FELIX"},
{stateCode: "13" , cityCode: "650", city: "SAN FERNANDO"},
{stateCode: "5" , cityCode: "652", city: "SAN FRANCISCO"},
{stateCode: "25" , cityCode: "658", city: "SAN FRANCISCO"},
{stateCode: "86" , cityCode: "755", city: "SAN FRANCISCO"},
{stateCode: "5" , cityCode: "654", city: "SAN GABRIEL"},
{stateCode: "68" , cityCode: "679", city: "SAN GIL"},
{stateCode: "13" , cityCode: "654", city: "SAN JACINTO"},
{stateCode: "13" , cityCode: "655", city: "SAN JACINTO DEL CAUCA"},
{stateCode: "5" , cityCode: "656", city: "SAN JERONIMO"},
{stateCode: "68" , cityCode: "682", city: "SAN JOAQUIN"},
{stateCode: "17" , cityCode: "665", city: "SAN JOSE"},
{stateCode: "5" , cityCode: "658", city: "SAN JOSE DE LA MONTA?A"},
{stateCode: "68" , cityCode: "684", city: "SAN JOSE DE MIRANDA"},
{stateCode: "15" , cityCode: "664", city: "SAN JOSE DE PARE"},
{stateCode: "18" , cityCode: "610", city: "SAN JOSE DEL FRAGUA"},
{stateCode: "95" , cityCode: "1", city: "SAN JOSE DEL GUAVIARE"},
{stateCode: "27" , cityCode: "660", city: "SAN JOSE DEL PALMAR"},
{stateCode: "50" , cityCode: "683", city: "SAN JUAN DE ARAMA"},
{stateCode: "70" , cityCode: "702", city: "SAN JUAN DE BETULIA"},
{stateCode: "25" , cityCode: "662", city: "SAN JUAN DE RIO SECO"},
{stateCode: "5" , cityCode: "659", city: "SAN JUAN DE URABA"},
{stateCode: "44" , cityCode: "650", city: "SAN JUAN DEL CESAR"},
{stateCode: "13" , cityCode: "657", city: "SAN JUAN NEPOMUCENO"},
{stateCode: "50" , cityCode: "686", city: "SAN JUANITO"},
{stateCode: "52" , cityCode: "687", city: "SAN LORENZO"},
{stateCode: "5" , cityCode: "660", city: "SAN LUIS"},
{stateCode: "73" , cityCode: "678", city: "SAN LUIS"},
{stateCode: "15" , cityCode: "667", city: "SAN LUIS DE GACENO"},
{stateCode: "85" , cityCode: "325", city: "SAN LUIS DE PALENQUE"},
{stateCode: "70" , cityCode: "708", city: "SAN MARCOS"},
{stateCode: "50" , cityCode: "689", city: "SAN MARTIN"},
{stateCode: "20" , cityCode: "770", city: "SAN MARTIN"},
{stateCode: "13" , cityCode: "667", city: "SAN MARTIN DE LOBA"},
{stateCode: "15" , cityCode: "673", city: "SAN MATEO"},
{stateCode: "68" , cityCode: "686", city: "SAN MIGUEL"},
{stateCode: "86" , cityCode: "757", city: "SAN MIGUEL (LA DORADA)"},
{stateCode: "15" , cityCode: "676", city: "SAN MIGUEL DE SEMA"},
{stateCode: "70" , cityCode: "713", city: "SAN ONOFRE"},
{stateCode: "13" , cityCode: "670", city: "SAN PABLO"},
{stateCode: "52" , cityCode: "693", city: "SAN PABLO"},
{stateCode: "15" , cityCode: "681", city: "SAN PABLO DE BORBUR"},
{stateCode: "5" , cityCode: "664", city: "SAN PEDRO"},
{stateCode: "76" , cityCode: "670", city: "SAN PEDRO"},
{stateCode: "70" , cityCode: "717", city: "SAN PEDRO"},
{stateCode: "52" , cityCode: "694", city: "SAN PEDRO DE CARTAGO"},
{stateCode: "5" , cityCode: "665", city: "SAN PEDRO DE URABA"},
{stateCode: "23" , cityCode: "686", city: "SAN PELAYO"},
{stateCode: "5" , cityCode: "667", city: "SAN RAFAEL"},
{stateCode: "5" , cityCode: "670", city: "SAN ROQUE"},
{stateCode: "19" , cityCode: "693", city: "SAN SEBASTIAN"},
{stateCode: "47" , cityCode: "692", city: "SAN SEBASTIAN DE BUENAVIST"},
{stateCode: "5" , cityCode: "674", city: "SAN VICENTE"},
{stateCode: "68" , cityCode: "689", city: "SAN VICENTE DE CHUCURI"},
{stateCode: "18" , cityCode: "753", city: "SAN VICENTE DEL CAGUAN"},
{stateCode: "47" , cityCode: "703", city: "SAN ZENON"},
{stateCode: "52" , cityCode: "683", city: "SANDONA"},
{stateCode: "47" , cityCode: "707", city: "SANTA ANA"},
{stateCode: "5" , cityCode: "679", city: "SANTA BARBARA"},
{stateCode: "68" , cityCode: "705", city: "SANTA BARBARA"},
{stateCode: "52" , cityCode: "696", city: "SANTA BARBARA (ISCUANDE)"},
{stateCode: "13" , cityCode: "673", city: "SANTA CATALINA"},
{stateCode: "68" , cityCode: "720", city: "SANTA HELENA DEL OPON"},
{stateCode: "73" , cityCode: "686", city: "SANTA ISABEL"},
{stateCode: "8" , cityCode: "675", city: "SANTA LUCIA"},
{stateCode: "41" , cityCode: "676", city: "SANTA MARIA"},
{stateCode: "15" , cityCode: "690", city: "SANTA MARIA"},
{stateCode: "47" , cityCode: "1", city: "SANTA MARTA"},
{stateCode: "13" , cityCode: "683", city: "SANTA ROSA"},
{stateCode: "19" , cityCode: "701", city: "SANTA ROSA"},
{stateCode: "66" , cityCode: "682", city: "SANTA ROSA DE CABAL"},
{stateCode: "5" , cityCode: "686", city: "SANTA ROSA DE OSOS"},
{stateCode: "15" , cityCode: "693", city: "SANTA ROSA DE VITERBO"},
{stateCode: "13" , cityCode: "688", city: "SANTA ROSA DEL SUR"},
{stateCode: "99" , cityCode: "624", city: "SANTA ROSALIA"},
{stateCode: "15" , cityCode: "696", city: "SANTA SOFIA"},
{stateCode: "52" , cityCode: "699", city: "SANTACRUZ (GUACHAVES)"},
{stateCode: "15" , cityCode: "686", city: "SANTANA"},
{stateCode: "76" , cityCode: "625", city: "SANTANDER"},
{stateCode: "19" , cityCode: "698", city: "SANTANDER DE QUILICHAO"},
{stateCode: "54" , cityCode: "680", city: "SANTIAGO"},
{stateCode: "86" , cityCode: "760", city: "SANTIAGO"},
{stateCode: "5" , cityCode: "690", city: "SANTO DOMINGO"},
{stateCode: "8" , cityCode: "683", city: "SANTO TOMAS"},
{stateCode: "8" , cityCode: "685", city: "SANTO TOMAS"},
{stateCode: "66" , cityCode: "687", city: "SANTUARIO"},
{stateCode: "5" , cityCode: "697", city: "SANTUARIO"},
{stateCode: "52" , cityCode: "720", city: "SAPUYES"},
{stateCode: "81" , cityCode: "736", city: "SARAVENA"},
{stateCode: "54" , cityCode: "720", city: "SARDINATA"},
{stateCode: "25" , cityCode: "718", city: "SASAIMA"},
{stateCode: "15" , cityCode: "720", city: "SATIVANORTE"},
{stateCode: "15" , cityCode: "723", city: "SATIVASUR"},
{stateCode: "5" , cityCode: "736", city: "SEGOVIA"},
{stateCode: "25" , cityCode: "736", city: "SESQUILE"},
{stateCode: "76" , cityCode: "736", city: "SEVILLA"},
{stateCode: "15" , cityCode: "740", city: "SIACHOQUE"},
{stateCode: "25" , cityCode: "740", city: "SIBATE"},
{stateCode: "86" , cityCode: "749", city: "SIBUNDOY"},
{stateCode: "54" , cityCode: "743", city: "SILOS"},
{stateCode: "25" , cityCode: "743", city: "SILVANIA"},
{stateCode: "19" , cityCode: "743", city: "SILVIA"},
{stateCode: "68" , cityCode: "745", city: "SIMACOTA"},
{stateCode: "25" , cityCode: "745", city: "SIMIJACA"},
{stateCode: "13" , cityCode: "744", city: "SIMITI"},
{stateCode: "70" , cityCode: "742", city: "SINCE"},
{stateCode: "70" , cityCode: "1", city: "SINCELEJO"},
{stateCode: "27" , cityCode: "745", city: "SIPI"},
{stateCode: "47" , cityCode: "745", city: "SITIONUEVO"},
{stateCode: "25" , cityCode: "754", city: "SOACHA"},
{stateCode: "15" , cityCode: "753", city: "SOATA"},
{stateCode: "15" , cityCode: "757", city: "SOCHA"},
{stateCode: "68" , cityCode: "755", city: "SOCORRO"},
{stateCode: "15" , cityCode: "755", city: "SOCOTA"},
{stateCode: "15" , cityCode: "759", city: "SOGAMOSO"},
{stateCode: "18" , cityCode: "756", city: "SOLANO"},
{stateCode: "8" , cityCode: "758", city: "SOLEDAD"},
{stateCode: "18" , cityCode: "785", city: "SOLITA"},
{stateCode: "15" , cityCode: "761", city: "SOMONDOCO"},
{stateCode: "5" , cityCode: "756", city: "SONSON"},
{stateCode: "5" , cityCode: "761", city: "SOPETRAN"},
{stateCode: "13" , cityCode: "760", city: "SOPLAVIENTO"},
{stateCode: "25" , cityCode: "758", city: "SOPO"},
{stateCode: "15" , cityCode: "762", city: "SORA"},
{stateCode: "15" , cityCode: "764", city: "SORACA"},
{stateCode: "15" , cityCode: "763", city: "SOTAQUIRA"},
{stateCode: "19" , cityCode: "760", city: "SOTARA (PAISPAMBA)"},
{stateCode: "68" , cityCode: "770", city: "SUAITA"},
{stateCode: "8" , cityCode: "770", city: "SUAN"},
{stateCode: "73" , cityCode: "770", city: "SUAREZ"},
{stateCode: "19" , cityCode: "780", city: "SUAREZ"},
{stateCode: "41" , cityCode: "770", city: "SUAZA"},
{stateCode: "25" , cityCode: "769", city: "SUBACHOQUE"},
{stateCode: "70" , cityCode: "771", city: "SUCRE"},
{stateCode: "68" , cityCode: "773", city: "SUCRE"},
{stateCode: "25" , cityCode: "772", city: "SUESCA"},
{stateCode: "25" , cityCode: "777", city: "SUPATA"},
{stateCode: "17" , cityCode: "777", city: "SUPIA"},
{stateCode: "68" , cityCode: "780", city: "SURATA"},
{stateCode: "25" , cityCode: "779", city: "SUSA"},
{stateCode: "15" , cityCode: "774", city: "SUSACON"},
{stateCode: "15" , cityCode: "776", city: "SUTAMARCHAN"},
{stateCode: "25" , cityCode: "781", city: "SUTATAUSA"},
{stateCode: "15" , cityCode: "778", city: "SUTATENZA"},
{stateCode: "25" , cityCode: "785", city: "TABIO"},
{stateCode: "27" , cityCode: "787", city: "TADO"},
{stateCode: "13" , cityCode: "780", city: "TALAIGUA NUEVO"},
{stateCode: "20" , cityCode: "787", city: "TAMALAMEQUE"},
{stateCode: "85" , cityCode: "400", city: "TAMARA"},
{stateCode: "81" , cityCode: "794", city: "TAME"},
{stateCode: "5" , cityCode: "789", city: "TAMESIS"},
{stateCode: "52" , cityCode: "786", city: "TAMINANGO"},
{stateCode: "52" , cityCode: "788", city: "TANGUA"},
{stateCode: "97" , cityCode: "666", city: "TARAIRA"},
{stateCode: "91" , cityCode: "798", city: "TARAPACA"},
{stateCode: "5" , cityCode: "790", city: "TARAZA"},
{stateCode: "41" , cityCode: "791", city: "TARQUI"},
{stateCode: "5" , cityCode: "792", city: "TARSO"},
{stateCode: "15" , cityCode: "790", city: "TASCO"},
{stateCode: "85" , cityCode: "410", city: "TAURAMENA"},
{stateCode: "25" , cityCode: "793", city: "TAUSA"},
{stateCode: "41" , cityCode: "799", city: "TELLO"},
{stateCode: "25" , cityCode: "797", city: "TENA"},
{stateCode: "47" , cityCode: "798", city: "TENERIFE"},
{stateCode: "25" , cityCode: "799", city: "TENJO"},
{stateCode: "15" , cityCode: "798", city: "TENZA"},
{stateCode: "54" , cityCode: "800", city: "TEORAMA"},
{stateCode: "41" , cityCode: "801", city: "TERUEL"},
{stateCode: "41" , cityCode: "797", city: "TESALIA"},
{stateCode: "25" , cityCode: "805", city: "TIBACUY"},
{stateCode: "15" , cityCode: "804", city: "TIBANA"},
{stateCode: "15" , cityCode: "806", city: "TIBASOSA"},
{stateCode: "25" , cityCode: "807", city: "TIBIRITA"},
{stateCode: "54" , cityCode: "810", city: "TIBU"},
{stateCode: "23" , cityCode: "807", city: "TIERRALTA"},
{stateCode: "41" , cityCode: "807", city: "TIMANA"},
{stateCode: "19" , cityCode: "807", city: "TIMBIO"},
{stateCode: "19" , cityCode: "809", city: "TIMBIQUI"},
{stateCode: "15" , cityCode: "808", city: "TINJACA"},
{stateCode: "15" , cityCode: "810", city: "TIPACOQUE"},
{stateCode: "13" , cityCode: "810", city: "TIQUISIO (PUERTO RICO)"},
{stateCode: "5" , cityCode: "809", city: "TITIRIBI"},
{stateCode: "15" , cityCode: "814", city: "TOCA"},
{stateCode: "25" , cityCode: "815", city: "TOCAIMA"},
{stateCode: "25" , cityCode: "817", city: "TOCANCIPA"},
{stateCode: "15" , cityCode: "816", city: "TOGUI"},
{stateCode: "5" , cityCode: "819", city: "TOLEDO"},
{stateCode: "54" , cityCode: "820", city: "TOLEDO"},
{stateCode: "70" , cityCode: "820", city: "TOLU"},
{stateCode: "70" , cityCode: "823", city: "TOLUVIEJO"},
{stateCode: "68" , cityCode: "820", city: "TONA"},
{stateCode: "15" , cityCode: "820", city: "TOPAGA"},
{stateCode: "25" , cityCode: "823", city: "TOPAIPI"},
{stateCode: "19" , cityCode: "821", city: "TORIBIO"},
{stateCode: "76" , cityCode: "823", city: "TORO"},
{stateCode: "15" , cityCode: "822", city: "TOTA"},
{stateCode: "19" , cityCode: "824", city: "TOTORO"},
{stateCode: "85" , cityCode: "430", city: "TRINIDAD"},
{stateCode: "76" , cityCode: "828", city: "TRUJILLO"},
{stateCode: "8" , cityCode: "832", city: "TUBARA"},
{stateCode: "76" , cityCode: "834", city: "TULUA"},
{stateCode: "52" , cityCode: "835", city: "TUMACO"},
{stateCode: "15" , cityCode: "1", city: "TUNJA"},
{stateCode: "15" , cityCode: "832", city: "TUNUNGUA"},
{stateCode: "52" , cityCode: "838", city: "TUQUERRES"},
{stateCode: "13" , cityCode: "836", city: "TURBACO"},
{stateCode: "13" , cityCode: "838", city: "TURBANA"},
{stateCode: "5" , cityCode: "837", city: "TURBO"},
{stateCode: "15" , cityCode: "835", city: "TURMEQUE"},
{stateCode: "15" , cityCode: "837", city: "TUTA"},
{stateCode: "15" , cityCode: "839", city: "TUTASA"},
{stateCode: "25" , cityCode: "839", city: "UBALA"},
{stateCode: "25" , cityCode: "841", city: "UBAQUE"},
{stateCode: "25" , cityCode: "843", city: "UBATE"},
{stateCode: "76" , cityCode: "845", city: "ULLOA"},
{stateCode: "15" , cityCode: "842", city: "UMBITA"},
{stateCode: "25" , cityCode: "845", city: "UNE"},
{stateCode: "27" , cityCode: "800", city: "UNGUIA"},
{stateCode: "5" , cityCode: "842", city: "URAMITA"},
{stateCode: "54" , cityCode: "830", city: "URE?A"},
{stateCode: "44" , cityCode: "847", city: "URIBIA"},
{stateCode: "5" , cityCode: "847", city: "URRAO"},
{stateCode: "44" , cityCode: "855", city: "URUMITA"},
{stateCode: "8" , cityCode: "849", city: "USIACURI"},
{stateCode: "25" , cityCode: "851", city: "UTICA"},
{stateCode: "5" , cityCode: "854", city: "VALDIVIA"},
{stateCode: "23" , cityCode: "855", city: "VALENCIA"},
{stateCode: "68" , cityCode: "855", city: "VALLE DE SAN JOSE"},
{stateCode: "73" , cityCode: "854", city: "VALLE DE SAN JUAN"},
{stateCode: "20" , cityCode: "1", city: "VALLEDUPAR"},
{stateCode: "5" , cityCode: "856", city: "VALPARAISO"},
{stateCode: "18" , cityCode: "860", city: "VALPARAISO"},
{stateCode: "5" , cityCode: "858", city: "VEGACHI"},
{stateCode: "68" , cityCode: "861", city: "VELEZ"},
{stateCode: "73" , cityCode: "861", city: "VENADILLO"},
{stateCode: "5" , cityCode: "861", city: "VENECIA"},
{stateCode: "25" , cityCode: "506", city: "VENECIA (OSPINA PEREZ)"},
{stateCode: "15" , cityCode: "861", city: "VENTAQUEMADA"},
{stateCode: "25" , cityCode: "862", city: "VERGARA"},
{stateCode: "76" , cityCode: "863", city: "VERSALLES"},
{stateCode: "68" , cityCode: "867", city: "VETAS"},
{stateCode: "25" , cityCode: "867", city: "VIANI"},
{stateCode: "17" , cityCode: "867", city: "VICTORIA"},
{stateCode: "5" , cityCode: "873", city: "VIGIA DEL FUERTE"},
{stateCode: "76" , cityCode: "869", city: "VIJES"},
{stateCode: "54" , cityCode: "871", city: "VILLA CARO"},
{stateCode: "15" , cityCode: "407", city: "VILLA DE LEYVA"},
{stateCode: "54" , cityCode: "874", city: "VILLA DEL ROSARIO"},
{stateCode: "86" , cityCode: "865", city: "VILLA GUAMEZ (LA HORMIGA)"},
{stateCode: "19" , cityCode: "845", city: "VILLA RICA"},
{stateCode: "86" , cityCode: "885", city: "VILLAGARZON"},
{stateCode: "25" , cityCode: "871", city: "VILLAGOMEZ"},
{stateCode: "73" , cityCode: "870", city: "VILLAHERMOSA"},
{stateCode: "17" , cityCode: "873", city: "VILLAMARIA"},
{stateCode: "85" , cityCode: "440", city: "VILLANUEVA"},
{stateCode: "68" , cityCode: "872", city: "VILLANUEVA"},
{stateCode: "13" , cityCode: "873", city: "VILLANUEVA"},
{stateCode: "44" , cityCode: "874", city: "VILLANUEVA"},
{stateCode: "25" , cityCode: "873", city: "VILLAPINZON"},
{stateCode: "73" , cityCode: "873", city: "VILLARRICA"},
{stateCode: "50" , cityCode: "1", city: "VILLAVICENCIO"},
{stateCode: "41" , cityCode: "872", city: "VILLAVIEJA"},
{stateCode: "25" , cityCode: "875", city: "VILLETA"},
{stateCode: "25" , cityCode: "878", city: "VIOTA"},
{stateCode: "15" , cityCode: "879", city: "VIRACACHA"},
{stateCode: "50" , cityCode: "711", city: "VISTA HERMOSA"},
{stateCode: "66" , cityCode: "688", city: "VITERBO"},
{stateCode: "17" , cityCode: "877", city: "VITERBO"},
{stateCode: "25" , cityCode: "885", city: "YACOPI"},
{stateCode: "52" , cityCode: "885", city: "YACUANQUER"},
{stateCode: "41" , cityCode: "885", city: "YAGUARA"},
{stateCode: "5" , cityCode: "885", city: "YALI"},
{stateCode: "5" , cityCode: "887", city: "YARUMAL"},
{stateCode: "97" , cityCode: "889", city: "YAVARATE"},
{stateCode: "5" , cityCode: "890", city: "YOLOMBO"},
{stateCode: "5" , cityCode: "893", city: "YONDO (CASABE)"},
{stateCode: "85" , cityCode: "1", city: "YOPAL"},
{stateCode: "76" , cityCode: "890", city: "YOTOCO"},
{stateCode: "76" , cityCode: "892", city: "YUMBO"},
{stateCode: "13" , cityCode: "894", city: "ZAMBRANO"},
{stateCode: "68" , cityCode: "895", city: "ZAPATOCA"},
{stateCode: "5" , cityCode: "895", city: "ZARAGOZA"},
{stateCode: "76" , cityCode: "895", city: "ZARZAL"},
{stateCode: "15" , cityCode: "897", city: "ZETAQUIRA"},
{stateCode: "25" , cityCode: "898", city: "ZIPACON"},
{stateCode: "25" , cityCode: "899", city: "ZIPAQUIRA"},
  
];

const countries =[
  {countryCode: "AF", country: "AFGHANISTAN", currencyCode: "4"},
{countryCode: "AL", country: "ALBANIA", currencyCode: "8"},
{countryCode: "DE", country: "ALEMANIA", currencyCode: "280"},
{countryCode: "DZ", country: "ALGERIA", currencyCode: "12"},
{countryCode: "AD", country: "ANDORRA", currencyCode: "724"},
{countryCode: "AQ", country: "ANTARCTICA", currencyCode: "578"},
{countryCode: "AG", country: "ANTIGUA Y BARBUDA", currencyCode: "951"},
{countryCode: "AN", country: "ANTILLAS HOLANDESAS", currencyCode: "532"},
{countryCode: "SA", country: "ARABIA SAUDITA", currencyCode: "682"},
{countryCode: "AR", country: "ARGENTINA", currencyCode: "32"},
{countryCode: "AM", country: "ARMENIA", currencyCode: "840"},
{countryCode: "AW", country: "ARUBA", currencyCode: "533"},
{countryCode: "AU", country: "AUSTRALIA", currencyCode: "36"},
{countryCode: "AT", country: "AUSTRIA", currencyCode: "40"},
{countryCode: "AZ", country: "AZERBAIJAN", currencyCode: "840"},
{countryCode: "BS", country: "BAHAMAS", currencyCode: "44"},
{countryCode: "BH", country: "BAHRAIN", currencyCode: "48"},
{countryCode: "BD", country: "BANGLADESH", currencyCode: "50"},
{countryCode: "BB", country: "BARBADOS", currencyCode: "52"},
{countryCode: "BY", country: "BELARUS", currencyCode: "840"},
{countryCode: "BE", country: "BELGICA", currencyCode: "56"},
{countryCode: "BZ", country: "BELICE", currencyCode: "84"},
{countryCode: "BJ", country: "BENIN", currencyCode: "952"},
{countryCode: "BM", country: "BERMUDAS", currencyCode: "60"},
{countryCode: "BT", country: "BHUTAN", currencyCode: "356"},
{countryCode: "BO", country: "BOLIVIA", currencyCode: "68"},
{countryCode: "BW", country: "BOTSWANA", currencyCode: "72"},
{countryCode: "BV", country: "BOUVET ISLAND", currencyCode: "578"},
{countryCode: "BA", country: "BOZNIA-HERZEGOVINA", currencyCode: "840"},
{countryCode: "BR", country: "BRASIL", currencyCode: "76"},
{countryCode: "IO", country: "BRITISH INDIAN OCEAN TERRITORY", currencyCode: "480"},
{countryCode: "BN", country: "BRUNEI  DARUSSALAM", currencyCode: "96"},
{countryCode: "BG", country: "BULGARIA", currencyCode: "100"},
{countryCode: "HV", country: "BURKINA FASO", currencyCode: "952"},
{countryCode: "BI", country: "BURUNDI", currencyCode: "108"},
{countryCode: "CV", country: "CABO VERDE ISLAS", currencyCode: "132"},
{countryCode: "KY", country: "CAIMAN ISLAS", currencyCode: "136"},
{countryCode: "CM", country: "CAMERUN", currencyCode: "950"},
{countryCode: "CA", country: "CANADA", currencyCode: "124"},
{countryCode: "CP", country: "CENTRO AFRICA", currencyCode: "950"},
{countryCode: "TD", country: "CHAD", currencyCode: "950"},
{countryCode: "CL", country: "CHILE", currencyCode: "152"},
{countryCode: "CN", country: "CHINA", currencyCode: "156"},
{countryCode: "CY", country: "CHIPRE", currencyCode: "196"},
{countryCode: "CX", country: "CHRISTMAS ISLAND", currencyCode: "36"},
{countryCode: "CC", country: "COCOS (KEELING) ISLAS", currencyCode: "36"},
{countryCode: "CO", country: "COLOMBIA", currencyCode: "170"},
{countryCode: "KM", country: "COMOROS", currencyCode: "174"},
{countryCode: "CG", country: "CONGO", currencyCode: "950"},
{countryCode: "KP", country: "COREA DEL NORTE", currencyCode: "408"},
{countryCode: "KR", country: "COREA DEL SUR", currencyCode: "410"},
{countryCode: "CR", country: "COSTA RICA", currencyCode: "188"},
{countryCode: "HR", country: "CROACIA", currencyCode: "191"},
{countryCode: "CU", country: "CUBA", currencyCode: "192"},
{countryCode: "DK", country: "DINAMARCA", currencyCode: "208"},
{countryCode: "DJ", country: "DJIBOUTI", currencyCode: "262"},
{countryCode: "DM", country: "DOMINICA REP.", currencyCode: "951"},
{countryCode: "NQ", country: "DRONNIG MAUD LAND", currencyCode: "578"},
{countryCode: "EC", country: "ECUADOR", currencyCode: "218"},
{countryCode: "EG", country: "EGIPTO", currencyCode: "818"},
{countryCode: "SV", country: "EL SALVADOR", currencyCode: "222"},
{countryCode: "AE", country: "EMIRATOS ARABES UNIDOS", currencyCode: "784"},
{countryCode: "SK", country: "ESLOVAQUIA", currencyCode: "703"},
{countryCode: "SI", country: "ESLOVENIA", currencyCode: "705"},
{countryCode: "ES", country: "ESPA?A", currencyCode: "724"},
{countryCode: "US", country: "ESTADOS UNIDOS", currencyCode: "840"},
{countryCode: "EE", country: "ESTONIA", currencyCode: "233"},
{countryCode: "ET", country: "ETIOPIA", currencyCode: "230"},
{countryCode: "EMC", country: "EUROPEAN MONETARY COOPERATION FUND", currencyCode: "954"},
{countryCode: "FK", country: "FALKLAND ISLAS", currencyCode: "238"},
{countryCode: "FO", country: "FEROE ISLAS", currencyCode: "208"},
{countryCode: "FJ", country: "FIJI", currencyCode: "242"},
{countryCode: "PH", country: "FILIPINAS", currencyCode: "608"},
{countryCode: "FI", country: "FINLANDIA", currencyCode: "246"},
{countryCode: "FR", country: "FRANCIA", currencyCode: "250"},
{countryCode: "GA", country: "GABON", currencyCode: "950"},
{countryCode: "GM", country: "GAMBIA", currencyCode: "270"},
{countryCode: "GE", country: "GEORGIA", currencyCode: "840"},
{countryCode: "GH", country: "GHANA", currencyCode: "288"},
{countryCode: "GI", country: "GIBRALTAR", currencyCode: "292"},
{countryCode: "GD", country: "GRANADA", currencyCode: "951"},
{countryCode: "GR", country: "GRECIA", currencyCode: "300"},
{countryCode: "GL", country: "GROENLANDIA", currencyCode: "208"},
{countryCode: "GP", country: "GUADALUPE", currencyCode: "250"},
{countryCode: "GU", country: "GUAM", currencyCode: "840"},
{countryCode: "GT", country: "GUATEMALA", currencyCode: "320"},
{countryCode: "GQ", country: "GUINEA ECUATORIAL", currencyCode: "226"},
{countryCode: "GN", country: "GUINEA REPUBLICA", currencyCode: "324"},
{countryCode: "GW", country: "GUINEA-BISSAU", currencyCode: "624"},
{countryCode: "GY", country: "GUYANA", currencyCode: "328"},
{countryCode: "GF", country: "GUYANA FRANCESA", currencyCode: "250"},
{countryCode: "HT", country: "HAITI", currencyCode: "332"},
{countryCode: "KZ", country: "HAZAJSTAN", currencyCode: "840"},
{countryCode: "HM", country: "HEARD AND MACDONALD ISLAS", currencyCode: "36"},
{countryCode: "NL", country: "HOLANDA", currencyCode: "528"},
{countryCode: "HN", country: "HONDURAS", currencyCode: "340"},
{countryCode: "HK", country: "HONG KONG", currencyCode: "344"},
{countryCode: "HU", country: "HUNGRIA", currencyCode: "348"},
{countryCode: "ID", country: "INDONESIA", currencyCode: "360"},
{countryCode: "IR", country: "IRAN", currencyCode: "364"},
{countryCode: "IQ", country: "IRAQ", currencyCode: "368"},
{countryCode: "IE", country: "IRLANDA", currencyCode: "372"},
{countryCode: "MU", country: "ISLA MAURICIO", currencyCode: "480"},
{countryCode: "IS", country: "ISLANDIA", currencyCode: "352"},
{countryCode: "CK", country: "ISLAS COOK", currencyCode: "554"},
{countryCode: "SB", country: "ISLAS SALOMON", currencyCode: "90"},
{countryCode: "IL", country: "ISRAEL", currencyCode: "376"},
{countryCode: "IT", country: "ITALIA", currencyCode: "380"},
{countryCode: "CI", country: "IVORY COAST REP.", currencyCode: "952"},
{countryCode: "JM", country: "JAMAICA", currencyCode: "388"},
{countryCode: "JP", country: "JAPON", currencyCode: "392"},
{countryCode: "JT", country: "JOHNSTON ISLAND", currencyCode: "840"},
{countryCode: "JO", country: "JORDANIA", currencyCode: "400"},
{countryCode: "KH", country: "KAMPUCHEA, DEMOCRATIC", currencyCode: "116"},
{countryCode: "KE", country: "KENIA", currencyCode: "404"},
{countryCode: "KI", country: "KIRIBATI", currencyCode: "36"},
{countryCode: "KW", country: "KUWAIT", currencyCode: "414"},
{countryCode: "KG", country: "KYRGYZSTAN", currencyCode: "417"},
{countryCode: "LA", country: "LAOS", currencyCode: "418"},
{countryCode: "LS", country: "LESOTTO", currencyCode: "710"},
{countryCode: "LV", country: "LETONIA", currencyCode: "428"},
{countryCode: "LB", country: "LIBANO", currencyCode: "422"},
{countryCode: "LR", country: "LIBERIA", currencyCode: "430"},
{countryCode: "LY", country: "LIBIA", currencyCode: "434"},
{countryCode: "LI", country: "LIECHTENSTEIN", currencyCode: "756"},
{countryCode: "LT", country: "LITUANIA", currencyCode: "440"},
{countryCode: "LU", country: "LUXEMBURGO", currencyCode: "442"},
{countryCode: "MO", country: "MACAO", currencyCode: "446"},
{countryCode: "MK", country: "MACEDONIA", currencyCode: "807"},
{countryCode: "MG", country: "MADAGASCAR", currencyCode: "450"},
{countryCode: "MY", country: "MALASIA", currencyCode: "458"},
{countryCode: "MW", country: "MALAWI", currencyCode: "454"},
{countryCode: "MV", country: "MALDIVAS", currencyCode: "462"},
{countryCode: "ML", country: "MALI", currencyCode: "952"},
{countryCode: "MT", country: "MALTA", currencyCode: "470"},
{countryCode: "MA", country: "MARRUECOS", currencyCode: "504"},
{countryCode: "MQ", country: "MARTINICA", currencyCode: "250"},
{countryCode: "MR", country: "MAURITANIA", currencyCode: "478"},
{countryCode: "MX", country: "MEXICO", currencyCode: "484"},
{countryCode: "UM", country: "MIDWAY ISLAND", currencyCode: "840"},
{countryCode: "MD", country: "MOLDOVA", currencyCode: "840"},
{countryCode: "MC", country: "MONACO", currencyCode: "250"},
{countryCode: "MN", country: "MONGOLIA", currencyCode: "496"},
{countryCode: "MS", country: "MONTSERRAT", currencyCode: "951"},
{countryCode: "MZ", country: "MOZAMBIQUE", currencyCode: "508"},
{countryCode: "BU", country: "MYANMAR", currencyCode: "104"},
{countryCode: "NA", country: "NAMIBIA", currencyCode: "516"},
{countryCode: "NR", country: "NAURU", currencyCode: "36"},
{countryCode: "NP", country: "NEPAL", currencyCode: "524"},
{countryCode: "NI", country: "NICARAGUA", currencyCode: "558"},
{countryCode: "NE", country: "NIGER", currencyCode: "952"},
{countryCode: "NG", country: "NIGERIA", currencyCode: "566"},
{countryCode: "NU", country: "NIUE", currencyCode: "554"},
{countryCode: "NF", country: "NORFOLK ISLAND", currencyCode: "36"},
{countryCode: "NO", country: "NORUEGA", currencyCode: "578"},
{countryCode: "NC", country: "NUEVA CALEDONIA", currencyCode: "953"},
{countryCode: "NZ", country: "NUEVA ZELANDIA", currencyCode: "554"},
{countryCode: "OM", country: "OMAN", currencyCode: "512"},
{countryCode: "PC", country: "PACIFIC ISLAND TRUST TERR.", currencyCode: "840"},
{countryCode: "PA", country: "PANAMA", currencyCode: "590"},
{countryCode: "PZ", country: "PANAMA ZONA DEL CANAL", currencyCode: "840"},
{countryCode: "PG", country: "PAPUA NUEVA GUINEA", currencyCode: "598"},
{countryCode: "PK", country: "PAQUISTAN", currencyCode: "586"},
{countryCode: "PY", country: "PARAGUAY", currencyCode: "600"},
{countryCode: "PE", country: "PERU", currencyCode: "604"},
{countryCode: "PN", country: "PITCAIRN", currencyCode: "554"},
{countryCode: "PF", country: "POLINESIA FRANCESA", currencyCode: "953"},
{countryCode: "PL", country: "POLONIA", currencyCode: "616"},
{countryCode: "PT", country: "PORTUGAL", currencyCode: "620"},
{countryCode: "PR", country: "PUERTO RICO", currencyCode: "840"},
{countryCode: "QA", country: "QATAR", currencyCode: "634"},
{countryCode: "GB", country: "REINO UNIDO", currencyCode: "826"},
{countryCode: "CZ", country: "REPUBLICA CHECA", currencyCode: "203"},
{countryCode: "DO", country: "REPUBLICA DOMINICANA", currencyCode: "214"},
{countryCode: "RE", country: "REUNION", currencyCode: "250"},
{countryCode: "RO", country: "RUMANIA", currencyCode: "642"},
{countryCode: "RU", country: "RUSIA", currencyCode: "840"},
{countryCode: "RW", country: "RWANDA", currencyCode: "646"},
{countryCode: "WS", country: "SAMOA", currencyCode: "882"},
{countryCode: "AS", country: "SAMOA OCCIDENTAL", currencyCode: "840"},
{countryCode: "SM", country: "SAN MARINO", currencyCode: "380"},
{countryCode: "PM", country: "SAN PEDRO Y MIGUELON", currencyCode: "250"},
{countryCode: "VC", country: "SAN VICENTE Y GRANADINAS", currencyCode: "951"},
{countryCode: "LC", country: "SANTA LUCIA", currencyCode: "951"},
{countryCode: "ST", country: "SANTO TOME Y PRINCIPE", currencyCode: "678"},
{countryCode: "SN", country: "SENEGAL", currencyCode: "952"},
{countryCode: "SC", country: "SEYCHELLES REP. POPULAR", currencyCode: "690"},
{countryCode: "SL", country: "SIERRA LEONA", currencyCode: "694"},
{countryCode: "SG", country: "SINGAPUR", currencyCode: "702"},
{countryCode: "SY", country: "SIRIA", currencyCode: "760"},
{countryCode: "SO", country: "SOMALIA", currencyCode: "706"},
{countryCode: "LK", country: "SRI LANKA", currencyCode: "144"},
{countryCode: "SH", country: "ST. HELENA", currencyCode: "654"},
{countryCode: "KN", country: "ST. KITTS-NEVIS-ANGUILLA", currencyCode: "951"},
{countryCode: "SZ", country: "SUAZILANDIA", currencyCode: "748"},
{countryCode: "SD", country: "SUDAN", currencyCode: "736"},
{countryCode: "SE", country: "SUECIA", currencyCode: "752"},
{countryCode: "CH", country: "SUIZA", currencyCode: "756"},
{countryCode: "SR", country: "SURINAM", currencyCode: "740"},
{countryCode: "SJ", country: "SVALBARB ANH JAN MAYEN ISLANDS", currencyCode: "578"},
{countryCode: "TH", country: "TAILANDIA", currencyCode: "764"},
{countryCode: "TW", country: "TAIWAN", currencyCode: "901"},
{countryCode: "TJ", country: "TAJIKSTAN", currencyCode: "840"},
{countryCode: "TZ", country: "TANZANIA", currencyCode: "834"},
{countryCode: "TP", country: "TIMOR ORIENTAL", currencyCode: "626"},
{countryCode: "TG", country: "TOGO", currencyCode: "952"},
{countryCode: "TK", country: "TOKELAU", currencyCode: "554"},
{countryCode: "TO", country: "TONGA", currencyCode: "776"},
{countryCode: "TT", country: "TRINIDAD Y TOBAGO", currencyCode: "780"},
{countryCode: "TN", country: "TUNEZ", currencyCode: "788"},
{countryCode: "TC", country: "TURCAS Y CAICOS", currencyCode: "840"},
{countryCode: "TM", country: "TURKMENISTAN", currencyCode: "840"},
{countryCode: "TR", country: "TURQUIA", currencyCode: "792"},
{countryCode: "TV", country: "TUVALU", currencyCode: "36"},
{countryCode: "PU", country: "U.S. MISC. PACIFIC ISLANDS", currencyCode: "840"},
{countryCode: "UA", country: "UCRANIA", currencyCode: "804"},
{countryCode: "UG", country: "UGANDA", currencyCode: "800"},
{countryCode: "UY", country: "URUGUAY", currencyCode: "858"},
{countryCode: "UZ", country: "UZBEKISTAN", currencyCode: "840"},
{countryCode: "VU", country: "VANUATO", currencyCode: "548"},
{countryCode: "VA", country: "VATICAN CITY STATE", currencyCode: "380"},
{countryCode: "VE", country: "VENEZUELA", currencyCode: "928"},
{countryCode: "VN", country: "VIETNAM", currencyCode: "704"},
{countryCode: "VG", country: "VIRGENES BRITANICAS ISLAS", currencyCode: "840"},
{countryCode: "VI", country: "VIRGENES NORTEAMERICANAS", currencyCode: "840"},
{countryCode: "WK", country: "WAKE ISLAND", currencyCode: "840"},
{countryCode: "WF", country: "WALLIS Y FUTUNA", currencyCode: "953"},
{countryCode: "EH", country: "WESTERN SAHARA", currencyCode: "504"},
{countryCode: "xx", country: "XAMBI", currencyCode: "32"},
{countryCode: "YE", country: "YEMEN", currencyCode: "886"},
{countryCode: "YU", country: "YUGOSLAVIA", currencyCode: "890"},
{countryCode: "ZR", country: "ZAIRE", currencyCode: "180"},
{countryCode: "ZM", country: "ZAMBIA", currencyCode: "894"},
{countryCode: "ZW", country: "ZIMBABWE", currencyCode: "716"},
{countryCode: "NT", country: "ZONA NEUTRAL (ARABIA SAUD E IR", currencyCode: "682"},
];

const colombianStates = [
  {state: "ANTIOQUIA", stateCode: "5"},
{state: "NORTE SANTANDER", stateCode: "54"},
{state: "META", stateCode: "50"},
{state: "CHOCO", stateCode: "27"},
{state: "HUILA", stateCode: "41"},
{state: "BOLIVAR", stateCode: "13"},
{state: "CUNDINAMARCA", stateCode: "25"},
{state: "CESAR", stateCode: "20"},
{state: "SANTANDER", stateCode: "68"},
{state: "CALDAS", stateCode: "17"},
{state: "CASANARE", stateCode: "85"},
{state: "NARIÑO", stateCode: "52"},
{state: "CAQUETA", stateCode: "18"},
{state: "LA GUAJIRA", stateCode: "44"},
{state: "VALLE", stateCode: "76"},
{state: "CAUCA", stateCode: "19"},
{state: "BOYACA", stateCode: "15"},
{state: "TOLIMA", stateCode: "73"},
{state: "RISARALDA", stateCode: "66"},
{state: "MAGDALENA", stateCode: "47"},
{state: "ARAUCA", stateCode: "81"},
{state: "QUINDIO", stateCode: "63"},
{state: "CORDOBA", stateCode: "23"},
{state: "ATLANTICO", stateCode: "8"},
{state: "BOGOTA  DC.", stateCode: "11"},
{state: "SUCRE", stateCode: "70"},
{state: "GUAINIA", stateCode: "94"},
{state: "GUAVIARE", stateCode: "95"},
{state: "VAUPES", stateCode: "97"},
{state: "VICHADA", stateCode: "99"},
{state: "PUTUMAYO", stateCode: "86"},
{state: "AMAZONAS", stateCode: "91"},
{state: "SAN ANDRES", stateCode: "88"},
]

const loanMaxLimit = 100000; 

const bridgeAsset = process.env.REACT_APP_BRIDGE_ASSET;

const enableAccountAsset = process.env.REACT_APP_ENABLE_ACCOUNT_ASSET;

const enablePaymentValue = process.env.REACT_APP_ENABLE_ACCOUNT_PAYMENT_VALUE;

const environmentReferenceFundingAsset = process.env.REACT_APP_REFERENCE_FUNDING_ASSET;

const trustlineLimitValue = process.env.REACT_APP_TRUSTLINE_LIMIT_VALUE;

const wupoHotAddress = process.env.REACT_APP_WUPO_HOT;

const renderFirstView = process.env.REACT_APP_FIRST_VIEW_FOR_USERS;

const loanPaymentProvider = process.env.REACT_APP_PAYMENT_PROVIDER;

const xrplEnabled = process.env.REACT_APP_XRPL_ENABLED;

const b2bClient = process.env.REACT_APP_CLIENT

const isUsernamePhone = process.env.REACT_APP_IS_USERNAME_PHONE;

const isPhoneVerifyEnabled = process.env.REACT_APP_IS_VERIFY_PHONE;

const webauthnPKRpId = process.env.REACT_APP_PUBLICKEY_RPID;

export { exchangeCurrencies, retailerTokens, wupoServerUri, retailOperation, 
  stockOrderSymbol, bridgeAsset, enableAccountAsset, enablePaymentValue, environmentReferenceFundingAsset, 
  trustlineLimitValue, api, retailersTokensReferenceCurrency, wupoHotAddress, creditAsset, creditTerm, 
  loanMaxLimit, renderFirstView, loanPaymentProvider, xrplEnabled, b2bClient, isUsernamePhone, municipalities,
  bankCodes, bankAccountTypes, isPhoneVerifyEnabled, countries, colombianStates, webauthnPKRpId };

export default api;
