import React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button} from '@mui/material';
import Colors from "../../components/ui/Colors";
import NumberFormat from "react-number-format";
import swal from "sweetalert";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import {getCreditRiskWeights,  getDependentsWeight, updateDependentsWeights, getEmploymentTypeWeights,
    updateEmploymentTypeWeights, getMaritalStatusWeights, updateMaritalStatusWeights, getMonthlyExpensesWeights,
    updateMonthlyExpensesWeights, getMonthlyIncomeWeights, updateMonthlyIncomeWeights, getOtherIncomeWeights,
    updateOtherIncomeWeights, getResidenceTypeWeights, updateResidenceTypeWeights, getStratumWeights, updateStratumWeights,
    getStudyLevelWeights, updateStudyLevelWeights, getTotalLiabilitiesWeights, updateTotalLiabilitiesWeights, 
    getTotalAssetsWeights, updateTotalAssetsWeights} from "../../utilityFunctions/OpenCardUtil";

const cssLoader = css`
display: block;
margin-left: auto;
margin-right: auto;
`;


const riskVariables = ["Dependientes", "Tipo de Empleo", "Estado Civil", "Gastos Mensuales", "Ingresos Mensuales", 
    "Otros Ingresos", "Tipo de Residencia", "Estrato", "Nivel de Estudio", "Total Pasivos", "Total Activos"];

export default function RiskVariablesUpdate(props) {

    const {loginUser} = props;

    const [loading, setLoading] = React.useState(false);

    const[riskVarName, setRiskVarName] = React.useState("");
    const[valueVariables, setValueVariables] = React.useState(null);
    const[conventionVariables, setConventionVariables] = React.useState(null);
    const[valueVariablesArray, setValueVariablesArray] = React.useState(null);
    const[conventionVariablesArray, setConventionVariablesArray] = React.useState(null);

    const riskVariableChange = async (e) => {

        setRiskVarName(e.target.value);

        renderVariables(e.target.value);

    };

    const renderVariables = async (name) => {
        
        if(name === "Dependientes") {
            setLoading(true);
            const response = await getDependentsWeight(loginUser.jwtToken);
            setLoading(false);
            if(!response){
                swal({
                    title: "Error",
                    text: `No se pudo consultar la variable de riesgo.`,
                    icon: "error",
                    button: "Ok",
                });
                return;
            }

            const sortedResponse = response.sort((a, b) => a.id - b.id);

            setValueVariablesArray(sortedResponse);

            // renderValueVariables(response);

        }

        else if(name === "Tipo de Empleo") {
            setLoading(true);
            const response = await getEmploymentTypeWeights(loginUser.jwtToken);
            setLoading(false);
            if(!response){
                swal({
                    title: "Error",
                    text: `No se pudo consultar la variable de riesgo.`,
                    icon: "error",
                    button: "Ok",
                });
                return;
            }

            const sortedResponse = response.sort((a, b) => a.id - b.id);

            setConventionVariablesArray(sortedResponse);

            // renderConventionVariables(response);
        }

        else if(name === "Estado Civil") {
            setLoading(true);
            const response = await getMaritalStatusWeights(loginUser.jwtToken);
            setLoading(false);

            if(!response){
                swal({
                    title: "Error",
                    text: `No se pudo consultar la variable de riesgo.`,
                    icon: "error",
                    button: "Ok",
                });
                return;
            }

            const sortedResponse = response.sort((a, b) => a.id - b.id);

            setConventionVariablesArray(sortedResponse);

            // renderConventionVariables(response);
        }

        else if(name === "Gastos Mensuales") {
            setLoading(true);
            const response = await getMonthlyExpensesWeights(loginUser.jwtToken);
            setLoading(false);

            if(!response){
                swal({
                    title: "Error",
                    text: `No se pudo consultar la variable de riesgo.`,
                    icon: "error",
                    button: "Ok",
                });
                return;
            }

            const sortedResponse = response.sort((a, b) => a.id - b.id);

            setValueVariablesArray(sortedResponse);

            // renderValueVariables(response);
        }

        else if(name === "Ingresos Mensuales") {
            setLoading(true);
            const response = await getMonthlyIncomeWeights(loginUser.jwtToken);
            setLoading(false);

            if(!response){
                swal({
                    title: "Error",
                    text: `No se pudo consultar la variable de riesgo.`,
                    icon: "error",
                    button: "Ok",
                });
                return;
            }

            const sortedResponse = response.sort((a, b) => a.id - b.id);

            setValueVariablesArray(sortedResponse);

            // renderValueVariables(response);
        }

        else if(name === "Otros Ingresos") {
            setLoading(true);
            const response = await getOtherIncomeWeights(loginUser.jwtToken);
            setLoading(false);

            if(!response){
                swal({
                    title: "Error",
                    text: `No se pudo consultar la variable de riesgo.`,
                    icon: "error",
                    button: "Ok",
                });
                return;
            }

            const sortedResponse = response.sort((a, b) => a.id - b.id);

            setValueVariablesArray(sortedResponse);

            // renderValueVariables(response);
        }

        else if(name === "Tipo de Residencia") {
            setLoading(true);
            const response = await getResidenceTypeWeights(loginUser.jwtToken);
            setLoading(false);

            if(!response){
                swal({
                    title: "Error",
                    text: `No se pudo consultar la variable de riesgo.`,
                    icon: "error",
                    button: "Ok",
                });
                return;
            }

            const sortedResponse = response.sort((a, b) => a.id - b.id);

            setConventionVariablesArray(sortedResponse);

            // renderConventionVariables(response);
        }

        else if(name === "Estrato") {
            setLoading(true);
            const response = await getStratumWeights(loginUser.jwtToken);
            setLoading(false);
            if(!response){
                swal({
                    title: "Error",
                    text: `No se pudo consultar la variable de riesgo.`,
                    icon: "error",
                    button: "Ok",
                });
                return;
            }

            const sortedResponse = response.sort((a, b) => a.id - b.id);

            setValueVariablesArray(sortedResponse);

            // renderValueVariables(response);
        }

        else if(name === "Nivel de Estudio") {
            setLoading(true);
            const response = await getStudyLevelWeights(loginUser.jwtToken);
            setLoading(false);

            if(!response){
                swal({
                    title: "Error",
                    text: `No se pudo consultar la variable de riesgo.`,
                    icon: "error",
                    button: "Ok",
                });
                return;
            }

            const sortedResponse = response.sort((a, b) => a.id - b.id);

            setConventionVariablesArray(sortedResponse);

            // renderConventionVariables(response);
        }

        else if(name === "Total Pasivos") {
            setLoading(true);
            const response = await getTotalLiabilitiesWeights(loginUser.jwtToken);
            setLoading(false); 

            if(!response){
                swal({
                    title: "Error",
                    text: `No se pudo consultar la variable de riesgo.`,
                    icon: "error",
                    button: "Ok",
                });
                return;
            }
            setValueVariablesArray(response);

            // renderValueVariables(response);
        }

        else if(name === "Total Activos") {
            setLoading(true);
            const response = await getTotalAssetsWeights(loginUser.jwtToken);
            setLoading(false);

            if(!response){
                swal({
                    title: "Error",
                    text: `No se pudo consultar la variable de riesgo.`,
                    icon: "error",
                    button: "Ok",
                });
                return;
            }

            const sortedResponse = response.sort((a, b) => a.id - b.id);

            setValueVariablesArray(sortedResponse);

            // renderValueVariables(response);
        }
    
    };

    React.useEffect(() => {
        if(conventionVariablesArray){
            setValueVariables(null);
            renderConventionVariables(conventionVariablesArray);
        }
    }, [conventionVariablesArray]);

    React.useEffect(() => {
        if(valueVariablesArray){
            setConventionVariables(null);
            renderValueVariables(valueVariablesArray);
        }
    }, [valueVariablesArray]);

    const renderValueVariables = (records) => {

        setValueVariables(
            valueVariablesArray.map((record, index) => {
                return (
                    <>
                    <Grid item xs={3} sm={3} md={3} key={`${index}-code`}>
                        <TextField
                            key={`${index}-code-input`}
                            sx={{textAlign: "start"}}
                            variant="standard"
                            fullWidth={true}
                            value={record.code}
                            onChange={(e) => {
                                const updatedRecords = [...records];
                                updatedRecords[index].code = e.target.value;
                                setValueVariablesArray(updatedRecords);
                            }}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} key={`${index}-weight`}>
                        <NumberFormat
                            key={`${index}-weight-input`}
                            variant="standard"
                            style={{width: "100%", height: "90%"}}
                            customInput={TextField}
                            // prefix={'$ '}
                            // suffix={' meses'}
                            type="content"
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            autoComplete="off"
                            value={record.weight}
                            onValueChange={(values) => {
                                const updatedRecords = [...records];
                                updatedRecords[index].weight = values.floatValue;
                                setValueVariablesArray(updatedRecords);
                            }}
                        />
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} key={`${index}-lowValue`}>
                        <NumberFormat
                            key={`${index}-lowValue-input`}
                            variant="standard"
                            style={{width: "100%", height: "90%"}}
                            customInput={TextField}
                            // prefix={'$ '}
                            // suffix={' meses'}
                            type="content"
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            autoComplete="off"
                            value={record.lowValue}
                            onValueChange={(values) => {
                                const updatedRecords = [...records];
                                updatedRecords[index].lowValue = values.floatValue;
                                setValueVariablesArray(updatedRecords);
                            }}
                        />
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} key={`${index}-topValue`}>
                        <NumberFormat
                            key={`${index}-topValue-input`}
                            variant="standard"
                            style={{width: "100%", height: "90%"}}
                            customInput={TextField}
                            // prefix={'$ '}
                            // suffix={' meses'}
                            type="content"
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            autoComplete="off"
                            value={record.topValue}
                            onValueChange={(values) => {
                                const updatedRecords = [...records];
                                updatedRecords[index].topValue = values.floatValue;
                                setValueVariablesArray(updatedRecords);
                            }}
                        />
                    </Grid>
                    
                    </>
                    
                );
            }
        ));
        
    };

    const renderConventionVariables = (records) => {

        setConventionVariables(
            conventionVariablesArray.map((record, index) => {
                return (
                    <>
                    <Grid item xs={4} sm={4} md={4} key={`${index}-code`}>
                        <TextField
                            key={`${index}-code-input`}
                            sx={{textAlign: "start"}}
                            variant="standard"
                            fullWidth={true}
                            value={record.code}
                            onChange={(e) => {
                                const updatedRecords = [...records];
                                updatedRecords[index].code = e.target.value;
                                setConventionVariablesArray(updatedRecords);
                            }}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} key={`${index}-name`}>
                        <TextField
                            key={`${index}-name-input`}
                            sx={{textAlign: "start"}}
                            variant="standard"
                            fullWidth={true}
                            value={record.name}
                            onChange={(e) => {
                                const updatedRecords = [...records];
                                updatedRecords[index].name = e.target.value;
                                setConventionVariablesArray(updatedRecords);
                            }}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} key={`${index}-weight`}>
                        <NumberFormat
                            key={`${index}-weight-input`}
                            variant="standard"
                            style={{width: "100%", height: "90%"}}
                            customInput={TextField}
                            // prefix={'$ '}
                            // suffix={' meses'}
                            type="content"
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            autoComplete="off"
                            value={record.weight}
                            onValueChange={(values) => {
                                const updatedRecords = [...records];
                                updatedRecords[index].weight = values.floatValue;
                                setConventionVariablesArray(updatedRecords);
                            }}
                        />
                    </Grid>
                    </>
                    
                );
            }
        ));
    };

    const updateRiskVariables = async () => {

        if(riskVarName === "Dependientes") {
            setLoading(true);
            const response = await updateDependentsWeights( valueVariablesArray, loginUser.jwtToken);
            setLoading(false);
            if(!response){
                swal({
                    title: "Error",
                    text: `No se pudo actualizar la variable de riesgo.`,
                    icon: "error",
                    button: "Ok",
                });
                return;
            }
            swal({
                title: "Éxito",
                text: `Variable de riesgo actualizada.`,
                icon: "success",
                button: "Ok",
            });
            return;
        }

        else if(riskVarName === "Tipo de Empleo") {
            setLoading(true);
            const response = await updateEmploymentTypeWeights(conventionVariablesArray, loginUser.jwtToken);
            setLoading(false);
            if(!response){
                swal({
                    title: "Error",
                    text: `No se pudo actualizar la variable de riesgo.`,
                    icon: "error",
                    button: "Ok",
                });
                return;
            }
            swal({
                title: "Éxito",
                text: `Variable de riesgo actualizada.`,
                icon: "success",
                button: "Ok",
            });
            return;
        }

        else if(riskVarName === "Estado Civil") {
            setLoading(true);
            const response = await updateMaritalStatusWeights( conventionVariablesArray, loginUser.jwtToken);
            setLoading(false);

            if(!response){
                swal({
                    title: "Error",
                    text: `No se pudo actualizar la variable de riesgo.`,
                    icon: "error",
                    button: "Ok",
                });
                return;
            }
            swal({
                title: "Éxito",
                text: `Variable de riesgo actualizada.`,
                icon: "success",
                button: "Ok",
            });
            return;
        }

        else if(riskVarName === "Gastos Mensuales") {
            setLoading(true);
            const response = await updateMonthlyExpensesWeights( valueVariablesArray, loginUser.jwtToken,);
            setLoading(false);

            if(!response){
                swal({
                    title: "Error",
                    text: `No se pudo actualizar la variable de riesgo.`,
                    icon: "error",
                    button: "Ok",
                });
                return;
            }
            swal({
                title: "Éxito",
                text: `Variable de riesgo actualizada.`,
                icon: "success",
                button: "Ok",
            });
            return;
        }

        else if(riskVarName === "Ingresos Mensuales") {
            setLoading(true);
            const response = await updateMonthlyIncomeWeights( valueVariablesArray, loginUser.jwtToken);
            setLoading(false);

            if(!response){
                swal({
                    title: "Error",
                    text: `No se pudo actualizar la variable de riesgo.`,
                    icon: "error",
                    button: "Ok",
                });
                return;
            }
            swal({
                title: "Éxito",
                text: `Variable de riesgo actualizada.`,
                icon: "success",
                button: "Ok",
            });
            return;
        }

        else if(riskVarName === "Otros Ingresos") {
            setLoading(true);
            const response = await updateOtherIncomeWeights( valueVariablesArray, loginUser.jwtToken);
            setLoading(false);

            if(!response){
                swal({
                    title: "Error",
                    text: `No se pudo actualizar la variable de riesgo.`,
                    icon: "error",
                    button: "Ok",
                });
                return;
            }
            swal({
                title: "Éxito",
                text: `Variable de riesgo actualizada.`,
                icon: "success",
                button: "Ok",
            });
            return;
        }

        else if(riskVarName === "Tipo de Residencia") {
            setLoading(true);
            const response = await updateResidenceTypeWeights( conventionVariablesArray, loginUser.jwtToken);
            setLoading(false);

            if(!response){
                swal({
                    title: "Error",
                    text: `No se pudo actualizar la variable de riesgo.`,
                    icon: "error",
                    button: "Ok",
                });
                return;
            }
            swal({
                title: "Éxito",
                text: `Variable de riesgo actualizada.`,
                icon: "success",
                button: "Ok",
            });
            return;
        }

        else if(riskVarName === "Estrato") {
            setLoading(true);
            const response = await updateStratumWeights(valueVariablesArray, loginUser.jwtToken);
            setLoading(false);
            if(!response){
                swal({
                    title: "Error",
                    text: `No se pudo actualizar la variable de riesgo.`,
                    icon: "error",
                    button: "Ok",
                });
                return;
            }
            swal({
                title: "Éxito",
                text: `Variable de riesgo actualizada.`,
                icon: "success",
                button: "Ok",
            });
            return;
        }

        else if(riskVarName === "Nivel de Estudio") {
            setLoading(true);
            const response = await updateStudyLevelWeights( conventionVariablesArray, loginUser.jwtToken);
            setLoading(false);
            if(!response){
                swal({
                    title: "Error",
                    text: `No se pudo actualizar la variable de riesgo.`,
                    icon: "error",
                    button: "Ok",
                });
                return;
            }
            swal({
                title: "Éxito",
                text: `Variable de riesgo actualizada.`,
                icon: "success",
                button: "Ok",
            });
            return;
        }

        else if(riskVarName === "Total Pasivos") {
            setLoading(true);
            const response = await updateTotalLiabilitiesWeights( valueVariablesArray, loginUser.jwtToken);
            setLoading(false);

            if(!response){
                swal({
                    title: "Error",
                    text: `No se pudo actualizar la variable de riesgo.`,
                    icon: "error",
                    button: "Ok",
                });
                return;
            }
            swal({
                title: "Éxito",
                text: `Variable de riesgo actualizada.`,
                icon: "success",
                button: "Ok",
            });
            return;
        }

        else if(riskVarName === "Total Activos") {
            setLoading(true);
            const response = await updateTotalAssetsWeights( valueVariablesArray, loginUser.jwtToken);
            setLoading(false);

            if(!response){
                swal({
                    title: "Error",
                    text: `No se pudo actualizar la variable de riesgo.`,
                    icon: "error",
                    button: "Ok",
                });
                return;
            }
            swal({
                title: "Éxito",
                text: `Variable de riesgo actualizada.`,
                icon: "success",
                button: "Ok",
            });
            return;
        }

    };

    const generateRandomKey = () => {
        const randomString = Math.random().toString(36).slice(2);
        const timestamp = Date.now().toString(36);
        return randomString + '_' + timestamp;
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                    height: "90%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: "1.5rem"},
                }}
            >
                {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                    <Grid container direction="row" spacing={2}>
                        {riskVariables &&
                            <Grid item xs={12} sm={12} md={12} sx={{justifyContent: "center"}}>
                                <TextField
                                    sx={{textAlign: "start", width: {xs: "90%", sm: "50%", md: "50%"},}}
                                    variant="standard"
                                    fullWidth={true}
                                    select
                                    label={<Typography variant="body2"></Typography>}
                                    value={riskVarName}
                                    onChange={(e)=>riskVariableChange(e)}
                                    autoComplete="off"
                                >
                                    {riskVariables.map((el) => (
                                        <MenuItem key={el} value={el}>
                                            {el}
                                        </MenuItem>
                                    ))}
                                </TextField> 
                            </Grid>
                        }
                        {valueVariables &&
                            <>
                            <Grid item xs={3} sm={3} md={3} sx={{textAlign: "center"}}>
                                <Typography variant="body2" sx={{fontWeight: "bold"}}>Código</Typography>
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} sx={{textAlign: "center"}}>
                                <Typography variant="body2" sx={{fontWeight: "bold"}}>Peso</Typography>
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} sx={{textAlign: "center"}}>
                                <Typography variant="body2" sx={{fontWeight: "bold"}}>Límite inferior</Typography>
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} sx={{textAlign: "center"}}>
                                <Typography variant="body2" sx={{fontWeight: "bold"}}>Límite superior</Typography>
                            </Grid>
                            {valueVariables}
                            <Grid item xs={12} sm={12} md={12}>
                                <Button onClick={updateRiskVariables} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                    Actualizar
                                </Button>
                            </Grid>
                            </>
                        }
                        {conventionVariables &&
                            <>
                            <Grid item xs={4} sm={4} md={4} sx={{textAlign: "center"}}>
                                <Typography variant="body2" sx={{fontWeight: "bold"}}>Código</Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} sx={{textAlign: "center"}}>
                                <Typography variant="body2" sx={{fontWeight: "bold"}}>nombre</Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} sx={{textAlign: "center"}}>
                                <Typography variant="body2" sx={{fontWeight: "bold"}}>Peso</Typography>
                            </Grid>
                            {conventionVariables}
                            <Grid item xs={12} sm={12} md={12}>
                                <Button onClick={updateRiskVariables} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                    Actualizar
                                </Button>
                            </Grid>
                            </>
                        }
                    </Grid>
                }
            </Box>
        </Box>
    )
}
