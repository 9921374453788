import React from 'react';
import { Typography, Box } from '@mui/material';
import Colors from "../components/ui/Colors";

export default function CountDownTimer({ duration, onTimerEnd }) {
    const [seconds, setSeconds] = React.useState(duration);

   React.useEffect(() => {
      let interval;
      if (seconds > 0) {
        interval = setInterval(() => {
          setSeconds((prevSeconds) => prevSeconds - 1);
        }, 1000);
      } else {
        onTimerEnd();
      }
  
      return () => {
        clearInterval(interval);
      };
    }, [seconds, onTimerEnd]);
  
    return (
        <Typography variant="h3" align="center" 
            sx={{
                fontWeight: 'bold',
                color: Colors.primary, // Use the secondary color from Material-UI's color palette
            }}
        >
            {seconds}
        </Typography>
        
    );
}
