import { Typography, Box } from '@mui/material'
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function NoCardsMessage() {

    const { t } = useTranslation();

  return (
    <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", backgroundColor: "#ededed"}}>
        <Box
            sx={{
                display: "flex",
                // flex: 1,
                justifyContent: "center",
                alignItems: "center",
                width: "95%",
                height: "90%",
                textAlign: "center",
                marginTop: {xs: "1.3rem", sm: 0},
            }}
        >
            <Typography variant="h3">{t("NoCardMessage")}</Typography>  
        </Box>
    </Box>
  )
}
