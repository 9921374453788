import React from "react"
import { useHistory } from "react-router-dom"
import styled from "@emotion/styled"
import { FaRegAddressCard, FaWallet, FaCodepen } from "react-icons/fa"
import { IoStorefront } from "react-icons/io5"
import Colors from "../components/ui/Colors"
import Avatar from '@mui/material/Avatar';
import AvatarJorge from "../img/avatars/avatar_jorge.png";
import PersonIcon from '@mui/icons-material/Person';
import Grid from '@mui/material/Grid';
import { List, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import KeyIcon from '@mui/icons-material/Key';
import { useTranslation } from 'react-i18next';

const vanking = false;

const financial = false;

const showAll = false;

const openCard = true;

const navHeight = 60

let openCardMerchant = false;

let openCardAdmin = false;

// const navMenus = [
//   // {
//   //   id: "tx-a",
//   //   name: "ALIADOS",
//   //   to: "/storesmap", 
//   //   icon: <IoStorefront />
//   // },
//   {
//     id: "products",
//     name: t("NavBar.products"),
//     // to: "/account/create_account",
//     to: "/account/handle_account",
//     icon: <FaWallet />
//   },
//   {
//     id: "admin-a",
//     name: "",
//     to: "/handle_profile",
//     icon: <Avatar alt="Perfil" >
//             <PersonIcon style={{fontSize: "2rem"}} />
//           </Avatar>
//   },
//   {
//     id: "initialLogin",
//     name: t("NavBar.exit"),
//     to: "/",
//     icon: <FaRegAddressCard />
//   },
// ]

const NavBarStyle = styled.div(({
  display: "flex",
  flexDirection: "row",
  gap: "10%",
  height: "5rem",
  width: "100%",
  // maxWidth: "620px",
  // padding: "0.25rem 1.25rem",
  justifyContent: "center",
  alignSelf: "center",
  // backgroundColor: Colors.primary
  // backgroundColor: "#50C878"
  backgroundColor: "#4e30d8"
  // backgroundImage: "linear-gradient(to right, #56ccf2, #2f80ed)"
  // backgroundImage: "linear-gradient(to right, #009fff, #ec2f4b)"
  // backgroundImage: "linear-gradient(to right, #396afc, #2948ff)"
  // backgroundImage: "linear-gradient(to right, #0575E6, #00F260)"
  
}))

const NavItem = styled.div(({
  width: "20%",
  display: "flex",
  alignItems: "flex-end",
  color: "white",
  cursor: "pointer",
}))

const NavBar = ({ userdata, loginUser }) => {

  const history = useHistory();

  const { t } = useTranslation();

  React.useEffect(() => {
    handleProfile();
  }, []);

  const handleProfile = () => {
    if(loginUser.roles && !(Object.keys(loginUser.roles).length === 0 && loginUser.roles.constructor === Object)){
        
        if(loginUser.roles.some((rol) => rol.authority === "MERCHANT")){
            openCardMerchant=true;
            return;
        }

        else if(loginUser.roles.some((rol) => rol.authority === "ADMIN")){
          openCardAdmin=true;
          openCardMerchant=false;
        }

        else{
          openCardMerchant=false;
        }
    }

    else{
      openCardMerchant=false;
    }
}

  const navMenus = [
    // {
    //   id: "tx-a",
    //   name: "ALIADOS",
    //   to: "/storesmap", 
    //   icon: <IoStorefront />
    // },
    {
      id: "products",
      name: t("NavBar.products"),
      // to: "/account/create_account",
      to: "/account/handle_account",
      icon: <FaWallet />
    },
    {
      id: "admin-a",
      name: "",
      to: "/handle_profile",
      icon: <Avatar alt="Perfil" >
              <PersonIcon style={{fontSize: "2rem"}} />
            </Avatar>
    },
    {
      id: "initialLogin",
      name: t("NavBar.exit"),
      to: "/",
      icon: <FaRegAddressCard />
    },
  ]

  const handleClickMenu = (event, to, id) => {
    
    if(!to) return;
    if (to === "/") {
      localStorage.clear();
      userdata(null);
      history.push(to);
    }
    if(id==="products"){
      setAnchorEl(event.currentTarget);

    }
    else{
      history.push(to);
    }
    
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toCredit = ()=>{
    history.push("/account/create_credit");
    handleClose();
  };

  const toWallet = () => {
    history.push("/account/handle_account")
    handleClose();
  };

  const toCreateToken = () => {
    history.push("/account/create_account")
    handleClose();
  };

  const toCrm = () => {
    history.push("/commercial/deals")
    handleClose();
  };

  const toDelivery = () => {
    history.push("/lastmile/board")
    handleClose();
  };

  const toSelling = () => {
    history.push("/selling/marketplace")
    handleClose();
  };

  const toPortfolio = () => {
    history.push("/selling/client-portfolio");
    handleClose();
  };
  const toHistory = () => {
    history.push("/selling/marketplace/operations");
    handleClose();
  };
  const toCreditCard = () => {
    // history.push("/creditCard/offer");
    // history.push("/creditCard/management");
    history.push({
      pathname: "/creditCard/mount",
      state: {
        navBar: true
      }
    });
    handleClose();
  };
  const toPurchaseSimulator = () => {
    history.push("/creditCard/purchase-simulator");
    handleClose();
  };
  const toNewCreditCard = () => {
    history.push("/creditCard/offer");
    handleClose();
  };
  const toQrPayment = () => {
    history.push("/creditCard/qr-payment");
    handleClose();
  };
  const toSpbviKeyCreation = () => {
    history.push("/spbvi/create-paymentKey");
    handleClose();
  };
  const toSpbviKeyHandle = () => {
    history.push("/spbvi/handle-PaymentKey");
    handleClose();
  };
  

  return (
    <NavBarStyle>
      <Grid container>
        <Grid item xs={4} sm={6} md={8} lg={8} style={{display: "flex", justifyContent: "start", alignItems: "center"}}>
            <Box 
                component="img"
                sx={{
                  width: {xs:"115%", sm:"20%", md: "25%"},
                  height: {xs:"auto", sm:"auto", md: "auto"},
                  // minHeight: "410px",
                  paddingLeft: {xs: "0.9rem", sm: "1rem", md: "1.5rem"}
                  // position:"absolute", top: 10, left: 10 
                }}
                alt="Logo"
                src="/images/logo-opencard-white.png"

            />
        </Grid>
        <Grid item xs={8} sm={6} md={4} lg={4} style={{display: "flex", justifyContent: {xs: "end", sm: "center", md: "center"}, alignItems: "center", marginRight: {xs: "0.2rem", sm: "0.5rem", md: "0.5rem"}, marginLeft: "0.5rem", flex: 1}}>
          {navMenus.map(menu => (
            <Grid container key={menu.id} style={{justifyContent: "center"}}>
              <Grid item xs={3} sm={3} md={3}>
                <NavItem id={menu.id} key={menu.id} onClick={(e) => handleClickMenu(e, menu.to, menu.id)}>
                  <div style={{ textAlign: "center", marginTop: "1rem", marginBottom: "1rem" }}>
                    {menu.icon}
                    <p
                      style={{
                        margin: 0,
                        fontSize: "0.5rem",
                        fontWeight: "400",
                        overflowWrap: "break-word",
                        color: "white",
                      }}
                    >
                      {menu.name}
                    </p>
                  </div>
                </NavItem>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                
                {vanking === true &&
                  <>
                  <MenuItem onClick={toSelling}>Lotes en Venta</MenuItem>
                  <MenuItem onClick={toPortfolio}>Lotes Comprados</MenuItem>
                  <MenuItem onClick={toHistory}>Lotes Operando</MenuItem>
                  </>
                }
                {financial === true &&
                  <>
                  <MenuItem onClick={toCredit}>
                    <ListItemIcon>
                      <RequestQuoteOutlinedIcon sx={{color: "#03a9f4"}}/>
                    </ListItemIcon>
                    Crédito
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={toWallet}>
                    <ListItemIcon>
                      <AccountBalanceWalletOutlinedIcon sx={{color: "#03a9f4"}}/>
                    </ListItemIcon>
                    Billetera
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={toQrPayment}>
                    <ListItemIcon>
                      <QrCode2OutlinedIcon sx={{color: "#03a9f4"}}/>
                    </ListItemIcon>
                    Pago QR
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={toCreditCard}>
                    <ListItemIcon>
                      <CreditCardOutlinedIcon sx={{color: "#03a9f4"}}/>
                    </ListItemIcon>
                    Tarjetas
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={toPurchaseSimulator}>
                    <ListItemIcon>
                      <ShoppingCartOutlinedIcon sx={{color: "#03a9f4"}}/>
                    </ListItemIcon>  
                    Compra TC
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={toNewCreditCard}>
                    <ListItemIcon>
                      <AddCardOutlinedIcon sx={{color: "#03a9f4"}}/>
                    </ListItemIcon>
                    Nueva Tarjeta
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={toCreateToken}>
                    <ListItemIcon>
                      <MonetizationOnOutlinedIcon sx={{color: "#03a9f4"}}/>
                    </ListItemIcon>
                    Nuevo activo
                  </MenuItem>
                  </>

                }
                {openCard === true && openCardMerchant === false &&
                  <>
                  <MenuItem onClick={toCreditCard}>
                    <ListItemIcon>
                      <CreditCardOutlinedIcon sx={{color: "#03a9f4"}}/>
                    </ListItemIcon>
                    {t("NavBar.cards")}
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={toPurchaseSimulator}>
                    <ListItemIcon>
                      <ShoppingCartOutlinedIcon sx={{color: "#03a9f4"}}/>
                    </ListItemIcon>  
                    {t("NavBar.purchase")}
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={toNewCreditCard}>
                    <ListItemIcon>
                      <AddCardOutlinedIcon sx={{color: "#03a9f4"}}/>
                    </ListItemIcon>
                    {t("NavBar.newCard")}
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={toQrPayment}>
                    <ListItemIcon>
                      <QrCode2OutlinedIcon sx={{color: "#03a9f4"}}/>
                    </ListItemIcon>
                    {t("NavBar.qrPayment")}
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={toCredit}>
                    <ListItemIcon>
                      <RequestQuoteOutlinedIcon sx={{color: "#03a9f4"}}/>
                    </ListItemIcon>
                    {t("NavBar.loan")}
                  </MenuItem>
                  {openCardAdmin && openCardAdmin === true &&
                  <>
                    <Divider />
                    <MenuItem onClick={toSpbviKeyCreation}>
                      <ListItemIcon>
                        <KeyIcon sx={{color: "#03a9f4"}}/>
                      </ListItemIcon>
                      {t("NavBar.createPaymentKey")}
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={toSpbviKeyHandle}>
                      <ListItemIcon>
                        <KeyIcon sx={{color: "#03a9f4"}}/>
                      </ListItemIcon>
                      {t("NavBar.getPaymentKey")}
                    </MenuItem>
                  </>
                  }
                  </>

                }
                {openCard === true && openCardMerchant === true &&
                  <>
                  <MenuItem onClick={toQrPayment}>
                    <ListItemIcon>
                      <QrCode2OutlinedIcon sx={{color: "#03a9f4"}}/>
                    </ListItemIcon>
                    {t("NavBar.qrPayment")}
                  </MenuItem>
                  </>

                }
                {financial===false && showAll===true &&
                  <>
                  <MenuItem onClick={toCredit}>
                    <ListItemIcon>
                      <RequestQuoteOutlinedIcon/>
                    </ListItemIcon>
                    Crédito
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={toWallet}>
                    <ListItemIcon>
                      <AccountBalanceWalletOutlinedIcon/>
                    </ListItemIcon>
                    Billetera
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={toQrPayment}>
                    <ListItemIcon>
                      <QrCode2OutlinedIcon />
                    </ListItemIcon>
                    Pago QR
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={toCreditCard}>
                    <ListItemIcon>
                      <CreditCardOutlinedIcon />
                    </ListItemIcon>
                    Tarjetas
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={toPurchaseSimulator}>
                    <ListItemIcon>
                      <ShoppingCartOutlinedIcon />
                    </ListItemIcon>  
                    Compra TC
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={toNewCreditCard}>
                    <ListItemIcon>
                      <AddCardOutlinedIcon />
                    </ListItemIcon>
                    Nueva Tarjeta
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={toCreateToken}>Nuevo token</MenuItem>
                  <Divider />
                  <MenuItem onClick={toCrm}>Comercial</MenuItem>
                  <Divider />
                  <MenuItem onClick={toDelivery}>Entregas</MenuItem>
                  <Divider />
                  <MenuItem onClick={toSelling}>Inversiones</MenuItem>
                  <Divider />
                  <MenuItem onClick={toPortfolio}>Portafolio</MenuItem>
                  <Divider />
                  <MenuItem onClick={toHistory}>Operando</MenuItem>
                  </>
                }
                  
                </Menu>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </NavBarStyle>
  )
}

export default NavBar
