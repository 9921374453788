import React from 'react';
import {getParticipantCredentials, createParticipantCredentials} from "../../utilityFunctions/SpbviUtil";
import swal from "sweetalert";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Colors from "../../components/ui/Colors";
import { Button, Typography } from "@mui/material";
import Divider from '@mui/material/Divider';
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const apiKeyRequest = {
    username: "",
    domain: "",
};

export default function CreateApiKey(props) {

    const {loginUser} = props;

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        initialValues();
    }, []);

    const [receivedDomain, setReceivedDomain] = React.useState("");
    const [receivedApikey, setReceivedApikey] = React.useState("");

    const[apiKey, setApiKey] = React.useState("");
    const[domain, setDomain] = React.useState("");

    const initialValues = async() => {
        apiKeyRequest.username = loginUser.accountId;

        setLoading(true);
        const apiKeyResponse = await getParticipantCredentials(apiKeyRequest, loginUser.jwtToken);
        setLoading(false);

        if(apiKeyResponse){
            setReceivedApikey(apiKeyResponse.apiKey);
            setReceivedDomain(apiKeyResponse.domain);
        }
        else{
            setReceivedApikey("");
            setReceivedDomain("");
        }
    };
    
    const[disableRegister, setDisableRegister] = React.useState(true);

    const domainChange = (e) => {
        setDomain(e.target.value);

        const isValid = isValidDomain(e.target.value);
        setDisableRegister(!isValid);
    };

    const isValidDomain = (domain) => {
        // Regular expression pattern for a valid domain
        const domainPattern = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return domainPattern.test(domain);
    };

    const [changeApiKeyState, setChangeApiKeyState] = React.useState(false);
    const changeApiKey = async() => {
        setChangeApiKeyState(true);
    };

    const registerApiKey = async() => {
        setChangeApiKeyState(false);

        apiKeyRequest.username = loginUser.accountId;
        apiKeyRequest.domain = domain;

        setLoading(true);
        const apiKeyResponse = await createParticipantCredentials(apiKeyRequest, loginUser.jwtToken);
        setLoading(false);

        if(apiKeyResponse){
            setReceivedApikey(apiKeyResponse.apiKey);
            setReceivedDomain(apiKeyResponse.domain);
        }
        else{
            swal({
                title: "Error creando la llave",
                icon: "error",
                button: "ok",
            });
            return;
        }        
    }; 

    return (
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center",  width: "100%", height: "100%", backgroundColor: "#ededed",}}>
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
            <Box
            sx={{
                display: "flex",
                // flex: 1,
                justifyContent: "center",
                alignItems: {xs: "start", sm: "center", md: "center"},
                width: "90%",
                height: "100%",
                textAlign: "center",
            }}
            >
                <Grid container spacing={2} sx={{ display: "flex", justifyContent: {xs: "center", sm: "center"}, alignItems: {xs: "start", sm: "center", md: "center"}, width: "100%", marginLeft: "1%", marginTop: {xs: "10%", sm: "0%"}}}>
                    {receivedDomain && receivedApikey && changeApiKeyState===false?
                        <>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Typography sx={{color: "gray", fontSize: "0.7rem"}}>Api Key</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                            <Typography variant="body2" sx={{color: "black",}}>{receivedApikey}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", width: {xs: "90%", sm: "90%", md: "40%"}}}>
                            <Divider sx={{width: "40%"}} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center",}}>
                            <Typography sx={{color: "gray", fontSize: "0.7rem"}}>Dominio</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                            <Typography variant="body2" sx={{color: "black",}}>{receivedDomain}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", marginTop: "1.8rem", marginBottom: "1rem"}}>
                            <Button onClick={changeApiKey} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                                Cambiar
                            </Button>
                        </Grid>
                        </>
                        :
                        <>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                            <TextField
                                sx={{width: {xs: "90%", sm: "90%", md: "40%"},}}
                                variant="standard"
                                id="domain"
                                name="domain"
                                label="Incluye el dominio"
                                fullWidth
                                value={domain}
                                onChange={domainChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", marginTop: "1.8rem", marginBottom: "1rem"}}>
                            <Button disabled={disableRegister} onClick={registerApiKey} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                                Registrar
                            </Button>
                        </Grid>
                        </>
                    }
                    
                    
                </Grid>
            </Box>
            }
        </Box>
    )
}
