import React from 'react'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { colombianStates, municipalities, countries, bankCodes } from "../../Api";
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';
import 'moment/locale/es';
// import bank from 'epayco-sdk-node/lib/resources/bank';

export default function IDInformation(props) {
    const{
        dataForParent,
        setMunicipality,
        setIdIssueDate,
        setPersonTypewhenMerchant,
        setCountryFromList,
    }=props;

    const { t } = useTranslation();

    const profileTypes = [
      {
        type: "merchant",
        label: t("IDInformation.profileType.merchant")
      },
      {
        type: "user",
        label: t("IDInformation.profileType.user")
      }
    ];
    
    
    const clientTypes = [
      {
        type: "person",
        label: t("IDInformation.clientType.person")
      },
      {
        type: "company",
        label: t("IDInformation.clientType.company")
      },
    ];
    
    const clientIdTypes = [
      {
        type: "nationalId",
        label: t("IDInformation.clientIdTypes.nationalId")
      },
      {
        type: "passport",
        label: t("IDInformation.clientIdTypes.passport")
      },
      {
        type: "foreignId",
        label: t("IDInformation.clientIdTypes.foreignId")
      },
    ];

    const companyTypes = [
      {
        type: "0",
        label: t("IDInformation.companyTypes.other")
      },
      {
        type: "1",
        label: t("IDInformation.companyTypes.limitedCompany")
      },
      {
        type: "2",
        label: t("IDInformation.companyTypes.nonProfit")
      },
      {
        type: "3",
        label: t("IDInformation.companyTypes.privateLimitedCompany")
      },
      {
        type: "4",
        label: t("IDInformation.companyTypes.singlePersonCompany")
      },
      {
        type: "5",
        label: t("IDInformation.companyTypes.partnership")
      },
      {
        type: "6",
        label: t("IDInformation.companyTypes.limitedPartnership")
      },
      {
        type: "7",
        label: t("IDInformation.companyTypes.limitedPartnershipWithShares")
      },
      {
        type: "8",
        label: t("IDInformation.companyTypes.publicEntity")
      },
      {
        type: "10",
        label: t("IDInformation.companyTypes.precooperative")
      },
      {
        type: "11",
        label: t("IDInformation.companyTypes.cooperative")
      },
      {
        type: "12",
        label: t("IDInformation.companyTypes.employeeFunds")
      },
      {
        type: "13",
        label: t("IDInformation.companyTypes.sas")
      },
      {
        type: "14",
        label: t("IDInformation.companyTypes.workersPartnership")
      },
      {
        type: "15",
        label: t("IDInformation.companyTypes.workerCooperative")
      },
    ];
    
    const representativeIdTypes =[
      {
        type: "nationalId",
        label: t("IDInformation.representativeIdTypes.nationalId")
      },
      {
        type: "passport",
        label: t("IDInformation.representativeIdTypes.passport")
      },
      {
        type: "foreignId",
        label: t("IDInformation.representativeIdTypes.foreignId")
      },
    ];

    const incocreditoCodeExist = [
      {
        type: true,
        label: t("IDInformation.incocreditoCode.yes")
      },
      {
        type: false,
        label: t("IDInformation.incocreditoCode.no")
      },
    ];

    const bankAccountTypes = [
      {
        type: "A",
        label: t("IDInformation.bankInfo.bankAccountTypes.savings")
      },
      {
        type: "C",
        label: t("IDInformation.bankInfo.bankAccountTypes.checking")
      },
    ];

    React.useEffect(() => {
      bankCodes.sort((a, b) => (a.bank > b.bank) ? 1 : -1);
    }, []);

    
    const [id, setID] = React.useState('');
  
    const handleChange = (event) => {
        dataForParent(event);
    }; 

    const [idType, setIdType] = React.useState("");

    const idTypeChange = (e) => {
      setIdType(e.target.value);
      dataForParent(e);
    };
    
    const handleSelectChange=(event) => {
        setID(event.target.value);
        dataForParent(event);

    }

    const [geoState, setGeoState] = React.useState("");

    const geoStateChange = (event, value) => {
      if(!value){
        setGeoState("");
        return;
      }
      setGeoState(value.stateCode);
    };


    const[renderMunicipalities, setRenderMunicipalities]= React.useState(
      {
        options: municipalities,
        getOptionLabel: (option) => option.city,

      }
    );

    const onChangeAutocomplete = (event, value) => {
      if(!value){
        return;
      }
      // console.log(value);
      setMunicipality(value);
    };

    const [countriesAutocomplete, setCountriesAutocomplete] = React.useState(
      {
        options: countries,
        getOptionLabel: (option) => option.country,

      }
    );

    const [country, setCountry] = React.useState("");

    const countryChange = (event, value) => {
      if(!value){
        setCountry("");
        return;
      }

      console.log(value);
      setCountry(value.countryCode);
      setCountryFromList(value);
      

      if(value.countryCode!=="CO"){
        const nonColombianCity = {
          city: value.country,
          cityCode: "1",
          stateCode: "1",
        };
        setMunicipality(nonColombianCity);
      }
      
    };

    const[clientType, setClientType] = React.useState("");

    const clientTypeChange = (e) => {
      setClientType(e.target.value);
      dataForParent(e);
    };

    const[profile, setProfile] = React.useState("");

    const profileChange = (e) => {
      setProfile(e.target.value);
      dataForParent(e);

      if(e.target.value==="merchant"){
        setClientType("company");
        setPersonTypewhenMerchant("company");
      };
    };

    const[incocreditoCode, setIncocreditoCode] = React.useState("");
    const[uniqueCode, setUniqueCode] = React.useState("");
    const[mcc, setMcc] = React.useState("");

    const incocreditoCodeChange = (e) => {
      console.log(e.target.value);
      setIncocreditoCode(e.target.value);
    };

    const uniqueCodeChange = (e) => {
      setUniqueCode(e.target.value);
      dataForParent(e);
    };

    const mccChange = (e) => {
      setMcc(e.target.value);
      dataForParent(e);
    };

    const[representativeIdType, setRepresentativeIdType] = React.useState("");

    const representativeIdTypeChange = (e) => {
      setRepresentativeIdType(e.target.value);
      dataForParent(e);
    };

    const [selectedDate, setSelectedDate] = React.useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(moment(date).format('YYYYMMDD'));
        setIdIssueDate(moment(date).format('YYYYMMDD'));
    };

    const [companyType, setCompanyType] = React.useState("");

    const companyTypeChange = (e) => {
      setCompanyType(e.target.value);
      dataForParent(e);
    };

    const [telephone, setTelephone] = React.useState("");

    const telephoneChange = (e) => {
      setTelephone(e.target.value);
      dataForParent(e);
    };

    const [bankAccountType, setBankAccountType] = React.useState("");

    const bankAccountTypeChange = (e) => {
      setBankAccountType(e.target.value);
      dataForParent(e);
    };

    const [bankAccountNumber, setBankAccountNumber] = React.useState("");

    const bankAccountNumberChange = (e) => {
      setBankAccountNumber(e.target.value);
      dataForParent(e);
    };

    const [bank, setBank] = React.useState("");

    const bankChange = (e) => {
      setBank(e.target.value);
      dataForParent(e);
    };


    return (
      <Box
        sx={{
          display: "flex",
          // flexGrow: "1",
          width: "100%",
          // height: "80%",
          justifyContent: "center",
          alignContent:"center",
          // marginLeft: "1.5rem",
        }}
      >
        
        <Grid container spacing={3} sx={{ justifyContent: "center", width: "90%"}}>
          <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
              <TextField
                  sx={{textAlign: "start", width: {xs: "90%", sm: "70%", md: "50%"}}}
                  variant="standard"
                  fullWidth={true}
                  id="profile"
                  name="profile"
                  select
                  label={<Typography variant="body2">{t("IDInformation.profile")}</Typography>}
                  value={profile}
                  onChange={(e)=>profileChange(e)}
                  autoComplete="off"
              >
                  {profileTypes.map((typeEl) => (
                      <MenuItem key={typeEl.type} value={typeEl.type}>
                          {typeEl.label}
                      </MenuItem>
                  ))}
              </TextField> 
          </Grid>
          {profile && profile!=="merchant"&&
            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                <TextField
                    sx={{textAlign: "start", width: {xs: "90%", sm: "70%", md: "50%"}}}
                    variant="standard"
                    fullWidth={true}
                    id="clientType"
                    name="clientType"
                    select
                    label={<Typography variant="body2">{t("IDInformation.personType")}</Typography>}
                    value={clientType}
                    onChange={(e)=>clientTypeChange(e)}
                    autoComplete="off"
                >
                    {clientTypes.map((typeEl) => (
                        <MenuItem key={typeEl.type} value={typeEl.type}>
                            {typeEl.label}
                        </MenuItem>
                    ))}
                </TextField> 
            </Grid>
          }
          {profile && profile==="merchant"&&
            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                <TextField
                    sx={{textAlign: "start", width: {xs: "90%", sm: "70%", md: "50%"}}}
                    variant="standard"
                    fullWidth={true}
                    id="incocreditoCode"
                    name="incocreditoCode"
                    select
                    label={<Typography variant="body2">{t("IDInformation.incocreditoCodeLable")}</Typography>}
                    value={incocreditoCode}
                    onChange={(e)=>incocreditoCodeChange(e)}
                    autoComplete="off"
                >
                    {incocreditoCodeExist.map((typeEl) => (
                        <MenuItem key={typeEl.type} value={typeEl.type}>
                            {typeEl.label}
                        </MenuItem>
                    ))}
                </TextField> 
            </Grid>
          }
          {incocreditoCode && incocreditoCode===true&&
            <>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="uniqueCode"
                name="uniqueCode"
                label={t("IDInformation.uniqueCode")}
                fullWidth
                value={uniqueCode}
                onChange={uniqueCodeChange}
                inputProps={{ maxLength: 11 }} // Set the max length
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="mcc"
                name="mcc"
                label={t("IDInformation.mcc")}
                fullWidth
                value={mcc}
                onChange={mccChange}
                inputProps={{ maxLength: 4 }} // Set the max length
              />
            </Grid>
            </>
          }

          {clientType && clientType==="person" &&
            <>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="firstName"
                name="firstName"
                label={t("IDInformation.firstName")}
                fullWidth
                autoComplete="given-name"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="secondName"
                name="secondName"
                label={t("IDInformation.secondName")}
                fullWidth
                autoComplete="given-name"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="firstLastName"
                name="firstLastName"
                label={t("IDInformation.firstLastName")}
                fullWidth
                autoComplete="family-name"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="secondLastName"
                name="secondLastName"
                label={t("IDInformation.secondLastName")}
                fullWidth
                autoComplete="family-name"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                  sx={{textAlign: "start"}}
                  variant="standard"
                  fullWidth={true}
                  id="idType"
                  name="idType"
                  select
                  label={<Typography variant="body2">{t("IDInformation.idType")}</Typography>}
                  value={idType}
                  onChange={(e)=>idTypeChange(e)}
                  autoComplete="off"
              >
                  {clientIdTypes.map((typeEl) => (
                      <MenuItem key={typeEl.type} value={typeEl.type}>
                          {typeEl.label}
                      </MenuItem>
                  ))}
              </TextField> 
          </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="idNumber"
                name="idNumber"
                label={t("IDInformation.idNumbre")}
                fullWidth
                autoComplete="idNumber"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} sx={{width: "100%", marginTop: "0.5rem"}}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                        timezone="America/Bogota"
                        locale="es" 
                        label={<Typography variant="body2">Fecha de expedición</Typography>}
                        inputFormat="DD/MM/YYYY"
                        value={selectedDate}
                        onChange={handleDateChange}
                        renderInput={(params) => 
                            <TextField {...params} 
                                variant="standard"
                                fullWidth
                                sx={{width: {xs: "90%", sm: "80%", md: "80%"}}}
                            />}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="Address"
                name="Address"
                label={t("IDInformation.address")}
                fullWidth
                autoComplete="given-address"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Autocomplete sx={{ width: "100%"}}
                {...countriesAutocomplete}
                
                id="country"
                name="country"
                clearOnEscape
                autoComplete = {true}
                noOptionsText = {t("IDInformation.countryNotFound")}
                fullWidth
                onChange={countryChange}
                // onInputChange={onChange}
                renderInput={(params) => (
                  <TextField  {...params} label={t("IDInformation.country")} variant="standard" />
                )}
              />
            </Grid>
            {country && country==="CO" &&
            <>
            <Grid item xs={12} sm={6} md={6}>
              <Autocomplete sx={{ width: "100%"}}
                options={colombianStates}
                getOptionLabel={(option) => option.state}
                // {...renderMunicipalities}
                
                id="geoState"
                name="geoState"
                clearOnEscape
                autoComplete = {true}
                noOptionsText = {t("IDInformation.notFoundGeoState")}
                fullWidth
                onChange={geoStateChange}
                // onInputChange={onChange}
                renderInput={(params) => (
                  <TextField  {...params} label={t("IDInformation.geoState")} variant="standard" />
                )}
              />
            </Grid>
            {geoState &&
            <Grid item xs={12} sm={6} md={6}>
              <Autocomplete sx={{ width: "100%"}}
                options={municipalities.filter((municipality) => municipality.stateCode === geoState)}
                getOptionLabel={(option) => `${option.city} (${option.cityCode})`}
                // {...renderMunicipalities}
                
                id="Municipality"
                name="Municipality"
                clearOnEscape
                autoComplete = {true}
                noOptionsText = {t("IDInformation.notFoundCity")}
                fullWidth
                onChange={onChangeAutocomplete}
                // onInputChange={onChange}
                renderInput={(params) => (
                  <TextField  {...params} label={t("IDInformation.city")} variant="standard" />
                )}
              />
            </Grid>
            }
            </>
            }
            <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    variant="standard"
                    // required
                    id="phone"
                    type="tel"
                    name="phone"
                    label={t("IDInformation.phone")}
                    fullWidth
                    // value={value}
                    autoComplete="given-phone"
                    onChange={handleChange}
                    inputProps={{ maxLength: 10, minLength: 10}}
                  />
            </Grid>
            </>
          }
          {clientType && clientType==="company" &&
            <>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="firstName"
                name="firstName"
                label={t("IDInformation.businessName")}
                fullWidth
                onChange={handleChange}
                inputProps={{ maxLength: 30 }} // Set the max length
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="idNumber"
                name="idNumber"
                label={t("IDInformation.CompanyId")}
                fullWidth
                autoComplete="idNumber"
                onChange={handleChange}
                inputProps={{ maxLength: 20 }} // Set the max length
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                  sx={{textAlign: "start"}}
                  variant="standard"
                  fullWidth={true}
                  id="companyType"
                  name="companyType"
                  select
                  label={<Typography variant="body2">{t("IDInformation.companyType")}</Typography>}
                  value={companyType}
                  onChange={(e)=>companyTypeChange(e)}
                  autoComplete="off"
              >
                  {companyTypes.map((typeEl) => (
                      <MenuItem key={typeEl.type} value={typeEl.type}>
                          {typeEl.label}
                      </MenuItem>
                  ))}
              </TextField> 
          </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="Address"
                name="Address"
                label={t("IDInformation.address")}
                fullWidth
                autoComplete="given-address"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Autocomplete sx={{ width: "100%"}}
                {...countriesAutocomplete}
                
                id="country"
                name="country"
                clearOnEscape
                autoComplete = {true}
                noOptionsText = {t("IDInformation.countryNotFound")}
                fullWidth
                onChange={countryChange}
                // onInputChange={onChange}
                renderInput={(params) => (
                  <TextField  {...params} label={t("IDInformation.country")} variant="standard" />
                )}
              />
            </Grid>
            {country && country==="CO" &&
            <>
            <Grid item xs={12} sm={6} md={6}>
              <Autocomplete sx={{ width: "100%"}}
                options={colombianStates}
                getOptionLabel={(option) => option.state}
                // {...renderMunicipalities}
                
                id="geoState"
                name="geoState"
                clearOnEscape
                autoComplete = {true}
                noOptionsText = {t("IDInformation.notFoundGeoState")}
                fullWidth
                onChange={geoStateChange}
                // onInputChange={onChange}
                renderInput={(params) => (
                  <TextField  {...params} label={t("IDInformation.geoState")} variant="standard" />
                )}
              />
            </Grid>
            {geoState &&
            <Grid item xs={12} sm={6} md={6}>
              <Autocomplete sx={{ width: "100%"}}
                options={municipalities.filter((municipality) => municipality.stateCode === geoState)}
                getOptionLabel={(option) => `${option.city} (${option.cityCode})`}
                // {...renderMunicipalities}
                
                id="Municipality"
                name="Municipality"
                clearOnEscape
                autoComplete = {true}
                noOptionsText = {t("IDInformation.notFoundCity")}
                fullWidth
                onChange={onChangeAutocomplete}
                // onInputChange={onChange}
                renderInput={(params) => (
                  <TextField  {...params} label={t("IDInformation.city")} variant="standard" />
                )}
              />
            </Grid>
            }
            </>
            }
            <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    variant="standard"
                    // required
                    id="phone"
                    type="tel"
                    name="phone"
                    label={t("IDInformation.phone")}
                    fullWidth
                    // value={value}
                    autoComplete="given-phone"
                    onChange={handleChange}
                    inputProps={{ maxLength: 10, minLength: 10}}
                  />
            </Grid>
            {profile && profile==="merchant"&&
            <>
              <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    variant="standard"
                    // required
                    id="telephone"
                    type="telephone"
                    name="telephone"
                    label={t("IDInformation.telephone")}
                    fullWidth
                    value={telephone}
                    onChange={telephoneChange}
                    inputProps={{ maxLength: 20 }} // Set the max length
                  />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                    sx={{textAlign: "start"}}
                    variant="standard"
                    fullWidth={true}
                    id="bankAccountType"
                    name="bankAccountType"
                    select
                    label={<Typography variant="body2">{t("IDInformation.bankInfo.bankAccountType")}</Typography>}
                    value={bankAccountType}
                    onChange={(e)=>bankAccountTypeChange(e)}
                    autoComplete="off"
                >
                    {bankAccountTypes.map((typeEl) => (
                        <MenuItem key={typeEl.type} value={typeEl.type}>
                            {typeEl.label}
                        </MenuItem>
                    ))}
                </TextField> 
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  variant="standard"
                  id="bankAccountNumber"
                  name="bankAccountNumber"
                  label={t("IDInformation.bankInfo.bankAccountNumber")}
                  fullWidth
                  onChange={bankAccountNumberChange}
                  inputProps={{ maxLength: 20 }} // Set the max length
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                    sx={{textAlign: "start"}}
                    variant="standard"
                    fullWidth={true}
                    id="bank"
                    name="bank"
                    select
                    label={<Typography variant="body2">{t("IDInformation.bankInfo.bank")}</Typography>}
                    value={bank}
                    onChange={(e)=>bankChange(e)}
                    autoComplete="off"
                >
                    {bankCodes.map((typeEl) => (
                        <MenuItem key={typeEl.code} value={typeEl.code}>
                            {typeEl.bank}
                        </MenuItem>
                    ))}
                </TextField> 
              </Grid>
              
            </>  
            }
            <Grid item xs={12} sm={12} md={12} sx={{ marginTop: "1rem", marginBottom: "0.1rem"}}>
                  <Divider />
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{textAlign: "center"}}>
                  <Typography variant="body1" sx={{color: "black", fontWeight: "bold"}}> {t("IDInformation.representativeInfo")}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="representativeName"
                name="representativeName"
                label={t("IDInformation.name")}
                fullWidth
                autoComplete="given-name"
                onChange={handleChange}
                inputProps={{ maxLength: 18 }} // Set the max length
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="representativeLastName"
                name="representativeLastName"
                label={t("IDInformation.lastName")}
                fullWidth
                autoComplete="family-name"
                onChange={handleChange}
                inputProps={{ maxLength: 12 }} // Set the max length
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                  sx={{textAlign: "start"}}
                  variant="standard"
                  fullWidth={true}
                  id="representativeIdType"
                  name="representativeIdType"
                  select
                  label={<Typography variant="body2">{t("IDInformation.idType")}</Typography>}
                  value={representativeIdType}
                  onChange={(e)=>representativeIdTypeChange(e)}
                  autoComplete="off"
              >
                  {representativeIdTypes.map((typeEl) => (
                      <MenuItem key={typeEl.type} value={typeEl.type}>
                          {typeEl.label}
                      </MenuItem>
                  ))}
              </TextField> 
          </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="representativeIdNumber"
                name="representativeIdNumber"
                label={t("IDInformation.idNumbre")}
                fullWidth
                autoComplete="idNumber"
                onChange={handleChange}
                inputProps={{ maxLength: 12 }} // Set the max length
              />
            </Grid>
            </>
          }
        </Grid>
      </Box>
    );
}