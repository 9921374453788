import {wupoServerUri} from '../Api';

const registerCardAndKeyFlow = async(requestObject, jwtToken) => {
  
    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/openCardProductAndKey`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const responseObject = await response.json();
      console.log(responseObject);
  
      return responseObject;
    }
  
    else {
      return null;
    }
}

const getApiKeysByUser = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/paymentMethodKeysByUser`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const getApiKeysByProduct = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/paymentMethodKeysByProduct`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const getParticipantCredentials = async(requestObject, jwtToken) => {
  
    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/spbvi/get_participant_credentials`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const responseObject = await response.json();
      console.log(responseObject);
  
      return responseObject;
    }
  
    else {
      return null;
    }
}

const createParticipantCredentials = async(requestObject, jwtToken) => {
  
    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/spbvi/participant_credentials`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const responseObject = await response.json();
      console.log(responseObject);
  
      return responseObject;
    }
  
    else {
      return null;
    }
}

const blockApiKeyReq = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/paymentKeyBlock`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const unblockApiKeyReq = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/paymentKeyUnblock`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const portabilityApiKeyReq = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/paymentKeyPortability`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

export {registerCardAndKeyFlow, getApiKeysByUser, getApiKeysByProduct, getParticipantCredentials, createParticipantCredentials,
  portabilityApiKeyReq, blockApiKeyReq, unblockApiKeyReq}