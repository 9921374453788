import {wupoServerUri} from '../Api';

const createProduct = (formData, jwtToken) => {
  
    // console.log(productPic);

    // const formData  = new FormData();
    // formData.append("productPic", productPic, "Fede.png");

    console.log(formData.get("selectedImage"));
    console.log(formData.get("newProduct"));

    return(
      fetch(`${wupoServerUri.devnet}/api/secure/newcattle`, {
          method: "POST",
          body: formData,
        //   credentials: 'include',
          headers: {
            "Authorization": `Bearer ${jwtToken}`,
            // "Content-Type": "application/json; charset=UTF-8",
          },
      })
      .then((response) => {
          console.log(response.status);
          if(response.status === 200){
            return response.status;
          }
          else{
              return null;
          }
      })
      .catch((error) => {
          console.log("Error: " + error);
      })
    )
  
}

const getActiveProducts = async(jwtToken) => {
  
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/getcattle`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          // "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });

    console.log(response.status);
    if(response.status === 200 ){
      const cattleData = await response.json();
      console.log(cattleData);

      //Bard
      // const byteCharacters = Buffer.from(cattleData[0].cattleLotPic.encodedCattlePic, 'base64')
      // // console.log(byteCharacters);

      // var blob = new Blob([byteCharacters], {type: "application/octet-stream"});
      // console.log(blob)

      const cattleDataAndImages = cattleData.map((data) => {
        const byteImage = Buffer.from(data.cattleLotPic.cattlePic, 'base64');
        data.cattleLotPic.cattlePic = new Blob([byteImage], {type: "application/octet-stream"});
        return data;
      });

      console.log(cattleDataAndImages);

      return cattleDataAndImages;
    }

    else {
      return null;
    }
}

const getInactiveProducts = async(jwtToken) => {
  
  
  const response = await fetch(`${wupoServerUri.devnet}/api/secure/getinactivecattle`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const cattleData = await response.json();
    console.log(cattleData);

    //Bard
    // const byteCharacters = Buffer.from(cattleData[0].cattleLotPic.encodedCattlePic, 'base64')
    // // console.log(byteCharacters);

    // var blob = new Blob([byteCharacters], {type: "application/octet-stream"});
    // console.log(blob)

    const cattleDataAndImages = cattleData.map((data) => {
      const byteImage = Buffer.from(data.cattleLotPic.cattlePic, 'base64');
      data.cattleLotPic.cattlePic = new Blob([byteImage], {type: "application/octet-stream"});
      return data;
    });

    console.log(cattleDataAndImages);

    return cattleDataAndImages;
  }

  else {
    return null;
  }
}

const getPic = async(jwtToken) => {
  
  
  const response = await fetch(`${wupoServerUri.devnet}/api/secure/getcattlepic`, {
      method: "POST",
      // body: formData,
    //   credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    console.log(Array.isArray(response));
    const uint8Array = new Uint8Array(response);
    console.log(uint8Array);
    // const cattleData = await response.blob();
    const cattleData = await response.json();
    console.log(cattleData);

    const picBlobOriginal = new Blob([cattleData[0].cattlePic], { type: "application/octet-stream" });
    console.log(picBlobOriginal);

    // const cattlePic = await cattleData[0].cattleLotPic.cattlePic;
    // console.log(cattlePic)

    // const cattle = {
    //   cattleData: cattleData,
    //   cattlePic: cattlePic,
    // }
    return cattleData;
  }

  else {
    return null;
  }
}

const createInvestment = (investmentObject, jwtToken) => {
  
  console.log(investmentObject);

  return(
    fetch(`${wupoServerUri.devnet}/api/secure/investment`, {
        method: "POST",
        body: JSON.stringify(investmentObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .then((response) => {
        console.log(response.status);
        if(response.status === 200){
            return response.json();
        }
        else{
            return "Not found";
        }
    })
    .then((investResponse) => {
        console.log(investResponse); // DEBUG PRINTING
        
        return investResponse;
    })
    .catch((error) => {
        console.log("Error: " + error);
    })
  )

}

const getCodes = async(jwtToken) => {
  
  const response = await fetch(`${wupoServerUri.devnet}/api/secure/codes`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const codes = await response.json();
    console.log(codes);

    return codes;
  }

  else {
    return null;
  }
}

const createSettlement = (settlementObject, jwtToken) => {
  
  console.log(settlementObject);

  return(
    fetch(`${wupoServerUri.devnet}/api/secure/cattlesale`, {
        method: "POST",
        body: JSON.stringify(settlementObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .then((response) => {
        console.log(response.status);
        if(response.status === 200){
            return response.json();
        }
        else{
            return null;
        }
    })
    .then((sellingResponse) => {
        console.log(sellingResponse); // DEBUG PRINTING
        
        return sellingResponse;
    })
    .catch((error) => {
        console.log("Error: " + error);
    })
  )

}

const clientSettlement = (settlementObject, jwtToken) => {
  
  console.log(settlementObject);

  return(
    fetch(`${wupoServerUri.devnet}/api/secure/settlementDocs`, {
        method: "POST",
        body: JSON.stringify(settlementObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .then((response) => {
        console.log(response.status);
        if(response.status === 200){
            return response.json();
        }
        else{
            return null;
        }
    })
    .then((sellingResponse) => {
        console.log(sellingResponse); // DEBUG PRINTING
        
        return sellingResponse;
    })
    .catch((error) => {
        console.log("Error: " + error);
    })
  )

}

const settlementPaymentUpdate = (settlementObject, jwtToken) => {
  
  console.log(settlementObject);

  return(
    fetch(`${wupoServerUri.devnet}/api/secure/settlementPayment`, {
        method: "POST",
        body: JSON.stringify(settlementObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .then((response) => {
        console.log(response.status);
        if(response.status === 200){
            return response.json();
        }
        else{
            return null;
        }
    })
    .then((sellingResponse) => {
        console.log(sellingResponse); // DEBUG PRINTING
        
        return sellingResponse;
    })
    .catch((error) => {
        console.log("Error: " + error);
    })
  )

}

const getPendingPayments = async(jwtToken) => {
  
  const response = await fetch(`${wupoServerUri.devnet}/api/secure/pendingpayments`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const soldCattles = await response.json();
    console.log(soldCattles);

    return soldCattles;
  }

  else {
    return null;
  }
}

const getClientPortfolio = async(portfolioRequest, jwtToken) => {
  
  const requestObject = {
    username: portfolioRequest,
  }

  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/clientPortfolio`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const soldCattles = await response.json();
    console.log(soldCattles);

    return soldCattles;
  }

  else if(response.status === 204){
    return "No investments";
  }

  else {
    return null;
  }
}

const persistBankAccount = (formData, jwtToken) => {
  
  
  console.log(formData.get("accountCertification"));
  console.log(formData.get("idCopy"));
  console.log(formData.get("accountObject"));

  return(
    fetch(`${wupoServerUri.devnet}/api/secure/newbankaccount`, {
        method: "POST",
        body: formData,
      //   credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          // "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .then((response) => {
        console.log(response.status);
        if(response.status === 200){
            return response.json();
        }
        else{
            return null;
        }
    })
    .then((docResponse) => {
        console.log(docResponse); // DEBUG PRINTING
        
        return docResponse;
    })
    .catch((error) => {
        console.log("Error: " + error);
    })
  )

}

const bankAccountExist = async(username, jwtToken) => {
  
  const requestObject = {
    username: username,
  }

  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/bankAccountExist`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    return "ok";
  }

  else if(response.status === 423){
    return "pending";
  }

  else {
    return null;
  }
}

const getBankAccountDocs = async(requestObject, jwtToken) => {
  

  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/getbankAccountDocs`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const bankAccount = await response.json();
    console.log(bankAccount);

    return bankAccount;
  }

  else {
    return null;
  }
}

const getAllUsernames = async(jwtToken) => {
  
  
  const response = await fetch(`${wupoServerUri.devnet}/api/secure/allusernames`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const usernames = await response.json();
    // console.log(usernames);

    return usernames;
  }

  else {
    return null;
  }
}

const enableBankAccount = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/bankAccountEnabler`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const bankAccount = await response.json();
    console.log(bankAccount);

    return bankAccount;
  }

  else {
    return null;
  }
}

const getBankAccount = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/activebankaccount`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const bankAccount = await response.json();
    console.log(bankAccount);

    return bankAccount;
  }

  else if(response.status === 404 ){
    return "not found";
  }

  else if(response.status === 423 ){
    return "pending";
  }

  else {
    return null;
  }
}

const getClient = async(username, jwtToken) => {
  
  const requestObject = {
    username: username,
  }

  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/getMarketPlaceUser`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const user = await response.json();
    console.log(user);

    return user;
  }

  else {
    return null;
  }
}

const changeProductsStatus = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/cattlestatus`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const cattleChanged = await response.json();
    console.log(cattleChanged);

    return cattleChanged;
  }

  else {
    return null;
  }
}

const getAccountPendingUsernames = async(jwtToken) => {
  
  
  const response = await fetch(`${wupoServerUri.devnet}/api/secure/getAllMarketAccountUsernames`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const usernames = await response.json();
    console.log(usernames);

    return usernames;
  }

  else {
    return null;
  }
}

const getClientPii = async(requestObject, jwtToken) => {
  

  const clientPiiRequest = {
    accountId: requestObject,
  };

  console.log(clientPiiRequest);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/getclientmarketPii`, {
      method: "POST",
      body: JSON.stringify(clientPiiRequest),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const clientPii = await response.json();
    console.log(clientPii);

    return clientPii;
  }

  else {
    return null;
  }
}

const changeClientPii = async(requestObject, jwtToken) => {
  

  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/changeclientPii`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const clientPii = await response.json();
    console.log(clientPii);

    return clientPii;
  }

  else {
    return null;
  }
}

const qryAllPurchases = (jwtToken) => {

  return(
    fetch(`${wupoServerUri.devnet}/api/admin/completePortfolio`, {
        
        method: 'POST',
        // body: JSON.stringify(creditObject),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        // console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
          console.log(response.status);
          return response.blob();
        }
        else{
            return null;
        }
    
      }).then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "transacciones.csv";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();  //afterwards we remove the element again      
          
          return "ok";
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

const qryAllSettlements = (jwtToken) => {

  return(
    fetch(`${wupoServerUri.devnet}/api/admin/completePortfolioSettlements`, {
        
        method: 'POST',
        // body: JSON.stringify(creditObject),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        // console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
          console.log(response.status);
          return response.blob();
        }
        else{
            return null;
        }
    
      }).then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "transacciones.csv";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();  //afterwards we remove the element again      
          
          return "ok";
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

const qryAllCattles = (jwtToken) => {

  return(
    fetch(`${wupoServerUri.devnet}/api/admin/completeCattles`, {
        
        method: 'POST',
        // body: JSON.stringify(creditObject),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        // console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
          console.log(response.status);
          return response.blob();
        }
        else{
            return null;
        }
    
      }).then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "transacciones.csv";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();  //afterwards we remove the element again      
          
          return "ok";
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

export {createProduct, getActiveProducts, getPic, createInvestment, getCodes, createSettlement, clientSettlement, 
  getPendingPayments, settlementPaymentUpdate, getClientPortfolio, persistBankAccount, bankAccountExist, getAllUsernames,
  getBankAccountDocs, enableBankAccount, getBankAccount, getClient, getInactiveProducts, changeProductsStatus, 
  getAccountPendingUsernames, getClientPii, changeClientPii, qryAllPurchases, qryAllSettlements, qryAllCattles}