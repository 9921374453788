import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Button, DialogContent, Typography } from "@mui/material";
import Colors from "../../../components/ui/Colors";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

const dateOptions = {
  // weekday: "short",
  day: "numeric",
  month: "numeric",
  year: "numeric",
};

let clientPurchases;

export default function ClientPurchases(props) {

    React.useEffect(() => {
        clientPurchases="";
        renderPurchases();
    }, []);

    const initialValues = () => {
        if(props.location.state && props.location.state.user){
          console.log(props.location.state.user);
          return props.location.state.user;
        }
    };

    const [purchases, setPurchases] = React.useState();

    const renderPurchases = async () => {

        clientPurchases = await initialValues().clientsPortfolio;
        console.log(clientPurchases);

        setPurchases(
            <List sx={{width: "100%", marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}}}>
              <Grid container spacing={2}>
                {clientPurchases.map((purchase) => {
                  return(
                    <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                      <ListItemButton>
                        <Grid key={purchase.id} item xs={3} sm={3} md={3} sx={{display: { xs: "none", sm: "flex", md: "flex"}, justifyContent: "center", alignItems: "center"}}>
                            <Typography key={purchase.id} variant="body1" sx={{color: "black"}}>
                                {`${purchase.username}`}
                            </Typography>
                        </Grid>
                        <Grid key={purchase.id} item xs={4} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Typography key={purchase.id} variant="body1" sx={{color: "black"}}>
                                {`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(purchase.investmentDate))}`}
                            </Typography>
                        </Grid>
                        <Grid key={purchase.id} item xs={3} sm={3} md={3} sx={{display: { xs: "none", sm: "flex", md: "flex"}, justifyContent: "center", alignItems: "center"}}>
                            <Typography key={purchase.id} variant="body1" sx={{color: "black"}}>
                                {`$ ${new Intl.NumberFormat('es-CO').format(purchase.initialBalance)}`}
                            </Typography>
                        </Grid>
                        <Grid key={purchase.id} item xs={4} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Typography key={purchase.id} variant="body1" sx={{color: "black"}}>
                              {`${new Intl.NumberFormat('es-CO').format(purchase.investmentShare * 100)} %`}
                            </Typography>
                        </Grid>
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </Grid>
            </List>
          );
    
      };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
            sx={{
                display: "flex",
                // flex: 1,
                justifyContent: "center",
                alignItems: {xs: "start", sm: "center"},
                width: "90%",
                height: "100%",
                textAlign: "center",
                overflowY: "scroll",
            }}
            >
                {purchases}
            </Box>
        </Box>
    )
}
