import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PuffLoader } from "react-spinners";
import swal from "sweetalert";
import { css } from "@emotion/react";
import ContentForm from "../../layout/ContentForm";
import PageLabel from "../../components/ui/PageLabel";
import AppButton from "../../components/ui/AppButton";
import Colors from "../../components/ui/Colors";
import { xrpPayment, prepareStatement } from "../../utilityFunctions/Paymentutil";
import { loadXrpAddressCreds } from "../../utilityFunctions/FetchUtil";
import { postCredit, fetchCreditConditions, postRefinancing, fetchRefinancingConditions} from "../../utilityFunctions/CreditUtil";
import { errTransaction } from "../../Texts/OperationResult.js";
import { creditAsset, creditTerm, loanMaxLimit, wupoServerUri } from "../../Api";
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Divider from '@mui/material/Divider';
import Slider from '@mui/material/Slider';

let marksBalance = [
    {
      value: 100000,
      label: '$100.000',
    },
    {
      value: 500000,
      label: '$500.000',
    },
  ];

let marksTerm = [
    {
        value: 7,
        label: '7',
    },
    {
        value: 56,
        label: '56',
    },
];

let marksInstallments = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 2,
        label: '2',
    },
];

const useStyles = makeStyles((theme) => ({
    input: {
        width: "50%",
    },

    formControl: {
        width: "50%",
    },

    dialogStyle:{
        whiteSpace: "pre-line",
        
    },

    dialogContainer:{
        width: "25em"
    },

    typography:{
        color: Colors.primary,
        fontWeight: "bold",
    },
}));

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

//Refinancing loan conditions
let creditValueConditions = {
    asset: "COP",
    minValue: 0,
    maxValue: 0,
    interval: 0,
};

let creditTermConditions = {
    minTerm: 0,
    maxTerm: 0,
    interval: 0,
    termUnit: creditTerm.day,
    installmentsTerm:0,
};

let creditInstallmentConditions = {
    amountInstallments: 1 //Can be equal to the term selected by the client / interval (traditional behavior).
};

let creditInterestConditions = {
    interestRate: 0,
    interestRateDefaultMultiplier: 0,
    collectionsFee: 0
};

let creditCostsConditions = {
    costsWithVAT: 0, // Platform
    costsWithVATminimum: 0,

    costsWithoutVAT: 0, //Aval
    costsWithoutVATminimum: 0,
    costsWithoutVATFee: 0,

    paymentType: "prepaid",
};

let conditionsArray=[];
let creditCost=0;
let creditInterestRate=0;
const clientCreditAsset = creditAsset;


let loanLimit = loanMaxLimit;

//Credit front rendering
let totalPayment = 0;
let uxEDR = 0;
let disbursedBalanceBeforeInterests = 0;
let VAT = 0; // Local tax: IVA = 19%


//Global variables to conduct calculations based on the client's inputs
const calculationVariables = {
    loanAmount: 0,
    margenState: 0,
    interestState: 0,
    warrantyDiscount: 0,
    platfromDiscount: 0,
    disbursedBalance: 0,
    vatTax: 0,
    term: 0,
};

//Info required to persist the credit
const creditOptions = ["Cashback", "change"]

const creditInformation = {
    creditXrplAccount: "",
    creditClassicXrplAccount: "",
    creditXrplSecret: "",
    asset: clientCreditAsset,
    initialBalance: "",
    term: "",
    termUnit: creditTerm.day,
    dues: "",
    options: creditOptions,
    lender: "",
    account: "",
    paymentType: "",
    redeferredLoanId: "",
};

// Payment variables

const payment = {
    source: {
      address: "senderAddress",
      maxAmount: {
        value: "senderValue",
        currency: "senderAsset",
        //"counterparty": sendIssuer
      },
    },
    destination: {
      address: "destinationAddress",
      amount: {
        value: "destinationValue",
        currency: "destinationAsset",
        //"counterparty": destIssuer
      },
    },
};

const loanDisbursement = {
    source: {
      address: "",
      maxAmount: {
        value: "",
        currency: "",
        counterparty: ""
      },
    },
    destination: {
      address: "",
      amount: {
        value: "",
        currency: "",
        counterparty: ""
      },
    },
};

const refinancingAmounts = {
    initialDefaultBalance: 0,
    refinancingBalance: 0,
}

//**Other global variables**

//Date formatting options
const dateOptions = {
    // weekday: "short",
    day: "2-digit",
    month: "numeric",
    year: "numeric",
}

//Wupo credentials
let WUPOADDRESS = ""; 
let WUPOSECRET = "";

let lender = "WUPO"; //Currently a hardcoded WUPO, but needs to migrate to WUPO or a friend selected by the client.

let fullRender = false;

let minDues = 1;
export default function Refinancing(props) {
    const {
        loginUser,
        visitorAccounts,
        enableAccountAsset,
        enablePaymentValue,
        visitorLoans, 
        // loanCostsSettings, loanConditionsSettings,
        refinancingCostsSettings, refinancingConditionsSettings,
        loadProductsAndSetting
      } = props;

    const classes = useStyles();

    let history = useHistory();

    const [loading, setLoading] = React.useState(false);

    const [disableDues, setDisableDues] = React.useState(true);
    const [disableTerm, setDisableTerm] = React.useState(true);
    const [disableButton, setDisableButton] = React.useState(true);

    const[isAdmin, setIsAdmin] = React.useState(false);

    const handleProfile = () =>{
        if(loginUser.roles && !(Object.keys(loginUser.roles).length === 0 && loginUser.roles.constructor === Object)){
            
            if(loginUser.roles.some((rol) => rol.authority === "ADMIN")){
                setIsAdmin(true);
            }
        }
    }

    const valuetext = (value) => {
        // console.log(`${value} pesos`)
        return `${value} pesos`;
    }

    //Render variables for visual calculation of the loan
    const[loanAmount, setLoanAmount] = React.useState(0);
    const[platfromDiscount, setPlatfromDiscount] = React.useState(0);
    const[interestState, setInterestState] = React.useState(0);
    const[margenState, setMargenState] = React.useState(0);
    const[warrantyDiscount, setWarrantyDiscount] = React.useState(0);
    const[vatTax, setVatTax] = React.useState(0);
    const[disbursedBalanceState, setDisbursedBalanceState]= React.useState(0);

    const [balanceInputValue, setBalanceInputValue] = React.useState("");//Erase

    const [term, setTerm] = React.useState(0);
    const [dues, setDues] = React.useState(creditInstallmentConditions.amountInstallments);

    //Following functions are used to calculate initial values and settings of the loans
    const loanSettingsAssignment = () =>{
        return new Promise((resolve) =>{

        
            creditCostsConditions = {
                ...creditCostsConditions, 
                costsWithVAT: refinancingCostsSettings.costsWithTaxes, // Platform
                costsWithVATminimum: refinancingCostsSettings.costsWithTaxesMinimum,

                costsWithoutVATminimum: refinancingCostsSettings.costsWithoutTaxesMinimum,
                costsWithoutVATFee: refinancingCostsSettings.costsWithoutTaxes,

                // paymentType: "prepaid", //Pending include it in a table on the back 
            };

            creditInterestConditions = {
                ...creditInterestConditions,
                interestRate: refinancingCostsSettings.interestRate,
                interestRateDefaultMultiplier: refinancingCostsSettings.interestDefaultFactor,
                collectionsFee: refinancingCostsSettings.collectionsFee,
            };

            creditInstallmentConditions.amountInstallments = refinancingConditionsSettings.amountInstallments;

            creditTermConditions = {
                ...creditTermConditions,
                minTerm: refinancingConditionsSettings.minTerm,
                maxTerm: refinancingConditionsSettings.maxTerm,
                interval: refinancingConditionsSettings.termInterval,
                termUnit: refinancingConditionsSettings.termUnit,
                installmentsTerm: refinancingConditionsSettings.installmentsTerm
            };

            creditValueConditions = {
                ...creditValueConditions,
                // asset: "WUP", //Pending solve asset
                minValue: refinancingConditionsSettings.minValue,
                maxValue: refinancingConditionsSettings.maxValue,
                interval: refinancingConditionsSettings.balanceInterval,
            };

            VAT = refinancingCostsSettings.taxesRate;

            resolve("ok");
        })    
    };

    const dailyInterestCalc = () =>{
        console.log(creditInterestConditions.interestRate)
        console.log(calculationVariables.term)
        
        uxEDR = (Math.pow(1+creditInterestConditions.interestRate, calculationVariables.term/360)) - 1;
        console.log(uxEDR)

        return new Promise((resolve)=>{
            resolve(uxEDR);
        });
    }

    const VATCalculation = (discount) =>{
        if(discount){
            calculationVariables.vatTax = (creditCostsConditions.costsWithVAT - discount) * VAT;
            setVatTax((creditCostsConditions.costsWithVAT - discount) * VAT);
            // console.log(calculationVariables.vatTax)
        }else{
            calculationVariables.vatTax = (creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) * VAT;
            setVatTax((creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) * VAT);
            // console.log(calculationVariables.vatTax)
        }

        return calculationVariables.vatTax;
    };

    const handleInterests = () =>{
        setInterestState(calculationVariables.loanAmount * uxEDR);
        calculationVariables.interestState = calculationVariables.loanAmount * uxEDR;
        
        console.log(uxEDR);
        console.log(calculationVariables.loanAmount);

        return new Promise((resolve) => {
            resolve(calculationVariables.loanAmount * uxEDR);
        });
    };

    const disbursedBalanceRendered = (guarantorDiscount, discount, rate, tempVat) =>{
        console.log(`loan amount in disbursed: ${loanAmount}`)
        calculationVariables.disbursedBalance = calculationVariables.loanAmount - 
        ((creditCostsConditions.costsWithoutVATFee * calculationVariables.loanAmount) - guarantorDiscount)
        - (creditCostsConditions.costsWithVAT - discount) -tempVat - rate;
        setDisbursedBalanceState(calculationVariables.disbursedBalance );

        return new Promise((resolve) => {
            resolve(calculationVariables.disbursedBalance );
        });
    };

    const defineMargins = async (guarantorDiscount, discount, rate, tempVat) =>{
        console.log(`loan amount in margins ${loanAmount}`)
        const onMountBalance = await disbursedBalanceRendered(guarantorDiscount, discount, rate, tempVat);
        setMargenState((calculationVariables.loanAmount -  onMountBalance)/ calculationVariables.loanAmount);
    };

    const updateSettings = () =>{
        marksBalance = [
            {
              value: refinancingConditionsSettings.minValue,
              label: `$${new Intl.NumberFormat('es-CO', {maximumFractionDigits: 0,}).format(refinancingConditionsSettings.minValue)}`,
              
            },
            {
              value: refinancingConditionsSettings.maxValue,
              label: `$${new Intl.NumberFormat('es-CO', {maximumFractionDigits: 0,}).format(refinancingConditionsSettings.maxValue)}`,
            },
          ];
        
        marksTerm = [
            {
                value: refinancingConditionsSettings.minTerm,
                label: `${new Intl.NumberFormat('es-CO', {maximumFractionDigits: 0,}).format(refinancingConditionsSettings.minTerm)}`,
            },
            {
                value: refinancingConditionsSettings.maxTerm,
                label: `${new Intl.NumberFormat('es-CO', {maximumFractionDigits: 0,}).format(refinancingConditionsSettings.maxTerm)}`,
            },
        ];
        
        marksInstallments = [
            {
                value: 1,
                label: '1',
            },
            {
                value: refinancingConditionsSettings.amountInstallments,
                label: `${refinancingConditionsSettings.amountInstallments}`,
            },
        ];
    };

    const initialValuesCalculation = async () =>{

        //  Assign the setting fetched from the back
        await loanSettingsAssignment();
        setLoanAmount(creditValueConditions.minValue);
        calculationVariables.loanAmount = props.location.state.refinancingValue;
        // creditInformation.initialBalance = creditValueConditions.minValue;
        creditInformation.initialBalance = props.location.state.refinancingValue
        console.log(`Initial balance: ${ creditInformation.initialBalance}`); //DEBUG

        setTerm(creditValueConditions.minTerm);
        calculationVariables.term = creditTermConditions.minTerm;
        creditInformation.term = creditTermConditions.installmentsTerm;

        //Set with and without taxes initial costs discounts
        const guarantorDiscount = await calculateNoVATTermDiscount(creditCostsConditions.costsWithoutVATFee * calculationVariables.loanAmount);
        setWarrantyDiscount(guarantorDiscount);
        calculationVariables.warrantyDiscount = guarantorDiscount;

        const discount = await calculateWithVATTermDiscount();
        setPlatfromDiscount(discount);
        calculationVariables.platfromDiscount = discount;

        //Initial taxes calculation
        const tempVat = VATCalculation(discount);
        
        //Set dues
        setDues(minDues);
        creditInformation.dues=creditInstallmentConditions.amountInstallments;

         //Set termunit
        creditInformation.termUnit = creditTermConditions.termUnit;

        //Set interests calculations
        await dailyInterestCalc();
        const rate = await handleInterests();

        defineMargins(guarantorDiscount, discount, rate, tempVat);

        //Set marks accordingly to received info from back
        updateSettings();
    };

    const setRefinanceUpdate = () => {
        creditInformation.redeferredLoanId = visitorLoans.id;
    };

    useEffect(() => {
        minDues = 1;
        //Calculate initial values for rendering and calculation
        initialValuesCalculation();

        //loan update to define it as refinanced
        setRefinanceUpdate();

        //Profile verification
        handleProfile();

        //Credentials loading
        loadXrpAddressCreds(loginUser.jwtToken).then((credentials) => {
            
            WUPOADDRESS = credentials.xrpAddress;
            WUPOSECRET = credentials.xrpAddressSecret;
            // console.log(`address: ${WUPOADDRESS} secret: ${WUPOSECRET}`); //DEBUG
        });
        
        creditInformation.account = loginUser.accountId;

        loanLimit = loanMaxLimit;
       
    }, [])

   
    //Onboarding process begining

    const handleInputCreditInformation = () => {
        setLoading(true);
        
        creditInformation.paymentType = creditCostsConditions.paymentType;
        determineIfCreditProceed();
    }

    //Initial part of this function does not make a lot of sense with the new version (account created automatically or for whitelabels)
    const determineIfCreditProceed = () => {

    //    const loanAssetAccount = visitorAccounts.find((account) => {
    //         return account.asset === clientCreditAsset;
    //     });

    //     if(!loanAssetAccount){
    //         swal({
    //             title: "REQUISITO PREVIO",
    //             text: "Primero debes tener una cuenta",
    //             icon: "error",
    //             button: "Entiendo",
    //         }).then(() => {
    //             history.push({pathname: "/credit/handle_credit"});
    //         });
    //     }

    //     if(visitorLoans !== "Not found"){
    //         //TODO revolving actions
    //     }
        

        fetchRefinancingConditions(creditInformation, loginUser.jwtToken).then((loanConditions) =>{
           
            conditionsArray = [...loanConditions.installments];
            
            // console.log(conditionsArray); //DEBUG
            // console.log(loanConditions); //DEBUG
            creditCost = loanConditions.loanFee;
            creditInterestRate = loanConditions.loanInterestRate;
            handeConditions(); 
        }); 
        
    }

        /**
     * Loan conditions dialog and agreements dialog
     */

    const [conditions, setConditions] = React.useState(false);

    const handeConditions = ()=>{
        setConditions(true);
    };
    
    const handeConditionsClose = ()=>{
        setLoading(false);
        setConditions(false);
    };

    const handeAcceptConditions = ()=>{
        setConditions(false);

        handleAgreementsDialog();
    };

    

    const [agreements, setAgreements] = React.useState(false);

    const handleAgreementsDialog = () =>{
        setAgreements(true);
    }

    const closeAgreementsDialog = () => {
        setAgreements(false);
        setLoading(false);
    }

    const handleAgreements = () => {
    handeConditionsClose();
    setAgreements(false);
        console.log("Single refinancing loan") //DEBUG
        postCreditToBack();
    }

    //Post the credit to the back
    const postCreditToBack = () => {
        creditInformation.lender = lender;
        setLoading(true)
        postRefinancing(creditInformation, loginUser.jwtToken).then((loanResponse) => {
            
            setLoading(false);
           
            if(loanResponse === "Error"){
                
                swal({
                    title: "NO PUDIMOS CREAR TU PRODUCTO",
                    text: errTransaction,
                    icon: "error",
                    button: "Entiendo",
                }).then(() => {
                    history.push({pathname: "/credit/handle_credit"});
                }); 
            }

            else if(loanResponse === "invalid email"){
                swal({
                    title: "NO PUDIMOS CREAR TU PRODUCTO",
                    text: `No pudimos enviarte los documentos al correo ingresado. 
                    
                    Por favor revisa tu información y comunícate con nostros. `,
                    icon: "error",
                    button: "Entiendo",
                }).then(() => {
                    history.push({pathname: "/credit/handle_credit"});
                });
            }

            else{
                swal({
                    title: "¡Listo!",
                    text: `Así de fácil has refinanciado tu crédito.
                    
                    Sigues siendo un cliente muy importante para nosotros.`,
                    icon: "success",
                    button: "Súper",
                }).then(() => {
                    setLoading(true);
                    loadProductsAndSetting("/credit/handle_credit", loginUser.accountId, loginUser.jwtToken).then((loadedInfo) => {
                        history.push({pathname: "/credit/handle_credit"});
                        setLoading(false);

                    });
                });
            }
        });
        
    }

    //Calculations to render the loan and set the amounts to post the loan to the back
    
    //Credit Balance set by the client
    const handleBalanceInput = (inputValues) => {
      
        setBalanceInputValue(refinancingAmounts.refinancingBalance);
        // creditInformation.initialBalance = refinancingAmounts.refinancingBalance

        setLoanAmount(refinancingAmounts.refinancingBalance)
        // calculationVariables.loanAmount = refinancingAmounts.refinancingBalance;

        calculateBalanceModification();

    }

    const calculateBalanceModification = async () =>{

        // creditCostsConditions.costsWithoutVAT = creditCostsConditions.costsWithoutVATFee * creditInformation.initialBalance;
        creditCostsConditions.costsWithoutVAT = creditCostsConditions.costsWithoutVATFee * calculationVariables.loanAmount;

        calculationVariables.warrantyDiscount = await calculateNoVATTermDiscount();
        setWarrantyDiscount(calculationVariables.warrantyDiscount);

        VATCalculation();

        totalPayment = calculationVariables.loanAmount+ creditCostsConditions.costsWithoutVAT
                        - calculationVariables.warrantyDiscount + creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount
                        + calculationVariables.vatTax + calculationVariables.interestState;

        disbursedBalanceBeforeInterests = calculationVariables.loanAmount - (creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount) 
        - (creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) -calculationVariables.vatTax;

        await handleInterests();
        
        calculationVariables.disbursedBalance = calculationVariables.loanAmount - (creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount) 
                    - (creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) -calculationVariables.vatTax - calculationVariables.interestState;
        
        setDisbursedBalanceState(calculationVariables.disbursedBalance);

        setMargenState((calculationVariables.loanAmount -  calculationVariables.disbursedBalance)/ calculationVariables.loanAmount);
        // console.log(margen)
        
    };

    //Term set by the client
    const handleTermSelect = async(e) => {
        setTerm(e.target.value);
        calculationVariables.term = e.target.value;
        setDisableDues(false);
        
        creditInformation.term = e.target.value;

        await dailyInterestCalc();

        setDisableButton(false);

        calculateTermModification();
    }

    //Dues set by the client
    const handleDuesSelect = async (e) => {
        setDues(e.target.value);
        // console.log(e.target.value)
        setDisableButton(false);
        creditInformation.dues = e.target.value;
        
        //For refinancing the term is the amount of dues multiplied by the fix term of the installments period
        creditInformation.term = creditTermConditions.installmentsTerm * creditInformation.dues;
        calculationVariables.term = creditInformation.term;

        await dailyInterestCalc();
        handleInterests();
    }

    //Term calculations
    const calculateTermModification = async () =>{
        
        creditCostsConditions.costsWithoutVAT 
            = creditCostsConditions.costsWithoutVATFee * calculationVariables.loanAmount;
        // console.log(`costsWithoutVAT: ${creditCostsConditions.costsWithoutVAT}`)
        

        calculationVariables.platfromDiscount = await calculateWithVATTermDiscount();
        setPlatfromDiscount(calculationVariables.platfromDiscount);

        calculationVariables.warrantyDiscount = await calculateNoVATTermDiscount();
        setWarrantyDiscount(calculationVariables.warrantyDiscount);

        VATCalculation();

        totalPayment = calculationVariables.loanAmount + creditCostsConditions.costsWithoutVAT
                        - calculationVariables.warrantyDiscount + creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount
                        + calculationVariables.vatTax + interestState;

        disbursedBalanceBeforeInterests = calculationVariables.loanAmount - (creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount) 
        - (creditCostsConditions.costsWithVAT - platfromDiscount) -calculationVariables.vatTax;

        await handleInterests();
        
        calculationVariables.disbursedBalance = calculationVariables.loanAmount - (creditCostsConditions.costsWithoutVAT - warrantyDiscount) 
                    - (creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) -calculationVariables.vatTax - calculationVariables.interestState;

        setDisbursedBalanceState(calculationVariables.disbursedBalance);

        setMargenState((calculationVariables.loanAmount -  calculationVariables.disbursedBalance)/ calculationVariables.loanAmount);
        // console.log(margen)
    };

    const  calculateWithVATTermDiscount = () => {
        
        const termSteps = 1/(((creditTermConditions.maxTerm - creditTermConditions.minTerm)/creditTermConditions.interval));
        const currentTermSteps = (creditTermConditions.maxTerm - creditTermConditions.minTerm)/creditTermConditions.interval 
        - (creditTermConditions.maxTerm - calculationVariables.term)/creditTermConditions.interval;
        const minimumCostsWithVATFee = creditCostsConditions.costsWithVATminimum * creditCostsConditions.costsWithVAT;
        const remainingCostsWithVAT = creditCostsConditions.costsWithVAT - minimumCostsWithVATFee;
        const discountValue = remainingCostsWithVAT - (remainingCostsWithVAT * termSteps * currentTermSteps);
        // console.log(`discount value with VAT ${discountValue}`); //DEBUG
        return new Promise((resolve) => {
            resolve(discountValue);
        })
        
        
    };

    const calculateNoVATTermDiscount = (noTaxesCost) => {
       
        const termSteps = 1/(((creditTermConditions.maxTerm - creditTermConditions.minTerm)/creditTermConditions.interval));
        const currentTermSteps = (creditTermConditions.maxTerm - creditTermConditions.minTerm)/creditTermConditions.interval 
        - (creditTermConditions.maxTerm - calculationVariables.term)/creditTermConditions.interval;
        let minimumCostsWithoutVATFee;
        let remainingCostsWithoutVAT;

        if(noTaxesCost){
            minimumCostsWithoutVATFee = creditCostsConditions.costsWithoutVATminimum * noTaxesCost; 
            remainingCostsWithoutVAT = noTaxesCost - minimumCostsWithoutVATFee;  
        }else{
            minimumCostsWithoutVATFee = creditCostsConditions.costsWithoutVATminimum * creditCostsConditions.costsWithoutVAT;
            remainingCostsWithoutVAT = creditCostsConditions.costsWithoutVAT - minimumCostsWithoutVATFee;
        }
        const discountValue = remainingCostsWithoutVAT - (remainingCostsWithoutVAT * termSteps * currentTermSteps);

        // console.log(`discount value without VAT ${discountValue}`); //DEBUG
        return new Promise((resolve) => {
            resolve(discountValue);
        });
    };

    const backToHandleCredit = () => {
        
        //To account to refresh visitorsLoan (now commented due to update of loan and accounts)
        // if(!visitorLoans.isRedeferredEnabled){
        //     history.push({pathname: "/account"});
        //     return;
        // }

        history.push({pathname: "/credit/handle_credit"});
    };

    const generateRandomKey = () => {
        const randomString = Math.random().toString(36).slice(2);
        const timestamp = Date.now().toString(36);
        return randomString + '_' + timestamp;
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center",  alignItems: "center", width: "100%", height: "100%",}}>
            <Box sx={{display: "flex", justifyContent: "center", width: {xs: "95%", sm: "100%"}, height: {xs: "80%", sm: "100%"}}}>
                <Box sx={{width: "100%", maxWidth: "615px", display: "flex", justifyContent: "space-evenly",}}>
                {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                    <Grid container sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography variant="h5" sx={{fontWeight: "bold", marginTop: "1rem"}}>REFINANCIAMIENTO</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Grid container direction="column">
                                <Grid item>
                                    <Typography variant="body2">Monto Refinanciamiento</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5" sx={{color:"black", fontWeight:"bold",}}>{`$ ${new Intl.NumberFormat('es-CO', {maximumFractionDigits: 0}).format(creditInformation.initialBalance)}`}</Typography>
                                </Grid>   
                            </Grid>

                            <Grid item sx={{marginTop: "2rem"}}>
                                <Typography variant="body2">Debes hacer los pagos cada</Typography>
                            </Grid>
                            <Grid item>
                                {creditInformation.termUnit === "day" ?
                                    <Typography variant="h5" sx={{color:"black", fontWeight:"bold",}} setVatTax={{fontWeigth: "bold"}}>{`${creditTermConditions.installmentsTerm} días`}</Typography>
                                    : creditInformation.termUnit === "month" ? 
                                    <Typography  variant="h5" sx={{color:"black", fontWeight:"bold",}}>{`${creditTermConditions.installmentsTerm} meses`}</Typography>
                                    :<Typography  variant="h5" sx={{color:"black", fontWeight:"bold",}}>{`${creditTermConditions.installmentsTerm} semanas`}</Typography>
                                }
                            </Grid>
                        </Grid>
                        {creditInstallmentConditions.amountInstallments > 1 && 
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center" }}>
                            <Box sx={{width: {xs: "70%", sm: 300}}}>
                                <Typography gutterBottom>Cuotas (cantidad de pagos)</Typography>
                                <Slider
                                    value={dues}
                                    getAriaValueText={valuetext}
                                    step={1}
                                    marks={marksInstallments}
                                    valueLabelDisplay="on"
                                    min={1}
                                    max={creditInstallmentConditions.amountInstallments}
                                    onChange={handleDuesSelect}
                                />
                            </Box>
                        </Grid>    
                        }
                        {/* <Grid itme xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                            <Grid sx={{display: "flex", justifyContent: "center", width: "50%"}}>
                                <Grid item xs={8} sm={8} md={8} sx={{textAlign: "start"}}>
                                    <Typography >Intereses:</Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} sx={{textAlign: "end"}}>
                                    <Typography className={classes.dialogStyle}>
                                        {`$ ${new Intl.NumberFormat('es-CO', {
                                            maximumFractionDigits: 0,
                                        }).format(interestState)}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid> */}
                        {fullRender &&
                        <>
                            <Grid item xs={6} sm={6} md={6}>
                                <Typography  >
                                Monto:
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} >
                                <Typography className={classes.dialogStyle} style ={{color:"red", fontWeight:"bold"}}>
                                    {`$ ${new Intl.NumberFormat('es-CO').format(loanAmount)}`}
                                </Typography>
                            </Grid>
                        </>
                        }
                        {isAdmin && fullRender &&
                            <>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Typography  style={{fontWeight:"bold"}}>
                                        Margen:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} >
                                    <Typography className={classes.dialogStyle} style={{color:"black", fontWeight:"bold"}}>
                                        {`$ ${new Intl.NumberFormat('es-CO', {
                                            maximumFractionDigits: 0,
                                        }).format(margenState * 100)}%`}
                                    </Typography>
                                </Grid>
                            </>
                        }            
                        
                            { creditCostsConditions.costsWithoutVATFee > 0 &&
                            <>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Typography  >
                                    Costo Fiador:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} >
                                    <Typography className={classes.dialogStyle}>
                                        {/* {`$ ${new Intl.NumberFormat('es-CO').format((creditCostsConditions.costsWithoutVATFee * creditInformation.initialBalance))}`} */}
                                        {`$ ${new Intl.NumberFormat('es-CO').format((creditCostsConditions.costsWithoutVATFee * loanAmount))}`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Typography  >
                                    Descuento Fiador:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} >
                                    <Typography className={classes.dialogStyle} style={{color: "blue"}}>
                                        {`$ ${new Intl.NumberFormat('es-CO',
                                        {
                                            maximumFractionDigits: 0,
                                        }).format(warrantyDiscount * -1)}`}
                                    </Typography>
                                </Grid>
                            </>
                            }

                            { creditCostsConditions.costsWithVAT > 0 &&
                            <>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Typography  >
                                    Costo Plataforma:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} >
                                    <Typography className={classes.dialogStyle}>
                                        {`$ ${new Intl.NumberFormat('es-CO').format(creditCostsConditions.costsWithVAT)}`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Typography  >
                                    Descuento Plataforma:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} >
                                    <Typography className={classes.dialogStyle} style={{color: "blue"}}>
                                        {`$ ${new Intl.NumberFormat('es-CO',
                                        {
                                            maximumFractionDigits: 0,
                                        }).format(platfromDiscount * -1)}`}
                                    </Typography>
                                </Grid>
                            
                                <Grid item xs={6} sm={6} md={6}>
                                    <Typography  >
                                    IVA:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} >
                                    <Typography className={classes.dialogStyle}>
                                        {`$ ${new Intl.NumberFormat('es-CO', {
                                            maximumFractionDigits: 0,
                                        }).format(vatTax)}`}
                                    </Typography>
                                </Grid>
                            </>
                            }
                            {fullRender &&
                            <>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Typography  style={{fontWeight:"bold"}}>
                                        Monto a desembolsar
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} >
                                    <Typography className={classes.dialogStyle} style={{color:"black", fontWeight:"bold"}}>
                                        {`$ ${new Intl.NumberFormat('es-CO', {
                                            maximumFractionDigits: 0,
                                        }).format(disbursedBalanceState)}`}
                                    </Typography>
                                </Grid>
                            </>
                            }
                            <Box sx={{width: {xs: "80%", sm: "50%"}, display: "flex", justifyContent: "center", }}>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Button onClick={backToHandleCredit} size="large" variant="contained" sx={{backgroundColor: Colors.primary,}}>
                                        ATRÁS
                                    </Button>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Button onClick={handleInputCreditInformation} size="large" variant="contained" sx={{backgroundColor: Colors.primary,}}>
                                        ACEPTO
                                    </Button>
                                </Grid>
                            </Box>
                        </Grid>
                }
                </Box>
                <Dialog open={conditions} onClose={handeConditionsClose}
                    sx={{

                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "100%", sm: "80%", md: "35%"},
                            },
                        },
                    }}
                >
                    <Box sx={{width: "100%", display: "flex", justifyContent: "center", marginBottom: "1.5rem"}}>
                        <Grid container spacing={1} sx={{width: {xs: "90%", sm: "80%", md: "70%"}}}>
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", marginTop:"1rem", marginBottom: "0.5rem"}}>
                                <Typography variant="h6" sx={{fontWeight: "bold"}}>Detalle Crédito</Typography>
                            </Grid>
                        
                            <Grid item xs={1} sm={1} md={1} sx={{display: "flex", alignItems: "center"}}>
                                <CalendarMonthIcon sx={{color: "black", fontSize: "18px"}}/>
                            </Grid>
                            <Grid item xs={11} sm={11} md={11} sx={{display: "flex"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold"}}>
                                    Cuotas
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}  sx={{color: "gray", fontSize: "0.8rem"}}>
                                Fecha
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}  sx={{color: "gray", fontSize: "0.8rem"}}>
                                Pago
                            </Grid>
                            {conditionsArray.map((condition)=>{
                                return(
                                <>
                                    <Grid item xs={6} sm={6} md={6} key={generateRandomKey()}>
                                        <Typography key={generateRandomKey()} >
                                            {new Intl.DateTimeFormat("es-CO", dateOptions).format(new Date(condition.installmentDate))}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} key={generateRandomKey()}>
                                        <Typography key={generateRandomKey()}>
                                            {`$ ${new Intl.NumberFormat('es-CO', {maximumFractionDigits: 0,}).format(condition.installmentValue)}`}
                                        </Typography>
                                    </Grid>
                                </>
                                )
                            })}
                            <Grid item xs={12} sm={12} md={12}>
                                <Divider sx={{marginBottom: "1rem"}} />
                            </Grid>
                            <Grid item xs={1} sm={1} md={1} sx={{display: "flex", alignItems: "center"}}>
                                <ListAltIcon sx={{color: "black", fontSize: "18px"}}/>
                            </Grid>
                            <Grid item xs={11} sm={11} md={11} sx={{display: "flex"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold"}}>
                                    Detalle
                                </Typography>
                            </Grid>
                            
                            <Grid item xs={6} sm={6} md={6}>
                                <Typography>Intereses:</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                                <Typography className={classes.dialogStyle}>
                                    {`$ ${new Intl.NumberFormat('es-CO').format(creditCost)}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                                <Typography>
                                    {/* Fecha: {conditionsArray[conditionsArray.length-1].installmentDate} */}
                                    Tasa de interés:
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                                <Typography className={classes.dialogStyle}>
                                    {`${new Intl.NumberFormat('es-CO').format(creditInterestRate*100)}% E.A.`}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                                <Button onClick={handeConditionsClose} color="primary">
                                    ATRÁS
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                                <Button onClick={handleAgreements}>
                                    ACEPTO
                                </Button>
                            </Grid>
                                
                        </Grid>
                    </Box>
                </Dialog>

                <Dialog open={agreements} onClose={closeAgreementsDialog} aria-labelledby="form-dialog-tokens" >
                    <DialogTitle id="form-dialog-title3">ACUERDOS ENTRE TÚ Y WUPO</DialogTitle>
                    <DialogContent className={classes.dialogContainer}>
                        <a 
                            href={`${wupoServerUri.devnet}/contrato-credito.pdf`}
                            target='_blank' rel='noopener noreferrer'
                            style={{cursor: "pointer", color: Colors.fourth}}
                        >
                            Contrato de Préstamo
                        </a>
                        <DialogContentText className={classes.dialogStyle}>
                            {" "}
                        </DialogContentText>
                        <a 
                            href={`${wupoServerUri.devnet}/Pagare.pdf`}
                            target='_blank' rel='noopener noreferrer'
                            style={{cursor: "pointer", color: Colors.fourth, marginTop: "2rem"}}
                        >
                            Pagaré y Carta de Instrucciones
                        </a>
                        <DialogContentText className={classes.dialogStyle}>
                            {" "}
                        </DialogContentText>
                        {/* <a 
                            // href={`${wupoServerUri.devnet}/Pagare.pdf`}
                            href={`${wupoServerUri.devnet}/acuerdo-fg.pdf`}
                            target='_blank' rel='noopener noreferrer'
                            style={{cursor: "pointer", color: Colors.fourth, marginTop: "2rem"}}
                        >
                            Acuerdo Fondo de Garantía
                        </a>
                        <DialogContentText className={classes.dialogStyle}>
                            {" "}
                        </DialogContentText> */}
                        <DialogContentText style={{marginTop: "2rem"}}>
                            Si leíste y aceptas los acuerdos con PlaticApp por favor oprime el botón acepto.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeAgreementsDialog} color="primary">
                            ATRÁS
                        </Button>
                        <Button onClick={handleAgreements}>
                            ACEPTO
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>

    )
}
