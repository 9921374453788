import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PuffLoader } from "react-spinners";
import swal from "sweetalert";
import { css } from "@emotion/react";
import Colors from "../../components/ui/Colors";
import { xrpPayment, createTrustline, createXrplAccount, prepareStatement } from "../../utilityFunctions/Paymentutil";
import { loadXrpAddressCreds } from "../../utilityFunctions/FetchUtil";
import { postCredit, fetchCreditConditions, postRevolvingCredit, verifyBankAccount, 
    clientDocsStatus } from "../../utilityFunctions/CreditUtil";
import { updateExplanations } from "../../utilityFunctions/AdminUtil";
import { trustlineLimitValue, creditAsset, creditTerm, loanMaxLimit, wupoServerUri, b2bClient, 
    bankCodes, } from "../../Api";
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import FormControlLabel from '@mui/material/FormControlLabel';
import Slider from '@mui/material/Slider';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ListAltIcon from '@mui/icons-material/ListAlt';
import {creditExplanation, loggedSimulatorExplanation} from "../../Texts/Explanations";
import {disbursedText, rateText, warrantyText, platformText,
    platformDiscountText, } from "../../Texts/SimulatorExplanations"
import { BsInfoCircle } from "react-icons/bs";
import Tooltip from '@mui/material/Tooltip';
import {bankAccountExp,} from "../../Texts/OperationResult.js";
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';

const inlineSvg =  `<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'><rect fill='#ffffff' width='1600' height='900'/><defs><linearGradient id='a' x1='0' x2='0' y1='1' y2='0'><stop  offset='0' stop-color='#0FF'/><stop  offset='1' stop-color='#CF6'/></linearGradient><linearGradient id='b' x1='0' x2='0' y1='0' y2='1'><stop  offset='0' stop-color='#BD0BFF'/><stop  offset='1' stop-color='#FC0'/></linearGradient></defs><g fill='#FFF' fill-opacity='0' stroke-miterlimit='10'><g  stroke='url(#a)' stroke-width='2'><path  transform='translate(0 0)' d='M1409 581 1450.35 511 1490 581z'/><circle  stroke-width='4' transform='rotate(0 800 450)' cx='500' cy='100' r='40'/><path  transform='translate(0 0)' d='M400.86 735.5h-83.73c0-23.12 18.74-41.87 41.87-41.87S400.86 712.38 400.86 735.5z'/></g><g stroke='url(#b)' stroke-width='4'><path  transform='translate(0 0)' d='M149.8 345.2 118.4 389.8 149.8 434.4 181.2 389.8z'/><rect  stroke-width='8' transform='rotate(0 1089 759)' x='1039' y='709' width='100' height='100'/><path  transform='rotate(0 1400 132)' d='M1426.8 132.4 1405.7 168.8 1363.7 168.8 1342.7 132.4 1363.7 96 1405.7 96z'/></g></g></svg>`;

let marksBalance = [
    {
      value: 100000,
      label: '$100.000',
    },
    {
      value: 500000,
      label: '$500.000',
    },
];

let marksTerm = [
    {
        value: 7,
        label: '7',
    },
    {
        value: 56,
        label: '56',
    },
];

let marksInstallments = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 2,
        label: '2',
    },
];


const useStyles = makeStyles((theme) => ({
    input: {
        width: "50%",
    },

    formControl: {
        width: "50%",
    },

    dialogStyle:{
        whiteSpace: "pre-line",
        
    },

    dialogContainer:{
        width: "25em"
    },

    typography:{
        color: Colors.primary,
        fontWeight: "bold",
    },
    actionArea: {
        borderRadius: 16,
        transition: '0.2s',
        '&:hover': {
          transform: 'scale(1.05)',
          backgroundColor: Colors.primary,
        },
    },
    cardImage:{
        width: "20%",
        justifySelf: "center"
    },
    formControl: {
        minWidth: 120,
        marginRight:0
    },
    divider:{
        background: Colors.primary
    },
    svgBackground: {
        background: `url("data:image/svg+xml;utf8,${encodeURIComponent(inlineSvg)}") center center/cover no-repeat`,
    },

}));

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

let WUPOADDRESS = ""; 
let WUPOSECRET = ""; 

let clientXrplAddress = "";
let clientClassicXrplAddress = "";
let clientXrplSecret = "";

const QUALITYIN = 1;
const QUALITYOUT = 1;

const payment = {
    source: {
      address: "senderAddress",
      maxAmount: {
        value: "senderValue",
        currency: "senderAsset",
        //"counterparty": sendIssuer
      },
    },
    destination: {
      address: "destinationAddress",
      amount: {
        value: "destinationValue",
        currency: "destinationAsset",
        //"counterparty": destIssuer
      },
    },
};

const loanDisbursement = {
    source: {
      address: "",
      maxAmount: {
        value: "",
        currency: "",
        counterparty: ""
      },
    },
    destination: {
      address: "",
      amount: {
        value: "",
        currency: "",
        counterparty: ""
      },
    },
};

const clientCreditAsset = creditAsset;

const trustline = {
    currency: clientCreditAsset,
    counterparty: "",
    limit: "",
    qualityIn: QUALITYIN,
    qualityOut: QUALITYOUT,
};

let lender = "WUPO"; //Currently a hardcoded WUPO, but needs to migrate to WUPO or a friend selected by the client.
const creditOptions = ["Cashback", "change"]

const creditInformation = {
    creditXrplAccount: "",
    creditClassicXrplAccount: "",
    creditXrplSecret: "",
    asset: clientCreditAsset,
    initialBalance: "",
    term: "",
    termUnit: creditTerm.month,
    dues: "",
    options: creditOptions,
    lender: "",
    account: "",
    paymentType: "",
    transactionType: "cash",
    bankCode: "",
    bankAccount: "",
    bankAccountType: "",
};

let conditionsArray=[]; //Array fille with the credit conditions: dialog that shows conditions to clients
let creditCost=0; //payment included in the dialog that shows conditions to clients
let creditInterestRate=0; //interest rate included in the dialog that shows conditions to clients

const dateOptions = {
    // weekday: "short",
    day: "2-digit",
    month: "numeric",
    year: "numeric",
}

let loanLimit = loanMaxLimit;

//Credit front rendering
let totalPayment = 0;
let uxEDR = 0;
let disbursedBalanceBeforeInterests = 0;
let VAT = 0; // Local tax: IVA = 19%

//Credit Conditions:
let creditValueConditions = {
    asset: "COP",
    minValue: 0,
    maxValue: 0,
    interval: 0,
};

let creditTermConditions = {
    minTerm: 0,
    maxTerm: 0,
    interval: 0,
    termUnit: creditTerm.month,
};

let creditInstallmentConditions = {
    amountInstallments: 1, //Can be equal to the term selected by the client / interval (traditional behavior).
    paymentType: "",
};

let creditInterestConditions = {
    interestRate: 0,
    interestRateDefaultMultiplier: 0,
    collectionsFee: 0
};

let creditCostsConditions = {
    costsWithVAT: 0, // Platform
    costsWithVATminimum: 0,
    platformTimeDiscount: 0,
    platformBalanceDiscount: 0,

    costsWithoutVAT: 0, //Aval
    costsWithoutVATminimum: 0,
    costsWithoutVATFee: 0,
    warrantyTimeDiscount: 0,
    warrantyBalanceDiscount: 0,
};

const calculationVariables = {
    loanAmount: 0,
    margenState: 0,
    interestState: 0,
    warrantyDiscount: 0,
    platfromDiscount: 0,
    disbursedBalance: 0,
    vatTax: 0,
    term: 0,
    totalPayment: 0,
};

const margin = false;

const duesEqualTerm = true;

export default function Credit(props) {
    const {
        loginUser,
        visitorAccounts,
        enableAccountAsset,
        enablePaymentValue,
        visitorLoans, loanCostsSettings, loanConditionsSettings, loadProductsAndSetting, displayExplanations
      } = props;
    
    const classes = useStyles();

    let history = useHistory();

    const [loading, setLoading] = React.useState(false);

    const[loanAmount, setLoanAmount] = React.useState(0);
    const[platfromDiscount, setPlatfromDiscount] = React.useState(0);
    const[interestState, setInterestState] = React.useState(0);
    const[margenState, setMargenState] = React.useState(0);
    const[warrantyDiscount, setWarrantyDiscount] = React.useState(0);
    const[vatTax, setVatTax] = React.useState(0);
    const[disbursedBalanceState, setDisbursedBalanceState]= React.useState(0);

    const [term, setTerm] = React.useState(0);
    const [dues, setDues] = React.useState(creditInstallmentConditions.amountInstallments);
    const [disableDues, setDisableDues] = React.useState(true);
    const [disableTerm, setDisableTerm] = React.useState(true);
    const [disableButton, setDisableButton] = React.useState(true);

    const loanSettingsAssignment = () =>{
        return new Promise((resolve) =>{

        
            creditCostsConditions = {
                ...creditCostsConditions, 
                costsWithVAT: loanCostsSettings.costsWithTaxes, // Platform
                costsWithVATminimum: loanCostsSettings.costsWithTaxesMinimum,
                platformTimeDiscount: loanCostsSettings.platformTimeDiscount,
                platformBalanceDiscount: loanCostsSettings.platformBalanceDiscount,

                costsWithoutVATminimum: loanCostsSettings.costsWithoutTaxesMinimum,
                costsWithoutVATFee: loanCostsSettings.costsWithoutTaxes,
                warrantyTimeDiscount: loanCostsSettings.warrantyTimeDiscount,
                warrantyBalanceDiscount: loanCostsSettings.warrantyBalanceDiscount

                // paymentType: "prepaid", //Pending include it in a table on the back 
            };

            creditInterestConditions = {
                ...creditInterestConditions,
                interestRate: loanCostsSettings.interestRate,
                interestRateDefaultMultiplier: loanCostsSettings.interestDefaultFactor,
                collectionsFee: loanCostsSettings.collectionsFee,
            };

            creditInstallmentConditions.amountInstallments = loanConditionsSettings.amountInstallments;
            creditInstallmentConditions.paymentType = loanConditionsSettings.paymentType;
            console.log(creditInstallmentConditions.paymentType);

            creditTermConditions = {
                ...creditTermConditions,
                minTerm: loanConditionsSettings.minTerm,
                maxTerm: loanConditionsSettings.maxTerm,
                interval: loanConditionsSettings.termInterval,
                termUnit: loanConditionsSettings.termUnit,
            };

            creditValueConditions = {
                ...creditValueConditions,
                // asset: "WUP", //Pending solve asset
                minValue: loanConditionsSettings.minValue,
                maxValue: loanConditionsSettings.maxValue,
                interval: loanConditionsSettings.balanceInterval,
            };

            VAT = loanCostsSettings.taxesRate;

            resolve("ok");
        })    
    };

    const dailyInterestCalc = () =>{
        
        uxEDR = (Math.pow(1+creditInterestConditions.interestRate, calculationVariables.term/12)) - 1;

        return new Promise((resolve)=>{
            resolve(uxEDR);
        });
    }

    const VATCalculation = (discount) =>{
        
        calculationVariables.vatTax = ((creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) + (creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount) ) * VAT;
        setVatTax(calculationVariables.vatTax);

        return calculationVariables.vatTax;
    };

    const handleInterests = () =>{

        if(creditInstallmentConditions.paymentType === "postpaid"){
        //     calculationVariables.interestState = uxEDR * (calculationVariables.loanAmount + (creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount) 
        // + (creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) + calculationVariables.vatTax);

        calculationVariables.interestState = uxEDR * (calculationVariables.loanAmount);
        
        setInterestState(calculationVariables.interestState);
        }
        else{
            setInterestState(calculationVariables.loanAmount * uxEDR);
            calculationVariables.interestState = calculationVariables.loanAmount * uxEDR;
        }
     
        return new Promise((resolve) => {
            resolve(calculationVariables.loanAmount * uxEDR);
        });
    };

    const disbursedBalanceRendered = (guarantorDiscount, discount, rate, tempVat) =>{
        console.log(`loan amount in disbursed: ${loanAmount}`)
        // calculationVariables.disbursedBalance = calculationVariables.loanAmount - 
        // ((creditCostsConditions.costsWithoutVATFee * calculationVariables.loanAmount) - guarantorDiscount)
        // - (creditCostsConditions.costsWithVAT - discount) -tempVat - rate;

        calculationVariables.disbursedBalance = calculationVariables.loanAmount - (creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount) 
                    - (creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) -calculationVariables.vatTax - calculationVariables.interestState;
        
        // calculationVariables.totalPayment = calculationVariables.loanAmount + 
        // ((creditCostsConditions.costsWithoutVATFee * calculationVariables.loanAmount) - guarantorDiscount)
        // + (creditCostsConditions.costsWithVAT - discount) + tempVat + rate;

        calculationVariables.totalPayment = calculationVariables.loanAmount + (creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount) 
        + (creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) + calculationVariables.vatTax + calculationVariables.interestState;

        if(creditInstallmentConditions.paymentType === "prepaid"){
            setDisbursedBalanceState(calculationVariables.disbursedBalance );
        }else if(creditInstallmentConditions.paymentType === "postpaid"){
            setDisbursedBalanceState(calculationVariables.totalPayment );
        }else{
            setDisbursedBalanceState(calculationVariables.disbursedBalance );
        }

        return new Promise((resolve) => {
            resolve(calculationVariables.disbursedBalance );
        });
    };

    const defineMargins = async (guarantorDiscount, discount, rate, tempVat) =>{
        console.log(`loan amount in margins ${loanAmount}`)
        const onMountBalance = await disbursedBalanceRendered(guarantorDiscount, discount, rate, tempVat);
        setMargenState((calculationVariables.loanAmount -  onMountBalance)/ calculationVariables.loanAmount);
    };

    const[isAdmin, setIsAdmin] = React.useState(false);
    
    const handleProfile = () =>{
        if(loginUser.roles && !(Object.keys(loginUser.roles).length === 0 && loginUser.roles.constructor === Object)){
            
            if(loginUser.roles.some((rol) => rol.authority === "ADMIN")){
                setIsAdmin(true);
            }
        }
    }

    const updateSettings = () =>{
        marksBalance = [
            {
              value: loanConditionsSettings.minValue,
              label: `$${new Intl.NumberFormat('es-CO', {maximumFractionDigits: 0,}).format(loanConditionsSettings.minValue)}`,
              
            },
            {
              value: loanConditionsSettings.maxValue,
              label: `$${new Intl.NumberFormat('es-CO', {maximumFractionDigits: 0,}).format(loanConditionsSettings.maxValue)}`,
            },
          ];
        
        marksTerm = [
            {
                value: loanConditionsSettings.minTerm,
                label: `${new Intl.NumberFormat('es-CO', {maximumFractionDigits: 0,}).format(loanConditionsSettings.minTerm)}`,
            },
            {
                value: loanConditionsSettings.maxTerm,
                label: `${new Intl.NumberFormat('es-CO', {maximumFractionDigits: 0,}).format(loanConditionsSettings.maxTerm)}`,
            },
        ];
        
        marksInstallments = [
            {
                value: 1,
                label: '1',
            },
            {
                value: loanConditionsSettings.amountInstallments,
                label: `${loanConditionsSettings.amountInstallments}`,
            },
        ];
    };

    const initialValuesCalculation = async () =>{

        //  Assign the setting fetched from the back
        await loanSettingsAssignment();
        setLoanAmount(creditValueConditions.minValue);
        calculationVariables.loanAmount = creditValueConditions.minValue;
        creditInformation.initialBalance = creditValueConditions.minValue;

        setTerm(creditValueConditions.minTerm);
        calculationVariables.term = creditTermConditions.minTerm;
        creditInformation.term = creditTermConditions.minTerm;

        //Set initial cost without vat
        creditCostsConditions.costsWithoutVAT = creditCostsConditions.costsWithoutVATFee * calculationVariables.loanAmount;

        //Set with and without taxes initial costs discounts
        // const guarantorDiscount = await calculateNoVATTermDiscount(creditCostsConditions.costsWithoutVATFee * calculationVariables.loanAmount);
        // const guarantorDiscount = await calculateNoVATTermDiscount(); //UNDER REVIEW
        const guarantorDiscount = await calculateNoVatTotalDiscount();
        setWarrantyDiscount(guarantorDiscount);
        calculationVariables.warrantyDiscount = guarantorDiscount;

        // const discount = await calculateWithVATTermDiscount(); //UNDER REVIEW
        const discount = await calculateWithVatTotalDiscount();
        setPlatfromDiscount(discount);
        calculationVariables.platfromDiscount = discount;

        //Initial taxes calculation
        // const tempVat = VATCalculation(discount);
        const tempVat = VATCalculation();
        
        //Set dues
        setDues(creditInstallmentConditions.amountInstallments);

        if(duesEqualTerm===false){
            creditInformation.dues=creditInstallmentConditions.amountInstallments;
        }
        else{
            creditInformation.dues=calculationVariables.term;
        }

         //Set termunit
        creditInformation.termUnit = creditTermConditions.termUnit;

        //Set interests calculations
        await dailyInterestCalc();
        const rate = await handleInterests();

        defineMargins(guarantorDiscount, discount, rate, tempVat);

        //Set marks accordingly to received info from back
        updateSettings();

        setLoading(false);
    };

    const loadInitialProducts = () => {
        return (new Promise((resolve) => {
                if(props.location.state && props.location.state.fromHistory === "/login"){
                    resolve("200");
                }
            loadProductsAndSetting("/account/create_credit", loginUser.accountId, loginUser.jwtToken).then((result) => {
                resolve(result);
            });
            
        }));
           
    };

    const handleLoanSimulator = () => {
        return(new Promise((resolve) => {
            if(props.location.state && props.location.state.creditInformation){
                console.log(props.location.state.creditInformation);
                
                //Assign proper values to the object that will serve to post the loan to the back
                creditInformation.account = loginUser.accountId;
                creditInformation.initialBalance = props.location.state.creditInformation.initialBalance;
                creditInformation.term = props.location.state.creditInformation.term;
                creditInformation.dues = props.location.state.creditInformation.dues;

                console.log(creditInformation);
                handleInputCreditInformation();
                resolve("skipSimulation");
            }
            else{
                resolve("doSimulation");
            }
        }));
    }

    const explanationMessages = () => {
        if(displayExplanations && displayExplanations.newLoanMessage){
            if(props.location.state && props.location.state.creditInformation){
                swal({
                    title: "Explicación",
                    text: creditExplanation,
                    icon: "success",
                    button: "Entiendo",
                }).then(() => {
                    updateExplanations(loginUser.accountId, "newLoanMessage");
                });
            }
            else{
                swal({
                    title: "Explicación",
                    text: loggedSimulatorExplanation,
                    icon: "success",
                    button: "Entiendo",
                }).then(() => {
                    updateExplanations(loginUser.accountId, "newLoanMessage");
                })
            }
            
        }
    };
 
    useEffect(() => {

        if(visitorLoans){
            history.push({pathname: "/credit/handle_credit"});
        }

        setLoading(true);
        //Handle explanation message
        explanationMessages();

        loadInitialProducts().then((result) => {
            initialValuesCalculation().then(() => {

                //When loan was already simulated: first time user and B2B client decided that the first view is a loan simulator
                handleLoanSimulator();

            });
        });

        //Profile verification
        handleProfile();

        //Credentials loading
        loadXrpAddressCreds(loginUser.jwtToken).then((credentials) => {
            
            WUPOADDRESS = credentials.xrpAddress;
            WUPOSECRET = credentials.xrpAddressSecret;
            // console.log(`address: ${WUPOADDRESS} secret: ${WUPOSECRET}`); //DEBUG
        });
        
        //Include in the un mount when receiving from AML form
        creditInformation.account = loginUser.accountId;

        loanLimit = loanMaxLimit;

    }, [])

    const delay = ms => new Promise(res => setTimeout(res, ms));
    
    function handleInputCreditInformation(){
        setLoading(true);
        
        creditInformation.paymentType = creditInstallmentConditions.paymentType;
        determineIfCreditProceed();
    }

    function determineIfCreditProceed(){

       const loanAssetAccount = visitorAccounts.find((account) => {
            return account.asset === clientCreditAsset;
        });

        // if(!loanAssetAccount){
        //     swal({
        //         title: "REQUISITO PREVIO",
        //         text: "Primero debes abrir tu cuenta en WUPOS",
        //         icon: "error",
        //         button: "Entiendo",
        //     }).then(() => {
        //         // history.push({pathname: "/account/create_account"}); //IMS specific
        //     });
        // }

        if(visitorLoans !== "Not found"){
            //TODO revolving actions
        }
        

        fetchCreditConditions(creditInformation, loginUser.jwtToken).then((loanConditions) =>{
           
            conditionsArray = [...loanConditions.installments];
            
            // console.log(conditionsArray); //DEBUG
            // console.log(loanConditions); //DEBUG
            creditCost = loanConditions.loanFee;
            creditInterestRate = loanConditions.loanInterestRate;
            handeConditions(); 
        }); 
        
    }

    function createCreditXrplAccount(){
        setLoading(true);

        createXrplAccount().then((xrplAddress) =>{
            console.log(xrplAddress);
            clientClassicXrplAddress = xrplAddress.address;
            clientXrplAddress = xrplAddress.xAddress;
            clientXrplSecret = xrplAddress.secret;


            payment.source.address = WUPOADDRESS;
            payment.source.maxAmount.value = enablePaymentValue;
            payment.source.maxAmount.currency = enableAccountAsset;

            payment.destination.address = clientXrplAddress;
            payment.destination.amount.value = enablePaymentValue;
            payment.destination.amount.currency = enableAccountAsset;

            xrpPayment(payment, "", "", WUPOSECRET).then((paymentResult) =>{
                if(paymentResult === "payment ok"){
                   
                    createCreditTrustline();                    

                }else{
                    swal({
                        title: "NO PUDIMOS CREAR TU PRODUCTO",
                        // text: operationUnsuccesful,
                        icon: "error",
                        button: "Entiendo",
                    }).then(() => {
                        history.push({pathname: "/account/create_credit"});
                    });
                }
            })
        })

    }

   async function createCreditTrustline(){

        trustline.currency = clientCreditAsset;
        trustline.counterparty = WUPOADDRESS;
        trustline.limit = trustlineLimitValue;
        await delay(50); //Sometimes required when client's internet is slow to ensure proper creation of trustline
        createTrustline( clientXrplAddress, trustline, clientXrplSecret).then((trustlineResult) => {
            if(trustlineResult.resultCode === "tesSUCCESS"){
                
                postCreditToBack();

            }else{
                swal({
                    title: "NO PUDIMOS CREAR TU PRODUCTO",
                    // text: operationUnsuccesful,
                    icon: "error",
                    button: "Entiendo",
                }).then(() => {
                    history.push({pathname: "/account/create_credit"});
                });              
            }
        })
        .catch((error)=>{
            console.log(error);
            swal({
                title: "NO PUDIMOS CREAR TU PRODUCTO",
                // text: operationUnsuccesful,
                icon: "error",
                button: "Entiendo",
            }).then(() => {
                history.push({pathname: "/account/create_credit"});
            }); 
        })
    }

    function postCreditToBack(){

        // const paymentWindow = window.open('about:blank');

        // const paymentWindow = window.open('https://ims-customization.d8dvucg91qcfk.amplifyapp.com/withdrawmethod');
        // const paymentWindow = window.open('https://wupotest.ngrok.io/withdrawmethod');

        creditInformation.creditXrplAccount = clientXrplAddress;
        creditInformation.creditClassicXrplAccount = clientClassicXrplAddress;
        creditInformation.creditXrplSecret = clientXrplSecret;
        creditInformation.lender = lender;

        // console.log(creditInformation);

        postCredit(creditInformation, loginUser.jwtToken).then((loanResponse) => {
            
            loanDisbursement.source.address = WUPOADDRESS;
            loanDisbursement.source.maxAmount.counterparty = WUPOADDRESS;
            // loanDisbursement.source.maxAmount.currency = creditInformation.asset;
            loanDisbursement.source.maxAmount.currency = loanResponse.asset;
            // loanDisbursement.source.maxAmount.value = String(creditInformation.initialBalance);
            loanDisbursement.source.maxAmount.value = String(Number(loanResponse.balanceToBeDisbursed).toFixed(0));

            // loanDisbursement.destination.address = visitorAccounts[0].xrplAddress;
            loanDisbursement.destination.address = loanResponse.walletAddress;
            loanDisbursement.destination.amount.counterparty = WUPOADDRESS;
            // loanDisbursement.destination.amount.currency = creditInformation.asset;
            loanDisbursement.destination.amount.currency = loanResponse.asset;
            // loanDisbursement.destination.amount.value = String(creditInformation.initialBalance);
            loanDisbursement.destination.amount.value = String(Number(loanResponse.balanceToBeDisbursed).toFixed(0));

            if(loanResponse === "Error"){
                // paymentWindow.close();
                setLoading(false);
                swal({
                    title: "NO PUDIMOS CREAR TU PRODUCTO",
                    // text: operationUnsuccesful,
                    icon: "error",
                    button: "Entiendo",
                }).then(() => {
                    history.push({pathname: "/account/create_credit"});
                }); 
            }

            else if(loanResponse === "invalid email"){
                
                swal({
                    title: "NO PUDIMOS CREAR TU PRODUCTO",
                    text: `No pudimos enviarte los documentos al correo ingresado. 
                    
                    Por favor revisa tu información y comunícate con nostros. `,
                    icon: "error",
                    button: "Entiendo",
                }).then(() => {
                    history.push({pathname: "/account/create_credit"});
                });
            }

            else{
                
                //IMS disbursement
                console.log("*******Acá estoy")
                console.log(loanResponse.thirdPartyWithdrawLink);
                // paymentWindow.document.location.href = loanResponse.thirdPartyWithdrawLink;
                
                // window.open(loanResponse.thirdPartyWithdrawLink);

                //XRPL disbursement, only for clients different from IMS
                if(b2bClient && b2bClient !== "IMS"){
                    xrpPayment(loanDisbursement, "", "", WUPOSECRET).then((paymentResult) => {
                        setLoading(false);

                        if(paymentResult === "payment ok"){
                        
                            //Loan disbursment statement
                            const disbursedStatement = {
                                accountId: loginUser.accountId + creditInformation.asset,
                                account: loginUser.accountId,
                                asset: creditInformation.asset,
                                value: loanDisbursement.destination.amount.value,
                                transaction: "credit",
                                description: "Desembolso",
                            }
                        
                
                            prepareStatement(disbursedStatement, loginUser.jwtToken).then(statementResult =>{
                            // console.log(statementResult); // DEBUG PRINTING
                            });

                            swal({
                                title: "DESEMBOLSO EN PROCESO",
                                text: `Listo! Platica Yaap. 
                                
                                Así de fácil. Felicitaciones!

                                Por favor sigue las instricciones de nuestro socio ComboPay para retirar tu dinero.

                                Recibirás en tu correo electrónico registrado los documentos fimados electrónicamente (revisa correos no deseados)`,
                                icon: "success",
                                button: "Súper",
                            }).then(() => {
                                loadProductsAndSetting("/credit/handle_credit", loginUser.accountId, loginUser.jwtToken).then(() => {
                                    history.push({pathname: "/credit/handle_credit"});
                                });
                            });
                        }
                        else{
                            swal({
                                title: "NO PUDIMOS CREAR TU PRODUCTO",
                                // text: operationUnsuccesful,
                                icon: "error",
                                button: "Entiendo",
                            }).then(() => {
                                history.push({pathname: "/account/create_credit"});
                            }); 
                        }
                    })
                }

                else{
                     //Loan disbursment statement
                     const disbursedStatement = {
                        accountId: loginUser.accountId + creditInformation.asset,
                        account: loginUser.accountId,
                        asset: creditInformation.asset,
                        value: loanDisbursement.destination.amount.value,
                        transaction: "credit",
                        description: "Desembolso",
                    }
        
                    prepareStatement(disbursedStatement, loginUser.jwtToken).then(statementResult =>{
                    // console.log(statementResult); // DEBUG PRINTING
                    });

                    swal({
                        title: "DESEMBOLSO EN PROCESO",
                        text: `
                        Por favor sigue las instrucciones de la pasarela de pagos para retirar tu dinero.

                        Recibirás en tu correo electrónico registrado los documentos fimados electrónicamente (revisa correos no deseados)`,
                        icon: "success",
                        button: "Entiendo",
                    }).then(() => {
                        loadProductsAndSetting("/credit/handle_credit", loginUser.accountId, loginUser.jwtToken).then(() => {
                            history.push({pathname: "/credit/handle_credit"});
                        });
                    });
                }
            }
        });
    }

    function postRevolvingLoanToBack(){
        setLoading(true);

        creditInformation.lender = lender;

        // console.log(creditInformation);

        postRevolvingCredit(creditInformation, loginUser.jwtToken).then((loanResponse) => {
            
            

            if(loanResponse === "Error"){
                setLoading(false);
                swal({
                    title: "NO PUDIMOS CREAR TU PRODUCTO",
                    // text: operationUnsuccesful,
                    icon: "error",
                    button: "Entiendo",
                }).then(() => {
                    history.push({pathname: "/account/create_credit"});
                }); 
            }

            else{

                loanDisbursement.source.address = WUPOADDRESS;
                loanDisbursement.source.maxAmount.counterparty = WUPOADDRESS;
                // loanDisbursement.source.maxAmount.currency = creditInformation.asset;
                loanDisbursement.source.maxAmount.currency = loanResponse.asset;
                // loanDisbursement.source.maxAmount.value = String(creditInformation.initialBalance);
                loanDisbursement.source.maxAmount.value = String(Number(loanResponse.balanceToBeDisbursed).toFixed(2));
    
                // loanDisbursement.destination.address = visitorAccounts[0].xrplAddress;
                loanDisbursement.destination.address = loanResponse.walletAddress;
                loanDisbursement.destination.amount.counterparty = WUPOADDRESS;
                // loanDisbursement.destination.amount.currency = creditInformation.asset;
                loanDisbursement.destination.amount.currency = loanResponse.asset;
                // loanDisbursement.destination.amount.value = String(creditInformation.initialBalance);
                loanDisbursement.destination.amount.value = String(Number(loanResponse.balanceToBeDisbursed).toFixed(2));

                xrpPayment(loanDisbursement, "", "", WUPOSECRET).then((paymentResult) => {
                    setLoading(false);
                    
                    if(paymentResult === "payment ok"){
                        swal({
                            title: "DESEMBOLSO EXITOSO",
                            text: `Listo! Ya tienes los tokens en tu cuenta. 
                            
                            Sí, así de fácil. Felicitaciones!
                            
                            Recibiras en tu correo electrónico registrado los documentos fimados electrónicamente (revisa correos no deseados)`,
                            icon: "success",
                            button: "Súper",
                        }).then(() => {
                            loadProductsAndSetting("/credit/handle_credit", loginUser.accountId, loginUser.jwtToken).then(() => {
                                history.push({pathname: "/credit/handle_credit"});
                            });
                        });
                    }

                    else{
                        swal({
                            title: "NO PUDIMOS CREAR TU PRODUCTO",
                            // text: operationUnsuccesful,
                            icon: "error",
                            button: "Entiendo",
                        }).then(() => {
                            history.push({pathname: "/account/create_credit"});
                        }); 
                    }
                })

            }
        });
    }

    function handleBalanceInput(inputValues){
        
        setDisableTerm(false);

        setBalanceInputValue(inputValues.target.value);
        creditInformation.initialBalance = inputValues.target.value;

        setLoanAmount(inputValues.target.value)
        calculationVariables.loanAmount = inputValues.target.value;
        // creditValueConditions.minValue.loanAmount = inputValues.target.value;

        calculateBalanceModification();
    }

    async function handleTermSelect(e){
        setTerm(e.target.value);
        calculationVariables.term = e.target.value;
        setDisableDues(false);
        
        creditInformation.term = e.target.value;

        if(duesEqualTerm === true){
            creditInformation.dues=creditInformation.term;
        }

        await dailyInterestCalc();

        setDisableButton(false);

        calculateTermModification();

    }

    function handleDuesSelect(e){
        setDues(e.target.value);
        // console.log(e.target.value)
        setDisableButton(false);
        creditInformation.dues = e.target.value;

    }

    const calculateBalanceModification = async () =>{

        creditCostsConditions.costsWithoutVAT = creditCostsConditions.costsWithoutVATFee * calculationVariables.loanAmount;

        
        calculationVariables.platfromDiscount = await calculateWithVatTotalDiscount();
        setPlatfromDiscount(calculationVariables.platfromDiscount);
 
        calculationVariables.warrantyDiscount = await calculateNoVatTotalDiscount();
        setWarrantyDiscount(calculationVariables.warrantyDiscount);

        VATCalculation();

        disbursedBalanceBeforeInterests = calculationVariables.loanAmount - (creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount) 
        - (creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) -calculationVariables.vatTax;

        await handleInterests();

        calculationVariables.totalPayment = calculationVariables.loanAmount + (creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount) 
        + (creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) + calculationVariables.vatTax + calculationVariables.interestState;
        
        calculationVariables.disbursedBalance = calculationVariables.loanAmount - (creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount) 
                    - (creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) -calculationVariables.vatTax - calculationVariables.interestState;
        
        if(creditInstallmentConditions.paymentType === "prepaid"){
            setDisbursedBalanceState(calculationVariables.disbursedBalance );
        }else if(creditInstallmentConditions.paymentType === "postpaid"){
            setDisbursedBalanceState(calculationVariables.totalPayment );
        }else{
            setDisbursedBalanceState(calculationVariables.disbursedBalance );
        }

        setMargenState((calculationVariables.loanAmount -  calculationVariables.disbursedBalance)/ calculationVariables.loanAmount);
        // console.log(margen)
        
    };

    const calculateTermModification = async () =>{
        
        creditCostsConditions.costsWithoutVAT = creditCostsConditions.costsWithoutVATFee * calculationVariables.loanAmount;
        // console.log(`costsWithoutVAT: ${creditCostsConditions.costsWithoutVAT}`)
        

        calculationVariables.platfromDiscount = await calculateWithVatTotalDiscount();
        setPlatfromDiscount(calculationVariables.platfromDiscount);

        // calculationVariables.warrantyDiscount = await calculateNoVATTermDiscount();
        calculationVariables.warrantyDiscount = await calculateNoVatTotalDiscount();
        setWarrantyDiscount(calculationVariables.warrantyDiscount);

        VATCalculation();

        disbursedBalanceBeforeInterests = calculationVariables.loanAmount - (creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount) 
        - (creditCostsConditions.costsWithVAT - platfromDiscount) -calculationVariables.vatTax;

        await handleInterests();

        calculationVariables.totalPayment = calculationVariables.loanAmount + (creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount) 
        + (creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) + calculationVariables.vatTax + calculationVariables.interestState;
        
        calculationVariables.disbursedBalance = calculationVariables.loanAmount - (creditCostsConditions.costsWithoutVAT - warrantyDiscount) 
                    - (creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) -calculationVariables.vatTax - calculationVariables.interestState;

        if(creditInstallmentConditions.paymentType === "prepaid"){
            setDisbursedBalanceState(calculationVariables.disbursedBalance );
        }else if(creditInstallmentConditions.paymentType === "postpaid"){
            setDisbursedBalanceState(calculationVariables.totalPayment );
        }else{
            setDisbursedBalanceState(calculationVariables.disbursedBalance );
        }

        setMargenState((calculationVariables.loanAmount -  calculationVariables.disbursedBalance)/ calculationVariables.loanAmount);
        // console.log(margen)
    };
    
    const  calculateWithVATTermDiscount = () => {
        
        const termSteps = 1/(((creditTermConditions.maxTerm - creditTermConditions.minTerm)/creditTermConditions.interval));
        const currentTermSteps = (creditTermConditions.maxTerm - creditTermConditions.minTerm)/creditTermConditions.interval 
        - (creditTermConditions.maxTerm - calculationVariables.term)/creditTermConditions.interval;
        const minimumCostsWithVATFee = creditCostsConditions.costsWithVATminimum * creditCostsConditions.costsWithVAT;
        const remainingCostsWithVAT = creditCostsConditions.costsWithVAT - minimumCostsWithVATFee;
        const discountValue = remainingCostsWithVAT - (remainingCostsWithVAT * termSteps * currentTermSteps);
        // console.log(`discount value with VAT ${discountValue}`); //DEBUG

        //TODO: new way of calculation
        const stepDiscount = currentTermSteps * creditCostsConditions.platformTimeDiscount;
        console.log(creditCostsConditions.platformTimeDiscount);
        console.log(currentTermSteps);
        console.log(stepDiscount);

        // return new Promise((resolve) => {
        //     resolve(discountValue);
        // })

        return new Promise((resolve) => {
            resolve(stepDiscount);
        })
    };

    const calculateWithVATBalanceDiscount = () => {
        const currentBalanceSteps = (creditValueConditions.maxValue - creditValueConditions.minValue)/creditValueConditions.interval 
        - (creditValueConditions.maxValue - calculationVariables.loanAmount)/creditValueConditions.interval;

        const stepDiscount = currentBalanceSteps * creditCostsConditions.platformBalanceDiscount;
        
        console.log(currentBalanceSteps);
        console.log(stepDiscount);
        
        return new Promise((resolve) => {
            resolve(stepDiscount);
        })
    };

    const calculateWithVatTotalDiscount = async () => {

        const termDiscount = await calculateWithVATTermDiscount();
        const balanceDiscount = await calculateWithVATBalanceDiscount();

        let stepDiscountVat = 0;

        if(creditCostsConditions.costsWithVATminimum < 1){
            stepDiscountVat = (1 - creditCostsConditions.costsWithVATminimum) - termDiscount - balanceDiscount;
        }

        const finalDiscount = (stepDiscountVat * creditCostsConditions.costsWithVAT) < 0 ? 0 : (stepDiscountVat * creditCostsConditions.costsWithVAT);

        console.log(termDiscount)
        console.log(balanceDiscount)
        console.log(stepDiscountVat)
        console.log(finalDiscount)

        return new Promise((resolve) => {
            resolve(finalDiscount);
        });
    };

    const calculateNoVATTermDiscount = (noTaxesCost) => {
       
        const termSteps = 1/(((creditTermConditions.maxTerm - creditTermConditions.minTerm)/creditTermConditions.interval));
        const currentTermSteps = (creditTermConditions.maxTerm - creditTermConditions.minTerm)/creditTermConditions.interval 
        - (creditTermConditions.maxTerm - calculationVariables.term)/creditTermConditions.interval;
        let minimumCostsWithoutVATFee;
        let remainingCostsWithoutVAT;

        if(noTaxesCost){
            minimumCostsWithoutVATFee = creditCostsConditions.costsWithoutVATminimum * noTaxesCost; 
            remainingCostsWithoutVAT = noTaxesCost - minimumCostsWithoutVATFee;  
        }else{
            minimumCostsWithoutVATFee = creditCostsConditions.costsWithoutVATminimum * creditCostsConditions.costsWithoutVAT;
            remainingCostsWithoutVAT = creditCostsConditions.costsWithoutVAT - minimumCostsWithoutVATFee;
        }
        const discountValue = remainingCostsWithoutVAT - (remainingCostsWithoutVAT * termSteps * currentTermSteps);

        //TODO: new way of calculation
        const stepDiscount = currentTermSteps * creditCostsConditions.warrantyTimeDiscount;
        
        // console.log(currentTermSteps);
        // console.log(stepDiscount);

        // console.log(`discount value without VAT ${discountValue}`); //DEBUG
        // return new Promise((resolve) => {
        //     resolve(discountValue);
        // });

        return new Promise((resolve) => {
            resolve(stepDiscount);
        });
    };

    const calculateWithoutVATBalanceDiscount = () => {
        const currentBalanceSteps = (creditValueConditions.maxValue - creditValueConditions.minValue)/creditValueConditions.interval 
        - (creditValueConditions.maxValue - calculationVariables.loanAmount)/creditValueConditions.interval;

        const stepDiscount = currentBalanceSteps * creditCostsConditions.warrantyBalanceDiscount;
        
        // console.log(currentBalanceSteps);
        // console.log(stepDiscount);
       
        return new Promise((resolve) => {
            resolve(stepDiscount);
        })
    };

    const calculateNoVatTotalDiscount = async () => {

        const termDiscount = await calculateNoVATTermDiscount();
        const balanceDiscount = await calculateWithoutVATBalanceDiscount();

        let stepDiscountNoVat = 0;

        if(creditCostsConditions.costsWithoutVATminimum < 1){
            stepDiscountNoVat = (1 - creditCostsConditions.costsWithoutVATminimum) - termDiscount - balanceDiscount;
        }

        const finalDiscount = stepDiscountNoVat * creditCostsConditions.costsWithoutVAT;

        // console.log(termDiscount)
        // console.log(balanceDiscount)
        // console.log(stepDiscount)
        // console.log(finalDiscount)

        return new Promise((resolve) => {
            resolve(finalDiscount);
        });
    };

    /**
     * Loan conditions dialog
     */

    const [conditions, setConditions] = React.useState(false);

    const handeConditions = ()=>{
        setConditions(true);
    };
    
    const handeConditionsClose = ()=>{
        setLoading(false);
        setConditions(false);
    };

    const handeAcceptConditions = async ()=>{
        setConditions(false);
        
        console.log(loanConditionsSettings)
        if(loanConditionsSettings && loanConditionsSettings.isDocumented && loanConditionsSettings.isDocumented === true){

            const statusReqObject = {
                username: loginUser.accountId,
            };
            const docsStatus = await clientDocsStatus(statusReqObject, loginUser.jwtToken);

            console.log(docsStatus.documentedDocsStatus);

            if(!docsStatus.documentedDocsStatus){
                swal({
                    title: "Documentos Necesarios",
                    text: "Debes cargar los documentos solicitados a continuación para soportar tu información financiera.",
                    icon: "success",
                    button: "Súper",
                }).then(()=> {
                    history.push({pathname: "/loans/docs-upload"})
                })
                return;
            }

            if(docsStatus && docsStatus.documentedDocsStatus === "pending"){
                swal({
                    title: "Documentos Necesarios",
                    text: "Debes cargar los documentos solicitados a continuación para soportar tu información financiera.",
                    icon: "success",
                    button: "Súper",
                }).then(()=> {
                    history.push({pathname: "/loans/docs-upload"})
                })
                return;
            }

            if(docsStatus && docsStatus.documentedDocsStatus !== "active"){

                swal({
                    title: "Documentos en aprobación",
                    icon: "success",
                    button: "Ok",
                });

                return;
            }
        }

        handleAgreementsDialog(); 

    };

    const [balanceInputValue, setBalanceInputValue] = React.useState("");

    const [agreements, setAgreements] = React.useState(false);

    const handleAgreementsDialog = () =>{
        setAgreements(true);
    }

    const closeAgreementsDialog = () => {
        setAgreements(false);
        setLoading(false);
    }

    const handleAgreements = () => {
        setLoading(true)
        setAgreements(false);
        setDisbursementType(false);

        if(visitorLoans){
            console.log("Revolving loan")
            postRevolvingLoanToBack()        
        }
        else{
            // createCreditXrplAccount();
            console.log("Single loan")
            postCreditToBack();
        }

    }

    const valuetext = (value) => {
        // console.log(`${value} pesos`)
        return `${value} pesos`;
    }

    //Disbursement options dialogue
    const[disbursementType, setDisbursementType] = React.useState(false);

    const disbursementTypeOpen = () => {
        setDisbursementType(true);
    };
    
    const disbursementTypeClose = () => {
        setDisbursementType(false);
    };


    //transfer disbursement dialogue

    
    const[bankCode, setBankCode] = React.useState("");
    const[bankName, setBankName] = React.useState("");
    const[bankAccountType, setBankAccountType] = React.useState("");
    const[bankAccountNumber, setBankAccountNumber] = React.useState("");
    const[transferInfo, setTransferInfo] = React.useState(false);

    const[transferDisabled, setTransferDisabled] = React.useState(true);
    const[bankCodeDisabled, setBankCodeDisabled] = React.useState(true);
    const[bankAccountTypeDisabled, setBankAccountTypeDisabled] = React.useState(true);
    const[bankAccountNumberDisabled, setBankAccountNumberDisabled] = React.useState(true);

    const transferInfoOpen = () => {
        setLoading(true);
        setAgreements(false);
        verifyBankAccount(loginUser.accountId, loginUser.jwtToken).then((bankAccountPersisted) => {
            if(bankAccountPersisted !== "Not found"){
                const bank = bankCodes.find((account) => account.code === bankAccountPersisted.bankCode);
                setBankName(bank);
                setBankCode(bank.code);
                setBankAccountType(bankAccountPersisted.bankAccountType);
                setBankAccountNumber(bankAccountPersisted.bankAccount);
            }else{
                setBankCodeDisabled(false);
                setBankAccountTypeDisabled(false);
                setBankAccountNumberDisabled(false);
            }
            disbursementTypeClose();
            setTransferInfo(true);
            setLoading(false);
        });
    }; 

    const transferInfoClose = () => {
        setTransferInfo(false);
    };

    React.useEffect(() => {

        if(bankCode && bankAccountType && bankAccountNumber){
            setTransferDisabled(false);
        }else{
            setTransferDisabled(true);
        }

    }, [bankCode, bankAccountType, bankAccountNumber]);

    const handleBankAccount = () => {
        creditInformation.transactionType = "bankTransfer";
        creditInformation.bankCode = bankCode;
        creditInformation.bankAccountType = bankAccountType;
        creditInformation.bankAccount = bankAccountNumber;

        handleAgreements();
        setTransferInfo(false);
    };

    const changeBankCode = (e) => {
        console.log(e.target.value)
        setBankCode(e.target.value.code);
        setBankName(e.target.value);
    };

    const[agreementAcept, setAgreementAcept] = React.useState(false);
    const[noteAcept, setNoteAcept] = React.useState(false);
    const[insuranceAccept, setInsuranceAccept] = React.useState(false);
    const[aceptEnabled, setAceptEnabled] = React.useState(true);

    const agreementCheck = (e) => {
        console.log(e.target.checked);
        setAgreementAcept(e.target.checked);
    };

    const noteCheck = (e) => {
        console.log(e.target.checked);
        setNoteAcept(e.target.checked);
    };
    const insuranceCheck = (e) => {
        console.log(e.target.checked);
        setInsuranceAccept(e.target.checked);
    };

    React.useEffect(()=> {
        if(agreementAcept===true && noteAcept===true && insuranceAccept==true){
            setAceptEnabled(false);
        }
        else{
            setAceptEnabled(true);
        }
    }, [agreementAcept,noteAcept, insuranceAccept])

    const generateRandomKey = () => {
        const randomString = Math.random().toString(36).slice(2);
        const timestamp = Date.now().toString(36);
        return randomString + '_' + timestamp;
    };

    return (
        <>
        <Box sx={{display: "flex", justifyContent: "center",  width: "100%", height: "100%", backgroundColor: "#ededed",}}>
            <Box sx={{display: "flex", justifyContent: "center", alignItems: {xs: "start", sm: "center"} , width: {xs: "95%", sm: "100%"}, marginTop: {xs:"1.5rem", sm: "0"}}}>
                {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                    <Card sx={{ height: {xs:"98%", sm: "95%"}, width: {xs: "97%", sm: "50%", md: "40%"}, borderRadius: "25px", marginTop: {xs: "0.2rem", sm: "0"}}}>
                        <Grid container spacing={1} sx={{display: "flex", justifyContent: "center", marginTop: "1.5rem"}}>
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", width: "100%", justifyContent: "center"}}>
                                <Grid>
                                    <Grid item xs={12} sm={12} md= {12} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography variant="body2">Pago Total</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md= {12} sx={{display: "flex", justifyContent: "center", marginBottom: "1rem"}}>
                                        <Typography variant="h4" sx={{color:"black", fontWeight:"bold",}}>
                                            {`$ ${new Intl.NumberFormat('es-CO', {maximumFractionDigits: 0}).format(disbursedBalanceState)}`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", width: "100%", justifyContent: "center", marginTop: "1.5rem"}}>
                                <Box sx={{ width: {xs: "70%", sm: "60%"}, textAlign: "center" }}>
                                    <Typography gutterBottom>
                                        {creditInstallmentConditions.paymentType === "postpaid" ? "¿Cuánto necesitas?":"Valor a pagar"}
                                    </Typography>
                                    <Slider
                                        aria-label="Amount"
                                        // defaultValue={150000}
                                        value={loanAmount}
                                        getAriaValueText={valuetext}
                                        step={creditValueConditions.interval}
                                        marks={marksBalance}
                                        valueLabelDisplay="auto"
                                        min={creditValueConditions.minValue}
                                        max={creditValueConditions.maxValue}
                                        onChange={handleBalanceInput}
                                        sx={{
                                            '& .MuiSlider-valueLabel': {
                                                backgroundColor: Colors.primary,
                                                // color: "black",
                                                // borderRadius: "5%",

                                            }
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", width: "100%", justifyContent: "center"}}>
                                <Box sx={{ width: {xs: "70%", sm: "60%"}, textAlign: "center" }}>
                                    <Typography gutterBottom>Cuotas</Typography>
                                    <Slider
                                        aria-label="installments"
                                        // defaultValue={1}
                                        value={term}
                                        getAriaValueText={valuetext}
                                        step={creditTermConditions.interval}
                                        marks={marksTerm}
                                        valueLabelDisplay="auto"
                                        min={creditTermConditions.minTerm}
                                        max={creditTermConditions.maxTerm}
                                        onChange={handleTermSelect}
                                        sx={{
                                            '& .MuiSlider-valueLabel': {
                                                backgroundColor: Colors.primary,
                                            }
                                        }}
                                    />
                                </Box>
                            </Grid>
                            {creditInstallmentConditions.amountInstallments > 1 && 
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", width: "100%", justifyContent: "center"}}>
                                    <Box sx={{ width: {xs: "70%", sm: "60%"}, textAlign: "center" }}>
                                        <Typography gutterBottom>Cuotas (cantidad de pagos)</Typography>
                                        <Slider
                                            aria-label="installments"
                                            // defaultValue={1}
                                            value={dues}
                                            getAriaValueText={valuetext}
                                            step={1}
                                            marks={marksInstallments}
                                            valueLabelDisplay="on"
                                            min={1}
                                            max={creditInstallmentConditions.amountInstallments}
                                            onChange={handleDuesSelect}
                                        />
                                    </Box>
                                </Grid>
                            }
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", width: "100%", justifyContent: "center", marginTop: "1rem"}}>
                                <Grid container sx={{width: {xs: "80%", sm: "50%", md: "60%"}, textAlign: "start", marginLeft: {md: "1rem"}}}>
                                    <Grid item xs={7} sm={7} md={7}>
                                            <Typography  variant="body2">
                                                {creditInstallmentConditions.paymentType === "postpaid" ? "Cuánto necesitas":"Valor a pagar"}
                                            </Typography>
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={4} >
                                        <Typography variant="body2" sx ={{color:"black", fontWeight:"bold"}}>
                                            {`$ ${new Intl.NumberFormat('es-CO').format(loanAmount)}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1} >
                                        <Tooltip title={disbursedText} placement="top-end">
                                            <Typography>
                                                <BsInfoCircle size={13}/>
                                            </Typography>
                                        </Tooltip>
                                    </Grid>
                                    {isAdmin && margin===true &&
                                        <>
                                            <Grid item xs={8} sm={8} md={8}>
                                                <Typography  style={{fontWeight:"bold", fontSize:"0.95rem"}}>
                                                    Margen:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3} sm={3} md={3} >
                                                <Typography className={classes.dialogStyle} style={{color:"black", fontWeight:"bold", fontSize:"0.95rem"}}>
                                                    {`$ ${new Intl.NumberFormat('es-CO', {
                                                        maximumFractionDigits: 0,
                                                    }).format(margenState * 100)}%`}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={1} >
                                                {/* <Tooltip title={rateText} placement="top-end"> */}
                                                    <Typography>
                                                        <BsInfoCircle size={13}/>
                                                    </Typography>
                                                {/* </Tooltip> */}
                                            </Grid>
                                        </>
                                    }            
                                    
                                    <Grid item xs={7} sm={7} md={7}>
                                        <Typography  display="inline" variant="body2">
                                            {`Intereses`}
                                        </Typography>
                                        <Typography  display="inline" style={{fontSize: "0.6em"}}>
                                            {` (${Math.round(creditInterestConditions.interestRate*100)}% EM) `}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={4} >
                                        <Typography variant="body2">
                                            {`$ ${new Intl.NumberFormat('es-CO', {
                                                maximumFractionDigits: 0,
                                            }).format(interestState)}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1} >
                                        <Tooltip title={rateText} placement="top-end">
                                            <Typography>
                                                <BsInfoCircle size={13}/>
                                            </Typography>
                                        </Tooltip>
                                    </Grid>
                                    { creditCostsConditions.costsWithoutVATFee > 0 ?
                                        <>
                                        <Grid item xs={7} sm={7} md={7}>
                                            <Typography  variant="body2">
                                                Garantía
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4} >
                                            <Typography variant="body2">
                                                {/* {`$ ${new Intl.NumberFormat('es-CO').format((creditCostsConditions.costsWithoutVATFee * creditInformation.initialBalance))}`} */}
                                                {`$ ${new Intl.NumberFormat('es-CO').format((creditCostsConditions.costsWithoutVATFee * loanAmount))}`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1} >
                                            <Tooltip title={warrantyText} placement="top-end">
                                                <Typography>
                                                    <BsInfoCircle size={13}/>
                                                </Typography>
                                            </Tooltip>
                                        </Grid>
                                        {  warrantyDiscount > 0 ?
                                            <>
                                            <Grid item xs={7} sm={7} md={7}>
                                                <Typography  variant="body2">
                                                    Descuento Garantía:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={4} >
                                                <Typography variant="body2" style={{color: "blue"}}>
                                                    {`$ ${new Intl.NumberFormat('es-CO',
                                                    {
                                                        maximumFractionDigits: 0,
                                                    }).format(warrantyDiscount * -1)}`}
                                                </Typography>
                                            </Grid>
                                            </>
                                        :   <></>}
                                        </>
                                    : <></>
                                    }

                                        { creditCostsConditions.costsWithVAT > 0 ?
                                            <>
                                            <Grid item xs={7} sm={7} md={7}>
                                                <Typography  variant="body2">
                                                    Costo Plataforma:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={4} >
                                                <Typography variant="body2">
                                                    {`$ ${new Intl.NumberFormat('es-CO').format(creditCostsConditions.costsWithVAT)}`}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={1} >
                                                <Tooltip title={platformText} placement="top-end">
                                                    <Typography>
                                                        <BsInfoCircle size={13}/>
                                                    </Typography>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={7} sm={7} md={7}>
                                                <Typography  variant="body2">
                                                    Descuento Plataforma:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={4} >
                                                <Typography variant="body2" sx={{color: "blue"}}>
                                                    {`$ ${new Intl.NumberFormat('es-CO',
                                                    {
                                                        maximumFractionDigits: 0,
                                                    }).format(platfromDiscount * -1)}`}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={1} >
                                                <Tooltip title={platformDiscountText} placement="top-end">
                                                    <Typography>
                                                        <BsInfoCircle size={13}/>
                                                    </Typography>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={7} sm={7} md={7}>
                                                <Typography  display="inline" variant="body2">
                                                    {`IVA `}
                                                </Typography>
                                                <Typography  display="inline" variant="body2">
                                                    {`(${VAT * 100}%)`}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={4} >
                                                <Typography variant="body2">
                                                    {`$ ${new Intl.NumberFormat('es-CO', {
                                                        maximumFractionDigits: 0,
                                                    }).format(vatTax)}`}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={1} >
                                                {/* <Tooltip title={vatText} placement="top-end">
                                                    <Typography>
                                                        <BsInfoCircle size={13}/>
                                                    </Typography>
                                                </Tooltip> */}
                                            </Grid>
                                            </>
                                        : <></>
                                        }
                                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", marginTop: {xs:"2.1rem", sm:"2rem"}, marginBottom: "1.1rem"}}>
                                            <Button size="large" onClick={handleInputCreditInformation} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                                                Solicitar
                                            </Button>
                                        </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                }
                
            </Box>
        </Box>
            <>
            <Dialog open={conditions} onClose={handeConditionsClose}
                sx={{

                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "100%", sm: "80%", md: "35%"},
                            },
                        },
                    }}
            >
                <Box sx={{width: "100%", display: "flex", justifyContent: "center"}}>
                    <Grid container spacing={1} sx={{width: {xs: "90%", sm: "80%", md: "70%"}}}>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "start", marginTop:"1rem", marginBottom: "0.5rem"}}>
                            <Typography variant="h6" sx={{fontWeight: "bold"}}>Detalle Crédito</Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} sx={{display: "flex", alignItems: "center"}}>
                            <CalendarMonthIcon sx={{color: "black", fontSize: "18px"}}/>
                        </Grid>
                        <Grid item xs={11} sm={11} md={11} sx={{display: "flex"}}>
                            <Typography variant="body1" sx={{color: "black", fontWeight: "bold"}}>
                                Cuotas
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} key={generateRandomKey()} sx={{color: "gray", fontSize: "0.8rem"}}>
                            Fecha
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} key={generateRandomKey()} sx={{color: "gray", fontSize: "0.8rem"}}>
                            Pago
                        </Grid>
                        {conditionsArray.map((condition)=>{
                            return(
                                <>
                                    <Grid item xs={6} sm={6} md={6} key={generateRandomKey()}>
                                        <Grid item xs={12} sm={12} md={12} key={generateRandomKey()}>
                                            <Typography key = {generateRandomKey()} >
                                                {new Intl.DateTimeFormat("es-CO", dateOptions).format(new Date(condition.installmentDate))}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} key = {generateRandomKey()}>
                                        <Grid item xs={12} sm={12} md={12} key={generateRandomKey()}>
                                            <Typography className={classes.dialogStyle} key = {generateRandomKey()}>
                                                {`$ ${new Intl.NumberFormat('es-CO', {maximumFractionDigits: 0,}).format(condition.installmentValue)}`}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                
                                </>
                                )
                        })} 
                        <Grid item xs={12} sm={12} md={12}>
                            <Divider sx={{marginBottom: "1rem"}} />
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} sx={{display: "flex", alignItems: "center"}}>
                            <ListAltIcon sx={{color: "black", fontSize: "18px"}}/>
                        </Grid>
                        <Grid item xs={11} sm={11} md={11} sx={{display: "flex"}}>
                            <Typography variant="body1" sx={{color: "black", fontWeight: "bold"}}>
                                Detalle
                            </Typography>
                        </Grid>
                        {creditInstallmentConditions.paymentType === "postpaid" ?
                        <>
                            <Grid item xs={6} sm={6} md={6}>
                                <Typography>
                                    {/* Fecha: {conditionsArray[conditionsArray.length-1].installmentDate} */}
                                    Intereses
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4}>
                                <Typography className={classes.dialogStyle} sx={{fontSize: "0.9rem"}}>
                                    {`$ ${new Intl.NumberFormat('es-CO').format(creditCost)}`}
                                </Typography>
                            </Grid>
                        </>
                        :<></>
                        }
                        <Grid item xs={6} sm={6} md={6}>
                            <Typography>
                                {/* Fecha: {conditionsArray[conditionsArray.length-1].installmentDate} */}
                                Tasa de interés:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                            <Typography className={classes.dialogStyle}>
                                {`${new Intl.NumberFormat('es-CO').format(creditInterestRate*100)}% E.A.`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Divider sx={{marginBottom: "1rem"}} />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} sx= {{display: "flex", justifyContent: "end"}}>
                            <Button onClick={handeConditionsClose} color="primary">
                                ATRÁS
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} sx= {{display: "flex", justifyContent: "start"}}>
                            <Button onClick={handeAcceptConditions} color="primary">
                                ACEPTO
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>

            <Dialog open={agreements} onClose={closeAgreementsDialog}
                sx={{

                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: {xs: "100%", sm: "80%", md: "50%"},
                        },
                    },
                    }}
            >
                <DialogTitle>ACUERDOS</DialogTitle>
                <DialogContent>
                    <Grid container direction="row" spacing={1} sx={{width: {xs: "95%", sm: "80%", md: "80%"}, justifyContent: "center"}}>
                        <Grid item xs={2} sm={2} md={2} style={{textAlign: "center"}}>
                            <FormControlLabel
                                control={<Checkbox color="secondary" name="agreement" value="yes" />}
                                // label="Acepto la autorización de tratamiento de datos"
                                onChange={agreementCheck}
                            />
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} sx={{display: "flex", alignItems: "center"}}> 
                            <Link 
                                variant="body1"
                                href={`${wupoServerUri.devnet}/contrato-platicapp.pdf`}
                                target='_blank' rel='noopener noreferrer'
                                sx={{cursor: "pointer"}}
                            >
                                Contrato de Crédito
                            </Link>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} style={{textAlign: "center"}}>
                            <FormControlLabel
                                control={<Checkbox color="secondary" name="promissoryNote" value="yes" />}
                                onChange={noteCheck}
                            />
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} sx={{display: "flex", alignItems: "center"}}> 
                            <Link 
                                variant="body1"
                                href={`${wupoServerUri.devnet}/pagare-instrucciones-platicapp.pdf`}
                                target='_blank' rel='noopener noreferrer'
                                sx={{cursor: "pointer"}}
                            >
                                Pagaré e Instrucciones
                            </Link>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} style={{textAlign: "center"}}>
                            <FormControlLabel
                                control={<Checkbox color="secondary" name="promissoryNote" value="yes" />}
                                onChange={insuranceCheck}
                            />
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} sx={{display: "flex", alignItems: "center"}}>
                            <Link 
                                variant="body1"
                                href={`${wupoServerUri.devnet}/acuerdo-fg.pdf`}
                                target='_blank' rel='noopener noreferrer'
                                sx={{cursor: "pointer"}}
                            >
                                Acuerdo Garantía
                            </Link>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                            <Typography style={{marginTop: "1.2rem"}}>
                                Si leíste y aceptas los acuerdos por favor oprime el botón acepto.
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={closeAgreementsDialog} sx={{backgroundColor: Colors.primary}}>
                        ATRÁS
                    </Button>
                    <Button disabled={aceptEnabled}  variant="contained" onClick={handleAgreements} sx={{backgroundColor: Colors.primary}}>
                        ACEPTO
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={disbursementType} onClose={disbursementTypeClose}>
            <DialogTitle style={{color: "blue"}}>¿CÓMO QUIERES RECIBIR LA PLATA? </DialogTitle>
            <DialogContent className={classes.actionArea}>
                <Card sx={{ minWidth: 150 }}>
                    <CardActionArea onClick={transferInfoOpen}>    
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Transferencia Bancaria
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </DialogContent>

            <DialogContent className={classes.actionArea}>
              <Card sx={{ minWidth: 150 }}>
                <CardActionArea onClick={handleAgreements}>
                  <CardContent>
                    <Typography variant="h5" component="div">
                      Retiro en Efectivo
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </DialogContent>
          </Dialog>

          <Dialog open={transferInfo} onClose={transferInfoClose}>
            <DialogTitle style={{color: "blue"}}>CUENTA BANCARIA</DialogTitle>
            <DialogContent>
                <Typography variant="body2" style={{whiteSpace: 'pre-line', color: "GrayText"}} >
                    {bankAccountExp}
                 </Typography>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <FormControl disabled={bankCodeDisabled} className={classes.formControl} fullWidth>
                            <InputLabel id="bank">Escoge tu banco</InputLabel>
                            <Select
                                labelId="Escoge tu banco"
                                id="bank"
                                value={bankName}
                                name="bank"
                                onChange={changeBankCode}
                            >
                                {bankCodes.map((bankCode) => {
                                    return(
                                        <MenuItem key ={bankCode.code} value = {bankCode}>
                                            {`${bankCode.bank}`} 
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                            
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <FormControl disabled={bankAccountTypeDisabled} className={classes.formControl} fullWidth>
                            <InputLabel id="bankAccountType">Tipo de cuenta</InputLabel>
                            <Select
                                required
                                labelId="Tipo de cuenta"
                                id="bankAccountType"
                                value={bankAccountType}
                                name="bankAccountType"
                                onChange={(e) => setBankAccountType(e.target.value)}
                            >
                                <MenuItem value={7}>Cuenta de Ahorros</MenuItem>
                                <MenuItem value={1}>Cuenta Corriente</MenuItem>
                                <MenuItem value={9}>Depósito Electrónico</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            disabled={bankAccountNumberDisabled}
                            style={{marginTop: "1rem"}}
                            id="bankAccount"
                            name="bankAccount"
                            label="Ingresa tu cuenta bancaria"
                            fullWidth
                            // autoComplete="given-name"
                            onChange={(e) => setBankAccountNumber(e.target.value)}
                            value={bankAccountNumber}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={transferInfoClose} color="primary">
                    ATRÁS
                </Button>
                <Button 
                    onClick={handleBankAccount} color="grey" 
                    disabled={transferDisabled}>
                    CONTINUAR
                </Button>
            </DialogActions>
          </Dialog>
          
          </>
        </>
    )
}
