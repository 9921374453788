import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import EmailImage from "../../../img/redirects/email-image.png";
import { Typography, Button } from '@mui/material';
import {changeDealValue} from "../../../utilityFunctions/DealsUtil";
import swal from "sweetalert";
import NumberFormat from "react-number-format";
import TextField from '@mui/material/TextField';
import {useLocation, useHistory} from "react-router-dom";

const dealPotentialValue = {
    id: "",
    potentialValue: "",
};

export default function FormAction() {

    let history = useHistory();

    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search);

    const[projectValue, setProjectValue] = React.useState("");

    const handleChange = (event) => {
        dealPotentialValue.potentialValue = event.floatValue;
        setProjectValue(event.floatValue);
    };

    const submitValue = async () => {
        console.log(queryParameters.get("id"))
        dealPotentialValue.id = queryParameters.get("id");

        const changeResult = await changeDealValue(dealPotentialValue);

        if(changeResult !== "Not found"){

            swal({
                title: "Gracias",
                text: "Ya puedes cerrar esta ventana",
                icon: "success",
                button: "Entiendo",
            }).then(() => {
                history.push({pathname: "/"});
            });
        }else{
            swal({
                title: "Hubo un error",
                icon: "error",
                button: "Entiendo",
            });
        }
    };

    return (
        <Box
            sx={{
            display: "flex",
            // flexGrow: "1",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            // backgroundColor: "#ededed",
            }}
        >
            <Grid container sx={{height: "70%", alignItems: "center", justifyContent: "center"}}>
                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center",}}>
                    <Box 
                        component="img"
                        sx={{
                        height: "25%",
                        width: "30%",
                        }}
                        alt="logo"
                        src={EmailImage} 
                    >
                        
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} sx={{ display: "flex", justifyContent: "center", marginTop: "3rem"}}>
                    <NumberFormat
                        variant="standard"
                        style={{width: "20%", height: "50%"}}
                        name="projectValue"
                        customInput={TextField}
                        prefix={'$ '}
                        type="text"
                        label={<Typography variant="body2">Valor estimado del proyecto</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete="off"
                        value={projectValue}
                        onValueChange={(values) => {
                            // const {formattedValue, value, floatValue} = values;
                            handleChange(values, "realStateNumber");
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", marginTop: "2rem"}}>
                    <Button onClick={submitValue} sx={{backgroundColor: "#E2062C", color: "white"}}>
                        Interesado
                    </Button>
                </Grid> 
                <Grid item xs={12} sm={12} md={12} sx={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
                    <Typography variant="h4" sx={{fontWeight: "bold"}}>GRACIAS</Typography>
                </Grid>
            </Grid>
        </Box>
        )
}
