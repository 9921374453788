const privacyConsent = 
`
Este texto es la autorización de tratamiento de datos de Wupo. Eso significa que acá te explicamos cómo y para qué vamos a usar tus datos personales. Estas autorizaciones existen porque tu información personal es tuya y los terceros (incluyéndonos) solo la podemos usar si aceptas. Te pedimos tu autorización oprimiendo el botón acepto para poder usar tus datos personales como lo explicamos a continuación

Hay dos tipos de usos que le vamos a dar a tus datos. El primero es para soportar las actividades necesarias para prestarte nuestros servicios y el segundo para ofrecerte productos y servicios que se adaptan a tus necesidades y que consideramos te van a facilitar y alegrar la vida. Estos usos están divididos en el propósito del uso (finalidad) y la forma en la que los procesamos para ese propósito (tratamiento). A continuación, los describimos:

Finalidades:
i)	Verificación de identidad: Necesitamos saber quién eres para vincularte y ejecutar tus operaciones.
ii)	Ejecución de las actividades que soportan los productos y servicios que tienes con nosotros. 
iii)	Evaluación de riesgos: Incluyendo lavado de dinero, fraude y riesgos operativos. También riesgo financiero. 
iv)	Tenemos proveedores que nos prestan servicios para ser más eficientes. En caso de requerirse, estos terceros pueden procesar tus datos bajo nuestra responsabilidad (a esto se le conoce como transmisión). Tenemos proveedores de almacenamiento y procesamiento en la nube, evaluación de riesgos, biometría y otras actividades fundamentales para la prestación de nuestros servicios.
v)	Perfilamiento y ofrecimiento comercial: Para nosotros es muy importante entenderte y conocer tus necesidades para poder ofrecerte productos y servicios que realmente necesites y que te aporten a disfrutar la vida. Si no estás feliz con nuestros ofrecimientos comerciales, en cualquier momento nos puedes decir que dejemos de hacerlos (revocar tus datos).
vi)	Utilice cookies propias y de terceros para obtener datos de mi navegación, con el objetivo de ofrecerme un servicio personalizado y para obtener datos estadísticos de la navegación.  


Nos falta explicarte cómo vamos a lograr lo anterior. Lo hacemos procesando tus datos de las siguientes formas: capturándolos, comprobándolos, analizándolos, monitoreándolos, ordenándolos, actualizándolos, combinándolos, asociándolos y dividiéndolos. Todos estos verbos son los tipos de tratamientos específicos y los incluimos para que sepas exactamente lo que hacemos. 

Adicionalmente, para prestarte un mejor servicio podemos consultar bases de terceros legítimas.  

Como eres el dueño de tus datos tienes un conjunto de derechos que incluimos a continuación: i) Conocer, actualizar y rectificar tu información, ii) Solicitar prueba de la autorización, iii) ser informado sobre el uso de tus datos, iv) Presentar quejas ante los reguladores, v) Revocar la autorización y/o solicitar la eliminación de tus datos.

Recuerda que esta autorización la estás dando a Wupo S.A.S., dirección electrónica www.wupealo.com y teléfono: 313 8252848.  

`;

export {privacyConsent}

export default privacyConsent;