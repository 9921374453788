import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Colors from "../components/ui/Colors";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Card from '@mui/material/Card';
import NumberFormat from "react-number-format";
import TextField from '@mui/material/TextField';
import { Button, Typography } from "@mui/material";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import { useTranslation } from 'react-i18next';
import {qrPayment} from "../utilityFunctions/OpenCardUtil";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const qrRequest = {
    value: "",
    username: "",
};

export default function QrGenerator(props) {

    const {loginUser} = props;

    const [loading, setLoading] = React.useState(false);

    const { t } = useTranslation();

    const [purchaseValue, setPurchaseValue] = React.useState("");

    const valueChange = (e) => {
        setPurchaseValue(e.floatValue);
        qrRequest.value = e.floatValue * 100; // Multiplies by 100 to consider the configuration of bankbu
    };

    const [qrImg, setQrImg] = React.useState("");

    const generateQr = async () => {
        qrRequest.username=loginUser.accountId;
        setLoading(true);
        const qrResponse = await qrPayment(qrRequest, loginUser.jwtToken);
        setQrImg(qrResponse);
        setLoading(false);
    };

    React.useEffect(()=> {
        if(qrImg){
            setQrBox(true);
        }
    }, [qrImg]);

    const[qrBox, setQrBox] = React.useState(false);

    const closeQrBox = () => {
        setQrBox(false);
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center",  width: "100%", height: "100%", backgroundColor: "#ededed",}}>
            <Box
            sx={{
                display: "flex",
                // flex: 1,
                justifyContent: "center",
                alignItems: {xs: "center", sm: "center", md: "center"},
                width: "90%",
                height: "100%",
                textAlign: "center",
            }}
            >
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
            <>
                <Grid container spacing={2} sx={{display: "flex", justifyContent: {xs: "center", sm: "center"}, alignItems: "center", width: "100%"}}>
                    <Card sx={{maxWidth: "400px", minWidth: {xs: "90%", sm: "90%", md: "300px", borderRadius: "15px"}}}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1rem", marginBottom: "1rem"}}>
                                <NumberFormat
                                    variant="standard"
                                    style={{width: "80%", height: "90%"}}
                                    name="purchaseValue"
                                    customInput={TextField}
                                    prefix={'$ '}
                                    type="purchaseValue"
                                    label={<Typography variant="body2">{t("QrGenerator.purchaseValue")}</Typography>}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    autoComplete="off"
                                    value={purchaseValue}
                                    onValueChange={(values) => {
                                        valueChange(values);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", marginTop: "3rem", marginBottom: "1rem"}}>
                                <Button onClick={generateQr} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                                    {t("QrGenerator.generate")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Dialog open={qrBox} onClose={closeQrBox}>
                    <Grid container>
                    {qrImg &&
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1rem", width: "100%"}}>
                        <Box 
                            component="img"
                            sx={{
                            height: "100%",
                            // maxHeight: "200px",
                            width: "100%",
                            justifyContent: "center",
                            }}
                            alt="QR"
                            src={URL.createObjectURL(qrImg)}

                        />
                        </Grid>
                    }
                    </Grid>
                    <DialogActions sx={{display: "flex", justifyContent: "center"}}>
                        <Button onClick={closeQrBox} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
            }
            </Box>
        </Box>

    )
}
