import React from 'react';
import { getAllUsernames, getBankAccountDocs, enableBankAccount, getAccountPendingUsernames } from '../../../utilityFunctions/MarketPlaceUtil';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button} from '@mui/material';
import Colors from "../../../components/ui/Colors";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import swal from "sweetalert";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const docsUser = {
    username: "",
};

const enablerObject = {
    id: "",
};

let usernamesArray;

export default function DocsValidation(props) {
    const{loginUser, usernamesArrayledgerAccounts} = props;

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        docsUser.username="";
        enablerObject.id="";

        getUsernames();
    }, []);

    const getUsernames = async () => {
        setLoading(true);
        // usernamesArray = await getAllUsernames(loginUser.jwtToken);
        usernamesArray = await getAccountPendingUsernames(loginUser.jwtToken);

        if(usernamesArray){
            setUsersList(
                {
                    options: usernamesArray,
                    getOptionLabel: (option) => option.username,
                }
            );
        }
        setLoading(false);
    };

    const [usersList, setUsersList] = React.useState();

    const onChangeAutocomplete = (event, value) => {
        console.log(value);
        docsUser.username = value.username
    };

    const [certification, setCertification] = React.useState();
    const [idCopy, setIdCopy] = React.useState();

    const getDocs = async () => {
        setLoading(true);
        const userBankAccount = await getBankAccountDocs(docsUser, loginUser.jwtToken);

        console.log(userBankAccount);

        // if( userBankAccount.bankAccountDocs is an array)
        if(!userBankAccount){
            swal({
                title: "No Existe",
                text: "El cliente no tiene cuentas activas",
                icon: "info",
                button: "Entiendo",
            });
            setLoading(false);
            return; 
        }

            if(userBankAccount.bankAccountDocs === 0){
                swal({
                    title: "error",
                    text: "El cliente no tiene documentos",
                    icon: "error",
                    button: "Entiendo",
                });
                setLoading(false);
                return; 
            }

            enablerObject.id = userBankAccount.id;

            userBankAccount.bankAccountDocs.forEach((doc) => {
                if(doc.documentType === "accountCertification"){
                    const byteImage = Buffer.from(doc.document, 'base64');
                    setCertification( new Blob([byteImage], {type: "application/octet-stream"}));
                }

                if(doc.documentType === "idCopy"){
                    const byteImage = Buffer.from(doc.document, 'base64');
                    setIdCopy( new Blob([byteImage], {type: "application/octet-stream"}));
                }
            });

            setLoading(false);

            swal({
                title: "Busqueda Exitosa",
                icon: "success",
                button: "Súper",
            });
            setLoading(false);
       

    };

    const [certificationBox, setCertificationBox] = React.useState(false);
    const [idBox, setIdBox] = React.useState(false);

    const openCertificationBox = () => {
        setIdBox(false);
        setCertificationBox(true);
    };

    const closeCertificationBox = () => {
        setCertificationBox(false);
    };

    const openIdBox = () => {
        setCertificationBox(false);
        setIdBox(true);
    };

    const closeIdBox = () => {
        setIdBox(false);
    };

    const enableAccount = async () => {
        setLoading(true);
        const accountEnabled = await enableBankAccount(enablerObject, loginUser.jwtToken);

        if(!accountEnabled){
            swal({
                title: "error",
                text: "No se activó la cuenta, vuelve a intentarlo",
                icon: "error",
                button: "Listo",
            });
            setLoading(false);
            return; 
        }

        swal({
            title: "Cuenta Activa",
            icon: "success",
            button: "Súper",
        });

        setLoading(false);
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                }}
            >
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
            <Grid container direction="row" spacing={1} sx={{width: { xs: "95%", sm: "80%", md: "40%"}}}>
                <Grid item xs={12} sm={12} md={12}>
                    <Autocomplete
                        {...usersList}
                        id="usersList"
                        clearOnEscape
                        autoComplete = {true}
                        noOptionsText = {"Usuario no existe"}
                        // sx={{ width: 600 }}
                        onChange={onChangeAutocomplete}
                        // onInputChange={onChange}
                        renderInput={(params) => (
                            <TextField {...params} label={<Typography variant="body2">Correo electrónico</Typography>} variant="standard" />
                        )}
                    /> 
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <Button onClick={getDocs} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                        Buscar
                    </Button>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <Button onClick={enableAccount} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                        Activar
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Button onClick={openCertificationBox} sx={{marginTop: "2rem", backgroundColor: "none", color: "gray", fontSize: "1rem", fontWeight: "bold"}}>
                        Certificación
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Button onClick={openIdBox} sx={{marginTop: "2rem", backgroundColor: "none", color: "gray", fontSize: "1rem", fontWeight: "bold"}}>
                        Identificación
                    </Button>
                </Grid>
            </Grid>
            }
            </Box>
            <Dialog open={certificationBox} onClose={closeCertificationBox}>
                <DialogContent>
                {certification ? 
                <Box 
                    component="img"
                    sx={{
                    height: "90%",
                    width: "100%",
                    justifyContent: "center",
                    }}
                    alt="Foto"
                    src={URL.createObjectURL(certification)}
                >
                </Box>
                :
                <></>
                }
                </DialogContent>
                <DialogActions sx={{display: "flex", justifyContent: "center"}}>
                    <Button onClick={closeCertificationBox} variant="contained" sx={{backgroundColor: Colors.primary}}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={idBox} onClose={closeIdBox}>
                <DialogContent>
                {idCopy ? 
                <Box 
                    component="img"
                    sx={{
                    height: "90%",
                    width: "100%",
                    justifyContent: "center",
                    }}
                    alt="Foto"
                    src={URL.createObjectURL(idCopy)}
                >
                </Box>
                :
                <></>
                }
                </DialogContent>
                <DialogActions sx={{display: "flex", justifyContent: "center"}}>
                    <Button onClick={closeIdBox} variant="contained" sx={{backgroundColor: Colors.primary}}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            
        </Box>
    )
}
