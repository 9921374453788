import React from 'react';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Colors from "../../components/ui/Colors";
import NumberFormat from "react-number-format";
import swal from "sweetalert";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import {getScoreRange, createScoreRange, updateScoreRange} from "../../utilityFunctions/OpenCardUtil"

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const scoreRangeRequest = {};

export default function ScoreRangeUpdate(props) {

    const {loginUser} = props;

    const [loading, setLoading] = React.useState(false);

    const handleOptions = [
        {label: "Actualizar", value: "upd"}, 
        {label: "Nuevo Rango", value: "new"}, 
    ];

    const[selectedOption, setSelectedOption] = React.useState("");

    const selectedOptionChange = (e) => {
        setCurrentRanges("");
        clearStates();

        setSelectedOption(e.target.value);

        if(e.target.value === "upd"){
            initalValues();
            return;
        };


    };

    const [currentRanges, setCurrentRanges] = React.useState("");

    const[code, setCode] = React.useState('');
    const[minScore, setMinScore] = React.useState('');
    const[maxScore, setMaxScore] = React.useState('');
    const[principal, setPrincipal] = React.useState('');
    const[bin, setBin] = React.useState('');
    const[product, setProduct] = React.useState('');
    const[affinityGroup, setAffinityGroup] = React.useState('');

    const initalValues = async () => {

        setLoading(true);
        const response = await getScoreRange(loginUser.jwtToken);
        setLoading(false);

        if(!response){
            return;
        }

        setCurrentRanges(response);

    };

    const updateCodeChange = (e) => {
        setCode(e.target.value);
        scoreRangeRequest.code = e.target.value;

        selectedRangeValues(e.target.value);
    };

    const selectedRangeValues = (selectedCode) => {
        const selectedRange = currentRanges.find((el) => el.code === selectedCode);

        setMinScore(selectedRange.minScore);
        setMaxScore(selectedRange.maxScore);
        setPrincipal(selectedRange.principal);
        setBin(selectedRange.bin);
        setProduct(selectedRange.product);
        setAffinityGroup(selectedRange.affinityGroup);

        scoreRangeRequest.code = selectedRange.code;
        scoreRangeRequest.minScore = selectedRange.minScore;
        scoreRangeRequest.maxScore = selectedRange.maxScore;
        scoreRangeRequest.principal = selectedRange.principal;
        scoreRangeRequest.bin = selectedRange.bin;
        scoreRangeRequest.product = selectedRange.product;
        scoreRangeRequest.affinityGroup = selectedRange.affinityGroup;
        
    };

    const codeChange = (e) => {
        setCode(e.target.value);
        scoreRangeRequest.code = e.target.value;
    };

    const minScoreChange = (e) => {
        setMinScore(e.floatValue);
        scoreRangeRequest.minScore = e.floatValue;
    };

    const maxScoreChange = (e) => {
        setMaxScore(e.floatValue);
        scoreRangeRequest.maxScore = e.floatValue;
    };

    const principalChange = (e) => {
        setPrincipal(e.floatValue);
        scoreRangeRequest.principal = e.floatValue;
    };

    const binChange = (e) => {
        setBin(e.target.value);
        scoreRangeRequest.bin = e.target.value;
    };

    const productChange = (e) => {
        setProduct(e.target.value);
        scoreRangeRequest.product = e.target.value;
    };

    const affinityGroupChange = (e) => {
        setAffinityGroup(e.target.value);
        scoreRangeRequest.affinityGroup = e.target.value;
    };

    const newScoreRange = async () => {

        setLoading(true);
        const response = await createScoreRange(scoreRangeRequest, loginUser.jwtToken);
        setLoading(false);

        if(!response){
            swal({
                title: "Error",
                text: "Error al crear el rango de score",
                icon: "error",
                button: "Ok",
            });
            return;
        }

        swal({
            title: "Rango de score creado exitosamente",
            icon: "success",
            button: "Ok",
        });
        clearStates();
    };

    const updScoreRange = async () => {
          
        setLoading(true);
        const response = await updateScoreRange(scoreRangeRequest, loginUser.jwtToken);
        setLoading(false);

        if(!response){
            swal({
                title: "Error",
                text: "Error al actualizar el rango de score",
                icon: "error",
                button: "Ok",
            });
            return;
        }

        swal({
            title: "Rango de score actualizado exitosamente",
            icon: "success",
            button: "Ok",
        });
        clearStates();
        initalValues();
    };

    const clearStates = () => {

        setCode('');
        setMinScore('');
        setMaxScore('');
        setPrincipal('');
        setBin('');
        setProduct('');
        setAffinityGroup('');

        scoreRangeRequest.code = '';
        scoreRangeRequest.minScore = '';
        scoreRangeRequest.maxScore = '';
        scoreRangeRequest.principal = '';
        scoreRangeRequest.bin = '';
        scoreRangeRequest.product = '';
        scoreRangeRequest.affinityGroup = '';
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                    height: "90%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: "1.5rem"},
                }}
            >
                {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                            <TextField
                                sx={{textAlign: "start", width: {xs: "90%", sm: "70%", md: "50%"}}}
                                variant="standard"
                                fullWidth={true}
                                id="employmentType"
                                name="employmentType"
                                select
                                label={<Typography variant="body2">Selecciona la opción</Typography>}
                                value={selectedOption}
                                onChange={(e)=>selectedOptionChange(e)}
                                autoComplete="off"
                            >
                                {handleOptions.map((el) => (
                                    <MenuItem key={el.value} value={el.value}>
                                        {el.label}
                                    </MenuItem>
                                ))}
                            </TextField> 
                        </Grid>
                        {currentRanges &&
                            <>
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                <TextField
                                    sx={{textAlign: "start", width: {xs: "90%", sm: "70%", md: "50%"}}}
                                    variant="standard"
                                    fullWidth={true}
                                    id="code"
                                    name="code"
                                    select
                                    label={<Typography variant="body2">Rango a Actualizar</Typography>}
                                    value={code}
                                    onChange={(e)=>updateCodeChange(e)}
                                    autoComplete="off"
                                >
                                    {currentRanges.map((el) => (
                                        <MenuItem key={el.id} value={el.code}>
                                            {el.code}
                                        </MenuItem>
                                    ))}
                                </TextField> 
                            </Grid>
                            {code && 
                                <Grid item xs={12} sm={6} md={6}>
                                    <NumberFormat
                                        variant="standard"
                                        style={{width: "100%", height: "90%"}}
                                        name="minScore"
                                        customInput={TextField}
                                        // prefix={'$ '}
                                        // suffix={' meses'}
                                        type="minScore"
                                        label={<Typography variant="body2">Score mínimo</Typography>}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                        autoComplete="off"
                                        value={minScore}
                                        onValueChange={(values) => {
                                            minScoreChange(values);
                                        }}
                                    />
                                </Grid> 
                            }
                            {code && 
                                <Grid item xs={12} sm={6} md={6}>
                                    <NumberFormat
                                        variant="standard"
                                        style={{width: "100%", height: "90%"}}
                                        name="maxScore"
                                        customInput={TextField}
                                        // prefix={'$ '}
                                        // suffix={' meses'}
                                        type="maxScore"
                                        label={<Typography variant="body2">Score máximo</Typography>}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                        autoComplete="off"
                                        value={maxScore}
                                        onValueChange={(values) => {
                                            maxScoreChange(values);
                                        }}
                                    />
                                </Grid> 
                            }
                            {code && 
                                <Grid item xs={12} sm={6} md={6}>
                                    <NumberFormat
                                        variant="standard"
                                        style={{width: "100%", height: "90%"}}
                                        name="principal"
                                        customInput={TextField}
                                        // prefix={'$ '}
                                        // suffix={' meses'}
                                        type="principal"
                                        label={<Typography variant="body2">Cupo Producto</Typography>}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                        autoComplete="off"
                                        value={principal}
                                        onValueChange={(values) => {
                                            principalChange(values);
                                        }}
                                    />
                                </Grid> 
                            }
                            {code && 
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextField
                                        variant="standard"
                                        fullWidth={true}
                                        id="bin"
                                        name="bin"
                                        label={<Typography variant="body2">BIN Producto</Typography>}
                                        value={bin}
                                        onChange={(e)=> binChange(e)}
                                        autoComplete="off"
                                    />
                                </Grid>
                            }
                            {code && 
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextField
                                        variant="standard"
                                        fullWidth={true}
                                        id="product"
                                        name="product"
                                        label={<Typography variant="body2">Producto</Typography>}
                                        value={product}
                                        onChange={(e)=> productChange(e)}
                                        autoComplete="off"
                                    />
                                </Grid>
                            }
                            {code && 
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextField
                                        variant="standard"
                                        fullWidth={true}
                                        id="affinityGroup"
                                        name="affinityGroup"
                                        label={<Typography variant="body2">Grupo Afinidad</Typography>}
                                        value={affinityGroup}
                                        onChange={(e)=> affinityGroupChange(e)}
                                        autoComplete="off"
                                    />
                                </Grid>
                            }
                            <Grid item xs={12} sm={12} md={12}>
                                <Button onClick={updScoreRange} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                    Actualizar
                                </Button>
                            </Grid>
                            </>
                        }
                        {selectedOption && selectedOption === "new" &&
                            <>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField
                                    variant="standard"
                                    fullWidth={true}
                                    id="code"
                                    name="code"
                                    label={<Typography variant="body2">Código Rango</Typography>}
                                    value={code}
                                    onChange={(e)=> codeChange(e)}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <NumberFormat
                                    variant="standard"
                                    style={{width: "100%", height: "90%"}}
                                    name="minScore"
                                    customInput={TextField}
                                    // prefix={'$ '}
                                    // suffix={' meses'}
                                    type="minScore"
                                    label={<Typography variant="body2">Score mínimo</Typography>}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    autoComplete="off"
                                    value={minScore}
                                    onValueChange={(values) => {
                                        minScoreChange(values);
                                    }}
                                />
                            </Grid> 
                            <Grid item xs={12} sm={6} md={6}>
                                <NumberFormat
                                    variant="standard"
                                    style={{width: "100%", height: "90%"}}
                                    name="maxScore"
                                    customInput={TextField}
                                    // prefix={'$ '}
                                    // suffix={' meses'}
                                    type="maxScore"
                                    label={<Typography variant="body2">Score máximo</Typography>}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    autoComplete="off"
                                    value={maxScore}
                                    onValueChange={(values) => {
                                        maxScoreChange(values);
                                    }}
                                />
                            </Grid> 
                            <Grid item xs={12} sm={6} md={6}>
                                <NumberFormat
                                    variant="standard"
                                    style={{width: "100%", height: "90%"}}
                                    name="principal"
                                    customInput={TextField}
                                    // prefix={'$ '}
                                    // suffix={' meses'}
                                    type="principal"
                                    label={<Typography variant="body2">Cupo Producto</Typography>}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    autoComplete="off"
                                    value={principal}
                                    onValueChange={(values) => {
                                        principalChange(values);
                                    }}
                                />
                            </Grid> 
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField
                                    variant="standard"
                                    fullWidth={true}
                                    id="bin"
                                    name="bin"
                                    label={<Typography variant="body2">BIN Producto</Typography>}
                                    value={bin}
                                    onChange={(e)=> binChange(e)}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField
                                    variant="standard"
                                    fullWidth={true}
                                    id="product"
                                    name="product"
                                    label={<Typography variant="body2">Producto</Typography>}
                                    value={product}
                                    onChange={(e)=> productChange(e)}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField
                                    variant="standard"
                                    fullWidth={true}
                                    id="affinityGroup"
                                    name="affinityGroup"
                                    label={<Typography variant="body2">Grupo Afinidad</Typography>}
                                    value={affinityGroup}
                                    onChange={(e)=> affinityGroupChange(e)}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Button onClick={newScoreRange} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                    Cargar
                                </Button>
                            </Grid>
                            </>

                        }
                    </Grid>
                }
                
            </Box>
        </Box>
    )
}
