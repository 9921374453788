import React from 'react';
import {getPendingPayments} from "../../../utilityFunctions/MarketPlaceUtil";
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Colors from "../../../components/ui/Colors";
import TextField from '@mui/material/TextField';
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import { useHistory} from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const dateOptions = {
    // weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
  };

let soldCattleToPay = {
    code: "",
    id: "",
};

let enableSoldList = true;

let selectedCattleSold = "";

export default function ManualPayments(props) {

    const{loginUser} = props;

    const history = useHistory();

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        enableSoldList = true;
        selectedCattleSold = "";
        getInvestmentsToPay();
    },[])

    const [cattles, setCattles] = React.useState();
    const [settledInvestments, setSettledInvestments] = React.useState();
 
    const getInvestmentsToPay = async () => {
        setLoading(true);
        const cattlesPending = await getPendingPayments(loginUser.jwtToken);

        setCattles(
            {
                options: cattlesPending,
                getOptionLabel: (option) => option.code,
            }
        );

        setLoading(false);

    };

    const onChangeAutocomplete = (event, value) => {
        console.log(value);
        soldCattleToPay.code = value.code;
        
        setSettledInvestments(
            {
                options: value.cattleLotSelling,
                getOptionLabel: (option) => new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(option.date)),
            }
        );

        enableSoldList = false;

    };

    const onChangeSelling = (event, value) => {
        console.log(value);
        soldCattleToPay.id = value.id;

        selectedCattleSold = value;
    };

    const goToDetail = () => {
        

        history.push({ 
            pathname: "/settlement/payments-detail",
            state:{
                cattleSold: selectedCattleSold,
            }
        });

    }
    
    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
             <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                    height: "100%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                }}
            >
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Autocomplete
                            {...cattles}
                            id="cattles"
                            clearOnEscape
                            autoComplete = {true}
                            noOptionsText = {"Lote no existe"}
                            // sx={{ width: 600 }}
                            onChange={onChangeAutocomplete}
                            // onInputChange={onChange}
                            renderInput={(params) => (
                                <TextField {...params} label={<Typography variant="body2">Código del lote</Typography>} variant="standard" />
                            )}
                        /> 
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Autocomplete
                            disabled={enableSoldList}
                            {...settledInvestments}
                            id="settledInvestments"
                            clearOnEscape
                            autoComplete = {true}
                            noOptionsText = {"Venta no existe"}
                            // sx={{ width: 600 }}
                            onChange={onChangeSelling}
                            // onInputChange={onChange}
                            renderInput={(params) => (
                                <TextField {...params} label={<Typography variant="body2">Fecha venta</Typography>} variant="standard" />
                            )}
                        /> 
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Button onClick={goToDetail} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                            Buscar
                        </Button>
                    </Grid>
                </Grid>
            }
            </Box>
        </Box>
    )
}
