import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Colors from "../components/ui/Colors";
import { Typography } from '@mui/material';
import {loansBatchUpload, paymentsBatchUpload, definitionsUpload } from "../utilityFunctions/OpenCardUtil";

export default function UploadLoanInfo(props) {

    const {loginUser} = props;

    const[creditsFile, setCreditsFile] = React.useState(null);
    const[paymentsFile, setPaymentsFile] = React.useState(null);
    const[definitionsFile, setDefinitionsFile] = React.useState(null);

    const uploadLoans = (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }

        setCreditsFile(e.target.files[0]);
    };

    

    const uploadPayments = (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }

        setPaymentsFile(e.target.files[0]);
    };

    const uploadDefinitions = (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }

        setDefinitionsFile(e.target.files[0]);
    };

    const deleteCredits = () => {
        setCreditsFile(null);
        
    };

    const deletePayments = () => {
        setPaymentsFile(null);
    };

    const deleteDefinitions = () => {
        setDefinitionsFile(null);
    }

    const submitLoanFile = async () => {
        const formData  = new FormData();

        formData.append("loansFile", creditsFile);

        await loansBatchUpload(formData, loginUser.jwtToken);

    };

    const submitPaymentsFile = async () => {
        const formData  = new FormData();

        formData.append("paymentsFile", paymentsFile);

        await paymentsBatchUpload(formData, loginUser.jwtToken);
    };

    const submitDefinitonsFile = async () => {
        const formData  = new FormData();

        // const definitionsBlob = new Blob([definitionsFile], {
        //     type: "text/csv"
        // });

        formData.append("definitionsFile", definitionsFile);
        // formData.append("definitionsFile", definitionsBlob);

        await definitionsUpload(formData, loginUser.jwtToken);
    };


    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                    height: "100%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                }}
            >
                <Grid container direction="row" spacing={2} sx={{width: { xs: "95%", sm: "80%", md: "40%"}}}>
                    {!creditsFile &&
                        <Grid item xs={12} sm={12} md={12}>
                            <Button
                                sx={{marginTop: "2rem", backgroundColor: "none", color: "gray", fontSize: "0.88rem", fontWeight: "bold"}}
                                component="label"
                                >
                                Créditos
                                <input
                                    type="file"
                                    hidden
                                    onChange={(event) => {
                                        console.log(event.target.files[0]);
                                        uploadLoans(event);
                                    }}
                                />
                            </Button>
                        </Grid>
                    }
                    {creditsFile &&               
                        <Grid item xs={12} sm={12} md={12}>
                            <Grid container spacing={0} sx={{justifyContent: "center"}}>
                                <Grid item xs={12} sm={12} md={12} sx={{textAlign: "center"}}>
                                    <Typography variant="body2" sx={{color: "black", fontWeight: "bold"}}>{creditsFile.name}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                    <Button onClick={deleteCredits}  component="label" sx={{marginTop: "0rem", backgroundColor: "none", color: "gray", fontSize: "0.8rem"}}>
                                        Borrar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    {!paymentsFile &&
                        <Grid item xs={12} sm={12} md={12}>
                            <Button
                                sx={{marginTop: "2rem", backgroundColor: "none", color: "gray", fontSize: "0.88rem", fontWeight: "bold"}}
                                component="label"
                                >
                                Pagos
                                <input
                                    type="file"
                                    hidden
                                    onChange={(event) => {
                                        console.log(event.target.files[0]);
                                        uploadPayments(event);
                                    }}
                                />
                            </Button>
                        </Grid>
                    }
                    {paymentsFile &&               
                        <Grid item xs={12} sm={12} md={12}>
                            <Grid container spacing={0} sx={{justifyContent: "center"}}>
                                <Grid item xs={12} sm={12} md={12} sx={{textAlign: "center"}}>
                                    <Typography variant="body2" sx={{color: "black", fontWeight: "bold"}}>{paymentsFile.name}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                    <Button onClick={deletePayments}  component="label" sx={{marginTop: "0rem", backgroundColor: "none", color: "gray", fontSize: "0.8rem"}}>
                                        Borrar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    {!definitionsFile &&
                        <Grid item xs={12} sm={12} md={12}>
                            <Button
                                sx={{marginTop: "2rem", backgroundColor: "none", color: "gray", fontSize: "0.88rem", fontWeight: "bold"}}
                                component="label"
                                >
                                Definiciones
                                <input
                                    type="file"
                                    hidden
                                    onChange={(event) => {
                                        console.log(event.target.files[0]);
                                        uploadDefinitions(event);
                                    }}
                                />
                            </Button>
                        </Grid>
                    }
                    {definitionsFile &&               
                        <Grid item xs={12} sm={12} md={12}>
                            <Grid container spacing={0} sx={{justifyContent: "center"}}>
                                <Grid item xs={12} sm={12} md={12} sx={{textAlign: "center"}}>
                                    <Typography variant="body2" sx={{color: "black", fontWeight: "bold"}}>{definitionsFile.name}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                    <Button onClick={deleteDefinitions}  component="label" sx={{marginTop: "0rem", backgroundColor: "none", color: "gray", fontSize: "0.8rem"}}>
                                        Borrar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    {(creditsFile) &&
                        <Grid item xs={12} sm={12} md={12}>
                            <Button onClick={submitLoanFile} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                Cargar Créditos
                            </Button>
                        </Grid>
                    }
                    {paymentsFile &&
                        <Grid item xs={12} sm={12} md={12}>
                            <Button onClick={submitPaymentsFile} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                Cargar Pagos
                            </Button>
                        </Grid>
                    }
                    {definitionsFile &&
                        <Grid item xs={12} sm={12} md={12}>
                            <Button onClick={submitDefinitonsFile} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                Cargar Condiciones
                            </Button>
                        </Grid>
                    }
                </Grid>
            </Box>
        </Box>
    )
}
