import React from 'react';
import { financialDocsSubimit } from '../utilityFunctions/CreditUtil';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button} from '@mui/material';
import Colors from "../components/ui/Colors";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const financialObject = {
    username: null,
    documentedIncome: null,
};

const maxSize = 1024 * 1024; // 1 MB

export default function ClientDocsUpload(props) {

    const{loginUser} = props;

    const history = useHistory();

    const [loading, setLoading] = React.useState(false);

    const[employmentCert, setEmploymentCert] = React.useState(null);
    const[incomeCert, setIncomeCert] = React.useState(null);

    const employmentChange = (e) => {

        if(!e.target.files[0]){
            return;
        }

        if(e.target.files[0].size > maxSize){
            swal({
                title: "Tamaño excedido",
                text: "El documento cargado debe ser de máximo 1 Megabyte.",
                icon: "info",
                button: "Ok",
            });
            return;
        }

        setEmploymentCert(e.target.files[0]);

    };

    const deleteEmployementCert = () => {
        setEmploymentCert(null);
    };

    const incomeChange = (e) => {

        if(!e.target.files[0]){
            return;
        }

        if(e.target.files[0].size > maxSize){
            swal({
                title: "Tamaño excedido",
                text: "El documento cargado debe ser de máximo 1 Megabyte.",
                icon: "info",
                button: "Ok",
            });
            return;
        }

        setIncomeCert(e.target.files[0]);
    };

    const deleteIncomeCert = () => {
        setIncomeCert(null);
    };

    const submitFinancialDocs = async () => {

        if(!employmentCert || !incomeCert){
            swal({
                title: "Datos Incompletos",
                text: "Debes cargar todos los datos, incuyendo los documentos",
                icon: "info",
                button: "Entiendo",
            });
            setLoading(false);
            return; 
        }

        financialObject.username = loginUser.accountId;

        const formData  = new FormData();

        const json = JSON.stringify(financialObject);

        const docsRequest = new Blob([json], {
            type: 'application/json'
        });

        formData.append("docsRequest", docsRequest);

        formData.append("employmentCert", employmentCert);
        formData.append("incomeCert", incomeCert);

        setLoading(true);
        const docsResponse = await financialDocsSubimit(formData, loginUser.jwtToken);
        setLoading(false);

        if(!docsResponse){
            swal({
                title: "Error",
                text: "Hubo un error al cargar tus información",
                icon: "error",
                button: "ok",
            });
            return; 
        }

        swal({
            title: "Documentos Cargados",
            text: "Tus documentos fueron cargados y serán revisados",
            icon: "success",
            button: "Súper",
        }).then(()=> {
            history.push("/account/create_credit");
        });

    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                    height: "100%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                }}
            >
                {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                    <Grid container direction="row" spacing={2} sx={{width: { xs: "95%", sm: "80%", md: "40%"}}}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography variant="h4" sx={{color: "black", fontWeight: "bold"}}>Soportes Financieros</Typography>
                        </Grid>
                        {!employmentCert &&
                            <Grid item xs={12} sm={6} md={6}>
                                <Button
                                    sx={{marginTop: "2rem", backgroundColor: "none", color: "gray", fontSize: "0.88rem", fontWeight: "bold"}}
                                    // variant="contained"
                                    component="label"
                                    >
                                    Certificado Laboral
                                    <input
                                        type="file"
                                        hidden
                                        onChange={(event) => {
                                            console.log(event.target.files[0]);
                                            employmentChange(event);
                                        }}
                                    />
                                </Button>
                            </Grid>
                        }
                        {employmentCert &&
                            <Grid item xs={12} sm={6} md={6}>
                                <Grid container spacing={0} sx={{justifyContent: "center"}}>
                                    <Grid item xs={12} sm={12} md={12} sx={{textAlign: "center"}}>
                                    {console.log(employmentCert)}
                                        <Typography variant="body2" sx={{color: "black", fontWeight: "bold"}}>{employmentCert.name}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                        <Button onClick={deleteEmployementCert}  component="label" sx={{marginTop: "0rem", backgroundColor: "none", color: "gray", fontSize: "0.8rem"}}>
                                            Borrar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }

                        {!incomeCert &&
                            <Grid item xs={12} sm={6} md={6}>
                                <Button
                                    sx={{marginTop: "2rem", backgroundColor: "none", color: "gray", fontSize: "0.88rem", fontWeight: "bold"}}
                                    // variant="contained"
                                    component="label"
                                    >
                                    Desprendible de Nómina
                                    <input
                                        type="file"
                                        hidden
                                        onChange={(event) => {
                                            console.log(event.target.files[0]);
                                            incomeChange(event);
                                        }}
                                    />
                                </Button>
                            </Grid>
                        }
                        {incomeCert &&
                            <Grid item xs={12} sm={6} md={6}>
                                <Grid container spacing={0} sx={{justifyContent: "center"}}>
                                    <Grid item xs={12} sm={12} md={12} sx={{textAlign: "center"}}>
                                        <Typography variant="body2" sx={{color: "black", fontWeight: "bold"}}>{incomeCert.name}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                        <Button onClick={deleteIncomeCert}  component="label" sx={{marginTop: "0rem", backgroundColor: "none", color: "gray", fontSize: "0.8rem"}}>
                                            Borrar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        {employmentCert && incomeCert &&
                            <Grid item xs={12} sm={12} md={12}>
                                <Button onClick={submitFinancialDocs} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                    Cargar
                                </Button>
                            </Grid>
                        }
                    </Grid>
                }
            </Box>
        </Box>
    )
}
