import React from 'react';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button} from '@mui/material';
import Colors from "../../components/ui/Colors";
import swal from "sweetalert";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import { colombianStates, municipalities, countries } from "../../Api";
import Autocomplete from '@mui/material/Autocomplete';
import {createIssuer} from "../../utilityFunctions/OpenCardUtil"
import { useTranslation } from 'react-i18next';

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const issuerRequest = {};

export default function NewProductIssuer(props) {

    const {loginUser} = props;

    const [loading, setLoading] = React.useState(false);

    const { t } = useTranslation();

    const[issuerName, setIssuerName] = React.useState("");
    const[issuerIdentification, setIssuerIdentification] = React.useState("");
    const[issuerCode, setIssuerCode] = React.useState("");
    const[address, setAddress] = React.useState("");
    const[country, setCountry] = React.useState("");
    
    const[representativeName, setRepresentativeName] = React.useState("");
    const[representativeLastName, setRepresentativeLastName] = React.useState("");
    const[representativeIdType, setRepresentativeIdType] = React.useState("");
    const[representativeIdNumber, setRepresentativeIdNumber] = React.useState("");
    const[email, setEmail] = React.useState("");
    const[phone, setPhone] = React.useState("");
    const[telephone, setTelephone] = React.useState("");
    const[productBrand, setProductBrand] = React.useState("");
    const[creditBureau, setCreditBureau] = React.useState("");
    const[creditBureauScore, setCreditBureauScore] = React.useState("");

    const issuerNameChange = (e) => {
        setIssuerName(e.target.value);
        issuerRequest.issuerName = e.target.value;
    };

    const issuerIdentificationChange = (e) => {
        setIssuerIdentification(e.target.value);
        issuerRequest.issuerIdentification = e.target.value;
    };

    const issuerCodeChange = (e) => {
        setIssuerCode(e.target.value);
        issuerRequest.issuerCode = e.target.value;
    };

    const addressChange = (e) => {
        setAddress(e.target.value);
        issuerRequest.address = e.target.value;
    };

    const [countriesAutocomplete, setCountriesAutocomplete] = React.useState(
        {
          options: countries,
          getOptionLabel: (option) => option.country,
  
        }
    );

    const countryChange = (event, value) => {
        if(!value){
          setCountry("");
          return;
        }
  
        console.log(value);
        setCountry(value.countryCode);
        setCountryFromList(value);
        
  
        if(value.countryCode!=="CO"){
          const nonColombianCity = {
            city: value.country,
            cityCode: "1",
            stateCode: "1",
          };
          setCityLocation(nonColombianCity);
        }
        
    };

    const [geoState, setGeoState] = React.useState("");

    const geoStateChange = (event, value) => {
      if(!value){
        setGeoState("");
        return;
      }
      setGeoState(value.stateCode);
    };

    const cityChangeAutocomplete = (event, value) => {
        if(!value){
          return;
        }
        // console.log(value);
        setCityLocation(value);
    };

    const setCountryFromList = (value) => {
        issuerRequest.countryCode=value.countryCode;
        issuerRequest.country=value.country;
        issuerRequest.currency=value.currencyCode;
    };

    const setCityLocation = (value) => {
        console.log(value)
        issuerRequest.cityCode=value.cityCode;
        issuerRequest.city=value.city;
        issuerRequest.stateCode=value.stateCode;
    };

    const representativeNameChange = (e) => {
        setRepresentativeName(e.target.value);
        issuerRequest.representativeName = e.target.value;
    };

    const representativeLastNameChange = (e) => {
        setRepresentativeLastName(e.target.value);
        issuerRequest.representativeLastName = e.target.value;
    };

    const representativeIdTypeChange = (e) => {
        setRepresentativeIdType(e.target.value);
        issuerRequest.representativeIdType = e.target.value;
    };

    const representativeIdNumberChange = (e) => {
        setRepresentativeIdNumber(e.target.value);
        issuerRequest.representativeIdNumber = e.target.value;
    };

    const emailChange = (e) => {
        setEmail(e.target.value);
        issuerRequest.email = e.target.value;
    };

    const phoneChange = (e) => {
        setPhone(e.target.value);
        issuerRequest.phone = e.target.value;
    };

    const telephoneChange = (e) => {
        setTelephone(e.target.value);
        issuerRequest.telephone = e.target.value;
    };

    const productBrandChange = (e) => {
        setProductBrand(e.target.value);
        issuerRequest.productBrand = e.target.value;
    };

    const creditBureauChange = (e) => {
        setCreditBureau(e.target.value);
        issuerRequest.creditBureau = e.target.value;
    };

    const creditBureauScoreChange = (e) => {
        setCreditBureauScore(e.target.value);
        issuerRequest.creditBureauScore = e.target.value;
    };

    const submitIssuer = async() => {

        const createIssuerResponse = await createIssuer(issuerRequest, loginUser.jwtToken);

        if(!createIssuerResponse){
            swal({
                title: "Error",
                text: "Error al crear el emisor",
                icon: "error",
            });
            return;
        };

        swal({
            title: "Éxito",
            text: "Emisor creado correctamente",
            icon: "success",
        });

    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                    height: "90%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: "1.5rem"},
                }}
            >
                {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextField
                                variant="standard"
                                fullWidth={true}
                                id="issuerName"
                                name="issuerName"
                                label={<Typography variant="body2">Razón Social</Typography>}
                                value={issuerName}
                                onChange={(e)=> issuerNameChange(e)}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextField
                                variant="standard"
                                fullWidth={true}
                                id="issuerIdentification"
                                name="issuerIdentification"
                                label={<Typography variant="body2">Identificación</Typography>}
                                value={issuerIdentification}
                                onChange={(e)=> issuerIdentificationChange(e)}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextField
                                variant="standard"
                                fullWidth={true}
                                id="issuerCode"
                                name="issuerCode"
                                label={<Typography variant="body2">Código Emisor</Typography>}
                                value={issuerCode}
                                onChange={(e)=> issuerIdentificationChange(e)}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextField
                                variant="standard"
                                fullWidth={true}
                                id="address"
                                name="address"
                                label={<Typography variant="body2">Dirección</Typography>}
                                value={address}
                                onChange={(e)=> addressChange(e)}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Autocomplete sx={{ width: "100%"}}
                                {...countriesAutocomplete}
                                
                                id="country"
                                name="country"
                                clearOnEscape
                                autoComplete = {true}
                                noOptionsText = {t("IDInformation.countryNotFound")}
                                fullWidth
                                onChange={countryChange}
                                // onInputChange={onChange}
                                renderInput={(params) => (
                                    <TextField  {...params} label={<Typography variant="body2">{t("IDInformation.country")}</Typography>} variant="standard" />
                                )}
                            />
                        </Grid>
                        {country && country==="CO" &&
                            <>
                            <Grid item xs={12} sm={6} md={6}>
                                <Autocomplete sx={{ width: "100%"}}
                                    options={colombianStates}
                                    getOptionLabel={(option) => option.state}
                                    id="geoState"
                                    name="geoState"
                                    clearOnEscape
                                    autoComplete = {true}
                                    noOptionsText = {t("IDInformation.notFoundGeoState")}
                                    fullWidth
                                    onChange={geoStateChange}
                                    // onInputChange={onChange}
                                    renderInput={(params) => (
                                        <TextField {...params} label={<Typography variant="body2">{t("IDInformation.geoState")}</Typography>} variant="standard" />
                                    )}
                                />
                            </Grid>
                            {geoState &&
                                <Grid item xs={12} sm={6} md={6}>
                                    <Autocomplete sx={{ width: "100%"}}
                                        options={municipalities.filter((municipality) => municipality.stateCode === geoState)}
                                        getOptionLabel={(option) => `${option.city} (${option.cityCode})`}
                                        id="Municipality"
                                        name="Municipality"
                                        clearOnEscape
                                        autoComplete = {true}
                                        noOptionsText = {t("IDInformation.notFoundCity")}
                                        fullWidth
                                        onChange={cityChangeAutocomplete}
                                        // onInputChange={onChange}
                                        renderInput={(params) => (
                                            <TextField  {...params} label={<Typography variant="body2">{t("IDInformation.city")}</Typography>} variant="standard" />
                                        )}
                                    />
                                </Grid>
                            }
                            </>
                        }
                        <Grid item xs={12} sm={12} md={6}>
                            <TextField
                                variant="standard"
                                fullWidth={true}
                                id="representativeName"
                                name="representativeName"
                                label={<Typography variant="body2">Nombre Representante</Typography>}
                                value={representativeName}
                                onChange={(e)=> representativeNameChange(e)}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextField
                                variant="standard"
                                fullWidth={true}
                                id="representativeLastName"
                                name="representativeLastName"
                                label={<Typography variant="body2">Apellido Representante</Typography>}
                                value={representativeLastName}
                                onChange={(e)=> representativeLastNameChange(e)}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextField
                                variant="standard"
                                fullWidth={true}
                                id="representativeIdType"
                                name="representativeIdType"
                                label={<Typography variant="body2">Tipo Identificación Representante</Typography>}
                                value={representativeIdType}
                                onChange={(e)=> representativeIdTypeChange(e)}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextField
                                variant="standard"
                                fullWidth={true}
                                id="representativeIdNumber"
                                name="representativeIdNumber"
                                label={<Typography variant="body2">Número Identificación Representante</Typography>}
                                value={representativeIdNumber}
                                onChange={(e)=> representativeIdNumberChange(e)}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextField
                                variant="standard"
                                fullWidth={true}
                                id="email"
                                name="email"
                                label={<Typography variant="body2">Correo Electrónico</Typography>}
                                value={email}
                                onChange={(e)=> emailChange(e)}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextField
                                variant="standard"
                                fullWidth={true}
                                id="phone"
                                name="phone"
                                label={<Typography variant="body2">Celular</Typography>}
                                value={phone}
                                onChange={(e)=> phoneChange(e)}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextField
                                variant="standard"
                                fullWidth={true}
                                id="telephone"
                                name="telephone"
                                label={<Typography variant="body2">Teléfono</Typography>}
                                value={telephone}
                                onChange={(e)=> telephoneChange(e)}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextField
                                variant="standard"
                                fullWidth={true}
                                id="productBrand"
                                name="productBrand"
                                label={<Typography variant="body2">Marca Producto</Typography>}
                                value={productBrand}
                                onChange={(e)=> productBrandChange(e)}
                                autoComplete="off"
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={12} md={6}>
                            <TextField
                                variant="standard"
                                fullWidth={true}
                                id="creditBureau"
                                name="creditBureau"
                                label={<Typography variant="body2">Buro de Crédito</Typography>}
                                value={creditBureau}
                                onChange={(e)=> creditBureauChange(e)}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextField
                                variant="standard"
                                fullWidth={true}
                                id="creditBureauScore"
                                name="creditBureauScore"
                                label={<Typography variant="body2">Score Buro de Crédito</Typography>}
                                value={creditBureauScore}
                                onChange={(e)=> creditBureauScoreChange(e)}
                                autoComplete="off"
                            />
                        </Grid> */}
                        <Grid item xs={12} sm={12} md={12}>
                            <Button onClick={submitIssuer} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                Cargar
                            </Button>
                    </Grid>  
                    </Grid>
                }
            </Box>
        </Box>
    )
}
