import {wupoServerUri} from '../Api';

function postCredit(creditObject, jwtToken){
    console.log(creditObject);

    return(
      fetch(`${wupoServerUri.devnet}/api/secure/creditCreation`, {
          
          method: 'POST',
          body: JSON.stringify(creditObject),
          headers: {
            'Authorization': `Bearer ${jwtToken}`, 
            'Content-Type': 'application/json'                     
          }
          
        }).then((response) => { 
          console.log(response) // DEBUG PRINTING
          console.log(response.status)

          if(response.status === 200){
              return response.json();
          }

          else if(response.status === 400){
            return "invalid email"
          }
          else{
              return "Error";
          }
      
        }).then((loanCreated) => {
            console.log(loanCreated);
            return(loanCreated);
            
      }).catch(function(error){
          console.log("Error msg: "+ error);
      })
    );

};

function fetchCreditConditions(creditObject, jwtToken){
  console.log(creditObject);
  
  return(
    fetch(`${wupoServerUri.devnet}/api/secure/creditConditions`, {
          
        method: 'POST',
        body: JSON.stringify(creditObject),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        console.log(response.status) // DEBUG PRINTING
        return response.json();

      }).then((body) =>{
          console.log(JSON.stringify(body));
          return(body);
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );
}

function fetchRefinancingConditions(creditObject, jwtToken){
  console.log(creditObject);
  
  return(
    fetch(`${wupoServerUri.devnet}/api/secure/refinancingConditions`, {
          
        method: 'POST',
        body: JSON.stringify(creditObject),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        console.log(response.status) // DEBUG PRINTING
        return response.json();

      }).then((body) =>{
          console.log(JSON.stringify(body));
          return(body);
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );
}

function fetchLoan(visitorAccount, jwtToken){
  const fetchLoanObject ={
    account: visitorAccount
  };
  console.log(fetchLoanObject);

  return(
    fetch(`${wupoServerUri.devnet}/api/secure/visitorloans`, {
        method: "POST",
        body: JSON.stringify(fetchLoanObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
    })
    .then((response) => {
        console.log(response.status);
        // if(response.status === 302){
            return response.json();
        // }
        // else{
        //     return response.json();
        // }
    })
    .then((visitorLoan) => {
        console.log(visitorLoan); //LOANS DEBUG PRINTING
        
        return visitorLoan;
    })
    .catch((error) => {
        console.log("Error: " + error);
    })
  )

}

function fetchCreditPayments(visitorAccount, jwtToken){
  const paymentsRequest = {
    account: visitorAccount,
  }
  return(
    fetch(`${wupoServerUri.devnet}/api/secure/determinepayments`, {
        method: "POST",
        body: JSON.stringify(paymentsRequest),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
    })
    .then((response) => {
        console.log(response.status);
        if(response.status === 200){
            return response.json();
        }
        else{
            return "Not found";
        }
    })
    .then((visitorPayments) => {
        console.log(visitorPayments); // DEBUG PRINTING
        
        return visitorPayments;
    })
    .catch((error) => {
        console.log("Error: " + error);
    })
  )
}

function installmentPayment(installmentObject, jwtToken){
  console.log(installmentObject);

  return(
    fetch(`${wupoServerUri.devnet}/api/secure/installmentpayment`, {
        method: "POST",
        body: JSON.stringify(installmentObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
    })
    .then((response) => {
        console.log(response.status);
        if(response.status === 200){
            return response.json();
        }
        else{
            return "Not found";
        }
    })
    .then((installmentPaid) => {
        console.log(installmentPaid); // DEBUG PRINTING
        
        return installmentPaid;
    })
    .catch((error) => {
        console.log("Error: " + error);
    })
  )

}

const refinancingPayment = (installmentObject, jwtToken) => {
  console.log(installmentObject);

  return(
    fetch(`${wupoServerUri.devnet}/api/secure/refinancingpayment`, {
        method: "POST",
        body: JSON.stringify(installmentObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
    })
    .then((response) => {
        console.log(response.status);
        if(response.status === 200){
            return response.json();
        }
        else{
            return "Not found";
        }
    })
    .then((installmentPaid) => {
        console.log(installmentPaid); // DEBUG PRINTING
        
        return installmentPaid;
    })
    .catch((error) => {
        console.log("Error: " + error);
    })
  )

}

function prepareLoanStatement(prepareStatement, jwtToken){
  console.log(prepareStatement);

  return(
    fetch(`${wupoServerUri.devnet}/api/secure/prepareloanstatement`, {
        method: "POST",
        body: JSON.stringify(prepareStatement),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
    })
    .then((response) => {
        console.log(response.status);
        if(response.status === 200){
            return response.json();
        }
        else{
            return "Not found";
        }
    })
    .then((loanStatement) => {
        console.log(loanStatement); // DEBUG PRINTING
        
        return loanStatement;
    })
    .catch((error) => {
        console.log("Error: " + error);
    })
  )

}

function fetchStatement(loanStatement, jwtToken){
  console.log(loanStatement);

  return(
    fetch(`${wupoServerUri.devnet}/api/secure/loanstatement`, {
        method: "POST",
        body: JSON.stringify(loanStatement),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
    })
    .then((response) => {
        console.log(response.status);
        if(response.status === 200){
            return response.json();
        }
        else{
            return "Not found";
        }
    })
    .then((statement) => {
        console.log(statement); // DEBUG PRINTING
        
        return statement;
    })
    .catch((error) => {
        console.log("Error: " + error);
    })
  )
}

const fetchAccountStatement = (loanStatement, jwtToken) => {
  console.log(loanStatement);

  return(
    fetch(`${wupoServerUri.devnet}/api/secure/accountloanstatement`, {
        method: "POST",
        body: JSON.stringify(loanStatement),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
    })
    .then((response) => {
        console.log(response.status);
        if(response.status === 200){
            return response.json();
        }
        else{
            return "Not found";
        }
    })
    .then((statement) => {
        console.log(statement); // DEBUG PRINTING
        
        return statement;
    })
    .catch((error) => {
        console.log("Error: " + error);
    })
  )
}

function postRevolvingCredit(creditObject, jwtToken){
  console.log(creditObject);

    return(
      fetch(`${wupoServerUri.devnet}/api/secure/revolvingLoan`, {
          
          method: 'POST',
          body: JSON.stringify(creditObject),
          headers: {
            'Authorization': `Bearer ${jwtToken}`, 
            'Content-Type': 'application/json'                     
          }
          
        }).then((response) => { 
          console.log(response.status) // DEBUG PRINTING

          if(response.status === 200){
              return response.json();
          }
          else{
              return "Error";
          }
      
        }).then((loanCreated) => {
            console.log(loanCreated);
            return(loanCreated);
            
      }).catch(function(error){
          console.log("Error msg: "+ error);
          // return "Error"; // UNDER REVIEW
      })
    );

}

const fetchLoanParameters = (jwtToken) =>{
  return(
    fetch(`${wupoServerUri.devnet}/api/secure/getLoansCostsAndConditions`, {
        
        method: 'POST',
        // body: JSON.stringify(creditObject),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        console.log(response.status); // DEBUG PRINTING
        // console.log(response);

        if(response.status === 200){
            return response.json();
        }
        else{
            return "Error";
        }
    
      }).then((loansParameters) => {
          console.log(loansParameters);
          return(loansParameters);
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
        // return "Error"; // UNDER REVIEW
    })
  );

};

const updateLoanSettings = (updatedCosts, updatedConditions, jwtToken) =>{
  const updateSettings={
    updatedLoanCostsSettings: {...updatedCosts}, 
    updatedLoanConditionsSettings: {...updatedConditions},
  };
  console.log(updateSettings);
  return(
    fetch(`${wupoServerUri.devnet}/api/secure/updateLoanSettings`, {
        
        method: 'POST',
        body: JSON.stringify(updateSettings),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        console.log(response.status); // DEBUG PRINTING

        if(response.status === 200){
            return "ok";
        }
        else{
            return "Error";
        }
    
      })
      .then(() => {
          return("ok");
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
        // return "Error"; // UNDER REVIEW
    })
  );

};

const fetchRefinancingParameters = (jwtToken) =>{
  return(
    fetch(`${wupoServerUri.devnet}/api/secure/getRefinancingCostsAndConditions`, {
        
        method: 'POST',
        // body: JSON.stringify(creditObject),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        console.log(response.status); // DEBUG PRINTING
        console.log(response);

        if(response.status === 200){
            return response.json();
        }
        else{
            return "Error";
        }
    
      }).then((loansParameters) => {
          console.log(loansParameters);
          return(loansParameters);
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
        // return "Error"; // UNDER REVIEW
    })
  );

};

const postRefinancing = (creditObject, jwtToken) => {
  console.log(creditObject);

  return(
    fetch(`${wupoServerUri.devnet}/api/secure/creditRefinancing`, {
        
        method: 'POST',
        body: JSON.stringify(creditObject),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        console.log(response) // DEBUG PRINTING
        console.log(response.status)

        if(response.status === 200){
            return response.json();
        }
        else if(response.status === 400){
            return "invalid email";  
        }
        else{
            return "Error";
        }
    
      }).then((loanCreated) => {
          console.log(loanCreated);
          return(loanCreated);
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

const fetchSimulatorParameters = () =>{
  const publicApiKey = "MIDe6e9/SPuErz5NMXIcO2FGTJBpdcrwDrCuXVffkoE="; //Not sensitive, that's why it is here
  return(
    fetch(`${wupoServerUri.devnet}/api/auth/simulationCostsAndConditions`, {
        
        method: 'GET',
        // body: JSON.stringify(creditObject),
        headers: {
          'Authorization': `Bearer ${publicApiKey}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        console.log(response.status); // DEBUG PRINTING
        // console.log(response);

        if(response.status === 200){
            return response.json();
        }
        else{
            return "Error";
        }
    
      }).then((loansParameters) => {
          console.log(loansParameters);
          return(loansParameters);
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
        // return "Error"; // UNDER REVIEW
    })
  );

};

const combopayInstallment = (installmentObject, jwtToken) => {
  console.log(installmentObject);

  return(
    fetch(`${wupoServerUri.devnet}/api/secure/combopayinstallment`, {
        method: "POST",
        body: JSON.stringify(installmentObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
    })
    .then((response) => {
        console.log(response.status);
        if(response.status === 200){
            return response.json();
        }
        else{
            return "Not found";
        }
    })
    .then((comboPaymentResponse) => {
        console.log(comboPaymentResponse); // DEBUG PRINTING
        
        return comboPaymentResponse;
    })
    .catch((error) => {
        console.log("Error: " + error);
    })
  )

}

const verifyBankAccount = (username, jwtToken) => {
  
  const bankAccountRequest = {
    username: username,
  }
  
  console.log(bankAccountRequest);

  return(
    fetch(`${wupoServerUri.devnet}/api/secure/activebankaccount`, {
        method: "POST",
        body: JSON.stringify(bankAccountRequest),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
    })
    .then((response) => {
        console.log(response.status);
        if(response.status === 200){
            return response.json();
        }
        else{
            return "Not found";
        }
    })
    .then((bankAccountResponse) => {
        console.log(bankAccountResponse); // DEBUG PRINTING
        
        return bankAccountResponse;
    })
    .catch((error) => {
        console.log("Error: " + error);
    })
  )

}

const financialDocsSubimit = async(requestObject, jwtToken) => {
  
  console.log(requestObject.get("docsRequest"));

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/documentedLoanDocs`, {
      method: "POST",
      body: requestObject,
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    
    console.log("ok");

    return "ok";
  }

  else {
    return null;
  }
}

const getFinancialDocs = async(requestObject, jwtToken) => {
  

  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/viewDocumentedDocs`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const financialDocs = await response.json();
    console.log(financialDocs);

    return financialDocs;
  }

  else {
    return null;
  }
}

const getPendingUsernames = async(jwtToken) => {

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/getPendingUsernames`, {
      method: "POST",
      // body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const usernames = await response.json();
    console.log(usernames);

    return usernames;
  }

  else {
    return null;
  }
}

const handleDocumentedIncome = async(requestObject, jwtToken) => {
  

  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/handleDocumentedDocs`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const user = await response.json();
    console.log(user);

    return user;
  }

  else {
    return null;
  }
}

const clientDocsStatus = async(requestObject, jwtToken) => {
  

  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/clientdocsstatus`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const user = await response.json();
    console.log(user);

    return user;
  }

  else {
    return null;
  }
}

export{ postCredit, fetchCreditConditions, fetchLoan, fetchCreditPayments, installmentPayment,
        prepareLoanStatement, fetchStatement, postRevolvingCredit, fetchLoanParameters, updateLoanSettings, 
        fetchRefinancingParameters, refinancingPayment, postRefinancing, fetchSimulatorParameters, fetchAccountStatement,
        combopayInstallment, fetchRefinancingConditions, verifyBankAccount, financialDocsSubimit, getFinancialDocs, 
        getPendingUsernames, handleDocumentedIncome, clientDocsStatus }