import React from 'react';
import { getClientPortfolio } from "../../utilityFunctions/MarketPlaceUtil";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Button, Typography, DialogContent } from "@mui/material";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Colors from "../../components/ui/Colors";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import { makeStyles } from '@mui/styles';
import { useHistory} from "react-router-dom";


const inlineSvg =  `<svg xmlns='http://www.w3.org/2000/svg'  width='120' height='120' viewBox='0 0 120 120'><rect fill='#ddffaa' width='120' height='120'/><polygon  fill='#AE9' fill-opacity='1' points='120 120 60 120 90 90 120 60 120 0 120 0 60 60 0 0 0 60 30 90 60 120 120 120 '/></svg>`;
// const inlineSvg =  `<svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='0 0 800 800'><rect fill='#000000' width='800' height='800'/><g fill-opacity='0.86'><circle fill='#000000'  cx='400' cy='400' r='600'/><circle fill='#001f7a'  cx='400' cy='400' r='500'/><circle fill='#003296'  cx='400' cy='400' r='400'/><circle fill='#0049b3'  cx='400' cy='400' r='300'/><circle fill='#0061d0'  cx='400' cy='400' r='200'/><circle fill='#1E7AEE'  cx='400' cy='400' r='100'/></g></svg>`;

const useStyles = makeStyles({
    svgBackground: {
      background: `url("data:image/svg+xml;utf8,${encodeURIComponent(inlineSvg)}") center center/cover no-repeat`,
    },
});

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;


const dateOptions = {
    // weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
  };

let currentInvestment;

export default function ClientPortfolio(props) {

    const {loginUser} = props;

    const history = useHistory();

    const classes = useStyles();

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        renderPortfolio();
    }, []);

    const fetchPortfolio = async () => {
        setLoading(true);
        const portfolio = await getClientPortfolio(loginUser.accountId, loginUser.jwtToken);

        console.log(portfolio);

        return portfolio;
    };

    const [renderInvestments, setRenderInvestments] = React.useState();

    const renderPortfolio = async () => {

        const portfolio = await fetchPortfolio();
        setLoading(false);

        if(loading){
            setRenderInvestments(
                <PuffLoader size={100} color={Colors.secondary} css={cssLoader} />
            );   
        }
        else{
            setRenderInvestments(
                <Grid container spacing={2} sx={{justifyContent: {xs: "center", sm: "center"}}}>
                    {portfolio.map((investment) => {
                        return(
                            <Grid key={investment.clientsPortfolio.id} item xs={12} sm={6} md={4} lg={4} sx={{marginTop: "0.5rem", maxWidth:{xs: "300px"}, marginBottom: "0.5rem"}}>
                                <Card key={investment.clientsPortfolio.id}>
                                    <CardActions onClick={() => investmentDetail(investment)} sx={{cursor: "pointer"}}>
                                        <Grid container>
                                            <Grid item xs={3} sm={3} md={3}>
                                                <Box
                                                sx={{
                                                        ...((investment.clientsPortfolio.status === "active") && {
                                                            backgroundColor: Colors.primary,
                                                            color: "white",
                                                        }),
                                                        ...((investment.clientsPortfolio.status === "hasPayments") && {
                                                            backgroundColor: Colors.secondary,
                                                            color: "white",
                                                        }),
                                                        ...((investment.clientsPortfolio.status === "paid") && {
                                                            backgroundColor: Colors.third,
                                                            color: "white",
                                                        }),
                                                        borderRadius: "25%",
                                                        width: "3rem",
                                                        height: "3rem",
                                                        textAlign: "center",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        marginBottom: "0.5rem",
                                                    
                                                    }} 
                                                >
                                                    {`${investment.cattleLot.expectedRevenue * 100}%`  }
                                                </Box>
                                            </Grid>
                                            <Grid item xs={9} sm={9} md={9} sx={{textAlign: "end", display: "flex", alignItems: "center", justifyContent: "end"}}>
                                                <Grid container>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <Typography variant="body1" sx={{color: "black", fontSize: "0.8rem"}}>
                                                            {`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(investment.clientsPortfolio.investmentDate))}`}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "end"}}>
                                                        <Typography sx={{fontSize: "0.8rem"}}>
                                                            {investment.clientsPortfolio.status === "active" ? "Activo" : investment.clientsPortfolio.status === "hasPayments" ? "Con Pagos" : "Pago Total"}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>  
                                                    <Typography variant="body1" sx={{fontWeight: "bold"}} >
                                                    {`${investment.cattleLot.code}`}
                                                    </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>  
                                                <Typography variant="body1" sx={{color: "black", fontSize: "0.8rem"}}>
                                                    {`$ ${new Intl.NumberFormat('es-CO').format(investment.clientsPortfolio.initialBalance)}`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardActions>
                                </Card>
                            </Grid>
                        )
                    })}
                </Grid>
            );
        }
    };


    const [detailBox, setDetailBox] = React.useState(false);
    const [renderDetail, setRenderDetail] = React.useState();
    const [paymentsBox, setPaymentsBox] =  React.useState();
    const [renderSettlements, setRenderSettlements] = React.useState();

    const handleInvestmentDetail = (investment) => {
        setRenderDetail(
            <Grid container spacing={1} sx={{alignItems: "center", marginTop:{xs: "0.2rem", sm: "0.2rem", md: "0.2rem"}, maxWidth: "32rem"}}>
                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", marginTop:"0.2rem", marginBottom: "0.2rem"}}>
                    <Typography variant="h6" sx={{fontWeight: "bold", width: "100%"}}> {`${currentInvestment.cattleLot.code}`}</Typography>
                </Grid>

                <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.2rem"}}>
                    <Typography variant="body2" sx={{ fontWeight: "bold", width: "100%"}}>Rentabilidad Esperada:</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.2rem"}}>
                    <Typography variant="body2" sx={{ width: "100%"}}>{`${new Intl.NumberFormat('es-CO').format(currentInvestment.cattleLot.expectedRevenue)}`}</Typography>
                </Grid>

                <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.2rem"}}>
                    <Typography variant="body2" sx={{ fontWeight: "bold", width: "100%"}}>Duración:</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.2rem"}}>
                    <Typography variant="body2" sx={{ width: "100%"}}>{`${new Intl.NumberFormat('es-CO').format(currentInvestment.cattleLot.duration)} meses`}</Typography>
                </Grid>

                <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.2rem"}}>
                    <Typography variant="body2" sx={{ fontWeight: "bold", width: "100%"}}>Valor Inversión:</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.2rem"}}>
                    <Typography variant="body2" sx={{ width: "100%"}}>{`$ ${new Intl.NumberFormat('es-CO').format(currentInvestment.clientsPortfolio.initialBalance)}`}</Typography>
                </Grid>

                <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.2rem"}}>
                    <Typography variant="body2" sx={{ fontWeight: "bold", width: "100%"}}>Fecha Inversión:</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.2rem"}}>
                    <Typography variant="body2" sx={{ width: "100%"}}>{`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(investment.clientsPortfolio.investmentDate))}`}</Typography>
                </Grid>
            </Grid>
        );
    };

    const investmentDetail = (selectedInvestment) => {
        currentInvestment = selectedInvestment;
        handleInvestmentDetail(selectedInvestment);
        setDetailBox(true);
    };
    
    const closeDetailBox = () => {
        setDetailBox(false);
    };

    const reviewPayments = () => {
        
        console.log(currentInvestment);

        if(!currentInvestment.clientsPortfolio.clientsPortfolioSettlement){
            return;
        }
        
        setRenderSettlements(
            <List sx={{width: "100%", marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}}}>
                <Grid container spacing={2}>
                    {currentInvestment.clientsPortfolio.clientsPortfolioSettlement.map((settlement) => {
                        return(
                            <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                                <Grid key={settlement.id} item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <Typography key={settlement.id} variant="body2" sx={{color: "black"}}>
                                        {`$ ${new Intl.NumberFormat('es-CO').format(settlement.totalPayment)}`}
                                    </Typography>
                                </Grid>
                                <Grid key={settlement.id+1}  item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.2rem"}}>
                                    <Typography variant="body2" sx={{color: "black"}}>{
                                        `${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(settlement.settlementDate))}`}
                                    </Typography>
                                </Grid>
                                <Grid key={settlement.id} item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <Typography key={settlement.id} variant="body2" sx={{color: "black"}}>
                                        {settlement.isSigned ? "Firmado" : "No Firmado"}
                                    </Typography>
                                </Grid>
                            </ListItem>
                        )
                    })

                    }
                </Grid>
            </List>
        );
        closeDetailBox();
        setPaymentsBox(true);
    };

    const closePaymentsBox = () => {
        setPaymentsBox(false);
    };

    return (
        <Box className={classes.svgBackground} sx={{display: "flex", justifyContent: "center",  width: "100%", height: "100%",}}>
        <Box sx={{display: "flex", justifyContent: "center",  width: "100%", height: "100%",
            // backgroundColor: "#ededed",
        }}>

            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: {xs: "start", sm: "center", md: "center"},
                    width: "90%",
                    height: "100%",
                    textAlign: "center",
                    overflowY: "scroll",
                    
                }}
            >
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                <Grid container spacing={1} sx={{justifyContent: {xs: "center", sm: "center"}, height: "90%", marginBottom: {xs: "3rem", sm: "2rem", md: "2.5rem"}, marginTop: {xs: "2rem", sm: "1rem", md: "1rem"}}}>
                    {renderInvestments}
                </Grid>
            }
                <Dialog open={detailBox} onClose={closeDetailBox}>
                    <DialogTitle sx={{display: "flex", justifyContent: "center"}}>
                        <Typography variant="h4">Detalle Inversión</Typography> 
                    </DialogTitle> 
                    <DialogContent>
                        {renderDetail}
                    </DialogContent>
                    <DialogActions sx={{display: "flex", justifyContent: "center"}}>
                        <Button onClick={closeDetailBox} variant="contained" sx={{backgroundColor: Colors.primary, marginRight: "1rem"}}>
                            ATRÁS
                        </Button>
                        <Button 
                            onClick={() => reviewPayments(currentInvestment)} variant="contained" sx={{backgroundColor: Colors.primary, marginLeft: "1rem"}}>
                            PAGOS
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={paymentsBox} onClose={closePaymentsBox} fullWidth maxWidth="sm">
                    <DialogTitle sx={{display: "flex", justifyContent: "center"}}>
                        <Typography variant="h4">Detalle Pagos</Typography> 
                    </DialogTitle> 
                    <DialogContent>
                        {renderSettlements}
                    </DialogContent>
                    <DialogActions sx={{display: "flex", justifyContent: "center"}}>
                        <Button onClick={closePaymentsBox} variant="contained" sx={{backgroundColor: Colors.primary, marginRight: "1rem"}}>
                            ATRÁS
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>

        </Box>
        </Box>
    )
}
