import React from 'react';
import {getProductInfo} from "../../utilityFunctions/OpenCardUtil";
import {getApiKeysByProduct, portabilityApiKeyReq, blockApiKeyReq, unblockApiKeyReq} from "../../utilityFunctions/SpbviUtil";
import {qrConsumerPayment} from "../../utilityFunctions/OpenCardUtil";
import swal from "sweetalert";
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Colors from "../../components/ui/Colors";
import { Button, Typography, DialogContent } from "@mui/material";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import TextField from '@mui/material/TextField';
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import QrCode2Icon from '@mui/icons-material/QrCode2';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import { useTranslation } from 'react-i18next';
import { useHistory} from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;


const dateOptionsHour = {
    // weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: 'numeric',
    minute: 'numeric',
};

let keyToChange;

export default function SpbviKeyRetrieval(props) {

    const {loginUser} = props;

    const history = useHistory();

    const [loading, setLoading] = React.useState(false);

    const { t } = useTranslation();

    React.useEffect(()=> {
        fetchProducts();
    }, []);

    const[cards, setCards] = React.useState("");

    const fetchProducts = async () => {
        setLoading(true);

        const productRequest = {};

        productRequest.username = loginUser.accountId;

        setLoading(true);
        const productsResponse = await getProductInfo(productRequest, loginUser.jwtToken);
        setLoading(false);

        console.log(productsResponse);

        // setCards(
        //     {
        //         options: productsResponse.listGeneralProduct,
        //         getOptionLabel: (option) => option.cardNumber,
        //     }
        // );
        setCards(productsResponse.listGeneralProduct);
        setLoading(false);
    };

    const onChangeAutocomplete = (event, value) => {
        console.log(value);
        if(value){
            getApiKeys(value);
        }

        else{
            return;
        }
       
    };

    const getApiKeys = async(product) => {
        const apiKeyRequest = {};
        apiKeyRequest.cardNumber = product.cardNumber;
        
        setLoading(true);
        const apiKeysResponse = await getApiKeysByProduct(apiKeyRequest, loginUser.jwtToken);
        setLoading(false);

        if(!apiKeysResponse){
            swal({
                title: "Producto sin llaves",
                icon: "info",
                button: "ok",
            });
            return;
        }

        renderRegisterSeal(apiKeysResponse);

    }

    const [registerSeal, setRegisterSeal] = React.useState("");

    const renderRegisterSeal = (apiKeys) => {
        keyToChange = apiKeys[0];
        setRegisterSeal(
            <List sx={{width: "100%"}}>
                <Grid container spacing={2}>
                    {apiKeys.map((apiKey) => {
                        return(
                        <>
                        <ListItem key={generateRandomKey()} disablePadding sx={{marginBottom: "0.5rem"}}>
                                <Grid key={generateRandomKey()} item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                                    <Typography key={generateRandomKey()} variant="body2" sx={{color: "gray", fontSize: "0.8rem"}}>{`${new Intl.DateTimeFormat('es-CO', dateOptionsHour).format(new Date(apiKey.creationDate))}`}</Typography>
                                </Grid>
                        </ListItem>
                        <ListItem key={generateRandomKey()} disablePadding sx={{marginBottom: "1.2rem"}}>
                                <Grid key={generateRandomKey()} item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                                    <Typography key={generateRandomKey()} variant="body2" sx={{color: "black", fontWeight: "bold"}}>{apiKey.keyStatus === "1" ? "Activa" : apiKey.keyStatus === "2" ? "Bloqueada" : "Portabilidad"}</Typography>
                                </Grid>
                        </ListItem>
                        <ListItem key={generateRandomKey()} disablePadding divider sx={{marginBottom: "1rem"}}>
                            <Grid container direction="column" key={generateRandomKey()}>
                                <Grid key={generateRandomKey()} item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <Typography key={generateRandomKey()} sx={{color: "gray", fontSize: "0.6rem"}}>Llave de pago</Typography>
                                </Grid>
                                <Grid key={generateRandomKey()} item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                                    <Typography key={generateRandomKey()} variant="body1" sx={{color: "black"}}>{`${apiKey.keyNumber}`}</Typography>
                                </Grid>
                            </Grid>
                        </ListItem>
                        <ListItem key={generateRandomKey()} disablePadding divider sx={{marginBottom: "1rem"}}>
                            <Grid container direction="column" key={generateRandomKey()}>
                                <Grid key={generateRandomKey()} item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <Typography key={generateRandomKey()} sx={{color: "gray", fontSize: "0.6rem"}}>Método de pago</Typography>
                                </Grid>
                                <Grid key={generateRandomKey()} item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                                    <Typography key={generateRandomKey()} variant="body1" sx={{color: "black"}}>{apiKey.paymentMethod === "creditCard"? "Tarjeta de Crédito" : "Ahorros"}</Typography>
                                </Grid>
                            </Grid>
                        </ListItem>
                        <ListItem key={generateRandomKey()} disablePadding divider sx={{marginBottom: "1rem"}}>
                            <Grid container direction="column" key={generateRandomKey()}>
                                <Grid key={generateRandomKey()} item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <Typography key={generateRandomKey()} sx={{color: "gray", fontSize: "0.6rem"}}>Número Producto</Typography>
                                </Grid>
                                <Grid key={generateRandomKey()} item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                                    <Typography key={generateRandomKey()} variant="body1" sx={{color: "black"}}>{`${apiKey.paymentMethodNumber}`}</Typography>
                                </Grid>
                            </Grid>
                        </ListItem>
                        <ListItem key={generateRandomKey()} disablePadding sx={{marginBottom: "0.1rem"}}>
                            <ListItemButton  onClick={()=>generateQr(apiKey)}>
                                <Grid key={generateRandomKey()} item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "center", textAlign: "center", marginLeft: "1rem"}}>
                                    <QrCode2Icon sx={{color: "#03a9f4", fontSize: "3.5rem"}}/>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                        </>
                        )
                    })
                    }
                </Grid>
            </List>
        );

        openRegisterDone();
    };

    const [registerDone, setRegisterDone] = React.useState(false);
    
    const openRegisterDone = () => {
        setRegisterDone(true);
    };

    const closeRegisterDone = () => {
        setRegisterDone(false);
    }


    const [qrImg, setQrImg] = React.useState("");

    const generateQr = async (apiKey) => {
        const qrRequest = {};
        qrRequest.username=loginUser.accountId;
        qrRequest.value=20*100;
        qrRequest.consumerKey=apiKey.keyNumber;
        setLoading(true);
        const qrResponse = await qrConsumerPayment(qrRequest, loginUser.jwtToken);
        setQrImg(qrResponse);
        setLoading(false);
    };

    const[qrBox, setQrBox] = React.useState(false);

    React.useEffect(()=> {
        if(qrImg){
            setQrBox(true);
        }
    }, [qrImg]);

    const closeQrBox = () => {
        setQrBox(false);
    };

    const [apiKeyBox, setApiKeyBox] = React.useState(false);

    const closeApiKeyBox = () => {
        setApiKeyBox(false);
    };

    const openApiKey = () => {
        renderHandleKey(keyToChange);
        closeRegisterDone();
        setApiKeyBox(true);
    };

    const [handleKey, setHandleKey] = React.useState("");

    const renderHandleKey = (apiKey) => {
        setHandleKey(
            <List sx={{width: "100%",}}>
                <Grid container spacing={2}>
                    <ListItem disablePadding divider sx={{marginBottom: "1.2rem"}}>
                        <ListItemButton  onClick={()=>blockApiKey(apiKey)}>
                            <Grid item xs={2} sm={2} md={2} lg={2} sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                                <LockIcon sx={{color: "#03a9f4", fontSize: "1.5rem"}}/>
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                                <Typography variant="body2" sx={{color: "gray", fontSize: "0.8rem"}}>Bloquear Llave</Typography>
                            </Grid>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding divider sx={{marginBottom: "1.2rem"}}>
                        <ListItemButton  onClick={()=>unblockApiKey(apiKey)}>
                            <Grid item xs={2} sm={2} md={2} lg={2} sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                                <LockOpenIcon sx={{color: "#03a9f4", fontSize: "1.5rem"}}/>
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                                <Typography variant="body2" sx={{color: "gray", fontSize: "0.8rem"}}>Desbloquear Llave</Typography>
                            </Grid>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding divider sx={{marginBottom: "1.2rem"}}>
                        <ListItemButton  onClick={()=>portabilityApiKey(apiKey)}>
                            <Grid item xs={2} sm={2} md={2} lg={2} sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                                <FlightTakeoffIcon sx={{color: "#03a9f4", fontSize: "1.5rem"}}/>
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                                <Typography variant="body2" sx={{color: "gray", fontSize: "0.8rem"}}>Portabilidad Llave</Typography>
                            </Grid>
                        </ListItemButton>
                    </ListItem>
                </Grid>
            </List>
        );
    };

    const blockApiKey = async (apiKey) => {
        closeApiKeyBox();

        const blockRequest = {};
        blockRequest.keyNumber = apiKey.keyNumber;

        setLoading(true);
        const blockResponse = await blockApiKeyReq(blockRequest, loginUser.jwtToken);
        setLoading(false);

        if(!blockResponse){
            swal({
                title: "Error al bloquear la llave",
                icon: "error",
                button: "ok",
            });
            return;
        };

        swal({
            title: "Bloqueo exitoso",
            icon: "success",
            button: "ok",
        });
        return;


    };

    const unblockApiKey = async (apiKey) => {
        closeApiKeyBox();

        const blockRequest = {};
        blockRequest.keyNumber = apiKey.keyNumber;

        setLoading(true);
        const blockResponse = await unblockApiKeyReq(blockRequest, loginUser.jwtToken);
        setLoading(false);

        if(!blockResponse){
            swal({
                title: "Error al desbloquear la llave",
                icon: "error",
                button: "ok",
            });
            return;
        };

        swal({
            title: "Desbloqueo exitoso",
            icon: "success",
            button: "ok",
        });
        return;
    };
    const portabilityApiKey = async (apiKey) => {
        closeApiKeyBox();

        const blockRequest = {};
        blockRequest.keyNumber = apiKey.keyNumber;

        setLoading(true);
        const blockResponse = await portabilityApiKeyReq(blockRequest, loginUser.jwtToken);
        setLoading(false);

        if(!blockResponse){
            swal({
                title: "Error al generar la portabilidad",
                icon: "error",
                button: "ok",
            });
            return;
        };

        swal({
            title: "Registro exitoso",
            icon: "success",
            button: "ok",
        });
        return;
    };

    

    const generateRandomKey = () => {
        const randomString = Math.random().toString(36).slice(2);
        const timestamp = Date.now().toString(36);
        return randomString + '_' + timestamp;
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center",  width: "100%", height: "100%", backgroundColor: "#ededed",}}>
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
            <Box
            sx={{
                display: "flex",
                // flex: 1,
                justifyContent: "center",
                alignItems: {xs: "start", sm: "center", md: "center"},
                width: "90%",
                height: "100%",
                textAlign: "center",
            }}
            >
                <Grid container spacing={2} sx={{display: "flex", justifyContent: {xs: "center", sm: "center"}, alignItems: {xs: "start", sm: "center", md: "center"}, width: "100%", marginLeft: "1%", marginTop: {xs: "10%", sm: "0%"}}}>
                    <Card sx={{maxWidth: "400px", minWidth: {xs: "90%", sm: "90%", md: "300px", borderRadius: "15px"}}}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "center", width: "85%", position: "relative"}}>
                                <CardMedia
                                    component="img"
                                    // image={CardImg}
                                    image="/images/creditcards/card_clean.png"
                                    alt="Card"
                                    sx={{width: "95%"}}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "0.05rem", marginBottom: "2rem"}}>
                                <Autocomplete
                                    // {...cards}
                                    options={cards}
                                    getOptionLabel={(option) => option.cardNumber}
                                    sx={{width: "80%"}}
                                    id="cards"
                                    clearOnEscape
                                    autoComplete = {true}
                                    noOptionsText = {"Tarjeta no existe"}
                                    // sx={{ width: 600 }}
                                    onChange={onChangeAutocomplete}
                                    // onInputChange={onChange}
                                    renderInput={(params) => (
                                        <TextField {...params} label={<Typography variant="body2">{t("PurchaseSimulator.cardSelection")}</Typography>} variant="standard" />
                                    )}
                                /> 
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Dialog open={registerDone} onClose={closeRegisterDone} 
                    sx={{
                        // width: {xs: "100%", sm: "80%", md: "40%"}, 
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "100%", sm: "80%", md: "30%"},
                            },
                        },
                    }}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1rem"}}>
                            <Box
                                component="img"
                                sx={{
                                    height: {xs: 30, sm: 35},
                                    width: {xs: 188, sm: 210},
                                }}
                                alt="Logo"
                                src="/images/creditcards/logo-opencard.png"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "0.8rem", marginBottom: "0.5rem"}}>
                            <Divider/>
                        </Grid>
                    </Grid>
                    <DialogContent>
                        {registerSeal}                 
                    </DialogContent>
                    <DialogActions sx={{display: "flex", justifyContent: "center"}}>
                            <Button onClick={closeRegisterDone} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                                Atrás
                            </Button>
                            <Button onClick={openApiKey} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                                Admin
                            </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={qrBox} onClose={closeQrBox}>
                    <Grid container>
                    {qrImg &&
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1rem", width: "100%"}}>
                        <Box 
                            component="img"
                            sx={{
                            height: "100%",
                            // maxHeight: "200px",
                            width: "100%",
                            justifyContent: "center",
                            }}
                            alt="QR"
                            src={URL.createObjectURL(qrImg)}

                        />
                        </Grid>
                    }
                    </Grid>
                    <DialogActions sx={{display: "flex", justifyContent: "center"}}>
                        <Button onClick={closeQrBox} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={apiKeyBox} onClose={closeApiKeyBox} 
                    sx={{
                        // width: {xs: "100%", sm: "80%", md: "40%"}, 
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "100%", sm: "80%", md: "30%"},
                            },
                        },
                    }}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1rem"}}>
                            <Box
                                component="img"
                                sx={{
                                    height: {xs: 30, sm: 35},
                                    width: {xs: 188, sm: 210},
                                }}
                                alt="Logo"
                                src="/images/creditcards/logo-opencard.png"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "0.8rem", marginBottom: "0.5rem"}}>
                            <Divider/>
                        </Grid>
                    </Grid>
                    <DialogContent>
                        {handleKey}                 
                    </DialogContent>
                    <DialogActions sx={{display: "flex", justifyContent: "center"}}>
                        <Button onClick={closeApiKeyBox} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                            Atrás
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
            }
        </Box>
    )
}
