import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Input from "../../components/Input";
import swal from "sweetalert";
import { RingLoader } from "react-spinners";
import { css } from "@emotion/react";
import PageLabel from "../../components/ui/PageLabel";
import Content from "../../layout/Content";
import AppButton from "../../components/ui/AppButton";

// const cc = require("five-bells-condition");
// const crypto = require("crypto");

// const RippleAPI = require("ripple-lib").RippleAPI;

// const api = new RippleAPI({
//   server: "wss://s.altnet.rippletest.net:51233", // Public rippled server hosted by Ripple, Inc.
// });

const api = "default";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export default function HandleEscrow(props) {
  const history = useHistory();
  const { user, ledgerAccounts } = props;

  const userFromUserHandler = user.account;
  const tokenFromUserHandler = user.accountIdToken;
  const assetFromAccountHandler = props.match?.params?.asset;

  const [inputFromChild, setInputFromChild] = useState();
  const [eraseLabel, setEraseLabel] = useState(false);

  // Input and label variables
  const labelsArray = useRef([
    "VALOR",
    "DESTINO",
    "FECHA EJECUCIÓN",
    "FECHA CANCELACIÓN",
  ]);
  let labelIndex = useRef(0);

  //XRPL client's, receiver and Wupo's information
  const clientXrplAddress = useRef();
  const clientXrplToken = useRef();
  const destinationXrplAccount = useRef();

  const escrowCreation = useRef({
    destination: "", //Input
    amount: "", //Input
    allowExecuteAfter: "", //"2020-12-26T16:26:00.000-05:00", //Input. Optional. Should be presented as optional
    allowCancelAfter: "", //"2020-10-17T19:22:00.000-05:00", //Input Optional. Should be presented as optional
    condition: "", //"A025802064D250BACD5AE3032C0F2AD3B494CC7CC4F3059962138280BCD083CD57C9DD3D810120" //Input
  });

  //Escrow's Condition and Fullfilment
  const condition = useRef();
  const fulfillment = useRef();
  const sequence = useRef();

  //XRPL transaction ID
  const transactionId = useRef();

  //State that determines the rendering
  const [cardRender, setCardRender] = useState();
  //Fix me: Utility condition to deterine if the fetch has concluded. Change for a better async handling
  const fetchConcluded = useRef(false);

  useEffect(() => {
    swal({
      title: "¿QUÉ HAGO?",
      text: `\n Ingresa la información solicitada para generar un contrato digital en wupo.NET
                    \n las fechas solicitadas deben ingresarse con el formato AAAA-MM-DD (año-mes-día; no olvides los guiones)`,
      icon: "success",
      buttons: {
        cancel: "Entiendo",
        admin: "Admin",
      },
      //["Entiendo", "Más Info"]
    }).then((value) => {
      switch (value) {
        case "admin":
          swal({
            title: "PARA ADMINISTRADORES E INVERSIONISTAS",
            text: `\n Al crearse el contrato digital ocurre lo siguiente: \n\n
                        1. Creación de las llaves de establecimiento y liquidación (Hexadecimal de 72 y 78 caracteres respectivamente)
                        2. Creación de un fondo de XRP del valor ingresado que solo se ejecuta cuando se cumplen las condiciones. 
                        Nota: si el contrato no se liquida antes de la fecha de cancelación ingresada, los recursos retornan a la cuenta emisora. Adicionalmente, el contrato no puede liquidarse antes de la fecha de liquidación ingresada. Etos valores son parametrizables y se pueden eliminar si se desea
                        \n\n Te invitamos a revisar la consola (En Chrome: click derecho -> inspeccionar -> console) para ver lo que pasa por detrás en tiempo real!`,
            icon: "success",
            button: "Súper",
          });
          break;
        default:
      }
    });
  }, []);

  useEffect(() => {
    setEraseLabel(false);
  }, [eraseLabel]);

  useEffect(() => {
    if (fetchConcluded.current === false) {
      setCardRender(
        <Input
          onChangeInfo={(e) => setInputFromChild(e.target.value)}
          eraseInput={eraseLabel}
        />
      );
    }
  }, [eraseLabel]);

  function xrplCreateEscrow() {
    ledgerAccounts.forEach((element) => {
      if (
        element.accountId === userFromUserHandler + assetFromAccountHandler &&
        element.accountIdToken === tokenFromUserHandler
      ) {
        clientXrplAddress.current = element.xrplAddress;
        clientXrplToken.current = element.xrplAddressSecret; //review
        console.log("Client's address: " + clientXrplAddress.current);
        console.log("Client's token: " + clientXrplToken.current);
      }
      if (element.accountId === escrowCreation.current.destination) {
        destinationXrplAccount.current = element.xrplAddress;
        escrowCreation.current.destination = element.xrplAddress;
        console.log("destination address: " + destinationXrplAccount.current);
      }
      if (
        element.accountId.slice(0, element.accountId.length - 3) ===
        escrowCreation.current.destination
      ) {
        destinationXrplAccount.current = element.xrplAddress;
        escrowCreation.current.destination = element.xrplAddress;
        console.log(
          " 2nd if, destination address: " + destinationXrplAccount.current
        );
      }
    });

    console.log("escrow Json: " + JSON.stringify(escrowCreation.current));
    //Create the escrow in the XRPL

    api.on("error", (errorCode, errorMessage) => {
      console.log(errorCode + ": " + errorMessage);
    });

    api.connect().then(() => {
      api
        .prepareEscrowCreation(
          clientXrplAddress.current,
          escrowCreation.current
        )
        .then((prepare) => {
          console.log("prepare");
          console.log(prepare);
          const { signedTransaction, id } = api.sign(
            prepare.txJSON,
            clientXrplToken.current
          );
          transactionId.current = id;
          console.log("transaction ID: " + transactionId.current);
          api.submit(signedTransaction).then((result) => {
            console.log("result");
            console.log(result);
            console.log("Sequence in result: " + result.tx_json.Sequence);
            sequence.current = result.tx_json.Sequence;
            swal({
              title: "CONTRATO CREADO",
              text:
                "Guarda la siguiente información \n \n" +
                `Secuencia: ${result.tx_json.Sequence} \n \n` +
                `Condición: ${condition.current}\n \n` +
                `Llave liquidación: ${fulfillment.current}`,
              icon: "success",
              button: "Súper",
            }).then(() => {
              history.push(
                `/account/handle_account/choosetx/${assetFromAccountHandler}`
              );
            });

            // alert("Guarda esta información, la necesitarás cuando liquidez el contrato: " + "\n"
            // + "Secuencia: "+ result.tx_json.Sequence + "\n"
            // + "Condición: "+ condition.current + "\n"
            // + "Llave liquidación: "+ fulfillment.current
            // );
          });
        })
        .catch((errorPrepare) => {
          console.log(errorPrepare);
        });
    });
  }

  function generateConditions() {
    // const preimageData = crypto.randomBytes(32);
    // // const myFulfillment = new cc.PreimageSha256();
    // // myFulfillment.setPreimage(preimageData);
    // const myFulfillment = "";

    // escrowCreation.current.condition = myFulfillment
    //   .getConditionBinary()
    //   .toString("hex")
    //   .toUpperCase();
    // condition.current = escrowCreation.current.condition;
    // console.log("Condition: ", condition);
    // // (Random hexadecimal, 72 chars in length)

    // // keep secret until you want to finish executing the held payment:
    // fulfillment.current = myFulfillment
    //   .serializeBinary()
    //   .toString("hex")
    //   .toUpperCase();
    // console.log("Fulfillment: ", fulfillment);
    // // (Random hexadecimal, 78 chars in length)

    // //Call functions for xrpl escrow creation. The ilp payment must be called after the Escrow Execution (other component).
    // xrplCreateEscrow();
  }

  function handleInput() {
    if (labelIndex.current < labelsArray.current.length) {
      if (labelIndex.current === 0) {
        escrowCreation.current.amount = inputFromChild;
        console.log("input from child: " + inputFromChild);
        labelIndex.current += 1;
        setEraseLabel(true);
      } else if (labelIndex.current === 1) {
        escrowCreation.current.destination = inputFromChild;
        console.log("input from child: " + inputFromChild);
        labelIndex.current += 1;
        setEraseLabel(true);
      } else if (labelIndex.current === 2) {
        escrowCreation.current.allowExecuteAfter =
          inputFromChild + "T00:00:00.000-05:00";
        console.log(
          "input from child: " + inputFromChild + "T00:00:00.000-05:00"
        );
        labelIndex.current += 1;
        setEraseLabel(true);
      } else if (labelIndex.current === 3) {
        escrowCreation.current.allowCancelAfter =
          inputFromChild + "T00:00:00.000-05:00";
        console.log("input from child: " + inputFromChild);
        labelIndex.current = 0;
        setEraseLabel(true);
        fetchConcluded.current = true;
        setCardRender(
          <RingLoader size={150} color={"#63FF7E"} css={cssLoader} />
        );

        //Call functions for xrpl escrow creation. The ilp payment must be called in Handle Escrow Execution.
        generateConditions();
      }
    }
  }

  return (
    <Content>
      <PageLabel>{labelsArray.current[labelIndex.current]}</PageLabel>
      {cardRender}
      <AppButton primary onClick={handleInput}>
        Next
      </AppButton>
    </Content>
  );
}
