import {wupoServerUri} from '../Api';

const createOpenCardProduct = async(requestObject, jwtToken) => {
  

    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/clientOpenCard`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const product = await response.json();
      console.log(product);
  
      return product;
    }
  
    else {
      return null;
    }
}

const getProductInfo = async(requestObject, jwtToken) => {
  
    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/cardInfoOpenCard`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const product = await response.json();
      console.log(product);
  
      return product;
    }
  
    else {
      return null;
    }
}

const createCardPayment = async(requestObject, jwtToken) => {
  
    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/paymentOpenCard`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const payment = await response.json();
      console.log(payment);
  
      return payment;
    }
  
    else {
      return null;
    }
}

const getCardStatement = async(requestObject, jwtToken) => {
  
    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/transactionsOpenCard`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const transactions = await response.json();
      console.log(transactions);
  
      return transactions;
    }
  
    else {
      return null;
    }
}

const purchaseOtp= async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/paymentOtp`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const otpResponse = await response.json();
    console.log(otpResponse);

    return otpResponse;
  }

  else {
    return null;
  }
}

const purchaseOtpVerification= async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/paymentOtpVerification`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const otpResponse = await response.json();
    console.log(otpResponse);

    return otpResponse;
  }

  else {
    return null;
  }
}

const purchaseAuth = async(requestObject, jwtToken) => {
  
    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/authOpenCard`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const authResponse = await response.json();
      console.log(authResponse);
  
      return authResponse;
    }
  
    else {
      return null;
    }
}

const qrPayment = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/qrpayment`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const qrResponse = await response.blob();
    console.log(qrResponse);

    return qrResponse;
  }

  else {
    return null;
  }
}

const qrConsumerPayment = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/qrconsumerpayment`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const qrResponse = await response.blob();
    console.log(qrResponse);

    return qrResponse;
  }

  else {
    return null;
  }
}

const loansBatchUpload = async(requestObject, jwtToken) => {
  
  console.log(requestObject.get("loansFile"));

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/parseLoansFile`, {
      method: "POST",
      body: requestObject,
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    console.log("ok");

    return;
  }

  else {
    return null;
  }
}

const paymentsBatchUpload = async(requestObject, jwtToken) => {
  
  console.log(requestObject.get("paymentsFile"));

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/parsePaymentsFile`, {
      method: "POST",
      body: requestObject,
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    console.log("ok");

    return;
  }

  else {
    return null;
  }
}

const definitionsUpload = async(requestObject, jwtToken) => {
  
  console.log(requestObject.get("definitionsFile"));

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/parseDefinitionsFile`, {
      method: "POST",
      body: requestObject,
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    
    console.log("ok");

    return;
  }

  else {
    return null;
  }
}

const cardBlockingCall = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/blockingOpenCard`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const cardunBlockingCall = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/unblockingOpenCard`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
};

const openCardCreditRisk = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/creditRiskOpenCard`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const createIssuer = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/issuerOpenCard`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const getCreditRiskWeights = async(jwtToken) => {

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/getCreditRiskWeights`, {
      method: "POST",
      // body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const setCreditRiskWeights = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/setCreditRiskWeights`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const getScoreRange = async(jwtToken) => {

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/getScoreRange`, {
      method: "POST",
      // body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const createScoreRange = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/newScoreRange`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const updateScoreRange = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/updateScoreRange`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

//Include get and update functions for dependentsWeight, employmentTypeWeight, maritalStatusWeight, monthlyExpensesWeight, monthlyIncomeWeight, otherIncomeWeight, residenceTypeWeight, stratumWeight, studyLevelWeight, totalLiabilitiesWeight, totalAssetsWeight
const getDependentsWeight = async(jwtToken) => {

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/getDependentsWeights`, {
      method: "POST",
      // body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const updateDependentsWeights = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/updateDependentsWeights`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const getEmploymentTypeWeights = async(jwtToken) => {

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/getEmploymentTypeWeights`, {
      method: "POST",
      // body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const updateEmploymentTypeWeights = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/updateEmploymentTypeWeights`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const getMaritalStatusWeights = async(jwtToken) => {

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/getMaritalStatusWeights`, {
      method: "POST",
      // body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const updateMaritalStatusWeights = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/updateMaritalStatusWeights`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const getMonthlyExpensesWeights = async(jwtToken) => {

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/getMonthlyExpensesWeights`, {
      method: "POST",
      // body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const updateMonthlyExpensesWeights = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/updateMonthlyExpensesWeights`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const getMonthlyIncomeWeights = async(jwtToken) => {

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/getMonthlyIncomeWeights`, {
      method: "POST",
      // body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const updateMonthlyIncomeWeights = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/updateMonthlyIncomeWeights`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const getOtherIncomeWeights = async(jwtToken) => {

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/getOtherIncomeWeights`, {
      method: "POST",
      // body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const updateOtherIncomeWeights = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/updateOtherIncomeWeights`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const getResidenceTypeWeights = async(jwtToken) => {

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/getResidenceTypeWeights`, {
      method: "POST",
      // body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const updateResidenceTypeWeights = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/updateResidenceTypeWeights`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const getStratumWeights = async(jwtToken) => {

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/getStratumWeights`, {
      method: "POST",
      // body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const updateStratumWeights = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/updateStratumWeights`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const getStudyLevelWeights = async(jwtToken) => {

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/getStudyLevelWeights`, {
      method: "POST",
      // body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const updateStudyLevelWeights = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/updateStudyLevelWeights`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const getTotalLiabilitiesWeights = async(jwtToken) => {

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/getTotalLiabilitiesWeights`, {
      method: "POST",
      // body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const updateTotalLiabilitiesWeights = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/updateTotalLiabilitiesWeights`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const getTotalAssetsWeights = async(jwtToken) => {

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/getTotalAssetsWeights`, {
      method: "POST",
      // body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

const updateTotalAssetsWeights = async(requestObject, jwtToken) => {
  
  console.log(requestObject);

  const response = await fetch(`${wupoServerUri.devnet}/api/secure/updateTotalAssetsWeights`, {
      method: "POST",
      body: JSON.stringify(requestObject),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseObject = await response.json();
    console.log(responseObject);

    return responseObject;
  }

  else {
    return null;
  }
}

export{ createOpenCardProduct, getProductInfo, createCardPayment, getCardStatement, purchaseAuth, qrPayment, purchaseOtp,
  purchaseOtpVerification, loansBatchUpload, paymentsBatchUpload, definitionsUpload, cardBlockingCall, openCardCreditRisk,
  cardunBlockingCall, qrConsumerPayment, createIssuer, getCreditRiskWeights, setCreditRiskWeights, getScoreRange,
  createScoreRange, updateScoreRange, getDependentsWeight, updateDependentsWeights, getEmploymentTypeWeights,
  updateEmploymentTypeWeights, getMaritalStatusWeights, updateMaritalStatusWeights, getMonthlyExpensesWeights,
  updateMonthlyExpensesWeights, getMonthlyIncomeWeights, updateMonthlyIncomeWeights, getOtherIncomeWeights,
  updateOtherIncomeWeights, getResidenceTypeWeights, updateResidenceTypeWeights, getStratumWeights, updateStratumWeights,
  getStudyLevelWeights, updateStudyLevelWeights, getTotalLiabilitiesWeights, updateTotalLiabilitiesWeights, 
  getTotalAssetsWeights, updateTotalAssetsWeights}