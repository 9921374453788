import React from 'react';
import { getPendingUsernames, getFinancialDocs, handleDocumentedIncome } from '../utilityFunctions/CreditUtil';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button} from '@mui/material';
import Colors from "../components/ui/Colors";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import NumberFormat from "react-number-format";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const docsUser = {
    username: "",
};

const incomeRequest = {
    username: null,
    income: null,
}

export default function ClientDocsReview(props) {

    const{loginUser, } = props;

    const history = useHistory();

    const [loading, setLoading] = React.useState(false);

    const [usersList, setUsersList] = React.useState();

    React.useEffect(()=> {
        getUsernames();
    }, []);

    const getUsernames = async () => {
        setLoading(true);
        // usernamesArray = await getAllUsernames(loginUser.jwtToken);
        const usernamesArray = await getPendingUsernames(loginUser.jwtToken);

        if(!usernamesArray){
            swal({
                title: "Sin pendientes",
                text: "No hay documentados penditnes",
                icon: "info",
                button: "ok",
            }).then(() => {
                history.push({pathname: "/handle_profile"});
            })
            return; 
        }

        if(usernamesArray){
            setUsersList(
                {
                    options: usernamesArray,
                    getOptionLabel: (option) => option.username,
                }
            );
        }
        setLoading(false);
    };

    const onChangeAutocomplete = (event, value) => {
        if(!value){
            return;
        }
        docsUser.username = value.username;
    };

    const[employmentCert, setEmploymentCert] = React.useState(null);
    const[incomeCert, setIncomeCert] = React.useState(null);

    const getDocs = async () => {
        setLoading(true);
        
        setEmploymentCert(null);
        setIncomeCert(null);

        const financialDocs = await getFinancialDocs(docsUser, loginUser.jwtToken);

        if(!financialDocs){
            swal({
                title: "No Existe",
                text: "El cliente no tiene documentos cargados",
                icon: "info",
                button: "Entiendo",
            });
            setLoading(false);
            return; 
        }

        financialDocs.documentedLoanDocs.forEach((doc) => {
            if(doc.documentType === "employmentCert"){

                const fileType = doc.contentType ? doc.contentType : "application/octet-stream";

                const byteImage = Buffer.from(doc.document, 'base64');
                setEmploymentCert( new Blob([byteImage], {type: fileType}));
            }
            if(doc.documentType === "incomeCert"){

                const fileType = doc.contentType ? doc.contentType : "application/octet-stream";

                const byteImage = Buffer.from(doc.document, 'base64');
                setIncomeCert( new Blob([byteImage], {type: fileType}));
            }

            swal({
                title: "Busqueda Exitosa",
                icon: "success",
                button: "Súper",
            });
            setLoading(false);
        });

    };

    const openEmploymentCert = () => {
        const objectUrl = URL.createObjectURL(employmentCert);
        window.open(objectUrl, '_blank');
    };

    const openIncomeCert = () => {
        const objectUrl = URL.createObjectURL(incomeCert);
        window.open(objectUrl, '_blank');
    };

    const [incomeValue, setIncomeValue] = React.useState("");

    const valueChange = (e) => {
        setIncomeValue(e.floatValue);
        incomeRequest.documentedIncome = e.floatValue;
    };

    const submitIncome = async () => {
        incomeRequest.username = loginUser.accountId;

        setLoading(true);
        const userResponse = await handleDocumentedIncome(incomeRequest, loginUser.jwtToken);
        setLoading(false);

        if(!userResponse){
            swal({
                title: "Error",
                text: "Error al cargar la información",
                icon: "error",
                button: "Entiendo",
            });
            setLoading(false);
            return; 
        }

        swal({
            title: "Cargue exitoso",
            icon: "success",
            button: "ok",
        }).then(()=> {
            history.push({pathname: "/handle_profile"});
        });
        
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                }}
            >
                {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                    <Grid container direction="row" spacing={1} sx={{width: { xs: "95%", sm: "80%", md: "40%"}}}>
                        <Grid item xs={12} sm={12} md={12} sx={{width: "100%", display: "flex", justifyContent: "center"}}>
                            <Autocomplete
                                {...usersList}
                                fullWidth
                                sx={{width: "80%"}}
                                id="usersList"
                                clearOnEscape
                                autoComplete = {true}
                                noOptionsText = {"Usuario no existe"}
                                // sx={{ width: 600 }}
                                onChange={onChangeAutocomplete}
                                // onInputChange={onChange}
                                renderInput={(params) => (
                                    <TextField {...params} label={<Typography variant="body2">Corre electrónico</Typography>} variant="standard" />
                                )}
                            /> 
                        </Grid>
                        {employmentCert && incomeCert &&
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1rem", marginBottom: "1rem"}}>
                                <NumberFormat
                                    variant="standard"
                                    style={{width: "80%", height: "90%"}}
                                    name="incomeValue"
                                    customInput={TextField}
                                    prefix={'$ '}
                                    type="incomeValue"
                                    label={<Typography variant="body2">Ingresos Documentados</Typography>}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    autoComplete="off"
                                    value={incomeValue}
                                    onValueChange={(values) => {
                                        valueChange(values);
                                    }}
                                />
                            </Grid>
                        }
                        <Grid item xs={6} sm={6} md={6}>
                            <Button onClick={getDocs} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                Buscar
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                            <Button onClick={submitIncome} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                Cargar
                            </Button>
                        </Grid>
                        {employmentCert &&
                            <Grid item xs={12} sm={6} md={6}>
                                <Button onClick={openEmploymentCert} sx={{marginTop: "2rem", backgroundColor: "none", color: "gray", fontSize: "1rem", fontWeight: "bold"}}>
                                    Certificado Laboral
                                </Button>
                            </Grid>
                        }
                        {incomeCert &&
                            <Grid item xs={12} sm={6} md={6}>
                                <Button onClick={openIncomeCert} sx={{marginTop: "2rem", backgroundColor: "none", color: "gray", fontSize: "1rem", fontWeight: "bold"}}>
                                    Desprendible Nómina
                                </Button>
                            </Grid>
                        }
                    </Grid>
                }
            </Box>
        </Box>
    )
}
