import React from 'react';
import {getCardStatement, createCardPayment} from "../utilityFunctions/OpenCardUtil";
import swal from "sweetalert";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Button, Typography, DialogContent } from "@mui/material";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Colors from "../components/ui/Colors";
import Bancolombia from "../img/creditcards/logo-bancolombia.png";
import NumberFormat from "react-number-format";
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
// import CardImg from "../img/creditcards/card2.png";
import CardImg from "../img/creditcards/cardClean.png";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import { useTranslation } from 'react-i18next';
import { useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    overlayContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: "100%",
        padding: "8px",
        borderRadius: '5px',
    },
}));

const dateOptions = {
    // weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
};

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const statementRequest = {
    internalCardCode: "",
};

const paymentObject = {
    username: "",
    internalCardCode: "",
    operationAmount: "",
    paymentDirectedTo: "",
};

let isPayment = false;

export default function CreditCardStatement(props) {

    const {loginUser} = props;

    const history = useHistory();

    const classes = useStyles();

    const [loading, setLoading] = React.useState(false);

    const { t } = useTranslation();

    React.useEffect(()=> {
        isPayment = false;
        paymentObject.username = loginUser.accountId;
        getStatement();
    }, []);

    const getStatement = async () => {
        setLoading(true);
        if(!props.location.state || !props.location.state.product){
            return;
        };
        console.log(props.location.state.product);
        statementRequest.internalCardCode = props.location.state.product.internalCode;

        const statementResponse = await getCardStatement(statementRequest, loginUser.jwtToken);
        
        setLoading(false);

        if(!statementResponse){
            swal({
                title: t("CreditCardStatement.noMovements"),
                icon: "info",
                button: "ok",
            }).then(() => {
                history.push("/creditCard/mount");
            });

            return;
        };

        if(statementResponse[0] && statementResponse[0].errorMessage === "00001 Tarjeta sin movimientos"){
            swal({
                title: t("CreditCardStatement.noMovements"),
                icon: "info",
                button: "ok",
            }).then(() => {
                history.push("/creditCard/mount");
            });
            return;
        }

        renderStatement(statementResponse);

    };

    const[statementComponent, setStatementComponent] = React.useState();

    const renderStatement = (statementResponse) => {

        // console.log(statementResponse);

        if(!statementResponse){return}

        const initialBin = props.location.state.product.bin[0];
        console.log(initialBin);

        statementResponse.sort((a, b) => new Date(b.transactionDate) - new Date(a.transactionDate));
        
        setStatementComponent(
            <Grid container spacing={2} sx={{justifyContent: {xs: "center", sm: "center"}, alignItems: "center", width: "90%", marginTop: {xs: "10%", sm: "0%"}}}>
                    <Card sx={{maxWidth: "400px", minWidth: {xs: "100%", sm: "90%", md: "300px", borderRadius: "15px"}}}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "center", width: "85%", position: "relative"}}>
                                {/* <CardMedia
                                    component="img"
                                    // image={CardImg}
                                    image="/images/creditcards/opencard-card.png"
                                    alt="Card"
                                    sx={{width: "95%"}}
                                /> */}
                                {initialBin && initialBin === "3" ?
                                    <CardMedia
                                        component="img"
                                        // image={CardImg}
                                        image= "/images/creditcards/card_Amex2.png"
                                        alt="Card"
                                    />
                                    : initialBin && initialBin === "4" ?
                                    <CardMedia
                                        component="img"
                                        // image={CardImg}
                                        image= "/images/creditcards/opencard-card.png"
                                        alt="Card"
                                    />
                                    : initialBin && initialBin === "5" ?
                                    <CardMedia
                                        component="img"
                                        // image={CardImg}
                                        image= "/images/creditcards/card_mc.png"
                                        alt="Card"
                                    />
                                    :
                                    <CardMedia
                                        component="img"
                                        // image={CardImg}
                                        image= "/images/creditcards/card_brand.png"
                                        alt="Card"
                                    />
                                }
                                <Box className={classes.overlayContainer}>
                                    <Typography variant="h6" sx={{color: "black", textAlign: "center", marginTop: "37%", letterSpacing: '0.2rem'}}>{props.location.state.product.cardNumber}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                                <Box sx={{ display: "flex", justifyContent: "center", width: "100%", maxHeight: "200px", overflowY: "auto" }}>
                                    <Grid container sx={{width: "90%"}}>
                                    {statementResponse.map((operation) => {
                                        return(
                                            <> 
                                            {(operation.transactionDate && operation.transactionAmount) ?
                                            <>
                                            
                                                <Grid key={generateRandomKey()}  item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "start", textAlign: "start", marginTop: "0.8rem", marginBottom: "0.2rem"}}>
                                                    <Typography key={generateRandomKey()}  variant="body2" sx={{color: "gray", fontSize: "0.7rem"}}>{`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(operation.transactionDate))}`}</Typography>
                                                </Grid>
                                                <Grid key={generateRandomKey()}  item xs={5} sm={5} md={5} lg={5} onClick={()=>toPayment(operation)} sx={{display: "flex", justifyContent: "start", textAlign: "start", cursor: "pointer"}}>
                                                    <Typography variant="body2">{`$ ${new Intl.NumberFormat('es-CO').format(operation.transactionAmount)}`}</Typography>
                                                </Grid>
                                                <Grid key={generateRandomKey()}  item xs={6} sm={6} md={6} lg={6} onClick={()=>toPayment(operation)} sx={{display: "flex", justifyContent: "center", textAlign: "center", cursor: "pointer"}}>
                                                    {operation.merchantName ? 
                                                        <Typography variant="body2">{operation.merchantName}</Typography>
                                                    : operation.internalTrxDescription ?
                                                    <Typography variant="body2">{operation.internalTrxDescription}</Typography>
                                                    : operation.transactionConcept === "Pago"?
                                                    <Typography variant="body2">{t("CreditCardStatement.onlinePayment")}</Typography>
                                                    :
                                                    <Typography variant="body2">{t("CreditCardStatement.onlinePurchase")}</Typography>
                                                    }
                                                </Grid>
                                                <Grid key={generateRandomKey()}  item xs={1} sm={1} md={1} lg={1} onClick={()=>toPayment(operation)} sx={{display: "flex", justifyContent: "center", textAlign: "center", cursor: "pointer"}}>
                                                    <NavigateNextIcon sx={{color: "#03a9f4"}}/>
                                                </Grid>
                                                {operation.transactionTerm || operation.transactionTerm >= 0 ? 
                                                <>
                                                    <Grid key={generateRandomKey()}  item xs={5} sm={5} md={5} lg={5} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                                    </Grid>
                                                    <Grid key={generateRandomKey()}  item xs={6} sm={6} md={6} lg={6} onClick={()=>toPayment(operation)} sx={{display: "flex", justifyContent: "center", textAlign: "center", cursor: "pointer"}}>
                                                        <Typography variant="body2" sx={{color: "gray", fontSize: "0.7rem"}}>{`(${operation.transactionTerm} ${t("CreditCardStatement.installments")})`}</Typography>
                                                    </Grid>
                                                    <Grid key={generateRandomKey()}  item xs={1} sm={1} md={1} lg={1} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                                    </Grid>
                                                </>
                                                :<></>
                                                }
                                                <Grid key={generateRandomKey()}  item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "0.2rem", marginBottom: "0.2rem"}}>
                                                    <Divider/>
                                                </Grid>
                                            
                                            </>
                                            :<></>}
                                            </>
                                        )
                                    })}
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", marginTop: "1.5rem", marginBottom: "1rem"}}>
                                <Button onClick={toMount} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                                    {t("CreditCardStatement.back")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
        );

    };

    const toMount = () => {
        history.push("/creditCard/mount");
    };

    const generateRandomKey = () => {
        return Math.random().toString(36).substr(2, 9);
    };

    const[paymentDialog, setPaymentDialog] = React.useState(false);

    const closePaymentDialog = () => {
        setPaymentDialog(false);
    };

    const[paymentAction, setPaymentAction] = React.useState("");

    const toPayment = (product) => {
        
        setPaymentAction(
            <List sx={{width: "100%", marginTop: "1rem"}}>
                <Grid container spacing={2}>
                    {product.authorizationNumber ?
                        <ListItem disablePadding sx={{marginBottom: "2.5rem"}}>
                            <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                                <Typography variant="h6">{`Autorización: ${product.authorizationNumber}`}</Typography>
                            </Grid>
                        </ListItem>
                    : product.sequence ?
                        <ListItem disablePadding sx={{marginBottom: "2.5rem"}}>
                            <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                                <Typography variant="h6">{`${t("CreditCardStatement.secuence")}: ${product.sequence}`}</Typography>
                            </Grid>
                        </ListItem>
                        :<></>
                    }
                    {product.sequence && product.transactionConcept !== "Pago"?
                        <>
                        <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                            <ListItemButton  onClick={() => handleTotalPayment(product)}>
                                <Grid item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                    <Typography variant="body2">{`${t("CreditCardStatement.totalPayment")}:`}</Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "end", textAlign: "end"}}>
                                    <Typography variant="body2">{product.totalBalance ? `$ ${new Intl.NumberFormat('es-CO').format(product.totalBalance)}` : "0"}</Typography>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <Grid item xs={6} sm={4} md={3} lg={3} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                <Typography variant="body2">{`${t("CreditCardStatement.otherValue")}:`}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={8} md={9} lg={9} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1rem", marginBottom: "1rem"}}>
                                <NumberFormat
                                    variant="standard"
                                    style={{width: "95%", height: "50%"}}
                                    name="inputPayment"
                                    customInput={TextField}
                                    prefix={'$ '}
                                    type="inputPayment"
                                    label={<Typography variant="body2">{t("CreditCardStatement.value")}</Typography>}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    autoComplete="off"
                                    value={inputPayment}
                                    onValueChange={(values) => {
                                        valueChange(values, product);
                                    }}
                                />
                            </Grid>
                        </ListItem>
                        </>
                        : <></>
                    }
                </Grid>
            </List>
        );
        
        // setIsPayment(false);
        setPaymentDialog(true);
                                
    };

    const[inputPayment, setInputPayment] = React.useState("");

    const valueChange = (e, product) => {

        if(!e.floatValue){
            isPayment = false;
        }else{
            isPayment = true;
        }

        if(!product.totalBalance){
            setInputPayment("");
            swal({
                title: t("CreditCardStatement.paid"),
                text: t("CreditCardStatement.paidText"),
                icon: "info",
                button: "ok",
            });
            toPayment(product);
            return;
        }
        
        if(product.totalBalance && (e.floatValue > product.totalBalance)){
            setInputPayment("");
            swal({
                title: t("CreditCardStatement.maxValueTitle"),
                text: t("CreditCardStatement.maxValueText"),
                icon: "info",
                button: "ok",
            });
            toPayment(product);
            return;
        }

        setInputPayment(e.floatValue);
        paymentObject.operationAmount = e.floatValue;
        paymentObject.internalCardCode = statementRequest.internalCardCode;
        paymentObject.paymentDirectedTo = product.sequence;

    };

    const handleInputPayment = async () => {
        setLoading(true);
        const paymentResponse = await createCardPayment(paymentObject, loginUser.jwtToken);
        setLoading(false);

        if(!paymentResponse){
            swal({
                title: t("CreditCardStatement.unsuccessfulPaymentTitel"),
                text: t("CreditCardStatement.unsuccessfulPaymentText"),
                icon: "error",
                button: "ok",
            }).then(() => {
                setPaymentDialog(false);
            });
            
            return;
        }

        swal({
            title: t("CreditCardStatement.successfulPaymentTitle"),
            icon: "success",
            button: "Súper",
        }).then(() => {
            setPaymentDialog(false);
        });
    };

    const handleTotalPayment = async (product) => {

        if(!product.totalBalance){
            setInputPayment("");
            swal({
                title: t("CreditCardStatement.paid"),
                text: t("CreditCardStatement.paidText"),
                icon: "info",
                button: "Entiendo",
            });
            toPayment(product);
            return;
        }

        const paymentEnabled = await paymentWarning();
        
        console.log(paymentEnabled);
        if(!paymentEnabled){
            setPaymentDialog(false);
            return;
        }
        else{
            paymentObject.operationAmount = product.transactionAmount;
            paymentObject.internalCardCode = statementRequest.internalCardCode;
            paymentObject.paymentDirectedTo = product.sequence;
            
            setLoading(true);
            const paymentResponse = await createCardPayment(paymentObject, loginUser.jwtToken);
            setLoading(false);

            if(!paymentResponse){
                swal({
                    title: t("CreditCardStatement.unsuccessfulPaymentTitel"),
                    text: t("CreditCardStatement.unsuccessfulPaymentText"),
                    icon: "error",
                    button: "ok",
                }).then(() => {
                    setPaymentDialog(false);
                });
                
                return;
            }

            swal({
                title: t("CreditCardStatement.successfulPaymentTitle"),
                icon: "success",
                button: "Súper",
            }).then(() => {
                setPaymentDialog(false);
            });
        }
    };

    const paymentWarning = () =>{
        return new Promise((resolve, reject) => {
            swal({
                title: t("CreditCardStatement.confirmPaymentTitle"),
                text: t("CreditCardStatement.confirmPaymentText"),
                icon: "info",
                buttons: [t("CreditCardStatement.confirmPaymentBack"), t("CreditCardStatement.confirmPaymentContinue")]
            }).then((result) => {
                resolve(result);
            });
        })
        
    }

    return (
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", backgroundColor: "#ededed",}}>
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                <Box
                    sx={{
                        display: "flex",
                        // flex: 1,
                        justifyContent: "center",
                        alignItems: {xs: "start", sm: "center", md: "center"},
                        width: "100%",
                        height: "100%",
                        textAlign: "center",
                        overflowY: "scroll",
                        
                    }}
                >
                    {statementComponent}

                    <Dialog open={paymentDialog} onClose={closePaymentDialog} fullWidth>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1rem"}}>
                            <Box
                                component="img"
                                sx={{
                                    height: 35,
                                    width: 210,
                                }}
                                alt="Logo"
                                src="/images/creditcards/logo-opencard.png"
                            />
                        </Grid>
                    </Grid>
                    <DialogContent>
                        {paymentAction}                 
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closePaymentDialog} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                            {t("CreditCardStatement.back")}
                        </Button>
                        {isPayment?
                        <Button onClick={handleInputPayment} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                            {t("CreditCardStatement.pay")}
                        </Button>
                        :<></>
                        }
                    </DialogActions>
                </Dialog>
                </Box>
            }
        </Box>
    )

    
}
