import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Colors from "../../components/ui/Colors";
import { Button, Typography } from "@mui/material";
import Card from '@mui/material/Card';
import {getInactiveProducts, getPic } from "../../utilityFunctions/MarketPlaceUtil";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import { makeStyles } from '@mui/styles';
import { useHistory } from "react-router-dom";

const inlineSvg =  `<svg xmlns='http://www.w3.org/2000/svg'  width='120' height='120' viewBox='0 0 120 120'><rect fill='#ddffaa' width='120' height='120'/><polygon  fill='#AE9' fill-opacity='1' points='120 120 60 120 90 90 120 60 120 0 120 0 60 60 0 0 0 60 30 90 60 120 120 120 '/></svg>`;

const useStyles = makeStyles({
    svgBackground: {
      background: `url("data:image/svg+xml;utf8,${encodeURIComponent(inlineSvg)}") center center/cover no-repeat`,
    },
});

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export default function ProductsHistory(props) {
    const {loginUser} = props;

    let history = useHistory();

    const classes = useStyles();

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        callActiveCattles();
    }, []);

    const [products, setProducts] = React.useState();


    const callActiveCattles = async () => {


        const activeCattles = await getInactiveProducts(loginUser.jwtToken);

        if(!activeCattles){
            return;
        }

        setProducts(
            activeCattles.map((cattle) => {
                return(
                    <>
                        <Grid key={cattle.id} item xs={12} sm={6} md={4} lg={4} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Card key={cattle.id} onClick={() => productDetail(cattle)} sx={{width: "90%", height: "90%", cursor: "pointer", maxWidth: "900px", borderRadius: "25px"}}>
                                <Grid key={cattle.id+1} container direction = "column">
                                    <Grid key={cattle.id+2} item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <Box 
                                            component="img"
                                            sx={{
                                            height: "90%",
                                            width: "100%",
                                            justifyContent: "center",
                                            }}
                                            alt="Foto"
                                            src={URL.createObjectURL(cattle.cattleLotPic.cattlePic)}

                                        >
                                        </Box>
                                    </Grid>
                                    <Grid key={cattle.id+3} item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", marginTop:"0.5rem", marginBottom: "1rem"}}>
                                        <Grid container key={cattle.id+4} sx={{width: "90%"}}>
                                            <Grid key={cattle.id+5} item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", marginTop:"0.5rem", marginBottom: "0.2rem"}}>
                                                <Typography key={cattle.id}  variant="h6" sx={{fontWeight: "bold", width: "100%"}}>{cattle.code}</Typography>
                                            </Grid>
                                            <Grid key={cattle.id-1} item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", marginTop:"0.2rem", marginBottom: "0.2rem"}}>
                                                <Typography key={cattle.id-1}  variant="body2" sx={{fontWeight: "bold", width: "100%"}}>
                                                    {cattle.isSettled === true ? "Cerrado" : "En Operación"}
                                                </Typography>
                                            </Grid>

                                            <Grid key={cattle.id+6} item xs={8} sm={8} md={8} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={cattle.id+1} variant="body2" sx={{ width: "100%"}}>Rentabilidad Esperada:</Typography>
                                            </Grid>
                                            <Grid key={cattle.id+7} item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={cattle.id+2} variant="body2" sx={{ width: "100%"}}>{`${cattle.expectedRevenue*100}% E.A.`}</Typography>
                                            </Grid>

                                            <Grid key={cattle.id+8} item xs={8} sm={8} md={8} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={cattle.id+3} variant="body2" sx={{ width: "100%"}}>Duración:</Typography>
                                            </Grid>
                                            <Grid key={cattle.id+9} item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={cattle.id+4} variant="body2" sx={{ width: "100%"}}>{`${cattle.duration} meses`}</Typography>
                                            </Grid>

                                            <Grid key={cattle.id+10} item xs={8} sm={8} md={8} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={cattle.id+5} variant="body2" sx={{ width: "100%"}}>Raza:</Typography>
                                            </Grid>
                                            <Grid key={cattle.id+11} item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={cattle.id+6} variant="body2" sx={{ width: "100%"}}>{`${cattle.breed}`}</Typography>
                                            </Grid>

                                            <Grid key={cattle.id+12} item xs={8} sm={8} md={8} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={cattle.id+7} variant="body2" sx={{ width: "100%"}}>Cabezas de Ganado:</Typography>
                                            </Grid>
                                            <Grid key={cattle.id+13} item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={cattle.id+8} variant="body2" sx={{ width: "100%"}}>{`${new Intl.NumberFormat('es-CO').format(cattle.animalsAmount)}`}</Typography>
                                            </Grid>

                                            <Grid key={cattle.id+14} item xs={8} sm={8} md={8} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={cattle.id+9} variant="body2" sx={{ width: "100%"}}>Peso Total:</Typography>
                                            </Grid>
                                            <Grid key={cattle.id+15} item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={cattle.id+10} variant="body2" sx={{ width: "100%"}}>{`${new Intl.NumberFormat('es-CO').format(cattle.totalWeight)} Kg`}</Typography>
                                            </Grid>

                                            <Grid key={cattle.id+16} item xs={8} sm={8} md={8} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={cattle.id+11} variant="body2" sx={{ width: "100%"}}>Ubicación:</Typography>
                                            </Grid>
                                            <Grid key={cattle.id+17} item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={cattle.id+12} variant="body2" sx={{ width: "100%"}}>{`${cattle.location}`}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>   
                        </Grid>
                    </>
                    )
            })
        );

        setLoading(false);

    };

    const productDetail = (cattle) => {
        console.log(cattle);

        // history.push({ 
        //     pathname: "/selling/productDetail",
        //     state:{
        //         product: cattle,
        //     }
        // });

    };

    return(
        <Box className={classes.svgBackground} sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    flexGrow: "1",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    // backgroundColor: "#ededed",
                    // backgroundColor: "#A8E6CE",
                    // backgroundImage: "linear-gradient(to bottom right, #0CCDA3, #C1FCD3)"
                    // height: "85%",
                    // backgroundImage: "linear-gradient(to right, #E100FF, #7F00FF)"
                }}
            >
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                <Grid container spacing={3} sx={{width: {xs: "95%", sm: "95%", md: "85%"}, alignItems: "center", marginTop:{xs: "0.5rem", sm: "0.5rem", md: 0}, marginBottom: {xs: "3rem", sm: "2rem", md: "1.6rem"}}}>
                    {products}    
                </Grid>
            }
            </Box>
        </Box>
    )
}

