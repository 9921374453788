import React from 'react';
import {getProductInfo} from "../utilityFunctions/OpenCardUtil";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import { Box } from '@mui/system';
import Colors from "../components/ui/Colors";
import { useHistory} from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const productRequest = {
    username: "",
};

export default function CreditCardMount(props) {

    const {loginUser} = props;

    const history = useHistory();

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        // getProducts();
        handleProfile();
    }, []);

    const handleProfile = () => {
        // console.log(loginUser);
        if(loginUser.roles && !(Object.keys(loginUser.roles).length === 0 && loginUser.roles.constructor === Object)){
            
            if(loginUser.roles.some((rol) => rol.authority === "MERCHANT")){
                history.push("/creditCard/qr-payment");
                return;
            }

            // else if(loginUser.roles.some((rol) => rol.authority === "ADMIN")){
            //     history.push("/handle_profile");
            //     return;
            // }

            else{
                getProducts();
            }
        }

        else{
            getProducts();
        }
    }

    const getProducts = async ()=> {
        setLoading(true);
        productRequest.username = loginUser.accountId;
        const productsResponse = await getProductInfo(productRequest, loginUser.jwtToken);
        console.log(productsResponse);

        if(!productsResponse){

            // history.push("/creditCard/no-cards");

            if(props?.location?.state?.cardDeclined===true || props?.location?.state?.navBar===true){
                history.push("/creditCard/no-cards");
                return;
            }

            history.push("/creditCard/offer");
            setLoading(false);
            return;
        }

        history.push({
            pathname: "/creditCard/management",
            state:{
                products: productsResponse,
            }
        });
        setLoading(true);
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
            sx={{
                display: "flex",
                // flex: 1,
                justifyContent: "center",
                alignItems: "center",
                width: "95%",
                height: "100%",
                textAlign: "center",
                marginTop: {xs: "1.3rem", sm: 0},
            }}
            >
                {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :<></>}
            </Box>
        </Box>
    )
}
