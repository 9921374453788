import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Button, DialogContent, Typography } from "@mui/material";
import Colors from "../../../components/ui/Colors";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import {clientSettlement, settlementPaymentUpdate} from "../../../utilityFunctions/MarketPlaceUtil";
import { useHistory} from "react-router-dom";

let currentSettlement;

let cattlePayments

export default function ManualPaymentDetail(props) {

  const {loginUser} = props;

  const history = useHistory();

  React.useEffect(() => {
    currentSettlement = "";
    renderPayments();
  }, []);

  const [payments, setPayments] = React.useState();

  const initialValues = () => {
    if(props.location.state && props.location.state.cattleSold){
      console.log(props.location.state.cattleSold);
      return props.location.state.cattleSold;
    }
  };

  const renderPayments = async () => {

    cattlePayments = await initialValues().clientsPorfolioSettlement;
    console.log(cattlePayments);
    setPayments(
      <List sx={{width: "100%", marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}}}>
        <Grid container spacing={2}>
          {cattlePayments.map((payment) => {
            return(
              <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                <ListItemButton  onClick={() => handlePayment(payment)}>
                  <Grid key={payment.id} item xs={3} sm={3} md={3} sx={{display: { xs: "none", sm: "flex", md: "flex"}, justifyContent: "center", alignItems: "center"}}>
                      <Typography key={payment.id} variant="body1" sx={{color: "black"}}>
                          {`${payment.completeName}`}
                      </Typography>
                  </Grid>
                  <Grid key={payment.id} item xs={4} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <Typography key={payment.id} variant="body1" sx={{color: "black"}}>
                          {`${payment.idNumber}`}
                      </Typography>
                  </Grid>
                  <Grid key={payment.id} item xs={3} sm={3} md={3} sx={{display: { xs: "none", sm: "flex", md: "flex"}, justifyContent: "center", alignItems: "center"}}>
                      <Typography key={payment.id} variant="body1" sx={{color: "black"}}>
                          {`$ ${new Intl.NumberFormat('es-CO').format(payment.totalPayment)}`}
                      </Typography>
                  </Grid>
                  <Grid key={payment.id} item xs={4} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <Typography key={payment.id} variant="body1" sx={{color: "black"}}>
                          {payment.isSigned ? "Firmado" : "No Firmado"}
                      </Typography>
                  </Grid>
                  <Grid key={payment.id} item xs={4} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <Typography key={payment.id} variant="body1" sx={{color: "black"}}>
                          {payment.isPaid ? "Pago" : "No Pago"}
                      </Typography>
                  </Grid>
                </ListItemButton>
              </ListItem>
            );
          })

          }
        </Grid>
      </List>
    );

  };

  const updatePayments = async () => {

    console.log(cattlePayments);
    setPayments(
      <List sx={{width: "100%", height:{xs: "90%", sm: "90%", md: "95%"}, marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}, marginBottom: {xs: "2rem", sm: "2rem", md: "2rm"}}}>
        <Grid container spacing={2}>
          {cattlePayments.map((payment) => {
            return(
              <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                <ListItemButton  onClick={() => handlePayment(payment)}>
                  <Grid key={payment.id} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <Typography key={payment.id} variant="body2" sx={{color: "black"}}>
                          {`${payment.completeName}`}
                      </Typography>
                  </Grid>
                  <Grid key={payment.id} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <Typography key={payment.id} variant="body2" sx={{color: "black"}}>
                          {`${payment.idNumber}`}
                      </Typography>
                  </Grid>
                  <Grid key={payment.id} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <Typography key={payment.id} variant="body2" sx={{color: "black"}}>
                          {`$ ${new Intl.NumberFormat('es-CO').format(payment.totalPayment)}`}
                      </Typography>
                  </Grid>
                  <Grid key={payment.id} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <Typography key={payment.id} variant="body2" sx={{color: "black"}}>
                          {payment.isSigned ? "Firmado" : "No Firmado"}
                      </Typography>
                  </Grid>
                  <Grid key={payment.id} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <Typography key={payment.id} variant="body2" sx={{color: "black"}}>
                          {payment.isPaid ? "Pago" : "No Pago"}
                      </Typography>
                  </Grid>
                </ListItemButton>
              </ListItem>
            );
          })

          }
        </Grid>
      </List>
    );

  };

  const [detailBox, setDetailBox] = React.useState(false);
  const [renderDetail, setRenderDetail] = React.useState();

  const handlePayment = (selectedPayment) => {

    currentSettlement = selectedPayment;
    console.log(currentSettlement);

    setRenderDetail(
      <Grid container spacing={1} sx={{alignItems: "center", marginTop:{xs: "0.2rem", sm: "0.2rem", md: "0.2rem"}, maxWidth: "32rem"}}>
        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", marginTop:"0.2rem", marginBottom: "0.2rem"}}>
            <Typography variant="h6" sx={{fontWeight: "bold", width: "100%"}}> {`$ ${new Intl.NumberFormat('es-CO').format(selectedPayment.totalPayment)}`}</Typography>
        </Grid>
        
        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.2rem"}}>
            <Typography variant="body2" sx={{ fontWeight: "bold", width: "100%"}}>Nombre:</Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.2rem"}}>
            <Typography variant="body2" sx={{ width: "100%"}}>{`${selectedPayment.completeName}`}</Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.2rem"}}>
            <Typography variant="body2" sx={{ fontWeight: "bold", width: "100%"}}>Identificación:</Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.2rem"}}>
            <Typography variant="body2" sx={{ width: "100%"}}>{`${selectedPayment.idNumber}`}</Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.2rem"}}>
            <Typography variant="body2" sx={{ fontWeight: "bold", width: "100%"}}>Cuenta Número:</Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.2rem"}}>
            <Typography variant="body2" sx={{ width: "100%"}}>{`${selectedPayment.bankAccount.bankAccount}`}</Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.2rem"}}>
            <Typography variant="body2" sx={{ fontWeight: "bold", width: "100%"}}>Tipo de Cuenta:</Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.2rem"}}>
            <Typography variant="body2" sx={{ width: "100%"}}>{`${selectedPayment.bankAccount.bankAccountType}`}</Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.2rem"}}>
            <Typography variant="body2" sx={{ fontWeight: "bold", width: "100%"}}>Banco:</Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.2rem"}}>
            <Typography variant="body2" sx={{ width: "100%"}}>{`${selectedPayment.bankAccount.bankCode}`}</Typography>
        </Grid>
      </Grid>
    );

    setDetailBox(true);

  };

  const closeDetailBox = () => {
    setDetailBox(false);
  };

  const handleSettlement = async (selectedPayment) => {
    
    const updatedSoldCattle = await settlementPaymentUpdate(selectedPayment, loginUser.jwtToken);
    console.log(updatedSoldCattle);
    
    if(updatedSoldCattle.clientsPorfolioSettlement){
      cattlePayments = updatedSoldCattle.clientsPorfolioSettlement;
      updatePayments();
    }
    

    // renderPayments();
  };

  return (
    <Box sx={{display: "flex", justifyContent: "center"}}>
      <Box
            sx={{
                display: "flex",
                // flex: 1,
                justifyContent: "center",
                alignItems: {xs: "start", sm: "center"},
                width: "90%",
                height: "100%",
                textAlign: "center",
                overflowY: "scroll",
            }}
        >
          {payments}
          
        <Dialog open={detailBox} onClose={closeDetailBox}>
          <DialogTitle sx={{display: "flex", justifyContent: "center"}}>
            <Typography variant="h4">Detalle Pago</Typography> 
          </DialogTitle> 
          <DialogContent>
            {renderDetail}
          </DialogContent>
          <DialogActions sx={{display: "flex", justifyContent: "center"}}>
              <Button onClick={closeDetailBox} variant="contained" sx={{backgroundColor: Colors.primary, marginRight: "1rem"}}>
                  ATRÁS
              </Button>
              <Button 
                  onClick={() => handleSettlement(currentSettlement)} variant="contained" sx={{backgroundColor: Colors.primary, marginLeft: "1rem"}}>
                  PAGO
              </Button>
          </DialogActions>
        </Dialog>
          
        </Box>
    </Box>
  )
}
